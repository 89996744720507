import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function HomepageBanner({ banners, Category }) {
    console.log(Category);
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])

    return (
        <div className='w-full relative flex h-[550px] sm:h-[700px] flex-col justify-end items-center z-[99]'>
            <div className='flex h-full w-full flex-col sm:hidden'>
                <img className='w-full brightness-95 object-cover h-full object-top' src={banners[0].cover_url} />
            </div>
            <div className=' hidden w-full h-full sm:block'>
                {Category == 'news' ? <img onClick={() => navigate(`/news/${banners[0].id + `-` + 1}`, { state: { Lang: '', array: banners[0] } })} className='w-full brightness-95 object-cover h-full object-center' src={banners[0].gallery[0].url} /> :
                    Category == 'celebrity' ?
                        <div className='h-[700px] w-full bg-[#f2f4f5] overflow-hidden flex flex-row items-center'>
                            <div className='w-[65%] h-full bg-[#f2f4f5]'></div>
                            <div className=' w-[35%] relative h-full '>
                                <div className=' absolute overflow-hidden w-full -top-[100px] h-[200vh] rotate-18 '>
                                    <img className=' absolute  w-full h-full ' src={banners[0].cover_url} />
                                    <img className=' -rotate-18' src={banners[0].cover_url} />
                                </div>
                            </div>
                        </div> : <img onClick={() => navigate(`/${Category}/${banners[0].id + '-' + 1}`)} className='w-full brightness-95 object-cover h-full  object-center' src={banners[0].gallery[0].url} />}
            </div>
            <div className='container absolute sm:bottom-[50px] mx-auto flex flex-col sm:flex-row justify-between mb-5 sm:mb-0'>
                <div onClick={() => Category == 'news' ? navigate(`/news/${banners[0].id + `-` + 1}`, { state: { Lang: '', array: banners[0] } }) : navigate(`/${Category}/${banners[0].id + '-' + 1}`)} className='flex flex-col  gap-y-3 w-full sm:w-[40%] px-4 sm:px-0'>
                    {Category == 'news' ? <h1 className='lg:text-3xl line-clamp-4  sm:line-clamp-none text-xl text-[#0f172a] font-bold'>{banners[0].name || banners[0].title}</h1> :
                        <h1 className='lg:text-7xl text-3xl text-[#0f172a] font-bold'>{banners[0].name || banners[0].title}</h1>}
                    <div className='flex flex-row items-center space-x-3 mb-2'>
                        {banners[0].releaseYear != null ? <span class="text-[#0f172a] font-bold">{banners[0].releaseYear}</span> : null}
                        {banners[0].releaseYear != null && banners[0].type.length > 0 && <span className='text-[#0f172a]'>|</span>}
                        <span class="text-[#0f172a] font-bold"> {banners[0].type[0]}</span>
                        {/* <span className='text-white'>|</span>
                        <span class="text-white">2hr 13 mins</span> */}
                    </div>
                    {Category == 'celebrity' ? <span class="text-[#0f172a] md:block hidden font-bold"> {banners[0].info[0]?.description}</span> : null}

                    <div className='flex flex-row items-center mt-5 gap-x-3'>
                        {Category == 'news' ? null :
                            <div onClick={() => { Category == 'news' ? navigate(`/top-news`) : Category == 'movie' ? navigate(`/movies`) : navigate(`/${Category}`) }} className='sm:px-12 px-3 cursor-pointer rounded-lg sm:py-3 py-2 bg-primary'>
                                <h1 className='text-white font-bold text-base'>Show More</h1>
                            </div>}
                        {/* <div onClick={() => { Category == 'news' ? navigate(`/top-news`) : Category == 'movie' ? navigate(`/movies`) : navigate(`/${Category}`) }}
                            className='sm:px-12 px-3 cursor-pointer rounded-lg sm:py-3 py-1 border-white border-2'>
                            <h1 className='text-white font-bold text-base'>Show More</h1>
                        </div> */}
                    </div>
                </div>
                <div className='sm:w-[58%] w-full h-full px-2'>
                    <h1 className='text-[#0f172a] font-serif sm:text-3xl  mt-5 sm:mt-0 font-semibold mb-2'>Todays Recomendation</h1>
                    {Category == 'news' ?
                        <div className='w-full  h-full flex flex-row overflow-x-auto sm:grid sm:grid-cols-2 gap-5'>
                            {
                                banners.slice(1, 5).map((e, index) =>
                                    <div key={index} className='h-[180px] sm:w-full w-[85%] flex-none'>
                                        <div className='rounded-t-xl w-full h-full sm:h-full flex  flex-col shadow-sm'>
                                            <div className='h-full w-full rounded-xl relative '>
                                                <img onClick={() => navigate(`/news/${e.id + `-` + (index + 1)}`, { state: { Lang: '', array: banners } })} className={`rounded-xl h-full w-full hover:cursor-pointer object-cover object-center `} src={e.cover_url} />
                                                <div onClick={() => navigate(`/news/${e.id + `-` + (index + 1)}`, { state: { Lang: '', array: banners } })} className="rounded-b-xl sm:h-[35%] h-[55%] bg-gradient-to-t from-black via-black to-transparent flex flex-col justify-end absolute bottom-0 w-full z-[9] px-3">
                                                    <h1 onClick={() => navigate(`/news/${e.id + `-` + (index + 1)}`, { state: { Lang: '', array: banners } })} className={`text-white leading-6  text-sm font-bold hover:cursor-pointer hover:text-primary duration-200 line-clamp-2`}>{e.title}</h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        :
                        <div className='w-full h-full flex flex-row overflow-x-auto  gap-5'>
                            {
                                banners.slice(1, 5).map((e, index) =>
                                    // <img
                                    //     onClick={() => navigate(`/${Category}/${e.id + '-' + (index + 1)}`)} key={index}
                                    //     className=' w-[183px] border-primary border-2 sm:h-[274px] h-[200px] cursor-pointer rounded-xl object-cover object-top' src={e.cover_url} />
                                    <div key={index} className='border-2 border-primary flex flex-col flex-none w-[190px] h-[250px] rounded-lg'>
                                        <img className='h-full w-full rounded-lg object-cover object-top' src={e.cover_url}/>
                                    </div>
                                )
                            }
                        </div>}
                </div>
            </div>
        </div>
    )
}

export default HomepageBanner

// {celebritiesData.length > 0 &&
//     <div className='w-full relative flex h-[550px] sm:h-[700px] flex-col justify-end items-center z-[99]'>
//         <div className='flex h-full w-full flex-col sm:hidden'>
//             <img className='w-full brightness-95 object-cover h-full object-top'
//              src={'https://storage.googleapis.com/cinimini-console/celebrities/' + celebritiesData[0].id + '/' + celebritiesData[0].profilePictureUrls} />
//         </div>
//         <div className=' hidden w-full h-full sm:block'>
//             <img  className='w-full brightness-95 object-cover h-full  object-center' src={'https://storage.googleapis.com/cinimini-console/celebrities/' + celebritiesData[0].id + '/' + celebritiesData[0].profilePictureUrls} />
//         </div>
//         <div className='container absolute sm:bottom-[50px] mx-auto flex flex-col sm:flex-row justify-between mb-5 sm:mb-0'>
//             <div className='flex flex-col  gap-y-3 w-full sm:w-[40%] px-4 sm:px-0'>
//                     <h1 className='lg:text-7xl text-3xl text-[#0f172a] font-bold'>{celebritiesData[0].name}</h1>
//                 <div className='flex flex-row items-center space-x-3 mb-2'>
//                      {/* <span class="text-[#0f172a] font-bold">{JSON.parse(celebritiesData)[0].born}</span> */}
//                     {/* {celebritiesData[0].profilePictureUrls.releaseYear != null && celebritiesData[0].profilePictureUrls.type.length > 0 && <span className='text-[#0f172a]'>|</span>} */}
//                     {/* <span class="text-[#0f172a] font-bold"> {celebritiesData[0].profilePictureUrls.type[0]}</span> */}
//                 </div>
//                {/* <span class="text-[#0f172a] md:block hidden font-bold"> {celebritiesData[0].profilePictureUrls.info[0]?.description}</span>  */}

//                 <div className='flex flex-row items-center mt-5 gap-x-3'>
//                         <div className='sm:px-12 px-3 cursor-pointer rounded-lg sm:py-3 py-2 bg-primary'>
//                             <h1 className='text-white font-bold text-base'>Show More</h1>
//                         </div>
//                 </div>
//             </div>
//             <div className='sm:w-[58%] w-full h-full px-2'>
//                 <h1 className='text-[#0f172a] font-serif sm:text-3xl  mt-5 sm:mt-0 font-semibold mb-2'>Todays Recomendation</h1>

//                 {/* <div className='w-full h-full flex flex-row overflow-x-auto  gap-5'>
//                     {
//                         banners.slice(1, 5).map((e, index) =>
//                             // <img
//                             //     onClick={() => navigate(`/${Category}/${e.id + '-' + (index + 1)}`)} key={index}
//                             //     className=' w-[183px] border-primary border-2 sm:h-[274px] h-[200px] cursor-pointer rounded-xl object-cover object-top' src={e.cover_url} />
//                             <div key={index} className='border-2 border-primary flex flex-col flex-none w-[190px] h-[250px] rounded-lg'>
//                                 <img className='h-full w-full rounded-lg object-cover object-top' src={e.cover_url} />
//                             </div>
//                         )
//                     }
//                 </div> */}
//             </div>
//         </div>
//     </div>}