import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BiArrowBack } from 'react-icons/bi'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { useLocation, useNavigate } from 'react-router-dom'
import { GetTheData } from '../components/Api/FetchData'

function OffersPostingPage() {
    const navigate = useNavigate()
    const location = useLocation()
    let Category = location.state.rentalCategory
    const [userName, setUserName] = useState('')
    const [productName, setProductName] = useState('')
    const [mobileNumber, setMobileNumber] = useState('')
    const [description, setDescription] = useState('')
    const [address, setAddress] = useState('')
    const [originalAmount, setOriginalAmount] = useState(0)
    const [dicountAmount, setDicountAmount] = useState(0)
    const [productImages, setProductImages] = useState(null)
    const [productCategory, setProductCategory] = useState(-1)
    function RentalGalleryFunction(e) {
        let temp = [...e.target.files];
        if (temp.length >= 5) {
            setProductImages(temp.slice(0, 5));
        } else
            setProductImages(temp);
    }


    function PostTheRentalFunction() {
        if (userName.length > 0 && productName.length > 0 && mobileNumber.length > 0 && description.length > 0 && address.length > 0 && originalAmount.length > 0 && productImages != null && productCategory != -1) {
            if (mobileNumber.length == 10) {
                try {
                    const data = {
                        "postedByName": userName,
                        "postedByMobileNumber": mobileNumber,
                        "status": 0,
                        "picturesUrl": null,
                        "originalAmount": originalAmount,
                        "address": address,
                        "rentalCategory": productCategory,
                        "discountedPercentage": dicountAmount,
                        "description": description,
                        "name": productName,
                        "creationTime": new Date().getUTCFullYear() + '-' + (new Date().getUTCMonth() + 1) + '-' + new Date().getUTCDate() + '-' + new Date().getUTCHours() + '-' + new Date().getUTCMinutes() + '-' + new Date().getUTCSeconds(),
                        "updatedTime": new Date().getUTCFullYear() + '-' + (new Date().getUTCMonth() + 1) + '-' + new Date().getUTCDate() + '-' + new Date().getUTCHours() + '-' + new Date().getUTCMinutes() + '-' + new Date().getUTCSeconds(),
                        "rentedCount": 0
                    }
                    axios.post(`https://cinimini-apis-3nugbllihq-el.a.run.app/rental`, data)
                        .then((response) => {
                            if (response.status == 200) {
                                console.log(response.data);
                                alert('Your Product will be Visible Once It Will Be Approved')
                                setUserName('')
                                setProductName('')
                                setMobileNumber('')
                                setDescription('')
                                setAddress('')
                                setOriginalAmount('')
                                UploadImageIntoBucket(response.data.insertId)
                            }
                        })
                } catch (error) {

                }
            } else {
                alert('Mobile number Invalid')
            }

        } else {
            alert('Field are missing')
        }
    }

    function UploadImageIntoBucket(_id) {
        for (let i = 0; i < productImages.length; i++) {
            try {
                let formdata = new FormData()
                formdata.append("file", productImages[i])
                axios.post(`https://cinimini-apis-3nugbllihq-el.a.run.app/upload/rentals/${_id}`, formdata,
                    {
                        headers:
                        {
                            "Content-Type": 'multipart/form-data'
                        }
                    }).then((responce) => {
                        if (responce.status == 200) {
                            console.log(responce.data);
                        }
                    }).catch((error) => {
                        console.log(error.message);
                    })
            } catch (error) {
                console.log(error.message);
            }
        }
        PatchTheImages(_id)
    }
    function PatchTheImages(_id) {
        let temp = []
        for (let i = 0; i < productImages.length; i++) {
            temp.push(productImages[i].name)
        }
        try {
            const data = {
                "picturesUrl": JSON.stringify(temp)
            }
            axios.patch(`https://cinimini-apis-3nugbllihq-el.a.run.app/rental/${_id}`, data)
                .then((responce) => {
                    if (responce.status == 200) {
                        console.log(responce.data);
                        setProductImages(null)
                    }
                }).catch((error) => {
                    console.log(error.message);
                })
        } catch (error) {
            console.log(error.message);
        }
    }
    function DeleteBlogGalleryItem(index) {
        let temp = [...productImages]
        temp.splice(index, 1);
        setProductImages(temp)
        if (temp.length == 0) {
            setProductImages(null)
        }
    }


    return (
        <div>
            <div className='w-full z-[9]  bg-[#0e162b]'>
                <div className="h-10 bg-gray-900 rounded-xl m-2 flex flex-row ml-10 items-center px-3 text-gray-200 text-sm font-bold cursor-pointer z-10 fixed top-[100px]"
                    onClick={() => navigate(-1)}>
                    <BiArrowBack className="w-6 h-6 " /><span>Back</span>
                </div>
                <div className='max-w-4xl mx-auto flex flex-col gap-y-5 pb-20 px-5 pt-10'>
                    <div className='flex flex-col items-center w-full mt-20'>
                        {/* <div className='gap-y-2 flex w-full flex-col'>
                            <h1 className='text-white text-sm font-semibold mb-2'>Blog Banner *</h1>
                            <div className='w-full self-end relative h-[200px] lg:h-[440px]'>
                                <div  className='absolute w-6 h-6 -top-[10px] -right-[10px] bg-white rounded-full flex flex-col items-center justify-center '>
                                    <RiDeleteBin6Line className='w-4 h-4 cursor-pointer' />
                                </div>
                                {BlogBanner != null ? <img className='w-full h-full object-fill' src={URL.createObjectURL(BlogBanner)} /> : <div className='w-full h-full bg-white/75' />}
                            </div>
                            <div className='w-[110px] mt-5 flex  flex-col items-center justify-center rounded-sm h-[34px]  bg-white hov hover:bg-primary text-white duration-300 cursor-pointer' >
                                <h5 className='text-[13px] cursor-pointer text-black font-bold'>Upload Photo</h5>
                                <input onChange={(e) => setBlogBanner(e.target.files[0])} className='absolute w-[105px] bg-red-500 cursor-pointer opacity-0' type='file' />
                            </div>
                        </div> */}
                    </div>
                    <div className='flex flex-col  gap-5 w-full'>
                        <div className="relative w-full">
                            <h1 className='text-white text-sm font-semibold mb-2'>User Name *</h1>
                            <input value={userName} onChange={(e) => setUserName(e.target.value)} type="search" id="default-search" className="block w-full p-3 ps-4 outline-none font-semibold text-base text-gray-900 rounded-lg bg-gray-50 " placeholder="User Name" />
                        </div>
                        <div className="relative w-full">
                            <h1 className='text-white text-sm font-semibold mb-2'>Product Name *</h1>
                            <input value={productName} onChange={(e) => setProductName(e.target.value)} type="search" id="default-search" className="block w-full p-3 ps-4 outline-none font-semibold text-base text-gray-900 rounded-lg bg-gray-50 " placeholder="Product Name" />
                        </div>
                        <div className="relative w-full">
                            <h1 className='text-white text-sm font-semibold mb-2'>Mobile Number *</h1>
                            <input onChange={(e) => setMobileNumber(e.target.value)} type='number' className="block w-full p-3 ps-4 outline-none font-semibold text-base text-gray-900 rounded-lg bg-gray-50 " placeholder="Mobile Number" />
                        </div>
                        <div className="relative w-full">
                            <h1 className='text-white text-sm font-semibold mb-2'>Description *</h1>
                            <textarea value={description} onChange={(e) => setDescription(e.target.value)} type="search" id="default-search" className="block w-full p-3 ps-4 outline-none font-semibold text-base text-gray-900 rounded-lg bg-gray-50 " placeholder="Description" />
                        </div>
                        <div className="relative w-full">
                            <h1 className='text-white text-sm font-semibold mb-2'>Product Category *</h1>
                            <select onChange={(e) => setProductCategory(e.target.value)} className="block w-full rounded-md py-2 h-full outline-none  font-semibold text-base ">
                                <option value={-1}>Select</option>
                                {Category != undefined && Category.map((e, index) =>
                                    <option key={index} className='font-semibold' value={e.id}>{e.name}</option>
                                )}
                            </select>
                        </div>
                        <div className="relative w-full">
                            <h1 className='text-white text-sm font-semibold mb-2'>Address *</h1>
                            <textarea value={address} onChange={(e) => setAddress(e.target.value)} type="search" id="default-search" className="block w-full p-3 ps-4 outline-none font-semibold text-base text-gray-900 rounded-lg bg-gray-50 " placeholder="Address" />
                        </div>
                        <div className="relative w-full">
                            <h1 className='text-white text-sm font-semibold mb-2'>Amount Per Month *</h1>
                            <input value={originalAmount} onChange={(e) => setOriginalAmount(e.target.value)} type="number" id="default-search" className="block w-full p-3 ps-4 outline-none font-semibold text-base text-gray-900 rounded-lg bg-gray-50 " placeholder="Amount" />
                        </div>
                        <div className="relative w-full">
                            <h1 className='text-white text-sm font-semibold mb-2'>Discount *</h1>
                            <input value={dicountAmount} onChange={(e) => setDicountAmount(e.target.value)} type="number" id="default-search" className="block w-full p-3 ps-4 outline-none font-semibold text-base text-gray-900 rounded-lg bg-gray-50 " placeholder="Amount" />
                        </div>
                        <h1 className='text-white text-sm font-semibold '>Product Images *</h1>
                        <div className='gap-5 w-full grid grid-cols-1 lg:grid-cols-3 '>
                            {productImages != null ? productImages.map((e, index) =>
                                <div className='w-full self-end relative '>
                                    <div onClick={() => DeleteBlogGalleryItem(index)} className='absolute w-6 h-6 -top-[10px] -right-[10px] bg-white rounded-full flex flex-col items-center justify-center '>
                                        <RiDeleteBin6Line className='w-4 h-4 cursor-pointer' />
                                    </div>
                                    <img key={index} className='w-full h-[160px] object-fill' src={URL.createObjectURL(e)} />
                                </div>
                            ) : <div className='w-full bg-white/75 h-[160px]' />}
                        </div>
                        <div className='w-[110px] mt-5 flex  flex-col items-center justify-center rounded-sm h-[34px] cursor-pointer  bg-white hov hover:bg-primary text-white duration-300 ' >
                            <h5 className='text-[13px] cursor-pointer text-black font-bold'>Upload Photo</h5>
                            <input multiple={true} onChange={(e) => RentalGalleryFunction(e)} className='absolute w-[105px] bg-red-500 cursor-pointer opacity-0' type='file' />
                        </div>
                    </div>
                    <div onClick={() => PostTheRentalFunction()} className=" mt-5 ">
                        <button className="text-white bg-primary hover:bg-primary/75 px-10 rounded-full py-3 font-bold">Post The Rental</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OffersPostingPage