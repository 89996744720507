import { CelebrityTemplate } from "../view/templates";

import { useIsMobileViewContext } from '../context/isMobileView';
import { useEffect } from "react";

function CelebrityPage() {
    const { isMobileView } = useIsMobileViewContext();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'auto' });
    }, []);
    return (
        <main className={`flex-grow min-h-screen bg-[#131721] ${isMobileView ? "pt-12" : ""}`}>
            <CelebrityTemplate />
        </main>
    );
}

export default CelebrityPage;
