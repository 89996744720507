import { MovieDetailsTemplate } from "../view/templates";

function MovieDetailsPage() {
  return (
    <main className="flex-grow min-h-screen bg-gray-900">
      <MovieDetailsTemplate />
    </main>
  );
}

export default MovieDetailsPage;
