import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaSearch } from 'react-icons/fa';
import { InstantSearch, SearchBox, Index, Hits } from 'react-instantsearch-dom';

import { ImageAtom } from "../view/atoms";

import logo from "../assets/icons/logo.svg";

import { algoliaSearchClient } from "../algolia";

import { useAuthContext } from "../context/auth";
import { useIsMobileViewContext } from "../context/isMobileView";

import { useAppSelector } from "../redux";

const dummyImage = require('../assets/images/dummyUser.png')

const textStyle = "text-gray-300 text-xs mx-2";

const navigationList = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "News",
    path: "/top-news",
  },
  {
    title: "Movies",
    path: "/movies",
  },
  {
    title: "Stars",
    path: "/celebrity",
  },
  
  {
    title: "Rental",
    path: "/Offers",
  },
  {
    title: "Blog",
    path: "/JobPage",
  },

];
// {
//   title: "Top Review",
//   path: "/top-review",
// },
// {
//   title: "Products",
//   path: "/product",
// },
// {
//   title: "Brands",
//   path: "/brand",
// },
// {
//   title: "Shows",
//   path: "/shows",
// },
function Header({ HeaderBackground, HeaderText }) {
  const location = useLocation();

  const navigate = useNavigate();

  const { isMobileView } = useIsMobileViewContext();

  const user = useAppSelector((state) => state.user.profile);

  const { isUserLoggedIn } = useAuthContext();

  const handleSelected = (e, hit) => {
    console.log(e, hit);
  }

  const Hit = ({ hit }) => {
    return (
      <div className="flex flex-row items-center mx-2 my-3 rounded-lg p-3 cursor-pointer"
        onClick={(e) => handleSelected(e, hit)}>
        <img className="w-12 h-12 rounded-full" src={hit.cover_url} alt="" />
        <div className="flex flex-col justify-center ml-2">
          <span className="text-sm text-gray-200">{hit.name}</span>
          <span className="text-xs text-gray-400">{hit.type?.toString()}</span>
        </div>
      </div>
    );
  }



  return (
    <div className={`flex justify-between bg-[#0f172a] items-center sticky top-0 w-full z-[9999]  `}>
      <Link to="/">
        <ImageAtom imageStyle="w-20 m-2" img={logo} />
      </Link>
      <div className="flex items-center">
        {!isMobileView ?
          <div className="md:flex hidden flex-row">
            {navigationList.map((item) => (
              <Link
                key={item.path}
                to={item.path}
                className={`mx-5 cursor-pointer ${(location.pathname === "/" && location.pathname === item.path) ||
                  location.pathname === item.path ||
                  (item.path === "/"
                    && location.pathname.includes("Home")
                  )
                  ||
                  (location.pathname !== "/" && item.path !== "/" && (location.pathname.includes(item.path)))
                  ? "border-primary border-b-2 text-primary" : `${HeaderText}`}`}
              >
                {item.title}
              </Link>
            ))}
            {!isMobileView ?
              <div className="lg:flex hidden items-center sm:ml-4 sm:mr-6">
                <input
                  type="text"
                  placeholder="Search"
                  onClick={() => navigate("/search")}
                  className={`focus:border-primary  border-gray-300 rounded-sm ps-2 bg-white outline-none text-gray-300`} />
                <FaSearch className="hover:text-primary text-gray-400 cursor-pointer m-1" />
              </div> :
              <></>}
          </div>
          : <></>
        }

        {/* {location.pathname !== "/profile" && <>
          {isUserLoggedIn ? (
            <Link className={textStyle} to="/profile">
              {user.cover_url
                ? <img className="w-8 h-8 sm:mr-3 object-cover rounded-full" src={user.cover_url} alt="" />
                : <img className="w-8 h-8 sm:mr-3 object-cover rounded-full" src={dummyImage} alt="" />
              }
            </Link>
          ) : (
            <Link to="/login" className={textStyle}>
              <img className="w-8 h-8 sm:mr-3 object-cover rounded-full" src={dummyImage} alt="" />
            </Link>
          )}
        </>} */}
                {location.pathname !== "/profile" && <>
          {isUserLoggedIn ? (
            <Link className={textStyle} to="/profile">
             <img className="w-8 h-8 sm:mr-3 object-cover rounded-full" src={dummyImage} alt="" />
              
            </Link>
          ) : (
            <Link to="/login" className={textStyle}>
              <img className="w-8 h-8 sm:mr-3 object-cover rounded-full" src={dummyImage} alt="" />
            </Link>
          )}
        </>}
      </div>
    </div>
  );
}

export default Header;
  {/* <InstantSearch searchClient={algoliaSearchClient} indexName="celebrity">
                  <div className="w-full flex flex-col items-center bg-gray-700">
                    <SearchBox
                      searchAsYouType={true}
                      showReset={true}
                      submit={<span></span>}
                      reset={<span></span>}
                      showLoadingIndicator={true}
                      autoFocus={false}
                      translations={{
                        placeholder: 'Search',
                      }}
                      className="outline-none rounded-md p-3 text-gray-700 placeholder-gray-700"
                      onFocus={(_) => setShowSuggestions(true)}
                    />
                  </div>

                  {showSuggestions ?
                    <div className="bg-gray-800 w-full p-2">
                      <Index indexName="movie">
                        <span className="text-primary">Movies</span>
                        <Hits hitComponent={Hit} />
                      </Index>

                      <Index indexName="celebrity">
                        <span className="text-primary">Stars</span>
                        <Hits hitComponent={Hit} />
                      </Index>

                      <Index indexName="brand">
                        <span className="text-primary">Brands</span>
                        <Hits hitComponent={Hit} />
                      </Index>

                      <Index indexName="product">
                        <span className="text-primary">Products</span>
                        <Hits hitComponent={Hit} />
                      </Index>

                      <Index indexName="shows">
                        <span className="text-primary">Shows</span>
                        <Hits hitComponent={Hit} />
                      </Index>

                      <Index indexName="news">
                        <span className="text-primary">News</span>
                        <Hits hitComponent={Hit} />
                      </Index>
                    </div>
                    : <></>
                  }
                </InstantSearch> */}