import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { collection, db, getDocs } from "../../firebase";

const initialState = {
  advertisements: [],
  isLoading: false,
  isError: false,
  error: "",
};

export const loadAdvertisement = createAsyncThunk(
  "advertisement/loadAdvertisement",
  async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const querySnapShot = await getDocs(collection(db, "advertisements"));

        let list = [];
        querySnapShot.forEach((doc) => {
          list.push({ ...doc.data() });
        });
        resolve(list);
      } catch (e) {
        reject(e.message);
      }
    });
  }
);

export const advertisementSlice = createSlice({
  name: "advertisement",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(loadAdvertisement.pending, (state, _) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(loadAdvertisement.fulfilled, (state, { payload }) => {
      state.advertisements = payload;
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(loadAdvertisement.rejected, (state, { error }) => {
      state.isLoading = false;
      state.isError = false;
      state.error = error.message;
    });
  },
});

export const advertisementsReducer = advertisementSlice.reducer;
