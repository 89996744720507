import { Link } from "react-router-dom";

import { useAppSelector } from "../redux";

import { useAuthContext } from "../context/auth";

function MyPointsPage() {
  const { isUserLoggedIn } = useAuthContext();

  const user = useAppSelector(state => state.user.profile);

  return (
    <main className="min-h-screen bg-gray-900">
      {isUserLoggedIn
        ? <div className="flex flex-col items-center p-2">
          <div className="p-2 bg-gray-800 rounded-lg mx-4 mt-2 mb-2 w-full flex flex-col items-center">
            <span className="text-sm text-gray-500 font-semibold">Total stars gained</span>
            <span className="my-2 text-3xl text-white font-semibold">{(user.totalStarsGained || 0)}</span>
          </div>
          <div className="p-2 bg-gray-800 rounded-lg mx-4 mt-2 mb-2 w-full flex flex-col items-center">
            <span className="text-sm text-gray-500 font-semibold">Total stars given</span>
            <span className="my-2 text-3xl text-white font-semibold">{(user.totalRatings || 0)}</span>
          </div>
          <div className="p-2 bg-gray-800 rounded-lg mx-4 mt-2 mb-2 w-full flex flex-col items-center">
            <span className="text-sm text-gray-500 font-semibold">Total reviews given</span>
            <span className="my-2 text-3xl text-white font-semibold">{(user.totalReviews || 0)}</span>
          </div>
          <div className="p-2 bg-gray-800 rounded-lg mx-4 mt-2 mb-2 w-full flex flex-col items-center">
            <span className="text-sm text-gray-500 font-semibold">Total stars earned from referral</span>
            <span className="my-2 text-3xl text-white font-semibold">{(user.totalReferalStars || 0)}</span>
          </div>
          {/* <div className="flex items-center">
            <div className="flex flex-col items-center px-2 py-1 mx-1">
              <div className="bg-gray-800 rounded-full w-10 h-10 flex items-center justify-center">
                <span className="text-lg text-gray-100 font-semibold">{user.totalRatings || 0}</span>
              </div>
              <span className="text-xs text-gray-400">Ratings</span>
            </div>
            <div className="flex flex-col items-center px-2 py-1 mx-1">
              <div className="bg-gray-800 rounded-full w-10 h-10 flex items-center justify-center">
                <span className="text-lg text-gray-100 font-semibold">{user.totalReviews || 0}</span>
              </div>
              <span className="text-xs text-gray-400">Reviews</span>
            </div>
          </div> */}

          <img className="mt-6" src="https://img.icons8.com/external-ddara-lineal-color-ddara/64/000000/external-target-digital-marketing-ddara-lineal-color-ddara.png" alt="" />
          <span className="text-lg text-primary my-2">Redeem Offers - Coming Soon</span>
        </div>
        : <div className="flex flex-col items-center py-10">
          <span className="text-gray-200">Login to view this page</span>
          <Link to="/login" className="bg-primary text-white font-semibold text-sm p-1 rounded-md my-2">Login now</Link>
        </div>}
    </main>
  )
}

export default MyPointsPage