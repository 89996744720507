import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { orderBy } from "firebase/firestore";

import {
  db,
  collection,
  doc,
  setDoc,
  updateDoc,
  getDocs,
  query,
  startAfter,
  limit,
  serverTimestamp,
} from "../../firebase";

const initialState = {
  news: [],
  isEndReached: false,
  isLoading: false,
  isError: false,
  error: "",
};

var lastDoc = null;

export const loadNews = createAsyncThunk("news/loadNews", async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const runQuery = query(
        collection(db, "news"),
        orderBy("createdAt", "desc"),
        limit(50)
      );
      const querySnapshot = await getDocs(runQuery);

      let list = [];

      querySnapshot.forEach((doc) => {
        list.push({
          ...doc.data(),
        });
      });
      lastDoc =
        list.length > 0
          ? querySnapshot.docs[querySnapshot.docs.length - 1]
          : null;
      resolve(list);
    } catch (e) {
      reject(e.message);
    }
  });
});

export const loadMoreNews = createAsyncThunk("news/loadMoreNews", async () => {
  return new Promise(async (resolve, reject) => {
    try {
      let runQuery;
      if (lastDoc === null) {
        runQuery = query(
          collection(db, "news"),
          orderBy("createdAt", "desc"),
          limit(10)
        );
      } else {
        runQuery = query(
          collection(db, "news"),
          orderBy("createdAt", "desc"),
          startAfter(lastDoc),
          limit(10)
        );
      }

      const querySnapshot = await getDocs(runQuery);

      let list = [];

      querySnapshot.forEach((doc) => {
        list.push({
          ...doc.data(),
        });
      });

      lastDoc =
        list.length > 0
          ? querySnapshot.docs[querySnapshot.docs.length - 1]
          : null;
      resolve(list);
    } catch (e) {
      reject(e.message);
    }
  });
});

export const addNewsRating = createAsyncThunk(
  "news/addNewsRating",
  async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        await setDoc(doc(db, "news", data.newsId, "rating", data.userId), {
          createdAt: serverTimestamp(),
          id: data.userId,
          lastUpdatedAt: serverTimestamp(),
          rating: data.newUserRating,
        });

        resolve({ id: data.newsId, rating: data.newRating });
      } catch (e) {
        reject(e.message);
      }
    });
  }
);

export const updateNewsRating = createAsyncThunk(
  "news/updateNewsRating",
  async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        await updateDoc(doc(db, "news", data.newsId, "rating", data.userId), {
          lastUpdatedAt: serverTimestamp(),
          rating: data.newUserRating,
        });

        const ratingDifference =
          Math.max(data.newUserRating, data.oldUserRating) -
          Math.min(data.newUserRating, data.oldUserRating);

        if (ratingDifference > 0) {
          if (data.newUserRating > data.oldUserRating) {
            resolve({
              id: data.newsId,
              rating: data.oldRating + ratingDifference,
            });
          } else {
            resolve({
              id: data.newsId,
              rating: data.oldRating - Math.abs(ratingDifference),
            });
          }
        } else {
          resolve({ id: data.newsId, rating: data.newRating });
        }
      } catch (e) {
        reject(e.message);
      }
    });
  }
);

export const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    addNews: (state, { payload }) => {
      state.news = [...state.news, payload];
    },
    updateNewsReviewCount: (state, { payload }) => {
      let list = Object.assign([], state.news);
      let index = list.findIndex((item) => item.id === payload.id);
      if (index >= 0) {
        let item = {
          ...list[index],
          reviewCount: list[index].reviewCount || 0 + 1,
        };
        list[index] = item;
      }
      state.news = [...list];
    },
    reduceNewsReviewCount: (state, { payload }) => {
      let list = Object.assign([], state.news);
      let index = list.findIndex((item) => item.id === payload.id);
      if (index >= 0) {
        let item = {
          ...list[index],
          reviewCount:
            (list[index].reviewCount || 0) > 0
              ? list[index].reviewCount - 1
              : 0,
        };
        list[index] = item;
      }
      state.news = [...list];
    },
  },
  extraReducers: (builder) => {
    //loadNews
    builder.addCase(loadNews.pending, (state, _) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(loadNews.fulfilled, (state, { payload }) => {
      state.news = payload;
      state.isEndReached = payload.length < 10;
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(loadNews.rejected, (state, { error }) => {
      state.isLoading = false;
      state.isError = false;
      state.error = error.message;
    });
    //loadMoreNews
    builder.addCase(loadMoreNews.pending, (state, _) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(loadMoreNews.fulfilled, (state, { payload }) => {
      state.news = payload;
      state.isEndReached = payload.length < 10;
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(loadMoreNews.rejected, (state, { error }) => {
      state.isLoading = false;
      state.isError = false;
      state.error = error.message;
    });
    //addNewsRating
    builder.addCase(addNewsRating.pending, (state, _) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(addNewsRating.fulfilled, (state, { payload }) => {
      let news = Object.assign([], state.news);
      let index = news.findIndex((item) => item.id === payload.id);
      if (index >= 0) {
        let item = { ...news[index], rating: payload.rating };
        news[index] = item;
      }
      news = news.sort((a, b) => {
        if (a.rating === b.rating) return 0;
        else if (a.rating < b.rating) return 1;
        else return -1;
      });
      state.news = [...news];
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(addNewsRating.rejected, (state, { error }) => {
      state.isLoading = false;
      state.isError = true;
      state.error = error.message;
    });
    //updateNewsRating
    builder.addCase(updateNewsRating.pending, (state, _) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(updateNewsRating.fulfilled, (state, { payload }) => {
      let news = Object.assign([], state.news);
      let index = news.findIndex((item) => item.id === payload.id);
      if (index >= 0) {
        let item = { ...news[index], rating: payload.rating };
        news[index] = item;
      }
      news = news.sort((a, b) => {
        return(new Date(b.createdAt).getTime()-new Date(a.createdAt).getTime())
        // if (a.rating === b.rating) return 0;
        // else if (a.rating < b.rating) return 1;
        // else return -1;
      });
      state.news = [...news];
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(updateNewsRating.rejected, (state, { error }) => {
      state.isLoading = false;
      state.isError = true;
      state.error = error.message;
    });
  },
});

export const { addNews, updateNewsReviewCount, reduceNewsReviewCount } =
  newsSlice.actions;

export const newsReducer = newsSlice.reducer;
