import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  db,
  doc,
  getDoc,
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  ref,
  where,
  setDoc,
  updateDoc,
  getDownloadURL,
  auth,
  storage,
  uploadBytes,
} from "../../firebase";

const initialState = {
  profile: {},
  reviews: [],
  isLoading: false,
  isError: false,
  error: "",
};

export const loadUser = createAsyncThunk("user/loadUser", async (uid) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (uid) {
        const docRef = doc(db, "users", uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          let user = docSnap.data();

          if (!user.isAdmin) {
            let fileName = `user/${uid}.jpg`;
            const storageRef = ref(storage, fileName);
            getDownloadURL(storageRef).then(async (downloadUrl) => {
              user.cover_url = downloadUrl;
              resolve(user);
            });
          } else {
            await auth.signOut();
            reject("Forbidden to access this site.");
          }
        } else {
          reject("Unable to fetch your account details");
        }
      } else {
        reject("Login again to resolve error");
      }
    } catch (e) {
      reject(e.message);
    }
  });
});

export const createUser = createAsyncThunk("user/createUser", async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (data.image) {
        let fileName = `user/${data.uid}.jpg`;
        const storageRef = ref(storage, fileName);

        uploadBytes(storageRef, data.image).then(async (_) => {
          const newUser = {
            id: data.uid,
            name: data.name,
            cover_url: fileName,
            email: data.email,
            phone: data.phone,
            phone_ext: data.phone_ext,
            isAdmin: false,
          };

          await setDoc(doc(db, "users", data.uid), newUser);

          resolve(newUser);
        });
      } else {
        const newUser = {
          id: data.uid,
          name: data.name,
          cover_url: "",
          email: data.email,
          phone: data.phone,
          phone_ext: data.phone_ext,
          isAdmin: false,
        };

        await setDoc(doc(db, "users", data.uid), newUser);

        resolve(newUser);
      }
    } catch (e) {
      reject(e.message);
    }
  });
});

export const uploadImageWithUser = createAsyncThunk(
  "user/uploadImageWithUser",
  async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        let fileName = `user/${data.userId}.jpg`;
        const storageRef = ref(storage, fileName);

        uploadBytes(storageRef, data.image).then(async (_) => {
          await updateDoc(doc(db, "users", data.userId), {
            name: data.name,
            email: data.email,
            cover_url: fileName,
          });

          let downloadUrl = await getDownloadURL(storageRef);
          resolve({ ...data, cover_url: downloadUrl });
        });
      } catch (e) {
        reject(e.message);
      }
    });
  }
);

export const uploadUser = createAsyncThunk("user/uploadUser", async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      await updateDoc(doc(db, "users", data.userId), {
        name: data.name,
        email: data.email,
      });
      resolve(data);
    } catch (e) {
      reject(e.message);
    }
  });
});

export const loadUserReviews = createAsyncThunk(
  "user/loadUserReviews",
  async (uid) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (uid) {
          const runCelebrityQuery = query(
            collection(db, "celebrity_review"),
            orderBy("rating", "desc"),
            where("userId", "==", uid),
            limit(10)
          );

          const queryCelebritySnapshot = await getDocs(runCelebrityQuery);
          let celebrity = [];

          queryCelebritySnapshot.forEach((doc) => {
            celebrity.push({
              ...doc.data(),
            });
          });

          const runMovieQuery = query(
            collection(db, "movie_review"),
            orderBy("rating", "desc"),
            where("userId", "==", uid),
            limit(10)
          );

          const queryMovieSnapshot = await getDocs(runMovieQuery);
          let movie = [];

          queryMovieSnapshot.forEach((doc) => {
            movie.push({
              ...doc.data(),
            });
          });

          const runNewsQuery = query(
            collection(db, "news_review"),
            orderBy("rating", "desc"),
            where("userId", "==", uid),
            limit(10)
          );

          const queryNewsSnapshot = await getDocs(runNewsQuery);
          let news = [];

          queryNewsSnapshot.forEach((doc) => {
            news.push({
              ...doc.data(),
            });
          });

          const runProductQuery = query(
            collection(db, "product_review"),
            orderBy("rating", "desc"),
            where("userId", "==", uid),
            limit(10)
          );

          const queryProductSnapshot = await getDocs(runProductQuery);
          let product = [];

          queryProductSnapshot.forEach((doc) => {
            product.push({
              ...doc.data(),
            });
          });

          const runBrandQuery = query(
            collection(db, "brand_review"),
            orderBy("rating", "desc"),
            where("userId", "==", uid),
            limit(10)
          );

          const queryBrandSnapshot = await getDocs(runBrandQuery);
          let brand = [];

          queryBrandSnapshot.forEach((doc) => {
            brand.push({
              ...doc.data(),
            });
          });

          const runShowQuery = query(
            collection(db, "show_review"),
            orderBy("rating", "desc"),
            where("userId", "==", uid),
            limit(10)
          );

          const queryShowSnapshot = await getDocs(runShowQuery);
          let show = [];

          queryShowSnapshot.forEach((doc) => {
            show.push({
              ...doc.data(),
            });
          });

          let fileName = `user/${uid}.jpg`;
          const storageRef = ref(storage, fileName);
          let array = [
            ...celebrity,
            ...movie,
            ...news,
            ...product,
            ...brand,
            ...show,
          ];

          let downloadUrl = await getDownloadURL(storageRef);
          array = array.map((item) => ({ ...item, userCoverUrl: downloadUrl }));
          resolve(array);
        } else {
          reject("Login again to resolve error");
        }
      } catch (e) {
        reject(e.message);
      }
    });
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.profile = payload;
    },
    updateUserRatingCount: (state, { payload }) => {
      state.profile["totalRatings"] =
        (state.profile["totalRatings"] || 0) + payload;
    },
    addUserReview: (state, { payload }) => {
      state.reviews = [...state.reviews, payload];
      state.profile["totalReviews"] = (state.profile["totalReviews"] || 0) + 1;
    },
    updateUserReview: (state, { payload }) => {
      let reviews = Object.assign([], state.reviews);
      let index = reviews.findIndex((item) => item.id === payload.id);
      if (index > 0) {
        console.log(index);
        let review = Object.assign({}, payload);
        reviews[index] = review;
      }
      state.reviews = [...reviews];
    },
    removeUserReview: (state, { payload }) => {
      state.reviews = [...state.reviews.filter((item) => item.id !== payload)];
      state.profile["totalReviews"] =
        (state.profile["totalReviews"] || 0) > 0
          ? state.profile["totalReviews"] - 1
          : 0;
    },
  },
  extraReducers: (builder) => {
    //loadUser
    builder.addCase(loadUser.pending, (state, _) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(loadUser.fulfilled, (state, { payload }) => {
      state.profile = payload;
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(loadUser.rejected, (state, { error }) => {
      state.isLoading = false;
      state.isError = true;
      state.error = error.message;
    });
    //createUser
    builder.addCase(createUser.pending, (state, _) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(createUser.fulfilled, (state, { payload }) => {
      state.profile = payload;
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(createUser.rejected, (state, { error }) => {
      state.isLoading = false;
      state.isError = true;
      state.error = error.message;
    });
    //updateUser
    builder.addCase(uploadUser.pending, (state, _) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(uploadUser.fulfilled, (state, { payload }) => {
      state.profile = {
        ...state.profile,
        name: payload.name,
        email: payload.email,
      };
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(uploadUser.rejected, (state, { error }) => {
      state.isLoading = false;
      state.isError = true;
      state.error = error.message;
    });
    //updateImageUser
    builder.addCase(uploadImageWithUser.pending, (state, _) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(uploadImageWithUser.fulfilled, (state, { payload }) => {
      state.profile = {
        ...state.profile,
        name: payload.name,
        email: payload.email,
        cover_url: payload.cover_url,
      };
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(uploadImageWithUser.rejected, (state, { error }) => {
      state.isLoading = false;
      state.isError = true;
      state.error = error.message;
    });
    //loadUserReviews
    builder.addCase(loadUserReviews.pending, (state, _) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(loadUserReviews.fulfilled, (state, { payload }) => {
      state.reviews = payload;
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(loadUserReviews.rejected, (state, { error }) => {
      state.isLoading = false;
      state.isError = true;
      state.error = error.message;
    });
  },
});

export const {
  setUser,
  updateUserRatingCount,
  addUserReview,
  updateUserReview,
  removeUserReview,
} = userSlice.actions;

export const userReducer = userSlice.reducer;
