import { useParams } from "react-router-dom";

import ReviewList from '../../components/ReviewList';

function ReviewTemplate() {
  const params = useParams();
  const id = params.id;
  const source = params.type;

  return (<ReviewList key="review_list" source={source} sourceId={id} />);
}

export { ReviewTemplate }