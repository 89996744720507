import { useRef } from "react";

import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

function MySlider({ isScrollActionEnabled, children, isMobileView }) {
  const scrollDivRef = useRef(null);
  const previousCards = () => {
    if (scrollDivRef) {
      scrollDivRef.current.scrollLeft = scrollDivRef.current.scrollLeft - scrollDivRef.current.offsetWidth;
    }
  }

  const nextCards = () => {
    if (scrollDivRef) {
      scrollDivRef.current.scrollLeft = scrollDivRef.current.scrollLeft + scrollDivRef.current.offsetWidth;
    }
  }

  return (
    <div className="flex px-4 mb-4 relative">
      {!isMobileView && isScrollActionEnabled
        ? <div className="cursor-pointer flex items-center h-full z-20 my-auto mr-2">
          <IoIosArrowBack onClick={() => previousCards()} className="text-2xl text-gray-100 font-bold rounded-full bg-gray-800 hover:scale-110 transistion duration-500" />
        </div>
        : <></>
      }
      <div ref={scrollDivRef} className="flex items-center overflow-x-auto overflow-y-hidden scroll-smooth">
        {children}
      </div>
      {!isMobileView && isScrollActionEnabled
        ? <div className="cursor-pointer flex items-center h-full z-20 my-auto ml-2">
          <IoIosArrowForward onClick={() => nextCards()} className="text-2xl text-gray-100 font-bold rounded-full bg-gray-800 hover:scale-110 transistion duration-500" />
        </div>
        : <></>}
    </div>
  );
}

export default MySlider;