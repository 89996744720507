import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  db,
  collection,
  doc,
  getDocs,
  setDoc,
  updateDoc,
  query,
  startAfter,
  orderBy,
  limit,
  serverTimestamp,
} from "../../firebase";

const initialState = {
  products: [],
  isEndReached: false,
  isLoading: false,
  isError: false,
  error: "",
};

var lastDoc = null;
var docsCount = 0;

export const loadProduct = createAsyncThunk("product/loadProduct", async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const runQuery = query(
        collection(db, "product"),
        orderBy("rating", "desc"),
        limit(50)
      );
      const querySnapshot = await getDocs(runQuery);

      let list = [];
      var index = 1;

      querySnapshot.forEach((doc) => {
        list.push({
          rank: index,
          ...doc.data(),
        });
        index = index + 1;
      });
      lastDoc =
        list.length > 0
          ? querySnapshot.docs[querySnapshot.docs.length - 1]
          : null;
      docsCount = docsCount + querySnapshot.docs.length;
      resolve(list);
    } catch (e) {
      reject(e.message);
    }
  });
});

export const loadMoreProduct = createAsyncThunk(
  "product/loadMoreProduct",
  async () => {
    return new Promise(async (resolve, reject) => {
      try {
        let runQuery;
        if (lastDoc === null) {
          runQuery = query(
            collection(db, "product"),
            orderBy("rating", "desc"),
            limit(10)
          );
        } else {
          runQuery = query(
            collection(db, "product"),
            orderBy("rating", "desc"),
            startAfter(lastDoc),
            limit(10)
          );
        }

        const querySnapshot = await getDocs(runQuery);

        let list = [];
        var index = docsCount;

        querySnapshot.forEach((doc) => {
          list.push({
            rank: index,
            ...doc.data(),
          });
          index = index + 1;
        });

        lastDoc =
          list.length > 0
            ? querySnapshot.docs[querySnapshot.docs.length - 1]
            : null;
        docsCount = docsCount + querySnapshot.docs.length;
        resolve(list);
      } catch (e) {
        reject(e.message);
      }
    });
  }
);

export const addProductRating = createAsyncThunk(
  "product/addProductRating",
  async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        await setDoc(
          doc(db, "product", data.productId, "rating", data.userId),
          {
            createdAt: serverTimestamp(),
            id: data.userId,
            lastUpdatedAt: serverTimestamp(),
            rating: data.newUserRating,
          }
        );

        resolve({ id: data.productId, rating: data.newRating });
      } catch (e) {
        reject(e.message);
      }
    });
  }
);

export const updateProductRating = createAsyncThunk(
  "product/updateProductRating",
  async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        await updateDoc(
          doc(db, "product", data.productId, "rating", data.userId),
          {
            lastUpdatedAt: serverTimestamp(),
            rating: data.newUserRating,
          }
        );

        const ratingDifference =
          Math.max(data.newUserRating, data.oldUserRating) -
          Math.min(data.newUserRating, data.oldUserRating);

        if (ratingDifference > 0) {
          if (data.newUserRating > data.oldUserRating) {
            resolve({
              id: data.productId,
              rating: data.oldRating + ratingDifference,
            });
          } else {
            resolve({
              id: data.productId,
              rating: data.oldRating - Math.abs(ratingDifference),
            });
          }
        } else {
          resolve({ id: data.productId, rating: data.newRating });
        }
      } catch (e) {
        reject(e.message);
      }
    });
  }
);

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    addProduct: (state, { payload }) => {
      state.products = [...state.products, payload];
    },
    updateProductReviewCount: (state, { payload }) => {
      let list = Object.assign([], state.products);
      let index = list.findIndex((item) => item.id === payload.id);
      if (index >= 0) {
        let item = {
          ...list[index],
          reviewCount: list[index].reviewCount || 0 + 1,
        };
        list[index] = item;
      }
      state.products = [...list];
    },
    reduceProductReviewCount: (state, { payload }) => {
      let list = Object.assign([], state.products);
      let index = list.findIndex((item) => item.id === payload.id);
      if (index >= 0) {
        let item = {
          ...list[index],
          reviewCount:
            (list[index].reviewCount || 0) > 0
              ? list[index].reviewCount - 1
              : 0,
        };
        list[index] = item;
      }
      state.products = [...list];
    },
  },
  extraReducers: (builder) => {
    //loadProduct
    builder.addCase(loadProduct.pending, (state, _) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(loadProduct.fulfilled, (state, { payload }) => {
      state.products = payload;
      state.isEndReached = payload.length < 20;
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(loadProduct.rejected, (state, { error }) => {
      state.isLoading = false;
      state.isError = true;
      state.error = error.message;
    });
    //loadMoreProduct
    builder.addCase(loadMoreProduct.pending, (state, _) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(loadMoreProduct.fulfilled, (state, { payload }) => {
      state.products = payload;
      state.isEndReached = payload.length < 10;
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(loadMoreProduct.rejected, (state, { error }) => {
      state.isLoading = false;
      state.isError = true;
      state.error = error.message;
    });
    //addShowRating
    builder.addCase(addProductRating.pending, (state, _) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(addProductRating.fulfilled, (state, { payload }) => {
      let products = Object.assign([], state.products);
      let index = products.findIndex((product) => product.id === payload.id);
      if (index >= 0) {
        let product = { ...products[index], rating: payload.rating };
        products[index] = product;
      }
      products = products.sort((a, b) => {
        if (a.rating === b.rating) return 0;
        else if (a.rating < b.rating) return 1;
        else return -1;
      });
      state.products = [...products];
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(addProductRating.rejected, (state, { error }) => {
      state.isLoading = false;
      state.isError = true;
      state.error = error.message;
    });
    //updateProductRating
    builder.addCase(updateProductRating.pending, (state, _) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(updateProductRating.fulfilled, (state, { payload }) => {
      console.log(payload);
      let products = Object.assign([], state.products);
      let index = products.findIndex((product) => product.id === payload.id);
      console.log(index);
      if (index >= 0) {
        let product = { ...products[index], rating: payload.rating };
        products[index] = product;
      }
      products = products.sort((a, b) => {
        if (a.rating === b.rating) return 0;
        else if (a.rating < b.rating) return 1;
        else return -1;
      });
      state.products = [...products];
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(updateProductRating.rejected, (state, { error }) => {
      state.isLoading = false;
      state.isError = true;
      state.error = error.message;
    });
  },
});

export const {
  addProduct,
  updateProductReviewCount,
  reduceProductReviewCount,
} = productSlice.actions;

export const productReducer = productSlice.reducer;
