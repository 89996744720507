import { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { RWebShare } from "react-web-share";
import ReactPlayer from 'react-player';

import Modal from '../../components/Modal';
import ModalGallery from "../../components/ModalGallery";

//Atom
import { TextAtom } from "../atoms";
import CastCard from "../../components/CastCard";
import MovieBrandCard from "../../components/MovieBrandCard";
import { CardItem } from "../../components/MovieCardList";

//image
import bookmark from "../../assets/images/small_bookmark.png";

//icons
import { FaArrowRight, FaFileVideo, FaRegImages, FaShareSquare, FaVideo } from "react-icons/fa";
import { BsStarFill } from "react-icons/bs";
import { AiOutlineStar } from "react-icons/ai";
import { FaUserCheck } from "react-icons/fa";
import { BsCameraReels, BsTwitter, BsFacebook, BsInstagram, BsYoutube, BsGlobe } from 'react-icons/bs';
import { GiClapperboard } from 'react-icons/gi';

import { useAuthContext } from "../../context/auth";
import { useIsMobileViewContext } from "../../context/isMobileView";

import { useAppDispatch, useAppSelector, addMovieRating, updateMovieRating, updateUserRatingCount } from "../../redux";

import { hasUserAlreadyRated } from "../../service/movie.service";
import { loadUserReview } from "../../service/review.service";
import MySlider from '../../components/MySlider';
import { IoIosArrowDropright } from 'react-icons/io';

function MovieDetails({ rank, movie_id, movie, categories, info, cast, brand }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const { isUserLoggedIn } = useAuthContext();
  const { isMobileView } = useIsMobileViewContext();

  const [openModal, setOpenModal] = useState(false);
  const [openGalleryModal, setOpenGalleryModal] = useState(false);
  const [rating, setRating] = useState(movie.rating);
  const [userRatingStatus, setUserRatingStatus] = useState(false);
  const [userReviewStatus, setUserReviewStatus] = useState(false);
  const [userOldRating, setUserOldRating] = useState();
  const [suggestedMovies, setSuggestedMovies] = useState([]);

  const user = useAppSelector(state => state.user.profile);
  const movies = useAppSelector(state => state.movie.movies);

  const suggestedMovieIndexRef = useRef([]);
  const oldMovieIdRef = useRef("");

  useEffect(() => {
    if (movie && movie.rating) {
      setRating(movie.rating);
    } else {
      setRating(0);
    }
  }, [movie]);

  useEffect(() => {
    if (movies && suggestedMovies.length <= 0) {
      let newSuggestions = [];
      for (var i = 0; i < 3; i++) {
        let randomIndex = Math.floor(Math.random() * (movies.length));
        if (movie_id !== movies[randomIndex].id) {
          if (suggestedMovieIndexRef.current.length > 0) {
            if (suggestedMovieIndexRef.current.findIndex(item => item === randomIndex) < 0) {
              newSuggestions.push(movies[randomIndex]);
              suggestedMovieIndexRef.current.push(randomIndex);
            }
          } else {
            newSuggestions.push(movies[randomIndex]);
            suggestedMovieIndexRef.current.push(randomIndex);
          }
        }
      }
      setSuggestedMovies(newSuggestions);
    }
  }, [movies]);

  useEffect(() => {
    const getUserRatingStatus = async () => {
      hasUserAlreadyRated(movie_id, user.id)
        .then(result => {
          setUserOldRating(result);
          setUserRatingStatus(result && result > 0);
        })
        .catch(_ => {
          setUserOldRating(0);
          setUserRatingStatus(false);
        });
    }

    const getUserReviewStatus = async () => {
      loadUserReview("movie", movie_id, user.id)
        .then(result => {
          if (result !== null) {
            setUserReviewStatus(true);
          } else {
            setUserReviewStatus(false);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    if (movie_id && user.id && oldMovieIdRef.current !== movie_id) {
      oldMovieIdRef.current = movie_id;
      getUserRatingStatus();
      getUserReviewStatus();
    }
  }, [movie_id, user.id, location.pathname]);

  const handleStarClick = () => {
    if (isUserLoggedIn) setOpenModal(true)
    else navigate("/login");
  };

  const handleRating = (newRating) => {
    if (newRating) {
      const data = {
        newUserRating: newRating,
        movieId: movie_id,
        userId: user.id,
        oldRating: rating,
        oldUserRating: userOldRating,
        newRating: rating + newRating
      };

      if (userRatingStatus) {
        const ratingDifference =
          Math.max(newRating, userOldRating) -
          Math.min(newRating, userOldRating);

        if (ratingDifference > 0) {
          if (newRating > userOldRating) {
            setRating(rating + ratingDifference);
            dispatch(updateUserRatingCount(ratingDifference));
          } else {
            setRating(rating - Math.abs(ratingDifference));
            dispatch(updateUserRatingCount(-Math.abs(ratingDifference)));
          }
        } else {
          setRating(newRating);
          dispatch(updateUserRatingCount(newRating));
        }
        dispatch(updateMovieRating(data));
      }
      else {
        setRating(rating + newRating);
        dispatch(updateUserRatingCount(newRating));
        dispatch(addMovieRating(data));
      }

      setOpenModal(false)
      setUserRatingStatus(true)
    }
  }

  const closeModal = () => {
    setOpenModal(false)
  }

  const handleGalleryModalOpen = () => {
    setOpenGalleryModal(true);
  }

  const handleGalleryModalClose = () => {
    setOpenGalleryModal(false);
  }

  const handleReviewClick = () => {
    if (isUserLoggedIn) navigate(`/review/movie/${movie.id}`);
    else navigate("/login");
  };

  const [overview, setoverview] = useState(true)
  const [castdetail, setcastdetail] = useState(false)
  const [reviews, setreviews] = useState(false)
  const [behindscene, setbehindscene] = useState(false)
  const [news, setnews] = useState(false)

  function SelectedFieldFunction(params) {
    switch (params) {
      case 'Overview':
        setoverview(true)
        setcastdetail(false)
        setreviews(false)
        setbehindscene(false)
        setnews(false)
        break;
      case 'Cast':
        setoverview(false)
        setcastdetail(true)
        setreviews(false)
        setbehindscene(false)
        setnews(false)
        break;
      case 'Reviews':
        setoverview(false)
        setcastdetail(false)
        setreviews(true)
        setbehindscene(false)
        setnews(false)
        break;
      case 'Behindscene':
        setoverview(false)
        setcastdetail(false)
        setreviews(false)
        setbehindscene(true)
        setnews(false)
        break;
      case 'news':
        setoverview(false)
        setcastdetail(false)
        setreviews(false)
        setbehindscene(false)
        setnews(true)
        break;
      default:
        break;
    }
  }
  return (
    <>
      <Modal defaultRating={userOldRating} handleRating={handleRating} closeModal={closeModal} openModal={openModal} />

      <ModalGallery
        gallery={movie.gallery}
        openModal={openGalleryModal}
        handleCloseClick={handleGalleryModalClose} />

      <div className="bg-gray-800 pb-5">
        <img className={`absolute w-72 h-full sm:w-[1500px] object-cover blur-2xl ${isMobileView ? 'brightness-[80%]' : 'brightness-[25%]'} mix-blend-screen`} src={movie.cover_url} />
        <div className="lg:mx-2 w-full lg:w-[1500px] ">
          <div className="flex justify-between p-2 ">
            <div className="flex items-center flex-row">
              {rank > 0 ?
                <div
                  className={`${isMobileView ? "w-8 h-8" : "w-8 h-8"} text-center`}
                  style={{
                    backgroundImage: `url(${bookmark})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <span className={`text-gray-200 ${isMobileView ? "text-xs" : "text-md"}`}>{`#${rank}`}</span>
                </div>
                : <></>}
              <TextAtom
                text_style={`text-gray-300 ${isMobileView ? 'text-xl' : 'text-5xl'} font-semibold`}
                text={movie.name}
              />
            </div>
            <div className="cursor-pointer z-[9]  flex items-center">
              <RWebShare
                data={{
                  text: "Starpot",
                  url: `https://starpot.in${location.pathname}`,
                  title: `${movie.name}`,
                }}
              >
                <FaShareSquare
                  className="w-6 h-5 text-primary"
                />
              </RWebShare>
            </div>
          </div>
          <div className="flex items-center gap-x-3 px-2">
            <div className='flex flex-row items-center gap-x-3'>
              <TextAtom
                div_style=""
                text_style="text-gray-400 text-sm"
                text={movie.releaseYear}
              />
              <TextAtom
                div_style=""
                text_style="text-gray-400 text-sm"
                text={movie.length}
              />
              <TextAtom
                div_style=""
                text_style="text-gray-400 text-sm"
                text={movie.certificate}
              />
            </div>
            <div className="flex flex-row items-center flex-wrap">
              {categories && categories.map((item) => (
                <TextAtom
                  div_style="mx-1 rounded px-2 py-1 border border-gray-700 flex items-center"
                  text_style={`text-gray-300 ${isMobileView ? "text-xss" : "text-xs"}`}
                  text={item}
                />
              ))}
            </div>
          </div>
          <div className="flex flex-wrap  justify-between lg:mx-1">
            <div className="flex ">
              {movie.watchLink &&
                <a className="flex items-center border-1 border-gray-700 rounded-md px-2 mx-1 bg-gray-900 cursor-pointer"
                  href={movie.watchLink}
                  target="_blank" rel="noreferrer"
                >
                  <BsCameraReels className="text-gray-400" />
                  <TextAtom
                    div_style="mx-2"
                    text_style={`text-gray-400 font-semibold ${isMobileView ? "text-xs" : "text-sm"}`}
                    text="Watch On"
                  />
                </a>
              }
            </div>
          </div>

          <div className="my-2 relative px-2">
            <div className={`flex lg:flex-row flex-col items-center justify-between rounded-lg ${isMobileView ? "w-full" : "h-[550px]"}`}>
              {movie.trailerLink && <ReactPlayer width={`${isMobileView ? "100%" : "74.5%"}`} height={`${isMobileView ? "200px" : " 550px"}`} controls url={movie.trailerLink} />}
              <img src={movie.cover_url} alt="" className={` object-cover object-top ${isMobileView ? "w-full mt-2" : "w-[25%] h-[550px]"}`} />
            </div>
          </div>
          <div className="flex lg:flex-row flex-col gap-y-5 lg:gap-y-0 z-50 justify-between items-center mt-2 py-4 px-1">
            <div className='flex flex-row'>
              <div
                className="bg-gray-700 flex items-center z-[999] justify-center py-1 px-12 mx-1 rounded-md border-1 border-gray-600 cursor-pointer"
                onClick={handleStarClick}
              >
                {userRatingStatus ?
                  <BsStarFill className={`text-primary ${isMobileView ? "w-3 h-3" : "w-5 h-5"}`} /> :
                  <AiOutlineStar className={`text-gray-400 ${isMobileView ? "w-3 h-3" : "w-5 h-5"}`} />}
                <TextAtom
                  div_style="flex mx-1"
                  text_style={`text-gray-400 ${isMobileView ? "text-xs font-semibold" : "text-sm font-semibold"}`}
                  text={rating || 0}
                />
              </div>

              <div
                className="bg-gray-700 flex items-center z-[999] justify-center py-1 px-12 mx-1 rounded-md border-1 border-gray-600 cursor-pointer"
                onClick={handleReviewClick}
              >
                <FaUserCheck className={`${userReviewStatus ? "text-primary" : "text-gray-400"} ${isMobileView ? "w-3 h-3" : "w-5 h-5"}`} />
                <TextAtom
                  div_style="flex mx-1"
                  text_style={`text-gray-400 ${isMobileView ? "text-xs font-semibold" : "text-sm font-semibold"}`}
                  text={movie.reviewCount || 0}
                />
              </div>
            </div>
            <div className='flex flex-row'>
              {movie.gallery && movie.gallery.length > 0 &&
                <div className=" flex bg-gray-700 items-center z-[999] justify-center py-1 px-5 lg:px-12 mx-1 rounded-md border-1 border-gray-600 hover:cursor-pointer"
                  onClick={handleGalleryModalOpen}>
                  <FaRegImages className={` text-gray-400 ${isMobileView ? "w-3 h-3" : "w-5 h-5"}`} />
                  <TextAtom
                    div_style="flex mx-1"
                    text_style={`text-gray-400 ${isMobileView ? "text-xs font-semibold" : "text-sm font-semibold"}`}
                    text={'Images'}
                  />
                </div>
              }
              <div className=" flex bg-gray-700 z-[999] items-center justify-center py-1 px-5 lg:px-12 mx-1 rounded-md border-1 border-gray-600 hover:cursor-pointer"
              >
                <FaFileVideo className={` text-gray-400 ${isMobileView ? "w-3 h-3" : "w-5 h-5"}`} />
                <TextAtom
                  div_style="flex mx-1"
                  text_style={`text-gray-400 ${isMobileView ? "text-xs font-semibold" : "text-sm font-semibold"}`}
                  text={'Videos'}
                />
              </div>
            </div>
          </div>

          {movie.description && <TextAtom
            div_style="flex p-2"
            text_style={`text-white text-center font-serif tracking-wide text-justify ${isMobileView ? "text-xs" : "text-base"}`}
            text={movie.description}
          />}
          <div className="border-gray-700  border-t-1 my-3" />

          <div className="flex flex-wrap justify-end items-center mb-3">
            {movie.twitterUrl &&
              <a href={movie.twitterUrl} target="_blank" rel="norefer noreferrer">
                <BsTwitter className={`mx-2 cursor-pointer text-gray-400 hover:scale-110 duration-300 ${isMobileView ? "w-4 h-4" : "w-6 h-6"}`} />
              </a>
            }

            {movie.facebookUrl &&
              <a href={movie.facebookUrl} target="_blank" rel="norefer noreferrer">
                <BsFacebook className={`mx-2 cursor-pointer text-gray-400 hover:scale-110 duration-300 ${isMobileView ? "w-4 h-4" : "w-6 h-6"}`} />
              </a>
            }

            {movie.instagramUrl &&
              <a href={movie.instagramUrl} target="_blank" rel="norefer noreferrer">
                <BsInstagram className={`mx-2 cursor-pointer text-gray-400 hover:scale-110 duration-300 ${isMobileView ? "w-4 h-4" : "w-6 h-6"}`} />
              </a>
            }

            {movie.youtubeUrl &&
              <a href={movie.youtubeUrl} target="_blank" rel="norefer noreferrer">
                <BsYoutube className={`mx-2 cursor-pointer text-gray-400 hover:scale-110 duration-300 ${isMobileView ? "w-4 h-4" : "w-6 h-6"}`} />
              </a>
            }

            {movie.websiteUrl &&
              <a href={movie.websiteUrl} target="_blank" rel="norefer noreferrer">
                <BsGlobe className={`mx-2 cursor-pointer text-gray-400 hover:scale-110 duration-300 ${isMobileView ? "w-4 h-4" : "w-6 h-6"}`} />
              </a>
            }
          </div>

          <div className='flex flex-row overflow-x-scroll md:overflow-x-auto w-72 sm:w-full gap-x-3'>
            <div onClick={() => SelectedFieldFunction('Overview')} className={`flex ${overview ? 'bg-primary' : 'text-gray-700'} ${overview ? 'border-primary' : 'border-gray-600'}  bg-gray-700 items-center duration-500 transition-all z-[999] justify-center py-1  px-12  mx-1 rounded-md border-1   hover:cursor-pointer`}>
              <TextAtom
                div_style="flex mx-1"
                text_style={`${overview ? 'text-black' : 'text-gray-400'} ${isMobileView ? "text-xs font-semibold" : "text-sm font-semibold"}`}
                text={'Overview'}
              />
            </div>
            <div onClick={() => SelectedFieldFunction('Cast')} className={`${castdetail ? 'bg-primary' : 'text-gray-700'} ${castdetail ? 'border-primary' : 'border-gray-600'} flex  bg-gray-700 duration-500 transition-all items-center z-[999] py-1 justify-center  px-12  mx-1 rounded-md border-1 border-gray-600 hover:cursor-pointer`}>
              <TextAtom
                div_style="flex mx-1"
                text_style={`${castdetail ? 'text-black' : 'text-gray-400'} ${isMobileView ? "text-xs font-semibold" : "text-sm font-semibold"}`}
                text={'Cast'}
              />
            </div>
            <div onClick={() => SelectedFieldFunction('Reviews')} className={`${reviews ? 'bg-primary' : 'text-gray-700'} ${reviews ? 'border-primary' : 'border-gray-600'} flex  bg-gray-700 duration-500 transition-all items-center py-1 z-[999] justify-center px-12 mx-1 rounded-md border-1 border-gray-600 hover:cursor-pointer`}>
              <TextAtom
                div_style="flex mx-1"
                text_style={`${reviews ? 'text-black' : 'text-gray-400'} ${isMobileView ? "text-xs font-semibold" : "text-sm font-semibold"}`}
                text={'Reviews'}
              />
            </div>
            <div onClick={() => SelectedFieldFunction('Behindscene')} className={`${behindscene ? 'bg-primary' : 'text-gray-700'} ${behindscene ? 'border-primary' : 'border-gray-600'}  duration-500 transition-all bg-gray-700 py-1 items-center px-12 whitespace-nowrap z-[999] justify-center mx-1 rounded-md border-1 border-gray-600 hover:cursor-pointer`}>
              <TextAtom
                div_style="mx-1 flex flex-row"
                text_style={`${behindscene ? 'text-black' : 'text-gray-400'} ${isMobileView ? "text-xs font-semibold" : "text-sm font-semibold"}`}
                text={'Behind The Scenes'}
              />

            </div>
            <div onClick={() => SelectedFieldFunction('news')} className={`${news ? 'bg-primary' : 'text-gray-700'} ${news ? 'border-primary' : 'border-gray-600'} flex  duration-500 transition-all bg-gray-700 items-center z-[999] py-1 justify-center  px-12 mx-1 rounded-md border-1 border-gray-600 hover:cursor-pointer`}>
              <TextAtom
                div_style="flex mx-1"
                text_style={`${news ? 'text-black' : 'text-gray-400'} ${isMobileView ? "text-xs font-semibold" : "text-sm font-semibold"}`}
                text={'News'}
              />
            </div>
          </div>

          {/* <span className="text-sm text-gray-300"> */}
          {/* <div className="flex ml-5 my-2">
              {movie.certificate && <TextAtom
                div_style="mx-1 rounded px-2 py-1 border border-gray-700 flex items-center"
                text_style={`text-gray-300 ${isMobileView ? "text-xss" : "text-xs"}`}
                text={movie.certificate}
              />
              }
              {movie.length && <TextAtom
                div_style="mx-1 rounded px-2 py-1 border border-gray-700 flex items-center"
                text_style={`text-gray-300 ${isMobileView ? "text-xss" : "text-xs"}`}
                text={movie.length}
              />
              }
              {movie.releaseYear && <TextAtom
                div_style="mx-1 rounded px-2 py-1 border border-gray-700 flex items-center"
                text_style={`text-gray-300 ${isMobileView ? "text-xss" : "text-xs"}`}
                text={movie.releaseYear}
              />
              }
            </div> */}
          {/* </span> */}


          {/* {info && info.length > 0 ? (
            <>
              {info.map((item) => (
                <DetailText qus={item.title} ans={item.description} isMobileView={isMobileView} />
              ))}

              <div className="border-gray-700 border-t-1 mt-2" />
            </>
          ) : (
            <></>
          )} */}
          {
            overview &&
            <div>OverView</div>
          }
          {cast && cast.length > 0 && castdetail
            ? <>
              <CastCard cast={cast} />
            </>
            : <></>}

          {
            reviews &&
            <div>reviews</div>
          }
          {
            behindscene &&
            <div>behindscene</div>
          }
          {
            news ?
              isMobileView ?
                <div className='grid grid-cols-2 gap-x-2 mx-2'>
                  {
                    cast.map((e, index) =>
                      <div key={index} className={`${isMobileView ? '' : 'w-[300px]'} mt-5 h-auto rounded-xl bg-gray-900 `}>
                        <img className={`${isMobileView ? 'w-full h-[100px]' : 'w-[300px] h-[250px]'} object-cover object-top rounded-t-xl`} src={e.cover_url} />
                        <div className={`${isMobileView ? 'w-full' : 'w-[300px]'} px-2 mt-2 flex flex-col gap-y-2`}>
                          <h1 className={`text-white  ${isMobileView ? "text-xs font-semibold" : "text-[14px]  font-normal"}`}>Metrowater begins improving drinking water pipelines at Kannagi Nagar</h1>
                          <h1 className={`text-white  ${isMobileView ? "text-xs font-semibold" : "text-[14px]  font-normal"}`}>5 hrs ago</h1>
                          <div className='flex flex-row items-center gap-x-2 mt-1'>
                            <img src={e.cover_url} className={`${isMobileView ? 'w-6 h-6' : 'w-5 h-5'} rounded-full`} />
                            <h1 className={`text-white  ${isMobileView ? "text-xs font-semibold" : "text-[14px]  font-normal"}`}>Illustion spotline</h1>
                          </div>
                        </div>
                      </div>
                    )
                  }
                </div>
                :
                <MySlider isScrollActionEnabled={`${isMobileView ? 3 : 4}`} className=''>
                  <div className='gap-x-3 flex flex-row'>
                    {
                      cast.map((e, index) =>
                        <div key={index} className={`${isMobileView ? 'w-[100px]' : 'w-[300px]'} mt-5 h-auto rounded-xl bg-gray-900 pb-5`}>
                          <img className={`${isMobileView ? 'w-[100px] h-[100px]' : 'w-[300px] h-[250px]'} object-cover object-top rounded-t-xl`} src={e.cover_url} />
                          <div className={`${isMobileView ? 'w-[50px]' : 'w-[300px]'} px-2 mt-2 flex flex-col gap-y-2`}>
                            <h1 className={`text-white  ${isMobileView ? "text-xs font-semibold" : "text-[14px]  font-normal"}`}>Metrowater begins improving drinking water pipelines at Kannagi Nagar</h1>
                            <h1 className={`text-white  ${isMobileView ? "text-xs font-semibold" : "text-[14px]  font-normal"}`}>5 hrs ago</h1>
                            <div className='flex flex-row items-center gap-x-2 mt-1'>
                              <img src={e.cover_url} className={`${isMobileView ? 'w-6 h-6' : 'w-5 h-5'} rounded-full`} />
                              <h1 className={`text-white  ${isMobileView ? "text-xs font-semibold" : "text-[14px]  font-normal"}`}>Illustion spotline</h1>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  </div>
                </MySlider> : null
          }
          {brand && brand.length > 0
            ? <>
              <MovieBrandCard brand={brand} />
            </>
            : <></>}

          <TextAtom
            div_style="pt-2"
            text_style={`px-2 text-gray-300 ${isMobileView ? "text-sm" : "text-md"}`}
            text="People also search for"
          />

          <div className={`mx-3 grid ${isMobileView ? "mb-2 mt-1 grid-cols-3 gap-2" : "mb-4 mt-2 grid-col-3 lg:grid-cols-6 gap-5 "}`}>
            {suggestedMovies && suggestedMovies.map((item, index) => (
              <CardItem
                key={`item_${index}`}
                title={item.name}
                img={item.cover_url}
                link={item.id}
                rating={item.rating}
                reviews={item.reviewCount}
                isMobileView={isMobileView}
                year={item.releaseYear} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

function DetailText({ qus, ans, isMobileView }) {
  return (
    <div className="flex flex-wrap items-center mx-4">
      <TextAtom text_style={`text-gray-300 ${isMobileView ? "text-xs" : "text-sm"}`} text={qus} />
      <TextAtom text_style="text-gray-300 text-xs" text="&nbsp;:&nbsp;" />
      <TextAtom
        text_style={`text-wrap text-gray-500 font-semibold ${isMobileView ? "text-xs" : "text-sm"}`}
        text={ans}
      />
    </div>
  );
}

export { MovieDetails };
//overview cast reviews Behind the scense