import React, { useEffect, useState } from 'react'
import { BiArrowBack, BiLike, BiSolidLike } from 'react-icons/bi'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppSelector } from '../redux'
import axios from 'axios'
function JobpageDescription() {
    const navigate = useNavigate()
    const location = useLocation()
    const datas = location.state.data
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'auto' });
    }, []);
    const user = useAppSelector(state => state.user.profile)
    const [likes, setLikes] = useState(null)
    useEffect(() => {
        setLikes(datas.likes)
    }, [])

    function PatchTheLikeCount(_id) {
        if (user.id != null) {
            try {
                let temp = []
                if (datas.likes === null || JSON.parse(datas.likes).length == 0) {
                    temp.push(user.id)
                    setLikes(JSON.stringify(temp))
                } else if (datas.likes != null) {
                    let temp = [...JSON.parse(datas.likes)]
                    let result = temp.findIndex(e => e == user.id)
                    if (result == -1) {
                        temp.push(user.id)
                        setLikes(JSON.stringify(temp))
                    } else {
                        temp.splice(result, 1)
                        setLikes(JSON.stringify(temp))
                    }
                }
                console.log(temp);
                const data = {
                    "likes": temp.length == 0 ? null : JSON.stringify(temp),
                }
                axios.patch('https://cinimini-apis-3nugbllihq-el.a.run.app/blog/' + _id, data)
                    .then((responce) => {
                        if (responce.status == 200) {
                            console.log(responce.data);
                            datas.likes = JSON.stringify(temp)
                        }
                    }).catch((error) => {
                        console.log(error.message);
                    })
            } catch (error) {
                console.log(error.message);
            }
        }
        else {
            alert('Login Do First')
        }
    }

    return (
        <div className='w-full flex flex-col bg-[#0e162b] overflow-auto  '>
            <div className="h-10 bg-gray-900 rounded-xl m-2 flex flex-row items-center px-3 text-gray-200 text-sm font-bold cursor-pointer z-10 fixed top-[100px]"
                onClick={() => navigate(-1)}>
                <BiArrowBack className="w-6 h-6" /><span>Back</span>
            </div>
            <div className='container mx-auto mt-24  px-3'>
                <div className=' w-full  2xl:w-[70%] mx-auto flex flex-col '>
                    <div className='w-full flex flex-col gap-3 mb-10'>
                        <img className='w-full h-[400px] object-cover object-top' src={`https://storage.googleapis.com/cinimini-console/blogs/${datas?.id}/${datas?.thumbnailUrl}`} />
                        <div className='flex flex-col items-start'>
                            <section className='bg-primary px-3 py-1 text-white rounded-sm'>
                                <h1 className='text-[12px] font-bold'>Featured</h1>
                            </section>
                        </div>
                        <h1 className=' text-white font-bold text-xl lg:text-2xl'>{datas.title}</h1>
                        <div className='flex flex-row items-center gap-x-3'>
                            <h1 className='text-[14px] font-bold text-primary'>Featured</h1>
                            <h1 className='text-[12px] font-bold text-gray-100'>.</h1>
                            <h1 className='text-[12px] font-bold text-gray-100'>{new Date(datas.creationTime).getUTCDate() + ' ' + (new Date(datas.creationTime).toLocaleDateString('en-US', { month: 'short' })) + ' ' + new Date(datas.creationTime).getUTCFullYear()}</h1>
                            <div className='flex flex-row sm:hidden items-center gap-x-1'>
                                {likes!= null ? JSON.parse(datas.likes).findIndex(e => e == user.id) != -1 ?
                                    <BiSolidLike onClick={() => PatchTheLikeCount(datas.id)} className='text-primary cursor-pointer text-lg' /> :
                                    <BiLike onClick={() => PatchTheLikeCount(datas.id)} className='text-primary cursor-pointer text-lg' /> :
                                    <BiLike onClick={() => PatchTheLikeCount(datas.id)} className='text-primary cursor-pointer text-lg' />}
                                <h1 className='text-gray-500'>{likes != null ? JSON.parse(likes).findIndex(e => e == user.id) != -1 ? 1 : 0 : 0}</h1>
                            </div>
                        </div>
                        <h1 className='text-gray-300 text-wrap'>Praesent iaculis, purus ac vehicula mattis, arcu lorem blandit nisl, non laoreet dui mi eget elit. Donec porttitor ex vel augue maximus luctus. Vivamus finibus nibh eu nunc volutpat suscipit.Praesent iaculis, purus ac vehicula mattis, arcu lorem blandit nisl, non laoreet dui mi eget elit. Donec porttitor ex vel augue maximus luctus. Vivamus finibus nibh eu nunc volutpat suscipit.Praesent iaculis, purus ac vehicula mattis, arcu lorem blandit nisl, non laoreet dui mi eget elit. Donec porttitor ex vel augue maximus luctus. Vivamus finibus nibh eu nunc volutpat suscipit.Praesent iaculis, purus ac vehicula mattis, arcu lorem blandit nisl, non laoreet dui mi eget elit. Donec porttitor ex vel augue maximus luctus. Vivamus finibus nibh eu nunc volutpat suscipit.Praesent iaculis, purus ac vehicula mattis, arcu lorem blandit nisl, non laoreet dui mi eget elit. Donec porttitor ex vel augue maximus luctus. Vivamus finibus nibh eu nunc volutpat suscipit.Praesent iaculis, purus ac vehicula mattis, arcu lorem blandit nisl, non laoreet dui mi eget elit. Donec porttitor ex vel augue maximus luctus. Vivamus finibus nibh eu nunc volutpat suscipit.Praesent iaculis, purus ac vehicula mattis, arcu lorem blandit nisl, non laoreet dui mi eget elit. Donec porttitor ex vel augue maximus luctus. Vivamus finibus nibh eu nunc volutpat suscipit.Praesent iaculis, purus ac vehicula mattis, arcu lorem blandit nisl, non laoreet dui mi eget elit. Donec porttitor ex vel augue maximus luctus. Vivamus finibus nibh eu nunc volutpat suscipit.</h1>
                        <div className='flex flex-row items-center gap-x-1'>
                            {likes != null ? JSON.parse(likes).findIndex(e => e == user.id) != -1 ?
                                <BiSolidLike onClick={() => PatchTheLikeCount(datas.id)} className='text-primary cursor-pointer text-lg' /> :
                                <BiLike onClick={() => PatchTheLikeCount(datas.id)} className='text-primary cursor-pointer text-lg' /> :
                                <BiLike onClick={() => PatchTheLikeCount(datas.id)} className='text-primary cursor-pointer text-lg' />}
                            <h1 className='text-gray-500'>{likes != null ? JSON.parse(likes).findIndex(e => e == user.id) != -1 ? 1 : 0 : 0}</h1>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default JobpageDescription