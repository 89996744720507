import React from 'react'
import Close from '../assets/images/Close.png'
import ReactPlayer from 'react-player'
function OpenYoutubeBox({YtLink,handleYtFalseFunction}) {
    
    return (
        <div id='Video' className='md:h-[50vh] md:w-[50%] lg:w-[40%] w-full bg-[#131722] top-[200px] absolute z-[99] h-[250px] rounded-lg flex flex-col self-center items-center '>
            <div id='Video' className='py-5 h-full w-full bg-[#1b212f] absolute z-10 rounded-lg flex flex-col items-center '>
                <div id='Video' className=' flex flex-row justify-between w-full items-center px-5 pb-3'>
                    <h4 className='text-white font-normal'>Trailer</h4>
                    <img onClick={()=>handleYtFalseFunction()} className='w-[20px] hover:scale-125 hover:cursor-pointer duration-300 object-contain ' src={Close} />
                </div>
                <div id='Video' className='md:w-[100%] h-full w-full flex flex-col gap-3 px-5'>
                    <ReactPlayer
                        width="100%"
                        height='100%'
                        url={`https://www.youtube.com/watch?v=${YtLink}`}
                        playing={true}
                        controls={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default OpenYoutubeBox