import React, { useEffect, useState } from 'react'
import { FaPlay } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
function FullWidthConatiner({ handleYtFunction, youtubeUrl, internal, sectionId, ImageUrl, id, redirectUrl,title,subTitle }) {
    const [playHover, setPlayHover] = useState(false)
    useEffect(() => {
        if (internal !== 0) {
            let path = sectionId == 4 ? "webseries" : sectionId == 3 ? "movies" : sectionId == 2 ? "celebrities" : sectionId == 1 ? "news" : null
            FetchingData(path)
        }
    }, [])

    const [data, setData] = useState([])
    async function FetchingData(path) {
        const response = await axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/specific/${path}/${redirectUrl}`)
        if (response.status == 200) {
            setData(response.data[0])
            console.log(response.data);
        }
    }

    const navigate = useNavigate()
    return (
        <div className='w-full md:h-[700px] relative z-10'>
            <img onClick={() => navigate(`${sectionId==4?"/AboutWebseries":sectionId==3?"/MovieDetailPage":sectionId==2?"/Celebrity":sectionId==1?"/News":null}`, { state: { array:data }})} className='absolute w-full brightness-50 cursor-pointer h-full md:h-full object-cover md:object-fill object-top' src={`https://storage.googleapis.com/cinimini-console/advertisements/${id}/${ImageUrl}`} />
            <div className='container h-full m-auto flex md:flex-row flex-col items-center justify-center lg:pl-20'>
                <div onClick={() => navigate(`${sectionId==4?"/AboutWebseries":sectionId==3?"/MovieDetailPage":sectionId==2?"/Celebrity":sectionId==1?"/News":null}`, { state: { array:data }})} 
                className='px-2 w-[75%]  lg:px-0 z-50 ' >
                    <h1 className='md:text-5xl text-xl cursor-pointer text-white font-extrabold font-serif lg:mb-5'>{title}</h1>
                    <p className='xl:w-[800px] font-normal text-[15px] cursor-default text-gray-200'>{subTitle}</p>
                </div >
                <div onClick={() => handleYtFunction(youtubeUrl)} onMouseEnter={() => setPlayHover(true)} onMouseLeave={() => setPlayHover(false)} className='border-2 z-50 w-[60px] my-5 lg:my-10 h-[60px]  hover:border-white hover:cursor-pointer border-gray-300 rounded-full lg:w-[200px] lg:h-[200px] flex flex-col justify-center items-center'>
                    <FaPlay className={` lg:w-[73px] text-primary duration-300 lg:h-[84px] w-[20px] h-[20px]`} />
                </div>
            </div>
        </div>
    )
}

export default FullWidthConatiner
