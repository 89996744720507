import BackArrowNavigation from "../components/BackArrowNavigation";

import Logo from "../assets/images/logo.png";

function UpgradePage() {
    return (
        <main className="min-h-screen bg-gray-900">
            <BackArrowNavigation />

            <div className="pt-16 px-4">
                <div className="w-full bg-gray-800 p-4 flex flex-col items-center rounded-md mt-10">
                    <img className="p-10" src={Logo} alt="" />
                    <span className="text-2xl text-gray-400 my-4">StarPot Pro</span>
                    <span className="mb-4 text-md text-white bg-primary px-2 py-1 rounded-lg mt-10">Buy Now</span>
                </div>
            </div>
        </main>
    )
}

export default UpgradePage