import { useState, useEffect } from "react";

import { IsMobileViewContext } from "./useIsMobileView.context";

export const IsMobileViewProvider = (props) => {
  const [size, setSize] = useState({ width: window.innerWidth });

  useEffect(() => {
    const handleResize = () => {
      setSize({ width: window.innerWidth });
    };

    const unSubscribe = window.addEventListener("resize", handleResize);

    return () => {
      unSubscribe();
    };
  }, []);

  return (
    <IsMobileViewContext.Provider value={{ isMobileView: size.width <= 600 }}>
      {props.children}
    </IsMobileViewContext.Provider>
  );
};
