import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  doc,
  getDocs,
  getDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  query,
  orderBy,
  startAfter,
  addDoc,
  limit,
  where,
  serverTimestamp,
} from "firebase/firestore";
import {
  getAuth,
  onAuthStateChanged,
  signInWithPhoneNumber,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  RecaptchaVerifier,
} from "firebase/auth";
import { getToken } from "firebase/messaging";

import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCfDWefHxgakE_Q8JLnuvl9brMwTor1sVo",
  authDomain: "starpot-d121c.firebaseapp.com",
  projectId: "starpot-d121c",
  storageBucket: "starpot-d121c.appspot.com",
  messagingSenderId: "68210215627",
  appId: "1:68210215627:web:6fe7dd0664951af450a94e",
  measurementId: "G-T7R8Q2PX68",
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

const auth = getAuth();
auth.useDeviceLanguage();

const storage = getStorage();

// eslint-disable-next-line import/no-anonymous-default-export
export {
  db,
  auth,
  storage,
  RecaptchaVerifier,
  onAuthStateChanged,
  signInWithPhoneNumber,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  collection,
  doc,
  getDocs,
  getDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  query,
  orderBy,
  startAfter,
  addDoc,
  where,
  limit,
  serverTimestamp,
  ref,
  uploadBytes,
  getDownloadURL,
  getToken,
};
