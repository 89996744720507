import React from 'react'

function MoreMovies() {
    return (
        <main className="min-h-screen bg-gray-900 flex-grow">
            
        </main>
    )
}

export default MoreMovies