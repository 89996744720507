import { useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";

import { useAppDispatch, createUser, setUser } from "../redux";

import { FiEdit2 } from "react-icons/fi";

import { TextAtom } from '../view/atoms'

import { doc, db, getDoc, auth, signInWithPhoneNumber, RecaptchaVerifier } from "../firebase";

import LoadingIndicator from "../components/LoadingIndicator";

const dummyuserProfile = require("../assets/images/dummyUser.png")

const styles = {
  inputStyle:
    "outline-none w-full p-1 bg-gray-800 border-t-0 border-l-0 border-r-0 border-b border-gray-200 focus:border-primary text-sm text-white md:text-lg",
  errorLabelStyle: "text-red-400 text-xsm md:text-sm",
};

const NAME = "name", EMAIL = "email", PHONE = "phone";

function Register() {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [userNameErr, setUserNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [registerErr, setRegisterErr] = useState("");
  const [otpErr, setOtpErr] = useState("");
  const [isRegistering, setIsRegistering] = useState(false);
  const [isVerifyingOtp, setIsVerifyingOtp] = useState(false);

  const [showOtpInput, setShowOtpInput] = useState(false);

  const [profileImage, setProfileImage] = useState("")
  const [profileUrl, setProfileUrl] = useState(dummyuserProfile)

  const imageInputRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const phoneExtRef = useRef("+91");
  const userNameRef = useRef(null);
  const otpRef = useRef(null);

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
      'callback': (_) => { }
    }, auth);
  }

  const openImagePicker = () => {
    if (imageInputRef.current) {
      imageInputRef.current.click();
    }
  }

  const validation = (value, type) => {
    var errorMsg = "";
    switch (type) {
      case NAME:
        if (value === null || value.length <= 0) {
          errorMsg = "Name cannot be left blank";
        } else if (
          !RegExp(
            /^([ .\w-]{2,120})$/i
          ).test(value)
        ) {
          errorMsg = "Invalid name entered, Name should be between 2 to 20 characters long";
        } else {
          errorMsg = "";
        }
        setUserNameErr(errorMsg);
        break;
      case EMAIL:
        if (value === null || value.length <= 0) {
          errorMsg = "Email cannot be left bank";
        } else if (
          !RegExp(
            /^([\w.]+(([+]{1})*[\w.]+){1}@([\w-]+).([\w-]{2,4}))?$/i
          ).test(value)
        ) {
          errorMsg = "Email is invalid";
        } else {
          errorMsg = "";
        }
        setEmailErr(errorMsg);
        break;
      case PHONE:
        if (value === null || value.length <= 0) {
          errorMsg = "Phone cannot be left blank";
        } else if (
          !RegExp(
            /^([\d]{10})$/i
          ).test(value)
        ) {
          errorMsg = "Invalid Phone number";
        } else {
          errorMsg = "";
        }
        setPhoneErr(errorMsg);
        break;
      default:
    }
    return errorMsg.length < 1;
  };

  const register = async () => {
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phoneExtRef.current + phoneRef.current, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setShowOtpInput(true);
        setIsRegistering(false);
      }).catch((error) => {
        setRegisterErr(error.message);
        console.error(error);
        setIsRegistering(false);
      });
  };

  const handleRegisterClick = (e) => {
    e.preventDefault();

    if (validation(userNameRef.current, NAME)) {
      if (validation(emailRef.current, EMAIL)) {
        if (validation(phoneRef.current, PHONE)) {
          setIsRegistering(true);
          generateRecaptcha();
          register();
        }
      }
    }
  };

  const handleVerifyOtpClick = (e) => {
    e.preventDefault();

    if (otpRef.current?.length <= 0) {
      setOtpErr("OTP cannot be empty");
    } else {
      setIsVerifyingOtp(true);
      window.confirmationResult.confirm(otpRef.current)
        .then(async (result) => {
          let user = result.user;
          const docRef = doc(db, "users", user.uid);
          const docSnap = await getDoc(docRef);
          setIsVerifyingOtp(false);
          if (docSnap.exists()) {
            dispatch(setUser(docSnap.data()));
          } else {
            dispatch(createUser({ uid: user.uid, email: emailRef.current, name: userNameRef.current, image: profileImage, phone: phoneRef.current, phone_ext: phoneExtRef.current }));
          }
          navigate("/");
        })
        .catch(err => {
          setIsVerifyingOtp(false);
          setOtpErr(err.message);
        });
    }
  }

  const handleImage = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    let file = e.target.files[0];

    if (file) {
      reader.onloadend = () => {
        setProfileImage(file);
        setProfileUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  }

  return (
    <main className="min-h-screen flex-grow bg-gray-900">
      <div className="h-screen flex flex-col justify-center items-center p-6 w-full md:w-100 mx-auto">
        {showOtpInput
          ?
          <>
            <span className="my-4 text-xl md:text-2xl text-gray-300">
              Enter the OTP sent to {phoneExtRef.current} {phoneRef.current}
            </span>

            <div className="flex flex-row items-center w-4/6 md:w-5/6 mt-4">
              <input
                type="password"
                placeholder="OTP"
                maxLength={6}
                disabled={isVerifyingOtp}
                className={`text-center ${styles.inputStyle}`}
                onChange={(e) => (otpRef.current = e.target.value)}
              />
            </div>
            <span className={styles.errorLabelStyle}>{otpErr}</span>

            {isVerifyingOtp
              ? <LoadingIndicator />
              : <button
                className="bg-primary rounded-sm py-2 px-4 mt-6 mb-2 text-sm md:text-lg text-white"
                onClick={handleVerifyOtpClick}
              >
                VERIFY
              </button>
            }
          </>
          :
          <>
            <span className="my-4 text-xl md:text-2xl text-gray-300">
              Create a StarPot account
            </span>

            <label>
              <img className="w-28 h-28 mb-2 bg-white object-cover rounded-full" src={profileUrl} alt="userImage" onClick={openImagePicker} />
              <div className="flex items-center justify-center" onClick={openImagePicker}>
                <FiEdit2 className=" mr-2 w-4 h-4 text-gray-700" />
                <TextAtom text_style={"text-gray-500 "} text="EDIT" />
              </div>
            </label>

            <input ref={imageInputRef} onChange={(e) => handleImage(e)} name="profile" accept="image/png, image/jpeg" className="hidden" type="file" />

            <div className="flex flex-row items-center w-4/6 md:w-5/6 mt-4">
              <input
                type="text"
                placeholder="Name"
                maxLength={20}
                disabled={isRegistering}
                className={`${styles.inputStyle}`}
                onChange={(e) => (userNameRef.current = e.target.value)}
              />
            </div>
            <span className={styles.errorLabelStyle}>{userNameErr}</span>

            <div className="flex flex-row items-center w-4/6 md:w-5/6 mt-4">
              <input
                type="email"
                placeholder="Email"
                disabled={isRegistering}
                className={`${styles.inputStyle}`}
                onChange={(e) => (emailRef.current = e.target.value)}
              />
            </div>
            <span className={styles.errorLabelStyle}>{emailErr}</span>

            <div className="flex flex-row items-center w-4/6 md:w-5/6 mt-4">
              <input
                type="text"
                placeholder="+91"
                disabled={isRegistering}
                className="mr-1 w-12 outline-none p-1 bg-gray-800 border-t-0 border-l-0 border-r-0 border-b border-gray-200 focus:border-primary text-sm text-white md:text-lg"
                onChange={(e) => (phoneExtRef.current = e.target.value)}
              />
              <input
                type="number"
                placeholder="Mobile"
                disabled={isRegistering}
                className={`${styles.inputStyle}`}
                onChange={(e) => (phoneRef.current = e.target.value)}
              />
            </div>
            <span className={styles.errorLabelStyle}>{phoneErr}</span>

            {isRegistering
              ? <LoadingIndicator />
              : <button
                className="bg-primary rounded-sm py-2 px-4 mt-6 mb-2 text-sm md:text-lg text-white"
                onClick={(e) => handleRegisterClick(e)}
              >
                REGISTER
              </button>
            }
            <span className={styles.errorLabelStyle}>{registerErr}</span>

            <span className="text-xs md:text-sm text-gray-300 mt-4">
              By Signing up, you agree to our{" "}
              <Link to="/terms" className="text-blue-400">
                Terms
              </Link>{" "}
              and{" "}
              <Link to="/privacy" className="text-blue-400">
                Privacy Policy
              </Link>
              .
            </span>

            <Link
              to="/login"
              className="rounded-sm py-1 px-4 mt-8 text-sm md:text-lg text-gray-400"
            >
              Have an account ? <span className="text-primary">Login</span>
            </Link>

            <div id="recaptcha-container"></div>
          </>}
      </div>
    </main >
  );
}

export default Register;
