import { useNavigate } from "react-router-dom";

import { BsSearch } from "react-icons/bs";

function InputWithSearchIcon() {
  const navigate = useNavigate();

  return (
    <div className="bg-white sm:block hidden mb-2">
      <div className="bg-white flex flex-row items-center py-1 mx-4 rounded-md">
        <div className="p-1 mx-2">
          <BsSearch className="w-5 h-5 text-gray-700" />
        </div>
        <input
          onClick={() => navigate("/search")}
          className="w-full outline-none text-gray-700 text-sm placeholder-gray-700"
          placeholder="Search..."
        />
      </div>
    </div>
  );
}

export { InputWithSearchIcon };
