import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  db,
  collection,
  doc,
  getDocs,
  setDoc,
  updateDoc,
  query,
  startAfter,
  orderBy,
  limit,
  where,
  serverTimestamp,
} from "../../firebase";

const initialState = {
  celebrity: [],
  categorizedCelebrities: [],
  isEndReached: false,
  isCategoryEndReached: [],
  isLoading: false,
  isError: false,
  error: "",
};

var lastDoc = null;
var docsCount = 0;
var lastCategoryDoc = [];

export const loadCelebrity = createAsyncThunk(
  "celebrity/loadCelebrity",
  async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const runQuery = query(
          collection(db, "celebrity"),
          orderBy("rating", "desc"),
          limit(50)
        );

        const querySnapshot = await getDocs(runQuery);

        let list = [];
        var index = 1;

        querySnapshot.forEach((doc) => {
          list.push({
            rank: index,
            ...doc.data(),
          });
          index = index + 1;
        });
        lastDoc =
          list.length > 0
            ? querySnapshot.docs[querySnapshot.docs.length - 1]
            : null;
        docsCount = docsCount + querySnapshot.docs.length;
        resolve(list);
      } catch (e) {
        reject(e.message);
      }
    });
  }
);

export const loadMoreCelebrity = createAsyncThunk(
  "celebrity/loadMoreCelebrity",
  async () => {
    return new Promise(async (resolve, reject) => {
      try {
        let runQuery;
        if (lastDoc === null) {
          runQuery = query(
            collection(db, "celebrity"),
            orderBy("rating", "desc"),
            limit(10)
          );
        } else {
          runQuery = query(
            collection(db, "celebrity"),
            orderBy("rating", "desc"),
            startAfter(lastDoc),
            limit(10)
          );
        }

        const querySnapshot = await getDocs(runQuery);

        let list = [];
        var index = docsCount;

        querySnapshot.forEach((doc) => {
          list.push({
            rank: index,
            ...doc.data(),
          });
          index = index + 1;
        });

        lastDoc =
          list.length > 0
            ? querySnapshot.docs[querySnapshot.docs.length - 1]
            : null;
        docsCount = docsCount + querySnapshot.docs.length;
        resolve(list);
      } catch (e) {
        reject(e.message);
      }
    });
  }
);

export const loadCelebrityForCategory = createAsyncThunk(
  "celebrity/loadCelebrityForCategory",
  async (category) => {
    return new Promise(async (resolve, reject) => {
      try {
        const runQuery = query(
          collection(db, "celebrity"),
          where("type", "array-contains", category)
        );

        const querySnapshot = await getDocs(runQuery);

        let list = [];

        querySnapshot.forEach((doc) => {
          list.push(doc.data());
        });

        lastCategoryDoc[category] =
          querySnapshot.docs[querySnapshot.docs.length - 1];
        resolve({ list: list, category: category });
      } catch (e) {
        reject(e.message);
      }
    });
  }
);

export const addCelebrityRating = createAsyncThunk(
  "celebrity/addCelebrityRating",
  async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        await setDoc(
          doc(db, "celebrity", data.celebrityId, "rating", data.userId),
          {
            createdAt: serverTimestamp(),
            id: data.userId,
            lastUpdatedAt: serverTimestamp(),
            rating: data.newUserRating,
          }
        );

        resolve({ id: data.celebrityId, rating: data.newRating });
      } catch (e) {
        console.error(e);
        reject(e.message);
      }
    });
  }
);

export const updateCelebrityRating = createAsyncThunk(
  "celebrity/updateCelebrityRating",
  async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        await updateDoc(
          doc(db, "celebrity", data.celebrityId, "rating", data.userId),
          {
            lastUpdatedAt: serverTimestamp(),
            rating: data.newUserRating,
          }
        );

        const ratingDifference =
          Math.max(data.newUserRating, data.oldUserRating) -
          Math.min(data.newUserRating, data.oldUserRating);

        if (ratingDifference > 0) {
          if (data.newUserRating > data.oldUserRating) {
            resolve({
              id: data.celebrityId,
              rating: data.oldRating + ratingDifference,
            });
          } else {
            resolve({
              id: data.celebrityId,
              rating: data.oldRating - Math.abs(ratingDifference),
            });
          }
        } else {
          resolve({ id: data.celebrityId, rating: data.newRating });
        }
      } catch (e) {
        reject(e.message);
      }
    });
  }
);

export const celebritySlice = createSlice({
  name: "celebrity",
  initialState,
  reducers: {
    addCelebrity: (state, { payload }) => {
      state.celebrity = [...state.celebrity, payload];
    },
    updateCelebrityReviewCount: (state, { payload }) => {
      let list = Object.assign([], state.celebrity);
      let index = list.findIndex((item) => item.id === payload.id);
      if (index >= 0) {
        let item = {
          ...list[index],
          reviewCount: (list[index].reviewCount || 0) + 1,
        };
        list[index] = item;
      }
      state.celebrity = [...list];
    },
    reduceCelebrityReviewCount: (state, { payload }) => {
      let list = Object.assign([], state.celebrity);
      let index = list.findIndex((item) => item.id === payload.id);
      if (index >= 0) {
        let item = {
          ...list[index],
          reviewCount:
            (list[index].reviewCount || 0) > 0
              ? (list[index].reviewCount || 0) - 1
              : 0,
        };
        list[index] = item;
      }
      state.celebrity = [...list];
    },
  },
  extraReducers: (builder) => {
    //loadCelebrity
    builder.addCase(loadCelebrity.pending, (state, _) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(loadCelebrity.fulfilled, (state, { payload }) => {
      state.celebrity = payload;
      state.isEndReached = payload.length < 10;
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(loadCelebrity.rejected, (state, { error }) => {
      state.isLoading = false;
      state.isError = true;
      state.error = error.message;
    });
    //loadMoreCelebrity
    builder.addCase(loadMoreCelebrity.pending, (state, _) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(loadMoreCelebrity.fulfilled, (state, { payload }) => {
      state.celebrity = payload;
      state.isEndReached = payload.length < 10;
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(loadMoreCelebrity.rejected, (state, { error }) => {
      state.isLoading = false;
      state.isError = true;
      state.error = error.message;
    });
    //loadCelebrityForCategory
    builder.addCase(loadCelebrityForCategory.pending, (state, _) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(
      loadCelebrityForCategory.fulfilled,
      (state, { payload }) => {
        let newCategorizedCelebrities = Object.assign(
          [],
          state.categorizedCelebrities
        );
        let categoryCelebrities = payload.list;

        if (newCategorizedCelebrities[payload.category]) {
          categoryCelebrities = [
            ...newCategorizedCelebrities[payload.category],
            ...categoryCelebrities,
          ];
          categoryCelebrities = categoryCelebrities.sort(
            (a, b) => b.rating - a.rating
          );
          newCategorizedCelebrities[payload.category] = categoryCelebrities;
        } else {
          categoryCelebrities = categoryCelebrities.sort(
            (a, b) => b.rating - a.rating
          );
          newCategorizedCelebrities[payload.category] = categoryCelebrities;
        }

        state.categorizedCelebrities = newCategorizedCelebrities;
        state.isLoading = false;
        state.isError = false;
        state.error = "";
      }
    );
    builder.addCase(loadCelebrityForCategory.rejected, (state, { error }) => {
      state.isLoading = false;
      state.isError = true;
      state.error = error.message;
    });
    //addCelebrityRating
    builder.addCase(addCelebrityRating.pending, (state, _) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(addCelebrityRating.fulfilled, (state, { payload }) => {
      let celebrity = Object.assign([], state.celebrity);
      let index = celebrity.findIndex((item) => item.id === payload.id);
      if (index >= 0) {
        let item = { ...celebrity[index], rating: payload.rating };
        celebrity[index] = item;
      }
      celebrity = celebrity.sort((a, b) => {
        if (a.rating === b.rating) return 0;
        else if (a.rating < b.rating) return 1;
        else return -1;
      });
      state.celebrity = [...celebrity];
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(addCelebrityRating.rejected, (state, { error }) => {
      state.isLoading = false;
      state.isError = true;
      state.error = error.message;
    });
    //updateCelebrityRating
    builder.addCase(updateCelebrityRating.pending, (state, _) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(updateCelebrityRating.fulfilled, (state, { payload }) => {
      let celebrity = Object.assign([], state.celebrity);
      let index = celebrity.findIndex((item) => item.id === payload.id);
      if (index >= 0) {
        let item = { ...celebrity[index], rating: payload.rating };
        celebrity[index] = item;
      }
      celebrity = celebrity.sort((a, b) => {
        if (a.rating === b.rating) return 0;
        else if (a.rating < b.rating) return 1;
        else return -1;
      });
      state.celebrity = [...celebrity];
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(updateCelebrityRating.rejected, (state, { error }) => {
      state.isLoading = false;
      state.isError = true;
      state.error = error.message;
    });
  },
});

export const {
  addCelebrity,
  updateCelebrityReviewCount,
  reduceCelebrityReviewCount,
} = celebritySlice.actions;

export const celebrityReducer = celebritySlice.reducer;
