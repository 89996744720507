import { useNavigate } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';

function BackArrowNavigation() {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  }

  return (
    <div className="h-10 bg-gray-900 rounded-xl m-2 flex flex-row items-center px-3 text-gray-200 text-sm font-bold cursor-pointer z-[999] fixed top-10"
      onClick={() => handleBackClick()}>
      <BiArrowBack className="w-6 h-6" /><span>Back</span>
    </div>
  )
}

export default BackArrowNavigation;