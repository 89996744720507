import { TextAtom } from "../view/atoms";

import { useNavigate } from "react-router-dom";


import { useAppSelector } from '../redux'
import { Link } from "react-router-dom";

import { useAuthContext } from "../context/auth";

const dummyImage = require('../assets/images/dummyUser.png')

function UserProfile() {
  const { onLogout, isUserLoggedIn } = useAuthContext()
  const navigate = useNavigate()

  const user = useAppSelector(state => state.user.profile)

  const handleSignOut = () => {
    onLogout()
    navigate('/login')
  }

  return (
    <div className="h-screen  flex flex-col items-center p-6 w-full bg-gray-900">
      {isUserLoggedIn && user.cover_url ? <img className="w-28 h-28 mb-2 object-cover rounded-full" src={user.cover_url} alt="" /> : <img className="w-28 h-28 mb-2 object-cover rounded-full" src={dummyImage} alt="" />}
      {isUserLoggedIn ? <TextAtom div_style="my-1 mt-4" text_style="text-gray-500 text-lg font-semibold" text={user.name} /> : <TextAtom div_style="my-1 mt-4" text_style="text-gray-500 text-lg font-semibold" text={""} />}
      {isUserLoggedIn ? <TextAtom text_style="text-gray-500" text={user.email} /> : <TextAtom div_style="my-1 mt-4" text_style="text-gray-500 text-lg font-semibold" text="" />}

      {isUserLoggedIn ? <Link to="/profile_edit" className="mt-4 mx-4 text-sm md:text-md text-gray-800 bg-primary p-2 rounded-lg font-semibold cursor-pointer">
        Edit Profile
      </Link> : null}

      {isUserLoggedIn ? <div onClick={() => handleSignOut()} className="fixed bottom-0 mb-20 cursor-pointer">
        <TextAtom text_style="text-gray-300 border border-gray-300 px-3 py-1 rounded-md" text={"Signout"} />
      </div> : null}
    </div>
  )
}

export default UserProfile