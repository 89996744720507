import BackArrowNavigation from '../components/BackArrowNavigation'

function RefEarnPage() {
    return (
        <main className="min-h-screen bg-gray-900">
            <BackArrowNavigation />
        </main>
    )
}

export default RefEarnPage