import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  db,
  collection,
  doc,
  getDocs,
  setDoc,
  updateDoc,
  ref,
  storage,
  getDownloadURL,
  query,
  orderBy,
  limit,
  serverTimestamp,
} from "../../firebase";

const initialState = {
  top_review: [],
  isEndReached: false,
  currentPageNum: 1,
  totalPages: 0,
  isLoading: false,
  isError: false,
  error: "",
};

export const loadTopReview = createAsyncThunk(
  "topReview/loadTopReview",
  async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const runCelebrityQuery = query(
          collection(db, "celebrity_review"),
          orderBy("rating", "desc"),
          limit(10)
        );

        const queryCelebritySnapshot = await getDocs(runCelebrityQuery);
        let celebrity = [];

        queryCelebritySnapshot.forEach((doc) => {
          celebrity.push({
            ...doc.data(),
          });
        });

        const runMovieQuery = query(
          collection(db, "movie_review"),
          orderBy("rating", "desc"),
          limit(10)
        );

        const queryMovieSnapshot = await getDocs(runMovieQuery);
        let movie = [];

        queryMovieSnapshot.forEach((doc) => {
          movie.push({
            ...doc.data(),
          });
        });

        const runNewsQuery = query(
          collection(db, "news_review"),
          orderBy("rating", "desc"),
          limit(10)
        );

        const queryNewsSnapshot = await getDocs(runNewsQuery);
        let news = [];

        queryNewsSnapshot.forEach((doc) => {
          news.push({
            ...doc.data(),
          });
        });

        const runProductQuery = query(
          collection(db, "product_review"),
          orderBy("rating", "desc"),
          limit(10)
        );

        const queryProductSnapshot = await getDocs(runProductQuery);
        let product = [];

        queryProductSnapshot.forEach((doc) => {
          product.push({
            ...doc.data(),
          });
        });

        const runBrandQuery = query(
          collection(db, "brand_review"),
          orderBy("rating", "desc"),
          limit(10)
        );

        const queryBrandSnapshot = await getDocs(runBrandQuery);
        let brand = [];

        queryBrandSnapshot.forEach((doc) => {
          brand.push({
            ...doc.data(),
          });
        });

        const runShowQuery = query(
          collection(db, "show_review"),
          orderBy("rating", "desc"),
          limit(10)
        );

        const queryShowSnapshot = await getDocs(runShowQuery);
        let show = [];

        queryShowSnapshot.forEach((doc) => {
          show.push({
            ...doc.data(),
          });
        });

        loadProfileImages(
          0,
          [...celebrity, ...movie, ...news, ...product, ...brand, ...show],
          resolve
        );
      } catch (e) {
        reject(e.message);
      }
    });
  }
);

const loadProfileImages = async (index, list, resolve) => {
  if (index < list.length) {
    try {
      let data = list[index];
      let fileName = `user/${data.userId}.jpg`;
      const storageRef = ref(storage, fileName);
      let downloadUrl = await getDownloadURL(storageRef);
      list[index].userCoverUrl = downloadUrl;
      loadProfileImages(index + 1, list, resolve);
    } catch (err) {
      loadProfileImages(index + 1, list, resolve);
    }
  } else {
    resolve(list);
  }
};

export const addReviewRating = createAsyncThunk(
  "topReview/addReviewRating",
  async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        await setDoc(
          doc(
            db,
            `${data.sourceType}_review`,
            data.reviewId,
            "rating",
            data.userId
          ),
          {
            createdAt: serverTimestamp(),
            id: data.userId,
            lastUpdatedAt: serverTimestamp(),
            rating: data.newUserRating,
          }
        );

        resolve({ id: data.reviewId, rating: data.newRating });
      } catch (e) {
        reject(e.message);
      }
    });
  }
);

export const updateReviewRating = createAsyncThunk(
  "topReview/updateReviewRating",
  async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        await updateDoc(
          doc(
            db,
            `${data.sourceType}_review`,
            data.reviewId,
            "rating",
            data.userId
          ),
          {
            lastUpdatedAt: serverTimestamp(),
            rating: data.newUserRating,
          }
        );

        const ratingDifference =
          Math.max(data.newUserRating, data.oldUserRating) -
          Math.min(data.newUserRating, data.oldUserRating);

        if (ratingDifference > 0) {
          if (data.newUserRating > data.oldUserRating) {
            resolve({
              id: data.reviewId,
              rating: data.oldRating + ratingDifference,
            });
          } else {
            resolve({
              id: data.reviewId,
              rating: data.oldRating - Math.abs(ratingDifference),
            });
          }
        } else {
          resolve({ id: data.reviewId, rating: data.newRating });
        }
      } catch (e) {
        reject(e.message);
      }
    });
  }
);

export const topReviewSlice = createSlice({
  name: "topReview",
  initialState,
  extraReducers: (builder) => {
    //loadTopReview
    builder.addCase(loadTopReview.pending, (state, _) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(loadTopReview.fulfilled, (state, { payload }) => {
      state.top_review = payload.sort(
        (a, b) => parseInt(b.rating) - parseInt(a.rating)
      );
      state.isEndReached = payload.length < 50;
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(loadTopReview.rejected, (state, { error }) => {
      state.isLoading = false;
      state.isError = true;
      state.error = error.message;
    });
    //addReviewRating
    builder.addCase(addReviewRating.pending, (state, _) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(addReviewRating.fulfilled, (state, { payload }) => {
      let reviews = Object.assign([], state.top_review);
      let index = reviews.findIndex((review) => review.id === payload.id);
      if (index >= 0) {
        let review = { ...reviews[index], rating: payload.rating };
        reviews[index] = review;
      }
      reviews = reviews.sort((a, b) => {
        if (a.rating === b.rating) return 0;
        else if (a.rating < b.rating) return 1;
        else return -1;
      });
      state.top_review = [...reviews];
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(addReviewRating.rejected, (state, { error }) => {
      state.isLoading = false;
      state.isError = true;
      state.error = error.message;
    });
    //updateReviewRating
    builder.addCase(updateReviewRating.pending, (state, _) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(updateReviewRating.fulfilled, (state, { payload }) => {
      let reviews = Object.assign([], state.top_review);
      let index = reviews.findIndex((review) => review.id === payload.id);
      if (index >= 0) {
        let review = { ...reviews[index], rating: payload.rating };
        reviews[index] = review;
      }
      reviews = reviews.sort((a, b) => {
        if (a.rating === b.rating) return 0;
        else if (a.rating < b.rating) return 1;
        else return -1;
      });
      state.top_review = [...reviews];
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(updateReviewRating.rejected, (state, { error }) => {
      state.isLoading = false;
      state.isError = true;
      state.error = error.message;
    });
  },
});

export const topShowsReducer = topReviewSlice.reducer;
