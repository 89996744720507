import React, { useEffect, useState } from 'react'
import BannerSlider from '../components/banner_slider'
import MySlider from '../components/MySlider'
import { useIsMobileViewContext } from '../context/isMobileView'
import Slider from 'react-slick'
import latestDeals from '../assets/images/latestDeals.png'
import Rectangle2 from '../assets/images/Rectangle2.png'
import { useNavigate } from 'react-router-dom'
import { GetTheData } from '../components/Api/FetchData'
import { FaAngleLeft, FaAngleRight, FaRegHeart, FaTimes } from 'react-icons/fa'
function Offers() {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'auto' });
        FetchingOverAllData()
    }, []);
    const [rentalCategory, setRentalCategory] = useState([])
    const [rentalData, setRentalData] = useState([])
    const [FilteredBasedCategorys, setFilteredBasedCategorys] = useState([])
    async function FetchingOverAllData() {
        let temp1 = []
        let temp2 = []
        try {
            const response = await GetTheData('rentalCategory/active')
            setRentalCategory(response.data)
            temp1 = [...response.data]
        } catch (error) {
            console.log(error.message);
        }
        try {
            const response = await GetTheData('rental/approved')
            setRentalData(response.data)
            temp2 = [...response.data]
        } catch (error) {
            console.log(error.message);
        }

        FilteringByCategory(temp1, temp2)
    }

    function FilteringByCategory(cat, data) {
        let temp = []
        for (let i = 0; i < cat.length; i++) {
            let temp1 = []
            for (let j = 0; j < data.length; j++) {
                if (cat[i].id === data[j].rentalCategory) {
                    temp1.push(data[j])
                }
            }
            temp.push(temp1)
        }
        setFilteredBasedCategorys(temp)
    }
    const { isMobileView } = useIsMobileViewContext();
    const CustomNextArrow = ({ onClick }) => {
        return (
            <button
                className="custom-prev-button"
                style={{
                    position: 'absolute',
                    top: '50%',
                    right: '40px', /* Adjust as needed */
                    transform: 'translateY(-50%)',
                    backgroundColor: 'white', /* Adjust styles as needed */
                    border: 'none', /* Adjust styles as needed */
                    cursor: 'pointer',
                    borderRadius: 100
                }}
                onClick={onClick}
            >
                <div className='border-2 border-dashed border-primary rounded-full p-1'>
                    <div class="flex items-center lg:order-2 bg-red-9 gap-x-5 p-5 rounded-full bg-primary">
                        <FaAngleRight className="w-4 h-4 text-white font-normal" />
                    </div>
                </div>
            </button>
        );
    };
    const CustomPrevArrow = ({ onClick }) => {
        return (
            <button
                className="custom-prev-button"
                style={{
                    position: 'absolute',
                    top: '50%',
                    zIndex: '99',
                    left: '40px',
                    transform: 'translateY(-50%)',
                    backgroundColor: 'white',
                    border: 'none',
                    cursor: 'pointer',
                    borderRadius: 100
                }}
                onClick={onClick}
            >
                <div className='border-2 border-dashed border-primary rounded-full p-1'>
                    <div class="flex  items-center lg:order-2 bg-red-9 gap-x-5 p-5 rounded-full bg-primary">
                        <FaAngleLeft className="w-4 h-4 text-white font-normal" />
                    </div>
                </div>
            </button>
        );
    };
    const navigate = useNavigate()
    const [showProductDetail, setShowProductDetail] = useState(false)
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <CustomNextArrow onClick={onclick} />,
        prevArrow: <CustomPrevArrow onClick={onclick} />
    };

    const [popUpObject, setPopUpObject] = useState({})
    function OpenModalFunction(e) {
        setShowProductDetail(true)
        setPopUpObject(e)
    }
    function CloseModalFunction() {
        setPopUpObject(null)
        setShowProductDetail(false)
    }
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'auto' });
    }, [popUpObject]);

    const [searchContent, setSearchContent] = useState('')
    const [filteredArray, setFilteredArray] = useState([])
    const [SwapScreens, setSwapScreens] = useState(false)
    function SearchFunction(e) {
        setSearchContent(e.target.value)
        if (e.target.value.length >= 1) {
            const data = e.target.value.trim().toLowerCase()
            const response = rentalData.filter(e => e.name.trim().toLowerCase().match(data))
            setFilteredArray(response)
            setSwapScreens(true)
        } else {
            setSwapScreens(false)
            setFilteredArray(rentalData)
        }
    }
    function SelectingCategory(e) {
        console.log(e);
        if (e == -1) {
            setSwapScreens(false)
        } else {
            setSwapScreens(true)
            let tem=[...rentalData]
            console.log(tem);
            const responce = tem.filter(e1 => e1.rentalCategory === Number(e))
            console.log(responce);
            setFilteredArray(responce)
        }
    }
    return (
        <div className='w-full flex bg-[#0e162b] flex-col items-center'>
            {showProductDetail && popUpObject != null ?
                <div className=' w-full flex flex-col h-[100vh] absolute lg:justify-center items-center bg-transparent/75 z-[99]'>
                    <div className='max-w-screen-lg py-10 w-full bg-white  shadow-lg relative rounded-sm'>
                        <div onClick={() => CloseModalFunction()} className='p-2 rounded-full cursor-pointer bg-primary absolute z-10 right-[2px] top-[2px]'>
                            <FaTimes className='w-4 h-4' />
                        </div>
                        <div class="lg:grid lg:grid-cols-2 p-4 gap-16">
                            <div class="shrink-0 max-w-md lg:max-w-lg mx-auto">
                                <Slider {...settings}>
                                    {
                                        popUpObject.picturesUrl != null && JSON.parse(popUpObject.picturesUrl).map((e, index) =>
                                            <img key={index} className='w-full lg:h-[500px] object-cover object-top' src={`https://storage.googleapis.com/cinimini-console/rentals/${popUpObject.id}/${e}`} />
                                        )
                                    }
                                </Slider>
                            </div>

                            <div class="mt-6 sm:mt-8 lg:mt-0 flex flex-col gap-y-2">
                                <h1 class="text-xl font-QuicksandBold text-textColor sm:text-3xl dark:text-white">
                                    {popUpObject.name}
                                </h1>
                                <h1 class="text-lg f font-bold text-textColor  dark:text-white">
                                    Owner Name : {popUpObject.postedByName}
                                </h1>
                                <p class="my-2 text-gray-700 text-sm font-semibold ">
                                    {popUpObject.description}
                                </p>
                                <p class=" text-gray-700 text-sm w-full break-all font-semibold ">
                                    {popUpObject.address}
                                </p>
                                <div className='flex flex-row items-center gap-x-2'>
                                    <h1 class="text-xl font-QuicksandBold text-textColor text-red-700 sm:text-xl font-bold">
                                        ₹{popUpObject.originalAmount}/-per Month
                                    </h1>
                                    {/* <div className='rounded-full bg-[#f5fde2]'>
                                        <h3 className='text-textColor py-3 px-5 border-[1px] border-dashed rounded-full text-xs font-QuicksandSemiBold'>92 in stack</h3>
                                    </div> */}
                                </div>
                                <h5 className='font-QuicksandSemiBold text-textColor my-2 text-lg font-bold'>Contact Number :<span className='font-semibold text-primary'> +91 {popUpObject.postedByMobileNumber}</span></h5>
                                {/* <div className='flex flex-row items-center mt-5 gap-x-3'> */}
                                {/* <input placeholder='0' className='w-10 h-10 outline-none text-textColor text-center rounded-full px-1 bg-gray-200' /> */}
                                {/* <ButtonStyle text={'ADD TO CART'} backColor={'bg-[#fd8087]'} textcolor={'text-white px-12'} hovercolor={'hover:bg-[#81d1e5]'} /> */}
                                {/* <FaRegHeart className='w-6 h-6 cursor-pointer' /> */}
                                {/* </div> */}
                                <div className=' flex flex-row items-center gap-2'>
                                    <h5 className=' text-textColor text-xs font-bold'>Posted At :</h5>
                                    <h5 className='  text-textColor bg-gray-200 p-2 rounded-full px-4 font-bold text-[11px]'>{new Date(popUpObject.creationTime).getUTCFullYear() + '-' + (new Date(popUpObject.creationTime).getUTCMonth() + 1) + '-' + new Date(popUpObject.creationTime).getUTCDate()}</h5>
                                    {/* <h5 className='font-QuicksandSemiBold w-max text-textColor bg-gray-200 p-1 rounded-full px-2 text-[10px]'>Jeans</h5>
                                    <h5 className='font-QuicksandSemiBold w-max text-textColor bg-gray-200 p-1 rounded-full px-2 text-[10px]'>Rompers & Jumpsuits</h5> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : null}
            <div className='flex flex-col xl:flex-row mt-5 gap-5 sm:mt-10 lg:mt-0 xl:mt-10 mb-10 items-center justify-evenly w-full'>
                <div className='opacity-0'>dfdasdasdsdadsfsdfs</div>
                <div>
                    <form className="sm:max-w-6xl py-1 mx-auto bg-white flex flex-row rounded-full items-center px-2">
                        <div className="mx-4 sm:block hidden">
                            <select onChange={(e) => SelectingCategory(e.target.value)} className="block w-full rounded-md py-2 h-full outline-none  font-semibold text-base ">
                                <option value={-1}>Select</option>
                                {rentalCategory.length > 0 && rentalCategory.map((e, index) =>
                                    <option key={index} className='font-semibold' value={e.id}>{e.name}</option>
                                )}
                            </select>
                        </div>
                        <div className='font-bold sm:block hidden text-gray-500'>|</div>
                        <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                        <div className="relative">
                            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input value={searchContent} onChange={(e) => SearchFunction(e)} type="search" id="default-search" className="block min-[375px]:w-[300px] min-[325px]:w-full p-2 lg:p-4 ps-10 lg:ps-10 outline-none font-semibold text-base text-gray-900 rounded-lg bg-gray-50 " placeholder="Search Offers" />
                        </div>


                        {/* <div className='font-bold  sm:block hidden text-gray-500'>|</div>
                        <div className="ml-4  sm:block hidden">
                            <input type="search" id="another-search" className="block w-full p-4 text-base font-semibold text-gray-900 outline-none" placeholder="Search Location" />
                        </div> */}
                        <div className="ml-4  sm:block hidden">
                            <button className="text-white bg-primary hover:bg-primary/75 px-10 rounded-full py-3 font-bold">Search</button>
                        </div>
                    </form>
                </div>
                <div onClick={() => navigate('/OffersPostingPage', { state: { rentalCategory: rentalCategory } })} className='text-white hover:bg-primary hover:border-transparent cursor-pointer duration-200 border-2 border-white py-1 rounded-full px-10'>
                    <h2>Sell</h2>
                </div>
            </div>
            {/* <div className='relative w-full h-[550px] sm:h-auto  flex flex-col items-center'>
                <img className='w-full h-[350px] sm:h-[780px] overflow-hidden object-cover object-center' src={'https://vodi.madrasthemes.com/main/wp-content/uploads/sites/2/2019/05/1-home-v2-bg-new.jpg'} />
                <div className='w-full absolute sm:top-[180px] top-[40px] '>
                    <h3 className='text-[#19272e] px-5 font-serif text-2xl sm:text-5xl font-bold'>Super Deals</h3>
                    <h4 className='text-red-600 text-base sm:text-xl px-5 w-full sm:w-[500px] font-semibold font-sans'>Unbeatable deals await! Grab them now before they're gone, with discounts up to <span className=''>60% off!</span></h4>
                    <div className='mt-10 w-full'>
                        <MySlider isScrollActionEnabled={true}>
                            {
                                superDeals.map((e, index) =>
                                    <div key={index} className='sm:w-[250px] flex-none w-full border-2 border-dashed border-teal-500 sm:h-[350px] h-auto relative bg-gray-200 rounded-xl sm:mr-6 flex flex-col justify-between py-2 items-center px-2'>
                                        <div className='bg-white absolute right-[10px] top-[10px] rounded-full px-2'>
                                            <h2 className=' text-red-600 font-bold'>{e.offer}% OFF</h2>
                                        </div>
                                        <img className='absolute left-[10px] top-[10px] rounded-full w-10 h-10' src={'https://thedealapp.in/assets/avatars/3Z4P4CS55EAqKvSv.png'} />
                                        <img className='rounded-md sm:w-[180px] h-[200px] sm:h-[200px] mt-3 object-contain' src={e.Productimage} />
                                        <div className='flex flex-row items-center gap-x-2'>
                                            <h1 className='text-sm text-black line-through'>₹{e.OriginalAmount} </h1>
                                            <span className='text-base lining-nums text-red-600 font-bold'>₹{e.Actual}/-</span>
                                        </div>
                                        <h1 className='text-base font-semibold line-clamp-2 text-wrap text-center'>{e.name}</h1>
                                        <div className='border-b-[1px] mb-2 border-b-gray-600 w-full' />
                                        <div className='flex flex-row items-center justify-between w-full '>
                                            <img className='w-[30%] h-full object-contain' src={e.CompanyImage} />
                                            <div className='py-1 px-2 rounded-full border-2 border-green-600'>
                                                <h3 className='text-green-600 text-sm font-bold'>{e.Deal}</h3>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </MySlider>
                    </div>
                </div>
            </div> */}
            {SwapScreens == false ? <>
                <div className='w-full '>
                    <h3 className='text-white px-5 font-serif text-2xl sm:text-3xl font-bold'>Recently Added</h3>
                    <div className='mt-5 w-full'>
                        <MySlider isScrollActionEnabled={rentalData.length > 6 || isMobileView ? true : false}>
                            {
                                rentalData.map((e, index) =>
                                    <div onClick={() => OpenModalFunction(e)} key={index} className='sm:w-[250px] cursor-pointer flex-none w-full border-2 border-dashed border-teal-500 sm:h-[350px] h-auto relative bg-gray-200 rounded-xl sm:mr-6 flex flex-col justify-between py-2 items-center px-2'>
                                        <div className='bg-white absolute right-[10px] top-[10px] rounded-full px-2'>
                                            <h2 className=' text-red-600 font-bold'>{e.discountedPercentage}% OFF</h2>
                                        </div>
                                        <img className='absolute left-[10px] top-[10px] rounded-full w-10 h-10' src={'https://thedealapp.in/assets/avatars/3Z4P4CS55EAqKvSv.png'} />
                                        <img className='rounded-md w-full h-[240px] sm:h-[200px] mt-3 object-cover' src={`https://storage.googleapis.com/cinimini-console/rentals/${e?.id}/${JSON.parse(e.picturesUrl)[0]}`} />
                                        <div className='flex '>
                                            {/* <h1 className='text-sm text-black line-through'>₹10</h1> */}
                                            <span className='text-base lining-nums text-red-600 font-bold'>₹{e.originalAmount}/-per Month</span>
                                        </div>
                                        <h1 className='text-base font-semibold line-clamp-2 text-wrap text-center'>{e.name}</h1>
                                        <div className='border-b-[1px] border-b-gray-600 w-full' />
                                        <h1 className='text-sm font-semibold line-clamp-1 text-wrap text-center'>{e.address}</h1>
                                    </div>
                                )
                            }
                        </MySlider>
                    </div>
                </div>
                {
                    FilteredBasedCategorys.length > 0 && FilteredBasedCategorys.map((e1, index1) => e1.length > 0 ?
                        <div key={index1} className='w-full '>
                            <h3 className='text-white px-5 font-serif text-2xl sm:text-3xl font-bold'>{rentalCategory[index1].name}</h3>
                            <div className='mt-5 w-full'>
                                <MySlider isScrollActionEnabled={e1.length > 6 || isMobileView ? true : false}>
                                    {
                                        e1.map((e, index) =>
                                            <div onClick={() => OpenModalFunction(e)} key={index} className='sm:w-[250px] cursor-pointer flex-none w-full border-2 border-dashed border-teal-500 sm:h-[350px] h-auto relative bg-gray-200 rounded-xl sm:mr-6 flex flex-col justify-between py-2 items-center px-2'>
                                                <div className='bg-white absolute right-[10px] top-[10px] rounded-full px-2'>
                                                    <h2 className=' text-red-600 font-bold'>{e.discountedPercentage}% OFF</h2>
                                                </div>
                                                <img className='absolute left-[10px] top-[10px] rounded-full w-10 h-10' src={'https://thedealapp.in/assets/avatars/3Z4P4CS55EAqKvSv.png'} />
                                                <img className='rounded-md w-full h-[240px] sm:h-[200px] mt-3 object-cover' src={`https://storage.googleapis.com/cinimini-console/rentals/${e?.id}/${JSON.parse(e.picturesUrl)[0]}`} />
                                                <div className='flex '>
                                                    {/* <h1 className='text-sm text-black line-through'>₹10</h1> */}
                                                    <span className='text-base lining-nums text-red-600 font-bold'>₹{e.originalAmount}/-per Month</span>
                                                </div>
                                                <h1 className='text-base font-semibold line-clamp-2 text-wrap text-center'>{e.name}</h1>
                                                <div className='border-b-[1px] border-b-gray-600 w-full' />
                                                <h1 className='text-sm font-semibold line-clamp-1 text-wrap text-center'>{e.address}</h1>
                                                {/* <div className='flex flex-row items-center justify-between w-full '>
                                        <img className='w-[30%] h-full object-contain' src={e.CompanyImage} />
                                        <div className='py-1 px-2 rounded-full border-2 border-green-600'>
                                            <h3 className='text-green-600 text-sm font-bold'>{e.Deal}</h3>
                                        </div>
                                    </div> */}
                                            </div>
                                        )
                                    }
                                </MySlider>
                            </div>
                        </div>
                        : null
                    )
                }
                {/* <img className='w-full my-5 h-40 object-cover' src={'https://img4.nbstatic.in/tr:w-2800/660f9db9017022000c3a4712.jpg'} />
                <div className='relative w-full  flex flex-col items-center'>
                    <img className='w-full h-screen sm:h-[780px] overflow-hidden object-cover object-center' src={latestDeals} />
                    <div className='w-full absolute sm:top-[180px] top-[60px] '>
                        <img src={'https://media.tenor.com/gMwv2TUO2FEAAAAM/scentsy-homescentsbypam.gif'}/>
                        <h3 className='text-[#19272e] px-5 font-serif text-2xl sm:text-5xl font-bold'>Latest Deals</h3>
                        <h4 className='text-red-600 text-base sm:text-xl px-5 w-full sm:w-[500px] font-semibold font-sans'>Unbeatable deals await! Grab them now before they're gone, with discounts up to <span className=''>60% off!</span></h4>
                        <div className='mt-10 w-full'>
                            <MySlider isScrollActionEnabled={true}>
                                {
                                    Computer.map((e, index) =>
                                        <div key={index} className='sm:w-[250px] flex-none w-full border-2 border-dashed border-teal-500 sm:h-[350px] h-auto relative bg-gray-200 rounded-xl sm:mr-6 flex flex-col justify-between py-2 items-center px-2'>
                                            <div className='bg-white absolute right-[10px] top-[10px] rounded-full px-2'>
                                                <h2 className=' text-red-600 font-bold'>{e.offer}% OFF</h2>
                                            </div>
                                            <img className='absolute left-[10px] top-[10px] rounded-full w-10 h-10' src={'https://thedealapp.in/assets/avatars/3Z4P4CS55EAqKvSv.png'} />
                                            <img className='rounded-md sm:w-[180px] h-[300px] sm:h-[200px] mt-3 object-contain' src={e.Productimage} />
                                            <div className='flex flex-row items-center gap-x-2'>
                                                <h1 className='text-sm text-black line-through'>₹{e.OriginalAmount} </h1>
                                                <span className='text-base lining-nums text-red-600 font-bold'>₹{e.Actual}/-</span>
                                            </div>
                                            <h1 className='text-base font-semibold line-clamp-2 text-wrap text-center'>{e.name}</h1>
                                            <div className='border-b-[1px] mb-2 border-b-gray-600 w-full' />
                                            <div className='flex flex-row items-center justify-between w-full '>
                                                <img className='w-[30%] h-full object-contain' src={e.CompanyImage} />
                                                <div className='py-1 px-2 rounded-full border-2 border-green-600'>
                                                    <h3 className='text-green-600 text-sm font-bold'>{e.Deal}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </MySlider>
                        </div>
                    </div>
                </div> */}
            </> : <div className='w-full flex flex-row gap-5 px-5 mb-10 items-center flex-wrap'>
                {
                    filteredArray.map((e, index) =>
                        <div onClick={() => OpenModalFunction(e)} key={index} className='sm:w-[250px] cursor-pointer flex-none w-full border-2 border-dashed border-teal-500 sm:h-[350px] h-auto relative bg-gray-200 rounded-xl  flex flex-col justify-between py-2 items-center px-2'>
                            <div className='bg-white absolute right-[10px] top-[10px] rounded-full px-2'>
                                <h2 className=' text-red-600 font-bold'>{e.discountedPercentage}% OFF</h2>
                            </div>
                            <img className='absolute left-[10px] top-[10px] rounded-full w-10 h-10' src={'https://thedealapp.in/assets/avatars/3Z4P4CS55EAqKvSv.png'} />
                            <img className='rounded-md w-full h-[240px] sm:h-[200px] mt-3 object-cover' src={`https://storage.googleapis.com/cinimini-console/rentals/${e?.id}/${JSON.parse(e.picturesUrl)[0]}`} />
                            <div className='flex '>
                                {/* <h1 className='text-sm text-black line-through'>₹10</h1> */}
                                <span className='text-base lining-nums text-red-600 font-bold'>₹{e.originalAmount}/-per Month</span>
                            </div>
                            <h1 className='text-base font-semibold line-clamp-2 text-wrap text-center'>{e.name}</h1>
                            <div className='border-b-[1px] border-b-gray-600 w-full' />
                            <h1 className='text-sm font-semibold line-clamp-1 text-wrap text-center'>{e.address}</h1>
                        </div>
                    )
                }
            </div>}
        </div>
    )
}

export default Offers

{/* <div className='xl:max-w-[1640px] w-full xl:mx-auto rounded-md pt-10'>
                {ads && <BannerSlider ads={ads} />}
            </div> */}
{/* <div className='w-full md:px-10 mt-10'>
                <h1 className='text-white font-bold sm:pl-0 pl-2 text-2xl mb-5'>Super Deals</h1>
                <MySlider isScrollActionEnabled={true}>
                    {
                        superDeals.map((e, index) =>
                            <div key={index} className='sm:w-[250px] flex-none w-full sm:h-[350px] h-auto relative  bg-white rounded-xl sm:mr-6 flex flex-col justify-between py-2 items-center px-2'>
                                <div className='bg-white absolute right-[10px] top-[10px] rounded-full px-2'>
                                    <h2 className=' text-red-600 font-bold'>{e.offer}% OFF</h2>
                                </div>
                                <img className='absolute left-[10px] top-[10px] rounded-full w-10 h-10' src={'https://thedealapp.in/assets/avatars/3Z4P4CS55EAqKvSv.png'} />
                                <img className='rounded-md sm:w-[180px] h-[300px] sm:h-[200px] mt-3 object-contain' src={e.Productimage} />
                                <div className='flex flex-row items-center gap-x-2'>
                                    <h1 className='text-sm text-black line-through'>₹{e.OriginalAmount} </h1>
                                    <span className='text-base lining-nums text-red-600 font-bold'>₹{e.Actual}/-</span>
                                </div>
                                <h1 className='text-base font-semibold line-clamp-2 text-wrap text-center'>{e.name}</h1>
                                <div className='border-b-[1px] mb-2 border-b-gray-600 w-full' />
                                <div className='flex flex-row items-center justify-between w-full '>
                                    <img className='w-[30%] h-full object-contain' src={e.CompanyImage} />
                                    <div className='py-1 px-2 rounded-full border-2 border-green-600'>
                                        <h3 className='text-green-600 text-sm font-bold'>{e.Deal}</h3>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </MySlider> */}
{/* <div className='xl:max-w-[1640px] mx-auto rounded-md pt-10'>
                    {ads && <BannerSlider ads={ads} />}
                </div>
                <h1 className='text-white font-bold sm:pl-0 pl-2 text-2xl mb-5 mt-10'>Computers & Mobiles</h1> */}
{/* <MySlider isScrollActionEnabled={true}>
                    {
                        Computer.map((e, index) =>
                            <div key={index} className='sm:w-[250px] flex-none w-full sm:h-[350px] h-auto relative  bg-white rounded-xl sm:mr-6 flex flex-col justify-between py-2 items-center px-2'>
                                <div className='bg-white absolute right-[10px] top-[10px] rounded-full px-2'>
                                    <h2 className=' text-red-600 font-bold'>{e.offer}% OFF</h2>
                                </div>
                                <img className='absolute left-[10px] top-[10px] rounded-full w-10 h-10' src={'https://thedealapp.in/assets/avatars/3Z4P4CS55EAqKvSv.png'} />
                                <img className='rounded-md sm:w-[180px] h-[300px] sm:h-[200px] mt-3 object-cover object-top' src={e.Productimage} />
                                <div className='flex flex-row items-center gap-x-2'>
                                    <h1 className='text-sm text-black line-through'>₹{e.OriginalAmount} </h1>
                                    <span className='text-base lining-nums text-red-600 font-bold'>₹{e.Actual}/-</span>
                                </div>
                                <h1 className='text-sm font-semibold line-clamp-2 text-wrap text-center'>{e.name}</h1>
                                <div className='border-b-[1px] mb-2 border-b-gray-600 w-full' />
                                <div className='flex flex-row items-center justify-between w-full '>
                                    <img className='w-[30%] h-full object-contain' src={e.CompanyImage} />
                                    <div className='py-1 px-2 rounded-full border-2 border-green-600'>
                                        <h3 className='text-green-600 text-sm font-bold'>{e.Deal}</h3>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </MySlider> */}
{/* </div> */ }