function TextMarquee({ title, marqueeAfterLength, text_style, div_style }) {
    return (
        <div className={`overflow-x-hidden ${div_style}`}>
            <div className={`text-center whitespace-nowrap 
            ${title && title.length > marqueeAfterLength ? "animate-marquee" : ""}`}>
                <span className={text_style}>
                    {title}
                </span>
            </div>
        </div>
    )
}

export default TextMarquee;