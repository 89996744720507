import React, { useEffect, useState } from 'react';
import BannerSlider from "../../components/banner_slider";
import { ProductCard } from "../../components/ProductCard";

import { loadAdvertisement, loadProduct, useAppDispatch, useAppSelector } from '../../redux'

import { useIsMobileViewContext } from "../../context/isMobileView";

function ProductTemplate() {
  const dispatch = useAppDispatch();

  const { isMobileView } = useIsMobileViewContext()

  const [adsList, setAdsList] = useState(null)

  const product = useAppSelector(state => state.product.products);
  const isProductEndReached = useAppSelector(state => state.product.isEndReached);
  const advertisements = useAppSelector((state) => state.advertisement.advertisements);

  useEffect(() => {
    if (!isProductEndReached && product.length <= 0)
      dispatch(loadProduct())

    if (advertisements.length <= 0)
      dispatch(loadAdvertisement())
  }, [dispatch])

  useEffect(() => {
    setAdsList(advertisements.filter(item => item.type === "products" && item.orientation === "horizontal"))
  }, [advertisements]);

  return <div className="bg-gray-900">
    <div className={`w-full mx-auto ${adsList && adsList.length > 1 ? isMobileView ? "px-6" : "px-10" : ""}`}>
      {adsList && <BannerSlider ads={adsList} />}
    </div>

    {product && <ProductCard list={product} />}
  </div>
}

export { ProductTemplate };
