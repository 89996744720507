import { BrandDetailsTemplate } from "../view/templates";

function BrandDetailsPage() {
    return (
        <main className="flex-grow min-h-screen bg-gray-900">
            <BrandDetailsTemplate />
        </main>
    )
}

export default BrandDetailsPage