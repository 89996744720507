import { db, doc, getDoc } from "../firebase";

const loadProductWithId = (id) => {
  return new Promise(async (resolve, reject) => {
    if (id !== undefined && id !== null && id.length > 0) {
      let docRef = doc(db, "product", id);
      let docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) resolve(docSnapshot.data());
      else reject("Data not found");
    } else {
      reject("Invalid ID");
    }
  });
};

const hasUserAlreadyRated = (productId, userId) => {
  return new Promise(async (resolve, reject) => {
    if (productId && userId) {
      const docRef = doc(db, "product", productId, "rating", userId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) resolve(docSnap.data().rating || 0);
      else reject("User Rating not found");
    } else {
      reject("Product not found");
    }
  });
};

export { loadProductWithId, hasUserAlreadyRated };
