import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  db,
  collection,
  doc,
  getDocs,
  setDoc,
  updateDoc,
  query,
  startAfter,
  orderBy,
  limit,
  serverTimestamp,
} from "../../firebase";

const initialState = {
  movies: [],
  isEndReached: false,
  isLoading: false,
  isError: false,
  error: "",
};

var lastDoc = null;
var docsCount = 0;

export const loadMovies = createAsyncThunk("movie/loadMovies", async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const runQuery = query(
        collection(db, "movie"),
        orderBy("createdAt", "desc"),
        limit(50)
      );
      const querySnapshot = await getDocs(runQuery);

      let list = [];
      var index = 1;
      querySnapshot.forEach((doc) => {
        list.push({
          rank: index,
          ...doc.data(),
        });
        index = index + 1;
      });
      lastDoc =
        list.length > 0
          ? querySnapshot.docs[querySnapshot.docs.length - 1]
          : null;
      docsCount = docsCount + querySnapshot.docs.length;
      resolve(list);
    } catch (e) {
      reject(e.message);
    }
  });
});

export const loadMoreMovies = createAsyncThunk(
  "movie/loadMoreMovies",
  async () => {
    return new Promise(async (resolve, reject) => {
      try {
        let runQuery;
        if (lastDoc === null) {
          runQuery = query(
            collection(db, "movie"),
            orderBy("createdAt", "desc"),
            limit(10)
          );
        } else {
          runQuery = query(
            collection(db, "movie"),
            orderBy("createdAt", "desc"),
            startAfter(lastDoc),
            limit(10)
          );
        }

        const querySnapshot = await getDocs(runQuery);

        let list = [];
        var index = docsCount;
        querySnapshot.forEach((doc) => {
          list.push({
            rank: index,
            ...doc.data(),
          });
          index = index = 1;
        });

        lastDoc =
          list.length > 0
            ? querySnapshot.docs[querySnapshot.docs.length - 1]
            : null;
        docsCount = docsCount + querySnapshot.docs.length;
        resolve(list);
      } catch (e) {
        reject(e.message);
      }
    });
  }
);

export const addMovieRating = createAsyncThunk(
  "movie/addMovieRating",
  async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        await setDoc(doc(db, "movie", data.movieId, "createdAt", data.userId), {
          createdAt: serverTimestamp(),
          id: data.userId,
          lastUpdatedAt: serverTimestamp(),
          rating: data.newUserRating,
        });

        resolve({ id: data.movieId, rating: data.newRating });
      } catch (e) {
        reject(e.message);
      }
    });
  }
);

export const updateMovieRating = createAsyncThunk(
  "movie/updateMovieRating",
  async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        await updateDoc(doc(db, "movie", data.movieId, "createdAt", data.userId), {
          lastUpdatedAt: serverTimestamp(),
          rating: data.newUserRating,
        });

        const ratingDifference =
          Math.max(data.newUserRating, data.oldUserRating) -
          Math.min(data.newUserRating, data.oldUserRating);

        if (ratingDifference > 0) {
          if (data.newUserRating > data.oldUserRating) {
            resolve({
              id: data.movieId,
              rating: data.oldRating + ratingDifference,
            });
          } else {
            resolve({
              id: data.movieId,
              rating: data.oldRating - Math.abs(ratingDifference),
            });
          }
        } else {
          resolve({ id: data.movieId, rating: data.newRating });
        }
      } catch (e) {
        reject(e.message);
      }
    });
  }
);

export const movieSlice = createSlice({
  name: "movie",
  initialState,
  reducers: {
    addMovie: (state, { payload }) => {
      state.movies = [...state.movies, payload];
    },
    updateMovieReviewCount: (state, { payload }) => {
      let list = Object.assign([], state.movies);
      let index = list.findIndex((item) => item.id === payload.id);
      if (index >= 0) {
        let item = {
          ...list[index],
          reviewCount: (list[index].reviewCount || 0) + 1,
        };
        list[index] = item;
      }
      state.movies = [...list];
    },
    reduceMovieReviewCount: (state, { payload }) => {
      let list = Object.assign([], state.movies);
      let index = list.findIndex((item) => item.id === payload.id);
      if (index >= 0) {
        let item = {
          ...list[index],
          reviewCount:
            (list[index].reviewCount || 0) > 0
              ? list[index].reviewCount - 1
              : 0,
        };
        list[index] = item;
      }
      state.movies = [...list];
    },
  },
  extraReducers: (builder) => {
    //loadMovies
    builder.addCase(loadMovies.pending, (state, _) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(loadMovies.fulfilled, (state, { payload }) => {
      state.movies = payload;
      state.isEndReached = payload.length < 10;
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(loadMovies.rejected, (state, { error }) => {
      state.isLoading = false;
      state.isError = true;
      state.error = error.message;
    });
    //loadMoreMovies
    builder.addCase(loadMoreMovies.pending, (state, _) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(loadMoreMovies.fulfilled, (state, { payload }) => {
      state.movies = payload;
      state.isEndReached = payload.length < 10;
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(loadMoreMovies.rejected, (state, { error }) => {
      state.isLoading = false;
      state.isError = true;
      state.error = error.message;
    });
    //addMovieRating
    builder.addCase(addMovieRating.pending, (state, _) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(addMovieRating.fulfilled, (state, { payload }) => {
      let movies = Object.assign([], state.movies);
      let index = movies.findIndex((movie) => movie.id === payload.id);
      if (index >= 0) {
        let movie = { ...movies[index], rating: payload.rating };
        movies[index] = movie;
      }
      movies = movies.sort((a, b) => {
        if (a.rating === b.rating) return 0;
        else if (a.rating < b.rating) return 1;
        else return -1;
      });
      state.movies = [...movies];
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(addMovieRating.rejected, (state, { error }) => {
      state.isLoading = false;
      state.isError = true;
      state.error = error.message;
    });
    //updateMovieRating
    builder.addCase(updateMovieRating.pending, (state, _) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(updateMovieRating.fulfilled, (state, { payload }) => {
      let movies = Object.assign([], state.movies);
      let index = movies.findIndex((movie) => movie.id === payload.id);
      if (index >= 0) {
        let movie = { ...movies[index], rating: payload.rating };
        movies[index] = movie;
      }
      movies = movies.sort((a, b) => {
        if (a.rating === b.rating) return 0;
        else if (a.rating < b.rating) return 1;
        else return -1;
      });
      state.movies = [...movies];
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(updateMovieRating.rejected, (state, { error }) => {
      state.isLoading = false;
      state.isError = true;
      state.error = error.message;
    });
  },
});

export const { addMovie, updateMovieReviewCount, reduceMovieReviewCount } =
  movieSlice.actions;

export const movieReducer = movieSlice.reducer;
