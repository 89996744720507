import { movieReducer } from "./movie.slice";
import { userReducer } from "./user.slice";
import { newsReducer } from "./news.slice";
import { celebrityReducer } from "./celebrity.slice";
import { productReducer } from "./product.slice";
import { advertisementsReducer } from "./advertisement.slice";
import { showsReducer } from "./shows.slice";
import { topShowsReducer } from "./topReview.slice";
import { brandReducer } from "./brand.slice";
import { notificationReducer } from "./notification.slice";

export const rootReducer = {
  movie: movieReducer,
  user: userReducer,
  news: newsReducer,
  celebrity: celebrityReducer,
  product: productReducer,
  advertisement: advertisementsReducer,
  shows: showsReducer,
  topReview: topShowsReducer,
  brand: brandReducer,
  notification: notificationReducer,
};

export * from "./movie.slice";
export * from "./user.slice";
export * from "./news.slice";
export * from "./celebrity.slice";
export * from "./product.slice";
export * from "./advertisement.slice";
export * from "./shows.slice";
export * from "./topReview.slice";
export * from "./brand.slice";
export * from "./notification.slice";
