import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import BannerSlider from "../../components/banner_slider";
import Footer from "../../components/Footer";
import BackArrowNavigation from "../../components/BackArrowNavigation";

import { MovieDetails } from "../organisms";

import { useAppSelector, useAppDispatch, loadMovies, addMovie } from "../../redux";

import { loadMovieWithId } from "../../service/movie.service";

function MovieDetailsTemplate() {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [adsFilter, setAdsFilter] = useState(null);
  const [verticalAd1, setVerticalAd1] = useState(null);
  const [verticalAd2, setVerticalAd2] = useState(null);
  const [rank, setRank] = useState(null)
  const [link, setLink] = useState(null)

  const movies = useAppSelector((state) => state.movie.movies);
  const advertisements = useAppSelector((state) => state.advertisement.advertisements);

  const [currentMovie, setCurrentMovie] = useState(null);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [link]);

  useEffect(() => {
    if (movies.length <= 0)
      dispatch(loadMovies());
  }, [dispatch, movies]);

  useEffect(() => {
    if (movies.length > 0 && link !== null) {
      if (movies.some(movie => movie.id === link))
        setCurrentMovie(movies.filter((movie) => movie.id === link)[0]);
      else loadMovieWithId(link)
        .then(result => {
          dispatch(addMovie(result));
          setCurrentMovie(result);
        })
        .catch(e => console.error(e));
    }
  }, [currentMovie, dispatch, link, movies]);

  useEffect(() => {
    setAdsFilter(advertisements.filter(item => item.type === "movies" && item.orientation === "horizontal"));
    let verticalAds = advertisements.filter(item => item.type === "movies" && item.orientation === "vertical");
    if (verticalAds.length > 0) {
      setVerticalAd1(verticalAds[Math.floor(Math.random(verticalAds.length) * verticalAds.length)]);
      setVerticalAd2(verticalAds[Math.floor(Math.random(verticalAds.length) * verticalAds.length)]);
    }
  }, [advertisements]);

  useEffect(() => {
    let path = location.pathname;
    let idWithRank = path.slice(path.lastIndexOf("/") + 1, path.length);
    if (idWithRank.includes("-")) {
      let id = idWithRank.slice(0, idWithRank.lastIndexOf("-"));
      let rank = idWithRank.slice(idWithRank.lastIndexOf("-") + 1, idWithRank.length);
      setRank(rank)
      setLink(id)
    } else {
      setRank(null);
      setLink(idWithRank);
    }
  }, [location.pathname]);

  return (
    <div className="bg-gray-900 w-full">
      <BackArrowNavigation />

      <div className="pt-10">
        {/* {adsFilter && <BannerSlider ads={adsFilter} />} */}

        <div>
          {currentMovie && (
            <>
              <div className="flex justify-center">
                <div className="flex w-full max-w-screen-3xl">
                  {/* {verticalAd1 &&
                    <a href={verticalAd1.link} target="_blank" rel="noreferrer">
                      <div className="md:w-40 lg:object-fill md:object-contain lg:w-72 w-0">
                        <img
                          src={verticalAd1.banner_url}
                          alt="" />
                      </div>
                    </a>
                  } */}

                  <div className="mx-4 pt-2 pb-4">
                    <MovieDetails
                      rank={rank}
                      movie_id={link}
                      movie={currentMovie}
                      categories={currentMovie.type}
                      info={currentMovie.info}
                      cast={currentMovie.cast}
                      brand={currentMovie.brand}
                    />
                  </div>

                  {/* {verticalAd2 &&
                    <a href={verticalAd2.link} target="_blank" rel="noreferrer">
                      <div className="md:w-40 lg:object-fill md:object-contain lg:w-72 w-0">
                        <img
                          src={verticalAd2.banner_url}
                          alt="" />
                      </div>
                    </a>
                  } */}
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export { MovieDetailsTemplate };
