import React from "react";

function ImageAtom({ img, imageStyle, divStyle, width, height }) {
  return (
    <div className={` ${divStyle}`}>
      <img
        width={width}
        height={height}
        className={`${imageStyle}`}
        alt="starpot"
        src={img}
      />
    </div>
  );
}

export { ImageAtom };
