import { useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import { useAuthContext } from "../context/auth";

import { Logo } from "../assets/icons";

import { doc, db, getDoc, auth, signInWithPhoneNumber, RecaptchaVerifier } from "../firebase";

import LoadingIndicator from "../components/LoadingIndicator";

const styles = {
  inputStyle:
    "outline-none p-1 w-full text-white border-t-0 border-l-0 border-r-0 border-b border-gray-200 bg-gray-800 focus:border-primary text-sm md:text-lg",
  errorLabelStyle: "text-red-400 text-xsm md:text-sm",
};

const PHONE = "phone";

function Login() {
  const navigate = useNavigate();

  let { onLogout } = useAuthContext();

  const [phoneErr, setPhoneErr] = useState("");
  const [loginErr, setLoginErr] = useState("");
  const [otpErr, setOtpErr] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isVerifyingOtp, setIsVerifyingOtp] = useState(false);

  const [showOtpInput, setShowOtpInput] = useState(false);

  const phoneRef = useRef(null);
  const phoneExtRef = useRef("+91");
  const otpRef = useRef(null);

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
      'callback': (_) => { }
    }, auth);
  }

  const validation = (value, type) => {
    var errorMsg = "";
    switch (type) {
      case PHONE:
        if (value === null || value.length <= 0) {
          errorMsg = "Phone cannot be left blank";
        } else if (
          !RegExp(
            /^([\d]{10})$/i
          ).test(value)
        ) {
          errorMsg = "Invalid Phone number";
        } else {
          errorMsg = "";
        }
        setPhoneErr(errorMsg);
        break;
      default:
    }
    return errorMsg.length < 1;
  };

  const handleLoginClick = (e) => {
    e.preventDefault();

    if (validation(phoneRef.current, PHONE)) {
      setIsLoggingIn(true);
      generateRecaptcha();
      login();
    }
  };

  const login = () => {
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phoneExtRef.current + phoneRef.current, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setShowOtpInput(true);
        setIsLoggingIn(false);
      }).catch((error) => {
        setLoginErr(error.message);
        console.error(error);
        setIsLoggingIn(false);
      });
  };

  const handleVerifyOtpClick = (e) => {
    e.preventDefault();

    if (otpRef.current?.length <= 0) {
      setOtpErr("OTP cannot be empty");
    } else {
      setIsVerifyingOtp(true);
      window.confirmationResult.confirm(otpRef.current)
        .then(async (result) => {
          let user = result.user;
          const docRef = doc(db, "users", user.uid);
          const docSnap = await getDoc(docRef);
          setIsVerifyingOtp(false);
          if (docSnap.exists()) {
            navigate("/");
          } else {
            navigate("/not-exist");
            onLogout();
          }
        })
        .catch(err => {
          setIsVerifyingOtp(false);
          setOtpErr(err.message);
        });
    }
  }

  return (
    <main className="min-h-screen flex-grow bg-gray-900">
      <div className="h-screen flex flex-col justify-center items-center p-6 w-full md:w-100 mx-auto">
        {showOtpInput
          ?
          <>
            <span className="my-4 text-xl md:text-2xl text-gray-300">
              Enter the OTP sent to {phoneExtRef.current} {phoneRef.current}
            </span>

            <div className="flex flex-row items-center w-4/6 md:w-5/6 mt-4">
              <input
                type="password"
                placeholder="OTP"
                disabled={isVerifyingOtp}
                className={`text-center ${styles.inputStyle}`}
                onChange={(e) => (otpRef.current = e.target.value)}
              />
            </div>
            <span className={styles.errorLabelStyle}>{otpErr}</span>

            {isVerifyingOtp
              ? <LoadingIndicator />
              : <button
                className="bg-primary rounded-sm py-2 px-4 mt-6 mb-2 text-sm md:text-lg text-white"
                onClick={handleVerifyOtpClick}
              >
                VERIFY
              </button>
            }
          </>
          :
          <>
            <Logo className="w-24 h-24 bg-black rounded-full" />

            <span className="my-4 text-xl md:text-2xl text-gray-300">
              Login to your StarPot account
            </span>

            <div className="flex flex-row items-center w-4/6 md:w-5/6 mt-4">
              <input
                type="text"
                placeholder="+91"
                maxLength={3}
                disabled={isLoggingIn}
                className="mr-1 w-12 outline-none p-1 bg-gray-800 border-t-0 border-l-0 border-r-0 border-b border-gray-200 focus:border-primary text-sm text-white md:text-lg"
                onChange={(e) => (phoneExtRef.current = e.target.value)}
              />
              <input
                // type="number"
                maxLength={10}
                placeholder="Mobile"
                disabled={isLoggingIn}
                className={`${styles.inputStyle}`}
                onChange={(e) => {
                  if (e.target.value.length <= 10) {
                    phoneRef.current = e.target.value
                  }
                }}
              />
            </div>
            <span className={styles.errorLabelStyle}>{phoneErr}</span>

            {isLoggingIn
              ? <LoadingIndicator />
              : <button
                className="bg-primary rounded-sm py-2 px-4 mt-6 mb-2 text-sm md:text-lg text-white"
                onClick={(e) => handleLoginClick(e)}
              >
                LOGIN
              </button>
            }
            <span className={styles.errorLabelStyle}>{loginErr}</span>

            <Link
              to="/register"
              className="rounded-sm py-1 px-4 mt-14 text-sm md:text-lg text-gray-400"
            >
              Don't have an account ? <span className="text-primary">Register</span>
            </Link>

            <div id="recaptcha-container"></div>
          </>
        }
      </div>
    </main>
  );
}

export default Login;
