import { HomeTemplate } from "../view/templates";

import { useIsMobileViewContext } from '../context/isMobileView';
import bookmark from '../assets/images/bookmark.png'
import { FaAngleLeft, FaAngleRight, FaRegPlayCircle, FaStar, FaUserCheck } from "react-icons/fa";
import moreimg from '../assets/images/moreimg.png'
import { useEffect, useRef, useState } from "react";
import { loadBrands, loadCelebrity, loadMovies, loadNews, loadProduct, loadShows, useAppDispatch, useAppSelector } from "../redux";
import { MovieCardList } from "../components/MovieCardList";
import { BsStarFill } from "react-icons/bs";
import TextMarquee from "../view/atoms/TextMarquee";
import { TextAtom } from "../view/atoms";
import { Link, useNavigate } from "react-router-dom";
import HomepageBanner from "../components/HomepageBanner";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import Slider from "react-slick";
import leftarrow from '../assets/images/leftarrow.png'
import rightarrow from '../assets/images/rightarrow.png'
import saravanan from '../assets/images/saravaan.jpg'
import sidebanner1 from '../assets/images/sidebanner1.jpg'
import sidebannerleft from '../assets/images/sidebannerleft.jpg'
import { GetAdvertisements, GetTheData } from "../components/Api/FetchData";
import { MdPlayCircle } from "react-icons/md";
import FullWidthConatiner from "../components/FullWidthConatiner";
import OpenYoutubeBox from "../components/OpenYoutubeBox";
import Footer from "../components/Footer";
import Carousel from "react-multi-carousel";
import DesktopFooter from "../components/DesktopFooter";
function HomePage() {
    useEffect(() => {
        FetchingCelebrities()
        FetchingGenreData()
        FetchingMovieBanners()
        FetchingAdvertisements()
    }, [])

    const [celebritiesData, setCelebritiesData] = useState([])
    async function FetchingCelebrities() {
        try {
            const response = await GetTheData('celebrities')
            setCelebritiesData(response.data)
            console.log(response.data);
        } catch (error) {
            console.log(error.message);
        }
    }
    const [moviesData, setMoviesData] = useState([])
    async function FetchingMovieBanners() {
        try {
            const response = await GetTheData('movieBanners')
            setMoviesData(response.data)
            console.log(response.data);
        } catch (error) {
            console.log(error.message);
        }
    }
    const [GenreCollection, setGenreCollection] = useState([])
    async function FetchingGenreData() {
        try {
            const response = await GetTheData('movieGenres')
            setGenreCollection(response.data)
        } catch (error) {
            console.log(error.message);
        }
    }
    const [FullWidthBannerArray, setFullWidthBannerArray] = useState([])
    async function FetchingAdvertisements() {
        try {
            const data = await GetAdvertisements()
            if (data.length > 0) {
                // const data1 = data.filter((e) => e.destination == 1 && e.placement == 5)
                // setFullBannerData(data1)
                // const Header = data.filter((e) => e.destination == 1 && e.placement == 1)
                // setHeaderBanner(Header)
                // const square = data.filter((e) => e.destination == 1 && e.placement == 6)
                // setSquareBanner(square)
                // const leftBann = data.filter((e) => e.destination == 1 && e.placement == 4)
                // setLeftBanner(leftBann)
                // const rightbann = data.filter((e) => e.destination == 1 && e.placement == 2)
                // setRightBanner(rightbann)
                // const bottombann = data.filter((e) => e.destination == 1 && e.placement == 3)
                // setBottomBanner(bottombann)
                const wideBanner = data.filter((e) => e.destination == 1 && e.placement == 7)
                console.log(wideBanner);
                setFullWidthBannerArray(wideBanner);
            }
        } catch (error) {
            console.log(error.message);
        }

    }
    function FetchingGenreDataByID(params) {
        const value = params != undefined ? JSON.parse(params) : []
        try {
            if (value.length > 1) {
                let temp = ''
                for (let i = 0; i < value.length; i++) {
                    const data1 = GenreCollection.find(e => e.id === Number(value[i]))
                    if (data1 != undefined)
                        temp += data1?.name.trim()
                    if (i != value.length - 1 && data1 != undefined) {
                        temp += ', '
                    }
                }
                return temp;
            } else if (value.length > 0) {
                const data = GenreCollection.find(e => e.id === Number(value[0]))
                return data?.name;
            }
        } catch (error) {
            console.log('error genre');
        }
    }
    const { isMobileView } = useIsMobileViewContext();
    const movies = useAppSelector((state) => state.movie.movies);
    const celebrity = useAppSelector(state => state.celebrity.celebrity);
    const product = useAppSelector(state => state.product.products);
    const news = useAppSelector((state) => state.news.news);
    const brands = useAppSelector(state => state.brand.brands);
    const shows = useAppSelector((state) => state.shows.shows);
    const isShowEndReached = useAppSelector((state) => state.shows.isEndReached);
    const isBrandEndReached = useAppSelector((state) => state.brand.isEndReached);
    const isProductEndReached = useAppSelector(state => state.product.isEndReached);
    const isMovieEndReached = useAppSelector((state) => state.movie.isEndReached);
    const isCelebrityEndReached = useAppSelector(state => state.celebrity.isEndReached);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (!isMovieEndReached && movies.length <= 0)
            dispatch(loadMovies());
        if (!isCelebrityEndReached && celebrity.length <= 0)
            dispatch(loadCelebrity())
        if (!isProductEndReached && product.length <= 0)
            dispatch(loadProduct())
        if (news.length <= 0)
            dispatch(loadNews());
        if (!isBrandEndReached && brands.length <= 0)
            dispatch(loadBrands())
        if (!isShowEndReached && shows.length <= 0)
            dispatch(loadShows());
    }, [dispatch])
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'auto' });
        FetchingBanners()
    }, []);

    const [videoBanner, setVideoBanner] = useState(null)
    async function FetchingBanners() {

        const querySnapshot = await getDocs(collection(db, "VideoBanners"));
        if (querySnapshot.docs[0].data().url != undefined) {
            setVideoBanner(querySnapshot.docs[0].data().url)
        }
    }
    const [ImagePostion, setImagePostion] = useState(0)
    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "0px",
        slidesToShow: 1,
        speed: 200,
        // dots: true,
        autoplay: true,
        afterChange: current => setImagePostion(current),
        appendDots: dots => (
            <div
                style={{
                    backgroundColor: "transparent"
                }}
            >
                <ul style={{ marginTop: "10px", marginTop: '10px' }}> {dots} </ul>
            </div>
        ),
        customPaging: i => (
            <div
                style={{
                    width: '10px',
                    height: '10px',
                    marginTop: '10px',
                    backgroundColor: ImagePostion == i ? '#00c1f8' : 'white',
                    borderRadius: 10
                }}
            >
            </div>
        )
    };
    const settings1 = {
        className: "center",
        centerMode: isMobileView ? true : false,
        infinite: true,
        centerPadding: "0px",
        slickGutter: 10,
        slidesToScroll: isMobileView ? 1 : 4,
        arrows: false,
        speed: 200,
        // slickGutter:400,
        variableWidth: true,
        // dots: true,
        autoplay: true,
        afterChange: current => setImagePostion(current),

    };
    function createMarkup(params) {
        return { __html: params };
    }
    const sliderRef = useRef(null);
    const CustomNextArrow = ({ onClick }) => {
        return (
            <button
                className="custom-prev-button lg:block hidden"
                style={{
                    position: 'absolute',
                    top: '50%',
                    right: '50px', /* Adjust as needed */
                    transform: 'translateY(-20%)',
                    backgroundColor: 'white', /* Adjust styles as needed */
                    border: 'none', /* Adjust styles as needed */
                    cursor: 'pointer',
                    borderRadius: 100
                }}
                onClick={onClick}
            >
                <img src={rightarrow} className="w-10 h-10 font-normal" />
            </button>
        );
    };
    const CustomPrevArrow = ({ onClick }) => {
        return (
            <button
                className="custom-prev-button lg:block hidden"
                style={{
                    position: 'absolute',
                    top: '50%',
                    zIndex: '99',
                    left: '40px', /* Adjust as needed */
                    transform: 'translateY(-20%)',
                    backgroundColor: 'white', /* Adjust styles as needed */
                    border: 'none', /* Adjust styles as needed */
                    cursor: 'pointer',
                    borderRadius: 100
                }}
                onClick={onClick}
            >
                <img src={leftarrow} className="w-10 h-10 font-normal" />
            </button>
        );
    };
    const handlePrev = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };

    const handleNext = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };
    const navigate = useNavigate()
    const [bannerYTlink, setbannerYTLink] = useState(false)
    const [YtLink, setYtLink] = useState('')
    function handleYtFunction(params) {
        setYtLink(params)
        setbannerYTLink(true)
    }
    function handleYtFalseFunction() {
        setbannerYTLink(false)
    }
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <div className={`w-full  flex flex-col h-[100vh] lg:items-center overflow-auto`}>
            {/* <div className="absolute flex flex-col z-[99] top-[30vh] "> */}
            {
                bannerYTlink &&
                <OpenYoutubeBox YtLink={YtLink} handleYtFalseFunction={handleYtFalseFunction} />
            }
            {/* </div> */}
            <main className={`flex-grow  flex flex-col items-center justify-center  bg-[#0f172a] ${isMobileView ? "" : ""}`}>
                {/* <HomeTemplate/> */}
                <div class="w-full flex flex-col items-center ">
                    <div class={`w-full xl:w-screen xl:h-[700px]  pt-12 sm:pt-2`}>
                        <video controls={true} preload="" loop style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={videoBanner}></video>
                    </div>
                    <div className="container mx-auto max-h-[200px] my-2">
                        <img className="w-full" src={saravanan} />
                    </div>
                    <div className="bg-[#0a0d14] w-full flex flex-col items-center">
                        <div className="w-full bg-[#0a0d14] max-w-[1920px] my-5 self-center flex flex-row items-center justify-between">
                            <div className=" w-[12%] hidden h-full lg:flex flex-row justify-start">
                                <img src={sidebannerleft} />
                            </div>
                            <div className="flex flex-col w-full lg:flex-1 ">
                                <h1 class="text-white pl-3 font-bold text-lg sm:text-2xl mb-5">Recent News</h1>
                                <div className="mb-2 w-full  flex flex-col items-center">
                                    {news.length > 0 &&
                                        <div className="container w-full">
                                            <Slider ref={sliderRef} prevArrow={<CustomPrevArrow onClick={handlePrev} />} nextArrow={<CustomNextArrow onClick={handleNext} />} {...settings}>
                                                {news.length > 0 && news.slice(0, 5).map((e, index) =>
                                                    <div onClick={() => navigate(`/news/${e.id + `-` + 1}`, { state: { Lang: '', array: news } })} key={index} className='cursor-pointer overflow-hidden w-full flex flex-col relative h-[250px] sm:h-[300px] lg:h-[400px] 2xl:h-[550px] rounded-md '>
                                                        <div className=" absolute max-h-[100px] lg:max-h-[140px] h-full w-full bottom-[10px] lg:bottom-[40px] z-10  lg:px-36 px-2">
                                                            <div className="w-full h-full lg:gap-5 gap-2 lg:bg-black/75 bg-black/50 flex flex-row items-center">
                                                                <h1 className="w-2 h-full  bg-primary"></h1>
                                                                <div className="w-full h-full flex flex-col py-2 justify-center px-2">
                                                                    <h1 className="text-white line-clamp-2 lg:line-clamp-1 font-Tamil font-bold text-[14px] tracking-wide lg:text-xl">{e.title}</h1>
                                                                    <h4 className="text-gray-400 font-semibold line-clamp-1 font-Tamil lg:line-clamp-2 tracking-wider lg:pl-2 text-sm lg:text-base  mt-2" dangerouslySetInnerHTML={createMarkup(e.content)}></h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <img className="object-cover object-top absolute lg:h-[550px] h-full w-full rounded-md" src={e.gallery[0].url} />
                                                    </div>
                                                )}
                                            </Slider>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="w-[13%] hidden h-full lg:flex flex-row justify-end  ">
                                <img src={sidebanner1} />
                            </div>
                        </div>
                    </div>
                    {/* <div className="flex flex-col w-full bg-[#0e0d12]">
                        <div className="flex flex-row ">
                            <div className=" w-[10%] hidden h-full lg:flex flex-row justify-start">
                                <img src={sidebannerleft} />
                            </div>
                            <div className="flex flex-col w-full lg:w-[80%] ">
                                <h1 class="text-white pl-3 font-bold text-lg sm:text-2xl mt-4 mb-2">Recent News</h1>
                                <div className="mb-2 w-full flex flex-row  overflow-auto">
                                    {
                                        moviesData.length > 0 &&
                                        moviesData.map((e, index) =>
                                            e.thumbnailPictureUrl != null ? <img onClick={() => navigate('/MovieDetailPage', { state: { array: e } })} key={index}
                                                className=' h-[400px] border border-white cursor-pointer w-[270px] flex-none rounded-xl object-cover object-top' src={`https://storage.googleapis.com/cinimini-console/movies/${e.id}/${JSON.parse(e.thumbnailPictureUrl)}`} /> : null
                                        )
                                    }
                                </div>
                            </div>
                            <div className="w-[10%] hidden h-full lg:flex flex-row justify-end  ">
                                <img src={sidebanner1} />
                            </div>
                        </div>
                    </div> */}

                    {/* <div className=" bg-[#0e0d12] 2xl:max-w-[1920px] overflow-hidden 2xl:mx-auto w-full  flex flex-row items-center  justify-between">
                        <img className="w-[150px] hidden lg:block" src={sidebanner1} />
                        <div className="flex flex-col lg:w-[80%] w-full my-5">
                            <h1 class="text-white  font-bold text-lg sm:text-2xl pl-5 mb-4">Recent Movies</h1>
                            <div className="w-full ">
                                <Slider {...settings1}>
                                    {
                                        moviesData.length > 0 && moviesData.map((e, index) =>
                                            <div
                                                style={{ width: 280, height: 450 }} className="flex flex-col ">
                                                <div className="flex flex-col  h-[400px] w-full items-center justify-center relative">
                                                    <FaRegPlayCircle onClick={() => handleYtFunction(e.youtubeLink)} className={`text-white opacity-50 hover:opacity-100 z-[99] duration-300 cursor-pointer  w-12 h-12`} />
                                                    {e.thumbnailPictureUrl != null ? <img onClick={() => navigate('/MovieDetailPage', { state: { array: e } })} key={index}
                                                        className=' h-[400px] border border-white cursor-pointer w-full absolute top-0 rounded-xl object-cover object-top' src={`https://storage.googleapis.com/cinimini-console/movies/${e.id}/${JSON.parse(e.thumbnailPictureUrl)}`} /> : null}
                                                </div>
                                                <h1 className="px-2 mt-1 text-primary text-sm">{FetchingGenreDataByID(e.genreIds)}</h1>
                                                <div className="flex flex-row px-2 items-center justify-between">
                                                    <h1 onClick={() => navigate('/MovieDetailPage', { state: { array: e } })} className="text-white cursor-pointer hover:text-primary">{e.name}</h1>
                                                    <h1 className="text-white ">{new Date(e.releaseDate).getUTCFullYear()}</h1>
                                                </div>
                                                <div className="px-2 mt-2">
                                                    <div className="w-full text-white py-1 border border-gray-600 flex flex-col items-center justify-center rounded-full">
                                                        <h1>{e.displayTag}</h1>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </Slider>
                            </div>
                           
                        </div>
                        <img className="w-[150px] hidden lg:block" src={sidebanner1} />
                    </div> */}
                    {FullWidthBannerArray.length > 0 ?
                        <FullWidthConatiner handleYtFunction={handleYtFunction} youtubeUrl={FullWidthBannerArray[0].youtubeUrl} sectionId={FullWidthBannerArray[0].sectionId} internal={FullWidthBannerArray[0].internal} redirectUrl={FullWidthBannerArray[0].redirectUrl} id={FullWidthBannerArray[0].id} ImageUrl={FullWidthBannerArray[0].bannerUrl} subTitle={FullWidthBannerArray[0].subTitle} title={FullWidthBannerArray[0].title} /> : null}
                    <div className=" bg-[#0e0d12] flex flex-row items-center  justify-center">
                        {/* <div className="h-full lg:flex flex-row justify-end hidden ">
                        <img src={sidebanner1} />
                        </div> */}
                        {/* <div className="flex flex-col mb-10 w-full container">
                            <h1 class="text-white pl-3 font-bold text-lg sm:text-2xl mt-4 mb-4">Celebrities</h1>
                            <div className="w-full container">
                                <Slider {...settings1} >
                                    {
                                        moviesData.length > 0 && moviesData.map((e, index) =>
                                            <div onMouseEnter={() => setShowPlayButton(index)} onMouseLeave={() => setShowPlayButton(-1)}
                                                style={{ width: 280, height: 450 }} className="flex flex-col flex-none">
                                                <div className="flex flex-col  h-[400px] w-full items-center justify-center relative">
                                                    <FaRegPlayCircle onClick={() => handleYtFunction(e.youtubeLink)} className={`text-white opacity-50 hover:opacity-100 z-[99] duration-300 cursor-pointer  w-12 h-12`} />
                                                    <img key={index}
                                                        className=' h-[400px] border border-white cursor-pointer w-full absolute top-0 rounded-xl object-cover object-top' src={`https://storage.googleapis.com/cinimini-console/movies/${e.id}/${e.thumbnailPictureUrl}`} />

                                                </div>
                                                <h1 className="px-2 mt-1 text-primary text-sm">{FetchingGenreDataByID(e.genreIds)}</h1>
                                                <div className="flex flex-row px-2 items-center justify-between">
                                                    <h1 className="text-white ">{e.name}</h1>
                                                    <h1 className="text-white opacity-0">{new Date(e.releaseDate).getUTCFullYear()}</h1>
                                                </div>
                                                <div className="px-2 mt-2">
                                                    <div className="w-full text-white py-1 border border-gray-600 flex flex-col items-center justify-center rounded-full">
                                                        <h1>{e.displayTag}</h1>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </Slider>
                            </div>
                        </div> */}
                        {/* <div className=" h-full lg:flex flex-row justify-end hidden ">
                        <img src={sidebanner1} />
                    </div> */}
                    </div>
                    {/* <div className="my-5 px-5">
                        <span class="text-white font-bold text-lg sm:text-2xl">Top 5 Stars</span>
                    </div>
                    <div className="mt-5">
                        {celebritiesData.length > 0 &&
                            <div className='w-full relative flex h-[550px] sm:h-[700px] flex-col justify-end items-center z-[9]'>
                                <div className='flex h-full w-full flex-col sm:hidden'>
                                    <img className='w-full brightness-95 object-cover h-full object-top'
                                        src={'https://storage.googleapis.com/cinimini-console/celebrities/' + celebritiesData[0].id + '/' + celebritiesData[0].bannerPictureUrls} />
                                </div>
                                <div className=' hidden w-full h-full sm:block'>
                                    <div className='h-[700px] w-full bg-[#f2f4f5] overflow-hidden flex flex-row items-center'>
                                        <div className='w-[65%] h-full bg-[#f2f4f5]'></div>
                                        <div className=' w-[35%] relative h-full '>
                                            <div className=' absolute overflow-hidden w-full -top-[100px] h-[200vh] rotate-18 '>
                                                <img className='absolute w-full h-full ' src={'https://storage.googleapis.com/cinimini-console/celebrities/' + celebritiesData[0].id + '/' + celebritiesData[0].bannerPictureUrls} />
                                                <img className=' -rotate-18' src={'https://storage.googleapis.com/cinimini-console/celebrities/' + celebritiesData[0].id + '/' + celebritiesData[0].bannerPictureUrls} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='container absolute sm:bottom-[50px] mx-auto flex flex-col sm:flex-row justify-between mb-5 sm:mb-0'>
                                    <div className='flex flex-col  gap-y-3 w-full sm:w-[40%] px-4 sm:px-0'>
                                        <h1 className='lg:text-7xl text-3xl text-[#0f172a] font-bold'>{celebritiesData[0].name}</h1>
                                        <div className=" text-[#0f172a] md:block hidden font-bold">
                                            {celebritiesData[0].description}
                                        </div>
                                        <div className='flex flex-row items-center mt-5 gap-x-3'>
                                            <div className='sm:px-12 px-3 cursor-pointer rounded-lg sm:py-3 py-2 bg-primary'>
                                                <h1 className='text-white font-bold text-base'>Show More</h1>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sm:w-[58%] w-full h-full px-2'>
                                        <h1 className='text-[#0f172a] font-serif sm:text-3xl  mt-5 sm:mt-0 font-semibold mb-2'>Todays Recomendation</h1>

                                        <div className='w-full h-full flex flex-row overflow-x-auto gap-5'>
                                            {
                                                celebritiesData.slice(1, 5).map((e, index) =>
                                                    <div key={index} className='border-2 border-primary flex flex-col flex-none w-[190px] h-[300px] rounded-lg'>
                                                        <img className='h-full w-full rounded-lg object-cover object-top' src={'https://storage.googleapis.com/cinimini-console/celebrities/' + e.id + '/' + e.profilePictureUrls} />
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>}
                    </div> */}
                    {/* <span class="text-gray-300 font-bold mx-2 text-sm my-2 sm:text-xl">Top 5 Product</span>
                <div className="my-5">
                    {product.length > 0 && <HomepageBanner banners={product} />}
                </div> */}

                    {/* <span class="text-gray-300 font-bold mx-2 text-sm my-2 sm:text-xl">Top 5 Movies</span> */}
                    {/* <div className="flex px-4 relative sm:mb-10 mb-3">
                    <div className="flex items-center overflow-x-auto overflow-y-hidden scroll-smooth">
                        {movies &&
                            movies.map((e, index) => index < 5 ?
                                <div className="mr-2 m-2 bg-gray-800 rounded-lg drop-shadow-md" key={index}>
                                    <Link to={`/movie/${e.id}`}>
                                        <div className={`overflow-hidden relative bg-gray-700 rounded-tr-lg rounded-tl-lg ${isMobileView ? "w-28 h-32" : "w-72 h-80"}`}>
                                            {e.cover_url && (
                                                <img
                                                    alt=""
                                                    className={`hover:scale-110 transistion duration-500 object-cover object-top rounded-tr-lg rounded-tl-lg ${isMobileView ? "w-28 h-32" : "w-72 h-80"}`}
                                                    src={e.cover_url}
                                                />
                                            )}

                                            {
                                                <div
                                                    className={`absolute top-0 left-5 ${isMobileView ? "w-6 h-8" : "w-8 h-16"} ml-2 text-center`}
                                                    style={{
                                                        backgroundImage: `url(${bookmark})`,
                                                        backgroundSize: "contain",
                                                        backgroundRepeat: "no-repeat",
                                                    }}
                                                >
                                                    <span className={`text-gray-200 ${isMobileView ? "text-xs" : "text-md"}`}>{`#${index + 1}`}</span>
                                                </div>
                                            }
                                        </div>

                                        <TextMarquee
                                            title={e.name}
                                            marqueeAfterLength={isMobileView ? 18 : 30}
                                            text_style={`text-gray-200 w-full text-center pt-1 ${isMobileView ? "text-xs" : "text-md"}`}
                                            div_style={`${isMobileView ? "w-28" : "w-72"}`} />

                                        <h1
                                            className={`text-gray-400 w-full text-center truncate -mt-1 ${isMobileView ? "w-28 text-xss" : "w-72 text-sm"}`}>
                                            {e.releaseYear}
                                        </h1>

                                        <div className="flex justify-between items-center mt-1 ">
                                            <div className="flex-1 py-1 px-0.5 bg-gray-700 flex items-center justify-center mr-0.5 rounded-bl-lg">
                                                <BsStarFill className={`text-primary ${isMobileView ? "w-3 h-3" : "w-4 h-4"}`} />
                                                <TextAtom
                                                    div_style="flex mx-1"
                                                    text_style={`text-gray-400 ${isMobileView ? "text-xs" : "text-sm"}`}
                                                    text={25}
                                                />
                                            </div>
                                            <div className="flex-1 py-1 px-0.5 bg-gray-700  flex items-center justify-center ml-0.5 rounded-br-lg">
                                                <FaUserCheck className={`text-primary ${isMobileView ? "w-3 h-3" : "w-4 h-4"}`} />
                                                <TextAtom
                                                    div_style="flex mx-1"
                                                    text_style={`text-gray-400 ${isMobileView ? "text-xs" : "text-sm"}`}
                                                    text={79}
                                                />
                                            </div>
                                        </div>
                                    </Link>
                                </div> : null
                            )
                        }
                        <div class="mr-2 m-2 bg-opacity-0 rounded-lg drop-shadow-md">
                            <a href="/movies">
                                <div class={`overflow-hidden relative bg-opacity-0 rounded-tr-lg rounded-tl-lg ${isMobileView?'w-28 h-40':'w-72 h-80'}`}>
                                    <img class={`hover:scale-110 transistion duration-500 object-cover rounded-tr-lg rounded-tl-lg bg-opacity-0 ${isMobileView?'w-28 h-40':'w-72 h-80'}`} src={moreimg} />
                                </div>
                            </a>
                        </div>
                    </div>
                </div> */}
                    {/* <span class="text-gray-300 font-bold mx-2 text-sm my-2 sm:text-xl">Top 5 Stars</span> */}
                    {/* <div className="flex px-4 relative sm:mb-10 mb-3">
                    <div className="flex items-center overflow-x-auto overflow-y-hidden scroll-smooth">
                        {celebrity &&
                            celebrity.map((e, index) => index < 5 ?
                                <div className="mr-2 m-2 bg-gray-800 rounded-lg drop-shadow-md" key={index}>
                                    <Link to={`/celebrity/${e.id}`}>
                                        <div className={`overflow-hidden relative bg-gray-700 rounded-tr-lg rounded-tl-lg ${isMobileView ? "w-28 h-32" : "w-72 h-80"}`}>
                                            {e.cover_url && (
                                                <img
                                                    alt=""
                                                    className={`hover:scale-110 transistion duration-500 object-cover rounded-tr-lg rounded-tl-lg ${isMobileView ? "w-28 h-32" : "w-72 h-80"}`}
                                                    src={e.cover_url}
                                                />
                                            )}

                                            {
                                                <div
                                                    className={`absolute top-0 left-5 ${isMobileView ? "w-6 h-8" : "w-8 h-16"} ml-2 text-center`}
                                                    style={{
                                                        backgroundImage: `url(${bookmark})`,
                                                        backgroundSize: "contain",
                                                        backgroundRepeat: "no-repeat",
                                                    }}
                                                >
                                                    <span className={`text-gray-200 ${isMobileView ? "text-xs" : "text-md"}`}>{`#${index + 1}`}</span>
                                                </div>
                                            }
                                        </div>

                                        <TextMarquee
                                            title={e.name}
                                            marqueeAfterLength={isMobileView ? 18 : 30}
                                            text_style={`text-gray-200 w-full text-center pt-1 ${isMobileView ? "text-xs" : "text-md"}`}
                                            div_style={`${isMobileView ? "w-28" : "w-72"}`} />

                                        <h1
                                            className={`text-gray-400 w-full text-center truncate -mt-1 ${isMobileView ? "w-28 text-xss" : "w-72 text-sm"}`}>
                                            {e.releaseYear}
                                        </h1>

                                        <div className="flex justify-between items-center mt-1 ">
                                            <div className="flex-1 py-1 px-0.5 bg-gray-700 flex items-center justify-center mr-0.5 rounded-bl-lg">
                                                <BsStarFill className={`text-primary ${isMobileView ? "w-3 h-3" : "w-4 h-4"}`} />
                                                <TextAtom
                                                    div_style="flex mx-1"
                                                    text_style={`text-gray-400 ${isMobileView ? "text-xs" : "text-sm"}`}
                                                    text={25}
                                                />
                                            </div>
                                            <div className="flex-1 py-1 px-0.5 bg-gray-700  flex items-center justify-center ml-0.5 rounded-br-lg">
                                                <FaUserCheck className={`text-primary ${isMobileView ? "w-3 h-3" : "w-4 h-4"}`} />
                                                <TextAtom
                                                    div_style="flex mx-1"
                                                    text_style={`text-gray-400 ${isMobileView ? "text-xs" : "text-sm"}`}
                                                    text={79}
                                                />
                                            </div>
                                        </div>
                                    </Link>
                                </div> : null
                            )
                        }
                       <div class="mr-2 m-2 bg-opacity-0 rounded-lg drop-shadow-md">
                            <a href="/celebrity">
                                <div class={`overflow-hidden relative bg-opacity-0 rounded-tr-lg rounded-tl-lg ${isMobileView?'w-28 h-40':'w-72 h-80'}`}>
                                    <img class={`hover:scale-110 transistion duration-500 object-cover rounded-tr-lg rounded-tl-lg bg-opacity-0 ${isMobileView?'w-28 h-40':'w-72 h-80'}`} src={moreimg} />
                                </div>
                            </a>
                        </div>
                    </div>
                </div> */}
                    {/* <span class="text-gray-300 font-bold mx-2 text-sm my-2 sm:text-xl">Top 5 Products</span> */}
                    {/* <div className="flex px-4 relative sm:mb-10 mb-3">
                    <div className="flex items-center overflow-x-auto overflow-y-hidden scroll-smooth">
                        {product &&
                            product.map((e, index) => index < 5 ?
                                <div className="mr-2 m-2 bg-gray-800 rounded-lg drop-shadow-md" key={index}>
                                    <Link to={`/product/${e.id}`}>
                                        <div className={`overflow-hidden relative bg-gray-700 rounded-tr-lg rounded-tl-lg ${isMobileView ? "w-28 h-32" : "w-72 h-80"}`}>
                                            {e.cover_url && (
                                                <img
                                                    alt=""
                                                    className={`hover:scale-110 transistion duration-500 object-cover rounded-tr-lg rounded-tl-lg ${isMobileView ? "w-28 h-32" : "w-72 h-80"}`}
                                                    src={e.cover_url}
                                                />
                                            )}

                                            {
                                                <div
                                                    className={`absolute top-0 left-5 ${isMobileView ? "w-6 h-8" : "w-8 h-16"} ml-2 text-center`}
                                                    style={{
                                                        backgroundImage: `url(${bookmark})`,
                                                        backgroundSize: "contain",
                                                        backgroundRepeat: "no-repeat",
                                                    }}
                                                >
                                                    <span className={`text-gray-200 ${isMobileView ? "text-xs" : "text-md"}`}>{`#${index + 1}`}</span>
                                                </div>
                                            }
                                        </div>

                                        <TextMarquee
                                            title={e.name}
                                            marqueeAfterLength={isMobileView ? 18 : 30}
                                            text_style={`text-gray-200 w-full text-center pt-1 ${isMobileView ? "text-xs" : "text-md"}`}
                                            div_style={`${isMobileView ? "w-28" : "w-72"}`} />

                                        <h1
                                            className={`text-gray-400 w-full text-center truncate -mt-1 ${isMobileView ? "w-28 text-xss" : "w-72 text-sm"}`}>
                                            {e.releaseYear}
                                        </h1>

                                        <div className="flex justify-between items-center mt-1 ">
                                            <div className="flex-1 py-1 px-0.5 bg-gray-700 flex items-center justify-center mr-0.5 rounded-bl-lg">
                                                <BsStarFill className={`text-primary ${isMobileView ? "w-3 h-3" : "w-4 h-4"}`} />
                                                <TextAtom
                                                    div_style="flex mx-1"
                                                    text_style={`text-gray-400 ${isMobileView ? "text-xs" : "text-sm"}`}
                                                    text={25}
                                                />
                                            </div>
                                            <div className="flex-1 py-1 px-0.5 bg-gray-700  flex items-center justify-center ml-0.5 rounded-br-lg">
                                                <FaUserCheck className={`text-primary ${isMobileView ? "w-3 h-3" : "w-4 h-4"}`} />
                                                <TextAtom
                                                    div_style="flex mx-1"
                                                    text_style={`text-gray-400 ${isMobileView ? "text-xs" : "text-sm"}`}
                                                    text={79}
                                                />
                                            </div>
                                        </div>
                                    </Link>
                                </div> : null
                            )
                        }
                        <div class="mr-2 m-2 bg-opacity-0 rounded-lg drop-shadow-md">
                            <a href="/product">
                                <div class={`overflow-hidden relative bg-opacity-0 rounded-tr-lg rounded-tl-lg ${isMobileView ? 'w-28 h-40' : 'w-72 h-80'}`}>
                                    <img class={`hover:scale-110 transistion duration-500 object-cover rounded-tr-lg rounded-tl-lg bg-opacity-0 ${isMobileView ? 'w-28 h-40' : 'w-72 h-80'}`} src={moreimg} />
                                </div>
                            </a>
                        </div>
                    </div>
                </div> */}
                    {/* <span class="text-gray-300 font-bold mx-2 text-sm my-2 sm:text-xl">Top 5 News</span> */}
                    {/* <div className="flex px-4 relative sm:mb-10 mb-3">
                    <div className="flex items-center overflow-x-auto overflow-y-hidden scroll-smooth">
                        {news &&
                            news.map((e, index) => index < 5 ?
                                <div className="mr-2 m-2 bg-gray-800 rounded-lg drop-shadow-md" key={index}>
                                    <Link to={`/news/${e.id}`}>
                                        <div className={`overflow-hidden relative bg-gray-700 rounded-tr-lg rounded-tl-lg ${isMobileView ? "w-28 h-32" : "w-72 h-80"}`}>
                                            {e.cover_url && (
                                                <img
                                                    alt=""
                                                    className={`hover:scale-110 transistion duration-500 object-cover rounded-tr-lg rounded-tl-lg ${isMobileView ? "w-28 h-32" : "w-72 h-80"}`}
                                                    src={e.cover_url}
                                                />
                                            )}

                                            {
                                                <div
                                                    className={`absolute top-0 left-5 ${isMobileView ? "w-6 h-8" : "w-8 h-16"} ml-2 text-center`}
                                                    style={{
                                                        backgroundImage: `url(${bookmark})`,
                                                        backgroundSize: "contain",
                                                        backgroundRepeat: "no-repeat",
                                                    }}
                                                >
                                                    <span className={`text-gray-200 ${isMobileView ? "text-xs" : "text-md"}`}>{`#${index + 1}`}</span>
                                                </div>
                                            }
                                        </div>

                                        <TextMarquee
                                            title={e.name}
                                            marqueeAfterLength={isMobileView ? 18 : 30}
                                            text_style={`text-gray-200 w-full text-center pt-1 ${isMobileView ? "text-xs" : "text-md"}`}
                                            div_style={`${isMobileView ? "w-28" : "w-72"}`} />

                                        <h1
                                            className={`text-gray-400 w-full text-center truncate -mt-1 ${isMobileView ? "w-28 text-xss" : "w-72 text-sm"}`}>
                                            {e.releaseYear}
                                        </h1>

                                        <div className="flex justify-between items-center mt-1 ">
                                            <div className="flex-1 py-1 px-0.5 bg-gray-700 flex items-center justify-center mr-0.5 rounded-bl-lg">
                                                <BsStarFill className={`text-primary ${isMobileView ? "w-3 h-3" : "w-4 h-4"}`} />
                                                <TextAtom
                                                    div_style="flex mx-1"
                                                    text_style={`text-gray-400 ${isMobileView ? "text-xs" : "text-sm"}`}
                                                    text={25}
                                                />
                                            </div>
                                            <div className="flex-1 py-1 px-0.5 bg-gray-700  flex items-center justify-center ml-0.5 rounded-br-lg">
                                                <FaUserCheck className={`text-primary ${isMobileView ? "w-3 h-3" : "w-4 h-4"}`} />
                                                <TextAtom
                                                    div_style="flex mx-1"
                                                    text_style={`text-gray-400 ${isMobileView ? "text-xs" : "text-sm"}`}
                                                    text={79}
                                                />
                                            </div>
                                        </div>
                                    </Link>
                                </div> : null
                            )
                        }
                        <div class="mr-2 m-2 bg-opacity-0 rounded-lg drop-shadow-md">
                            <a href="/top-news">
                                <div class={`overflow-hidden relative bg-opacity-0 rounded-tr-lg rounded-tl-lg ${isMobileView ? 'w-28 h-40' : 'w-72 h-80'}`}>
                                    <img class={`hover:scale-110 transistion duration-500 object-cover rounded-tr-lg rounded-tl-lg bg-opacity-0 ${isMobileView ? 'w-28 h-40' : 'w-72 h-80'}`} src={moreimg} />
                                </div>
                            </a>
                        </div>
                    </div>
                </div> */}
                    {/* <span class="text-gray-300 font-bold mx-2 text-sm my-2 sm:text-xl">Top 5 Brands</span> */}
                    {/* <div className="flex px-4 relative sm:mb-10 mb-3 ">
                    <div className="flex items-center overflow-x-auto overflow-y-hidden scroll-smooth">
                        {brands &&
                            brands.map((e, index) => index < 5 ?
                                <div className="mr-2 m-2 bg-gray-800 rounded-lg drop-shadow-md" key={index}>
                                    <Link to={`/brand/${e.id}`}>
                                        <div className={`overflow-hidden relative bg-gray-700 rounded-tr-lg rounded-tl-lg ${isMobileView ? "w-28 h-32" : "w-72 h-80"}`}>
                                            {e.cover_url && (
                                                <img
                                                    alt=""
                                                    className={`hover:scale-110 transistion duration-500 object-cover rounded-tr-lg rounded-tl-lg ${isMobileView ? "w-28 h-32" : "w-72 h-80"}`}
                                                    src={e.cover_url}
                                                />
                                            )}

                                            {
                                                <div
                                                    className={`absolute top-0 left-5 ${isMobileView ? "w-6 h-8" : "w-8 h-16"} ml-2 text-center`}
                                                    style={{
                                                        backgroundImage: `url(${bookmark})`,
                                                        backgroundSize: "contain",
                                                        backgroundRepeat: "no-repeat",
                                                    }}
                                                >
                                                    <span className={`text-gray-200 ${isMobileView ? "text-xs" : "text-md"}`}>{`#${index + 1}`}</span>
                                                </div>
                                            }
                                        </div>

                                        <TextMarquee
                                            title={e.name}
                                            marqueeAfterLength={isMobileView ? 18 : 30}
                                            text_style={`text-gray-200 w-full text-center pt-1 ${isMobileView ? "text-xs" : "text-md"}`}
                                            div_style={`${isMobileView ? "w-28" : "w-72"}`} />

                                        <h1
                                            className={`text-gray-400 w-full text-center truncate -mt-1 ${isMobileView ? "w-28 text-xss" : "w-72 text-sm"}`}>
                                            {e.releaseYear}
                                        </h1>

                                        <div className="flex justify-between items-center mt-1 ">
                                            <div className="flex-1 py-1 px-0.5 bg-gray-700 flex items-center justify-center mr-0.5 rounded-bl-lg">
                                                <BsStarFill className={`text-primary ${isMobileView ? "w-3 h-3" : "w-4 h-4"}`} />
                                                <TextAtom
                                                    div_style="flex mx-1"
                                                    text_style={`text-gray-400 ${isMobileView ? "text-xs" : "text-sm"}`}
                                                    text={25}
                                                />
                                            </div>
                                            <div className="flex-1 py-1 px-0.5 bg-gray-700  flex items-center justify-center ml-0.5 rounded-br-lg">
                                                <FaUserCheck className={`text-primary ${isMobileView ? "w-3 h-3" : "w-4 h-4"}`} />
                                                <TextAtom
                                                    div_style="flex mx-1"
                                                    text_style={`text-gray-400 ${isMobileView ? "text-xs" : "text-sm"}`}
                                                    text={79}
                                                />
                                            </div>
                                        </div>
                                    </Link>
                                </div> : null
                            )
                        }
                        <div class="mr-2 m-2 bg-opacity-0 rounded-lg drop-shadow-md">
                            <a href="/brand">
                                <div class={`overflow-hidden relative bg-opacity-0 rounded-tr-lg rounded-tl-lg ${isMobileView ? 'w-28 h-40' : 'w-72 h-80'}`}>
                                    <img class={`hover:scale-110 transistion duration-500 object-cover rounded-tr-lg rounded-tl-lg bg-opacity-0 ${isMobileView ? 'w-28 h-40' : 'w-72 h-80'}`} src={moreimg} />
                                </div>
                            </a>
                        </div>
                    </div>
                </div> */}
                    {/* <span class="text-gray-300 font-bold mx-2 text-sm my-2 sm:text-xl">Top 5 Shows</span> */}
                    {/* <div className="flex px-4 relative">
                    <div className="flex items-center overflow-x-auto overflow-y-hidden scroll-smooth">
                        {shows &&
                            shows.map((e, index) => index < 5 ?
                                <div className="mr-2 m-2 bg-gray-800 rounded-lg drop-shadow-md" key={index}>
                                    <Link to={`/shows/${e.id}`}>
                                        <div className={`overflow-hidden relative bg-gray-700 rounded-tr-lg rounded-tl-lg ${isMobileView ? "w-28 h-32" : "w-72 h-80"}`}>
                                            {e.cover_url && (
                                                <img
                                                    alt=""
                                                    className={`hover:scale-110 transistion duration-500 object-cover rounded-tr-lg rounded-tl-lg ${isMobileView ? "w-28 h-32" : "w-72 h-80"}`}
                                                    src={e.cover_url}
                                                />
                                            )}

                                            {
                                                <div
                                                    className={`absolute top-0 left-5 ${isMobileView ? "w-6 h-8" : "w-8 h-16"} ml-2 text-center`}
                                                    style={{
                                                        backgroundImage: `url(${bookmark})`,
                                                        backgroundSize: "contain",
                                                        backgroundRepeat: "no-repeat",
                                                    }}
                                                >
                                                    <span className={`text-gray-200 ${isMobileView ? "text-xs" : "text-md"}`}>{`#${index + 1}`}</span>
                                                </div>
                                            }
                                        </div>

                                        <TextMarquee
                                            title={e.name}
                                            marqueeAfterLength={isMobileView ? 18 : 30}
                                            text_style={`text-gray-200 w-full text-center pt-1 ${isMobileView ? "text-xs" : "text-md"}`}
                                            div_style={`${isMobileView ? "w-28" : "w-72"}`} />

                                        <h1
                                            className={`text-gray-400 w-full text-center truncate -mt-1 ${isMobileView ? "w-28 text-xss" : "w-72 text-sm"}`}>
                                            {e.releaseYear}
                                        </h1>

                                        <div className="flex justify-between items-center mt-1 ">
                                            <div className="flex-1 py-1 px-0.5 bg-gray-700 flex items-center justify-center mr-0.5 rounded-bl-lg">
                                                <BsStarFill className={`text-primary ${isMobileView ? "w-3 h-3" : "w-4 h-4"}`} />
                                                <TextAtom
                                                    div_style="flex mx-1"
                                                    text_style={`text-gray-400 ${isMobileView ? "text-xs" : "text-sm"}`}
                                                    text={25}
                                                />
                                            </div>
                                            <div className="flex-1 py-1 px-0.5 bg-gray-700  flex items-center justify-center ml-0.5 rounded-br-lg">
                                                <FaUserCheck className={`text-primary ${isMobileView ? "w-3 h-3" : "w-4 h-4"}`} />
                                                <TextAtom
                                                    div_style="flex mx-1"
                                                    text_style={`text-gray-400 ${isMobileView ? "text-xs" : "text-sm"}`}
                                                    text={79}
                                                />
                                            </div>
                                        </div>
                                    </Link>
                                </div> : null
                            )
                        }
                        <div class="mr-2 m-2 bg-opacity-0 rounded-lg drop-shadow-md">
                            <a href="/shows">
                                <div class={`overflow-hidden relative bg-opacity-0 rounded-tr-lg rounded-tl-lg ${isMobileView ? 'w-28 h-40' : 'w-72 h-80'}`}>
                                    <img class={`hover:scale-110 transistion duration-500 object-cover rounded-tr-lg rounded-tl-lg bg-opacity-0 ${isMobileView ? 'w-28 h-40' : 'w-72 h-80'}`} src={moreimg} />
                                </div>
                            </a>
                        </div>
                    </div>
                    </div> */}
                </div>
            </main>
            <DesktopFooter />
        </div>
    );
}

export default HomePage;