import { useIsMobileViewContext } from '../context/isMobileView';
import MySlider from './MySlider';
import image from '../assets/images/bookmark.png'
import { Slide } from '@mui/material';
import Slider from 'react-slick';
import { useEffect } from 'react';
function ModalGallery({ gallery, openModal, handleCloseClick }) {
    const { isMobileView } = useIsMobileViewContext();
   
    
console.log(gallery);
    return (
        openModal ?
            <div className="flex justify-center items-center overflow-x-hidden z-[9999] overflow-y-auto fixed inset-0  outline-none focus:outline-none bg-opacity-75 bg-black">
                <div className="relative w-auto my-6 mx-auto max-w-[80%]">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-900 outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-gray-700 rounded-t ">
                            <h3 className={`font-semibold text-gray-200 ${isMobileView ? "text-sm" : "text-md"}`}>Gallery</h3>
                            <button
                                className="border-0 float-right bg-gray-800 rounded-full cursor-pointer"
                                onClick={() => handleCloseClick()}
                            >
                                <span className="text-gray-200 opacity-7 h-6 w-6 block py-0">
                                    x
                                </span>
                            </button>
                        </div>
                        {/* <div className={`w-full flex flex-row overflow-x-auto ${isMobileView ? "h-86" : "h-144"}`}>
                            {gallery.map(item => <img src={item.url} alt="" className="py-1 h-100 w-full" />)}
                        </div> */}
                        {/* <div className='overflow-x-auto overflow-y-hidden flex flex-row h-[600px] w-[700px] bg-green-600'>
                            {gallery.map((item, index) =>
                                <img key={index} src={item.url} alt="" className=" h-[600px] w-[700px] object-cover" />
                            )}
                        </div> */}
                        {/* <Slider>
                            {gallery.length>0&&gallery.map((item, index) =>
                                <img key={index} src={item} alt="" className="lg:p-6 lg:h-[800px] lg:w-[700px] object-contain" />
                            )}
                        </Slider> */}
                    </div>
                </div>
            </div >
            : <></>
    );
}

export default ModalGallery;