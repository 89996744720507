import BackArrowNavigation from "../components/BackArrowNavigation"

function HelpPage() {
    return (
        <main className="min-h-screen bg-gray-900">
            <BackArrowNavigation />

            <div className="text-gray-200 pt-12 px-2 flex flex-col items-center">
                <div className="mt-10 flex flex-col items-center">
                    <a className="bg-primary text-white rounded-md px-2 py-1" href="mailto:support@starpot.in">support@starpot.in</a>
                </div>
            </div>
        </main>
    )
}

export default HelpPage