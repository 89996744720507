import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { InstantSearch, SearchBox, Index, Hits } from 'react-instantsearch-dom';

import { algoliaSearchClient } from "../algolia";

import { useIsMobileViewContext } from "../context/isMobileView";

function SearchPage() {
  const navigate = useNavigate();

  const { isMobileView } = useIsMobileViewContext();

  const [showSuggestions, setShowSuggestions] = useState(false);

  const handleQueryChange = (value) => {
    if (value && value.length > 0) setShowSuggestions(true);
    else setShowSuggestions(false);
  }

  const MovieHit = ({ hit }) => {
    return (
      <div className="flex flex-row items-center mx-2 my-3 bg-gray-700 rounded-lg cursor-pointer"
        onClick={(_) => navigate(`/movie/${hit.objectID}`)}>
        <img className="w-32 h-24 rounded-tl-lg rounded-bl-lg" src={hit.cover_url} alt="" />
        <div className="flex flex-col justify-center ml-2 p-2">
          <span className="text-sm text-gray-200">{hit.name}</span>
          <span className="text-xs text-gray-400">{hit.type?.toString()}</span>
        </div>
      </div>
    );
  }

  const CelebrityHit = ({ hit }) => {
    return (
      <div className="flex flex-row items-center mx-2 my-3 bg-gray-700 rounded-lg cursor-pointer"
        onClick={(_) => navigate(`/celebrity/${hit.objectID}`)}>
        <img className="w-24 h-24 rounded-tl-lg rounded-bl-lg" src={hit.cover_url} alt="" />
        <div className="flex flex-col justify-center ml-2 p-2">
          <span className="text-sm text-gray-200">{hit.name}</span>
          <span className="text-xs text-gray-400">{hit.type?.toString()}</span>
        </div>
      </div>
    );
  }

  const BrandHit = ({ hit }) => {
    return (
      <div className="flex flex-row items-center mx-2 my-3 bg-gray-700 rounded-lg cursor-pointer"
        onClick={(_) => navigate(`/brand/${hit.objectID}`)}>
        <img className="w-32 h-24 rounded-tl-lg rounded-bl-lg" src={hit.cover_url} alt="" />
        <div className="flex flex-col justify-center ml-2 p-2">
          <span className="text-sm text-gray-200">{hit.title}</span>
          <span className="text-xs text-gray-400">{hit.type?.toString()}</span>
        </div>
      </div>
    );
  }

  const ProductHit = ({ hit }) => {
    return (
      <div className="flex flex-row items-center mx-2 my-3 bg-gray-700 rounded-lg cursor-pointer"
        onClick={(_) => navigate(`/product/${hit.objectID}`)}>
        <img className="w-32 h-24 rounded-tl-lg rounded-bl-lg" src={hit.cover_url} alt="" />
        <div className="flex flex-col justify-center ml-2 p-2">
          <span className="text-sm text-gray-200">{hit.title}</span>
          <span className="text-xs text-gray-400">{hit.brand?.toString()}</span>
        </div>
      </div>
    );
  }

  const ShowHit = ({ hit }) => {
    return (
      <div className="flex flex-row items-center mx-2 my-3 bg-gray-700 rounded-lg cursor-pointer"
        onClick={(_) => navigate(`/shows/${hit.objectID}`)}>
        <img className="w-32 h-24 rounded-tl-lg rounded-bl-lg" src={hit.cover_url} alt="" />
        <div className="flex flex-col justify-center ml-2 p-2">
          <span className="text-sm text-gray-200">{hit.title}</span>
          <span className="text-xs text-gray-400">{hit.type?.toString()}</span>
        </div>
      </div>
    );
  }

  const NewsHit = ({ hit }) => {
    return (
      <div className="flex flex-row items-center mx-2 my-3 bg-gray-700 rounded-lg cursor-pointer"
        onClick={(_) => navigate(`/news/${hit.objectID}`)}>
        <img className="w-32 h-24 rounded-tl-lg rounded-bl-lg" src={hit.cover_url} alt="" />
        <div className="flex flex-col justify-center ml-2 p-2">
          <span className="text-sm text-gray-200">{hit.title}</span>
          <span className="text-xs text-gray-400">{hit.type?.toString()}</span>
        </div>
      </div>
    );
  }

  return (
    <main className="min-h-screen bg-gray-900 flex flex-col items-center flex-grow">
      <div className={`${isMobileView ? "w-full" : "w-2/4 py-2"}`}>
        <input type='text' className="outline-none w-full p-2 rounded-md " placeholder="Search"/>
        <InstantSearch searchClient={algoliaSearchClient} indexName="celebrity">
          {/* <div className="flex flex-col items-center bg-gray-100">
            <SearchBox
              searchAsYouType={true}
              showReset={true}
              submit={<span></span>}
              reset={<span></span>}
              showLoadingIndicator={true}
              autoFocus={true}
              translations={{
                placeholder: 'Search',
              }}
              className="w-full outline-none rounded-md p-1 text-gray-700 placeholder-gray-700"
              onChange={(e) => handleQueryChange(e.target.value)}
            />
          </div> */}

          {showSuggestions ?
            <div className="min-h-screen">
              <div className="bg-gray-800 w-full p-2">
                <Index indexName="movie">
                  <span className="text-primary">Movies</span>
                  <Hits hitComponent={MovieHit} />
                </Index>

                <Index indexName="celebrity">
                  <span className="text-primary">Stars</span>
                  <Hits hitComponent={CelebrityHit} />
                </Index>

                <Index indexName="brand">
                  <span className="text-primary">Brands</span>
                  <Hits hitComponent={BrandHit} />
                </Index>

                <Index indexName="product">
                  <span className="text-primary">Products</span>
                  <Hits hitComponent={ProductHit} />
                </Index>

                <Index indexName="shows">
                  <span className="text-primary">Shows</span>
                  <Hits hitComponent={ShowHit} />
                </Index>

                <Index indexName="news">
                  <span className="text-primary">News</span>
                  <Hits hitComponent={NewsHit} />
                </Index>
              </div>
            </div>
            : <></>
          }
        </InstantSearch>
      </div>
    </main>
  )
}

export default SearchPage