import BackArrowNavigation from '../components/BackArrowNavigation'

function SettingsPage() {
    return (
        <main className="min-h-screen bg-gray-900">
            <BackArrowNavigation />

            <div className="pt-12 flex flex-col items-center">

            </div>
        </main>
    )
}

export default SettingsPage