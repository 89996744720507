import { onAuthStateChanged } from "../../firebase";
import { useState, useEffect, useRef } from "react";

import { auth } from "../../firebase";

import { AuthContext } from "./useAuth.context";

import { useAppDispatch, loadUser } from '../../redux';

export const AuthProvider = (props) => {
  const dispatch = useAppDispatch();

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [userID, setUserID] = useState(null);

  const unsubscribeRef = useRef(null);

  useEffect(() => {
    if (unsubscribeRef.current) { }
    else {
      unsubscribeRef.current = onAuthStateChanged(auth, (user) => {
        if (user) {
          dispatch(loadUser(user.uid));
          setUserID(user.uid);
          handleStatusChange(true);
        } else {
          setUserID(null);
          handleStatusChange(false);
        }
      });
    }

    return () => {
      if (unsubscribeRef.current)
        unsubscribeRef.current();
    }
  }, []);

  const handleStatusChange = (status) => {
    setIsUserLoggedIn(status);
  };

  const onLogout = async () => {
    await auth.signOut();
    setIsUserLoggedIn(false);
  };

  const onLogin = (id) => {
    setUserID(id);
    setIsUserLoggedIn(true);
  };

  return (
    <AuthContext.Provider
      value={{
        isUserLoggedIn,
        userID,
        onLogin,
        onLogout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
