import { ProductDetailsTemplate } from "../view/templates";

function ProductDetails() {
  return (
    <main className="flex-grow min-h-screen bg-gray-900">
      <ProductDetailsTemplate />
    </main>
  )
}

export default ProductDetails