import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RWebShare } from "react-web-share";
import { BsTwitter, BsFacebook, BsInstagram, BsYoutube, BsGlobe } from 'react-icons/bs';

//icons
import { BsStarFill } from "react-icons/bs";
import { FaUserCheck, FaShareSquare, FaHome } from "react-icons/fa";
import { AiOutlineStar } from "react-icons/ai";

import { useIsMobileViewContext } from "../../context/isMobileView";
import { useAuthContext } from "../../context/auth";
import Footer from "../../components/Footer";
import BannerSlider from "../../components/banner_slider";
//image
import bookmark from "../../assets/images/small_bookmark.png";
import sidebanner from '../../assets/images/sidebanner.jpg'
import { useAppDispatch, useAppSelector, loadNews, addNews, addNewsRating, updateNewsRating, updateUserRatingCount } from "../../redux";

import { loadNewsWithId, hasUserAlreadyRated } from "../../service/news.service";
import { loadUserReview } from "../../service/review.service";

import { TextAtom } from "../atoms";

import Modal from "../../components/Modal";
import ModalGallery from "../../components/ModalGallery";
import BackArrowNavigation from "../../components/BackArrowNavigation";
import ad from '../../assets/images/ad.jpg'
import ad2 from '../../assets/images/ad2.jpg'
function NewsDetailsTemplate() {
  const { isMobileView } = useIsMobileViewContext();
  const location = useLocation();

  const RecentNews = location.state?.array;

  const language = location.state?.Lang;
  const { isUserLoggedIn } = useAuthContext();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(state => state.user.profile);
  const news = useAppSelector((state) => state.news.news);
  const advertisements = useAppSelector((state) => state.advertisement.advertisements);

  const [openGalleryModal, setOpenGalleryModal] = useState(false);

  const [adsFilter, setAdsFilter] = useState(null);
  const [verticalAd1, setVerticalAd1] = useState(null);
  const [verticalAd2, setVerticalAd2] = useState(null);
  const [userRatingState, setUserRatingState] = useState(false);
  const [userReviewStatus, setUserReviewStatus] = useState(false);
  const [currentNews, setCurrentNews] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [rating, setRating] = useState(null);
  const [userOldRating, setUserOldRating] = useState();
  const [rank, setRank] = useState(null)
  const [link, setLink] = useState(null)

  useEffect(() => {
    if (news.length <= 0)
      dispatch(loadNews());
  }, [news, dispatch]);

  useEffect(() => {
    if (news.length > 0 && currentNews === null && link !== undefined && link !== null) {
      if (news.some(item => item.id === link))
        setCurrentNews(news.filter((item) => item.id === link)[0]);
      else loadNewsWithId(link)
        .then(result => {
          dispatch(addNews(result));
          setCurrentNews(result);
        })
        .catch(e => console.error(e));
    }
  }, [currentNews, dispatch, link, news]);

  useEffect(() => {
    if (currentNews !== null) {
      setRating(currentNews.rating)
    }
  }, [currentNews])

  useEffect(() => {
    setAdsFilter(advertisements.filter(item => item.type === "news" && item.orientation === "horizontal"));
    let verticalAds = advertisements.filter(item => item.type === "news" && item.orientation === "vertical");
    if (verticalAds.length > 0) {
      setVerticalAd1(verticalAds[Math.floor(Math.random(verticalAds.length) * verticalAds.length)]);
      setVerticalAd2(verticalAds[Math.floor(Math.random(verticalAds.length) * verticalAds.length)]);
    }
  }, [advertisements]);

  useEffect(() => {
    const getUserRatingStatus = async () => {
      hasUserAlreadyRated(link, user.id)
        .then(result => {
          setUserOldRating(result);
          setUserRatingState(result && result > 0);
        })
        .catch(_ => setUserRatingState(false));
    }

    const getUserReviewStatus = async () => {
      loadUserReview("news", link, user.id)
        .then(result => {
          if (result !== null) {
            setUserReviewStatus(true);
          } else {
            setUserReviewStatus(false);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    if (link && user.id) {
      getUserRatingStatus();
      getUserReviewStatus();
    }
  }, [link, user.id]);

  useEffect(() => {
    let path = location.pathname;
    let idWithRank = path.slice(path.lastIndexOf("/") + 1, path.length);
    if (idWithRank.includes("-")) {
      let id = idWithRank.slice(0, idWithRank.lastIndexOf("-"));
      let rank = idWithRank.slice(idWithRank.lastIndexOf("-") + 1, idWithRank.length);
      setRank(rank)
      setLink(id)
    } else {
      setLink(idWithRank);
    }
  }, [location.pathname]);

  const handleStarClick = () => {
    if (isUserLoggedIn) setOpenModal(true)
    else navigate("/login");
  };

  const handleReviewClick = () => {
    if (isUserLoggedIn) navigate(`/review/news/${currentNews.id}`);
    else navigate("/login");
  };

  const handleRating = (newRating) => {
    if (newRating) {
      const data = {
        newUserRating: newRating,
        newsId: currentNews.id,
        userId: user.id,
        oldRating: rating,
        oldUserRating: userOldRating,
        newRating: rating + newRating
      };

      if (userRatingState) {
        const ratingDifference =
          Math.max(newRating, userOldRating) -
          Math.min(newRating, userOldRating);

        if (ratingDifference > 0) {
          if (newRating > userOldRating) {
            setRating(rating + ratingDifference);
            dispatch(updateUserRatingCount(ratingDifference));
          } else {
            setRating(rating - Math.abs(ratingDifference));
            dispatch(updateUserRatingCount(-Math.abs(ratingDifference)));
          }
        } else {
          setRating(newRating);
          dispatch(updateUserRatingCount(newRating));
        }

        dispatch(updateNewsRating(data));
      }
      else {
        setRating(rating + newRating);
        dispatch(updateUserRatingCount(newRating));
        dispatch(addNewsRating(data))
      }

      setOpenModal(false)
      setUserRatingState(true);
    }
  }

  const closeModal = () => {
    setOpenModal(false)
  }

  function createMarkup() {
    return { __html: currentNews.title };
  }
  function createMarkup1(index) {
    return { __html: currentNews.content.split('|p|')[index] };
  }

  const handleGalleryModalOpen = () => {
    setOpenGalleryModal(true);
  }

  const handleGalleryModalClose = () => {
    setOpenGalleryModal(false);
  }

  const returnThePaddedValues = (val) => {
    if (val.toString().length < 2) {
      return val.toString().padStart(2, 0);
    }
    else {
      return val;
    }
  }
  const getThePostTime = (_date) => {
    console.log(_date);
    let msecs = new Date(new Date().getUTCFullYear() + "-" + returnThePaddedValues(Number(new Date().getUTCMonth()) + 1) + "-" + returnThePaddedValues(new Date().getUTCDate()) + " " + returnThePaddedValues(new Date().getUTCHours()) + ":" + returnThePaddedValues(new Date().getUTCMinutes()) + ":" + returnThePaddedValues(new Date().getUTCSeconds())).getTime() - new Date(new Date(_date).getUTCFullYear() + "-" + returnThePaddedValues(Number(new Date(_date).getUTCMonth()) + 1) + "-" + returnThePaddedValues(new Date(_date).getUTCDate()) + " " + returnThePaddedValues(new Date(_date).getUTCHours()) + ":" + returnThePaddedValues(new Date(_date).getUTCMinutes()) + ":" + returnThePaddedValues(new Date(_date).getUTCSeconds())).getTime();
    let secs = msecs / 1000;
    let mins = secs / 60;
    let hours = mins / 60;
    let days = hours / 24;
    let weeks = days / 7;
    let months = weeks / 4;
    let years = months / 12;
    if (secs > 59) {
      if (mins > 59) {
        if (hours > 23) {
          if (days > 6) {
            if (weeks > 3) {
              if (months > 11) {
                return years == 0 ? 1 + "y" : years.toFixed(0) + "y";
              }
              else {
                return months == 0 ? 1 + "mo" : months.toFixed(0) + "mo";
              }
            }
            else {
              return weeks == 0 ? 1 + "w" : weeks.toFixed(0) + "w";
            }
          }
          else {
            return days == 0 ? 1 + "d" : days.toFixed(0) + "d";
          }
        }
        else {
          return hours == 0 ? 1 + "h" : hours.toFixed(0) + "h";
        }
      }
      else {
        return mins == 0 ? 1 + "m" : mins.toFixed(0) + "m";
      }
    }
    else {
      return secs == 0 ? 1 + "s" : secs.toFixed(0) + "s";
    }
  }

  function HandleRecentNewsNavigation(e, index) {

    setCurrentNews(news.find(e1 => e1.id == e.id));
  }
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, []);
  return (
    <>
      {currentNews &&
        <Modal defaultRating={currentNews.rating} handleRating={handleRating} closeModal={closeModal} openModal={openModal} />}

      {currentNews &&
        <ModalGallery
          gallery={currentNews.gallery}
          openModal={openGalleryModal}
          handleCloseClick={handleGalleryModalClose} />
      }

      <div className="bg-gray-900 pb-5">
        <BackArrowNavigation />
        <div className="">
          
          {/* {adsFilter && <BannerSlider ads={adsFilter} />} */}
          <div className="w-full flex flex-col items-center justify-between mb-5 py-2 bg-[#f8f8f8]">
            <img src={ad2} />
          </div>
          <div className="max-w-[1800px]  mx-auto flex sm:flex-row flex-col justify-between gap-x-2">
            <div className=" w-[200px] hidden sm:block relative">
              <img className="sticky object-cover top-0" src={sidebanner} />
            </div>
            <div className="flex flex-col xl:flex-row gap-5 w-full justify-between">
              <div className="xl:w-[70%] w-full py-1 px-5  sm:rounded-xl bg-white pb-10">
                <div>
                  <div className="flex flex-row items-center gap-x-2 py-5">
                    <FaHome onClick={() => navigate('/')} className=" text-gray-600 hover:text-primary duration-200 cursor-pointer" />
                    <h5 onClick={() => navigate('/top-news')} className=" text-gray-600 hover:text-primary duration-200 font-semibold hover:cursor-pointer text-sm"> / News</h5>
                    <h5 className=" text-gray-600 font-semibold hover:cursor-pointer hover:text-primary duration-200 text-sm">/ About News</h5>
                  </div>
                </div>
                {currentNews ? <div className={`text-black  text-3xl tracking-wide font-Tamil`} dangerouslySetInnerHTML={createMarkup()} /> : null}
                <div className="flex flex-row items-center my-3 justify-end">
                  <h4 className="text-gray-700 font-semibold text-[13px] ">Published: Today at 3 AM</h4>
                </div>
                <div className="rounded-lg w-full bg-[#f3f2f2] py-3 my-5 flex flex-row pl-6 gap-x-4">
                  <div className="w-10 mt-2 h-5 bg-red-600"></div>
                  <h4 className="font-semibold text-[#828282] leading-7 text-base ">பாலிவுட் நடிகர் சல்மான் கான் வீட்டின் மீது துப்பாக்கிச்சூடு நடத்தப்பட்டுள்ள நிலையில், பாதுகாப்பான பெரிய வீட்டிற்குச் செல்லாமல் தொடர்ந்து சிறிய வீட்டில் வசித்து வருகிறார்.</h4>
                </div>
                {currentNews &&
                  <div className="my-1 relative flex flex-col items-center">
                    <img src={currentNews.cover_url} alt="" className={`rounded-xl object-cover ${isMobileView ? "w-full" : "h-[30rem] w-full"}`} />
                    <div className="w-full flex flex-col items-center my-5">
                      <div className="bg-gray-200 px-5 py-5">
                        <img src={ad2} />
                      </div>
                    </div>
                    {/* {currentNews.gallery && currentNews.gallery.length > 0 &&
                    <span className={`absolute bottom-0 right-0 m-2 p-1 rounded bg-gray-900 
                          text-gray-100 border border-primary cursor-pointer ${isMobileView ? "text-xs" : "text-md"}`}
                      onClick={handleGalleryModalOpen}>
                      More Images
                    </span>
                  } */}
                  </div>
                }
                {
                  currentNews ?
                    currentNews.gallery.map((e, index) =>
                      <div key={index}>
                        {currentNews ? <div className='text-black  text-base my-3' style={{ backgroundColor: 'transparent' }} dangerouslySetInnerHTML={createMarkup1(index)} /> : null}
                        {currentNews && <img src={e.url} alt="" className={`rounded-lg object-cover object-top ${isMobileView ? "w-full" : " w-full"}`} />}
                        <div className="w-full flex flex-col items-center my-5">
                          <div className="bg-gray-200 px-5 py-5">
                            <img src={ad2} />
                          </div>
                        </div>
                      </div>
                    )
                    : null
                }
              </div>
              <div className="xl:w-[30%] w-full bg-white sm:rounded-xl px-5 pb-2">
                <div className="w-full flex flex-col items-center my-5">
                  <div className="bg-gray-200 hidden sm:block px-5 py-5">
                    <img src={ad} />
                  </div>
                </div>
                <h1 className="text-[#1a202c] pt-5 pb-3 text-2xl font-semibold font-Tamil">{language === 'Tamil' ? 'தொடர்புடைய செய்திகள்' : 'Related News'}</h1>
                <div className="border-b-[1px] border-b-gray-400" />
                {
                  RecentNews.length > 0 && RecentNews.map((e, index) => index < 5 ?
                    <div className="flex flex-row w-full items-center py-3 border-b-[1px] gap-x-5 border-b-gray-400">
                      <h1 className="text-6xl w-[10%] text-[#b6b8c1] font-semibold">{index + 1}</h1>
                      <h3 onClick={() => HandleRecentNewsNavigation(e, index)} className="text-xl w-[50%] hover:cursor-pointer hover:text-primary duration-150 font-semibold font-Tamil line-clamp-3">{e.title}</h3>
                      <div onClick={() => HandleRecentNewsNavigation(e, index)} className="w-[40%] h-[80px] overflow-hidden rounded-xl ">
                        {currentNews && <img className="w-[100%] cursor-pointer h-full rounded-xl object-cover object-top hover:scale-110 duration-300" src={e.cover_url} />}
                      </div>
                    </div> : null
                  )
                }
                <div className="bg-gray-200 hidden  mt-5 sm:block px-5 py-5">
                  <img src={ad} />
                </div>
                <h1 className="text-[#1a202c] pt-5 pb-3 text-2xl font-semibold font-Tamil">{language === 'Tamil' ? 'லேட்டஸ்ட் செய்திகள்' : 'Latest News'}</h1>
                <div className="border-b-[1px] border-b-gray-400" />
                {
                  RecentNews.length > 0 && RecentNews.map((e, index) => index < 5 ?
                    <div className="flex flex-row w-full items-center py-3 border-b-[1px] gap-x-5 border-b-gray-400">
                      <h1 className="text-6xl w-[10%] text-[#b6b8c1] font-semibold">{index + 1}</h1>
                      <h3 className="text-xl w-[50%] hover:cursor-pointer hover:text-primary duration-150 font-semibold font-Tamil line-clamp-3">{e.title}</h3>
                      <div className="w-[40%] h-[80px] overflow-hidden rounded-xl ">
                        {currentNews && <img className="w-[100%] h-full rounded-xl object-cover object-top hover:scale-110 duration-300" src={e.cover_url} />}
                      </div>
                    </div> : null
                  )
                }
              </div>
            </div>
            <div className=" w-[200px] hidden sm:block relative">
              <img className="sticky object-cover top-0" src={sidebanner} />
            </div>
          </div>

        </div>

        <Footer />
      </div >
    </>

  );
}

export { NewsDetailsTemplate };

{/* <div className="pt-10 flex justify-center">
            <div className="flex max-w-screen-xl rounded-2xl bg-gray-800">
              <div className=" rounded-2xl">
                {currentNews && (
                  <div className=" px-4 bg-gray-800 rounded-2xl">
                    <div className="flex justify-between py-2">
                      <div className="flex items-center">
                        {rank &&
                          <div
                            className={`${isMobileView ? "w-6 h-8" : "w-10 h-12"} text-center`}
                            style={{
                              backgroundImage: `url(${bookmark})`,
                              backgroundSize: "contain",
                              backgroundRepeat: "no-repeat",
                            }}
                          >
                            <span className={`text-gray-200 ${isMobileView ? "text-xs" : "text-md"}`}>{`#${rank}`}</span>
                          </div>
                        }
                        <div className="ml-2 sm:block hidden -mt-1">
                          {currentNews && <TextAtom
                            text_style="text-gray-300 text-base font-semibold"
                            text={currentNews.title}
                          />}
                        </div>
                      </div>
                      <div className="cursor-pointer">
                        <RWebShare
                          data={{
                            text: "StarPot",
                            url: `https://starpot.in${location.pathname}`,
                            title: `${currentNews.title}`,
                          }}
                        >
                          <FaShareSquare
                            className={`${isMobileView ? 'w-5 h-5' : 'w-6 h-6'}  text-primary`}
                          />
                        </RWebShare>
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-end my-2">
                      {currentNews && <h4 className="text-gray-400 text-[13px]">Published: {new Date(Date(currentNews.createdAt)).getDate()+'-'+(new Date(Date(currentNews.createdAt)).getUTCMonth()+1)+'-'+(new Date(Date(currentNews.createdAt)).getUTCFullYear())}</h4>}
                    </div>
                    <div className="ml-2 sm:hidden block -mt-1">
                      {currentNews && <TextAtom
                        text_style="text-gray-600 text-base font-semibold"
                        text={currentNews.title}
                      />}
                    </div>

                    {currentNews &&
                      <div className="my-2 relative flex flex-col items-center">
                        <img src={currentNews.cover_url} alt="" className={`rounded-lg ${isMobileView ? "w-full" : "h-[38rem] w-full"}`} />
                        {currentNews.gallery && currentNews.gallery.length > 0 &&
                          <span className={`absolute bottom-0 right-0 m-2 p-1 rounded bg-gray-900 
                          text-gray-100 border border-primary cursor-pointer ${isMobileView ? "text-xs" : "text-md"}`}
                            onClick={handleGalleryModalOpen}>
                            More Images
                          </span>
                        }
                      </div>
                    }
                    <div className="flex justify-center pt-2 pb-1 items-center">
                      <div
                        className="bg-gray-700 flex items-center justify-center py-1 px-6 mx-1 rounded-md border-1 border-gray-600 cursor-pointer"
                        onClick={handleStarClick}
                      >
                        {userRatingState ?
                          <BsStarFill className={`text-primary ${isMobileView ? "w-3 h-3" : "w-5 h-5"}`} /> :
                          <AiOutlineStar className={`text-gray-400 ${isMobileView ? "w-3 h-3" : "w-5 h-5"}`} />}
                        <TextAtom
                          div_style="flex mx-1"
                          text_style={`text-gray-400 ${isMobileView ? "text-xs font-semibold" : "text-sm font-semibold"}`}
                          text={rating || currentNews.rating || 0}
                        />
                      </div>
                      <div
                        className="bg-gray-700  flex items-center justify-center py-1 px-6 mx-1 rounded-md border-1 border-gray-600 cursor-pointer"
                        onClick={handleReviewClick}
                      >
                        <FaUserCheck className={`${userReviewStatus ? "text-primary" : "text-gray-400"} ${isMobileView ? "w-3 h-3" : "w-5 h-5"}`} />
                        <TextAtom
                          div_style="flex mx-1"
                          text_style={`text-gray-400 ${isMobileView ? "text-xs font-semibold" : "text-sm font-semibold"}`}
                          text={currentNews.reviewCount || 0}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="border-gray-700 border-t-1 my-1" />
                <div className="mx-4 mt-2">
                  {/* {currentNews ? <div className="text-white">{currentNews.content}</div> : null} */}
//         {currentNews ? <div className="text-white" dangerouslySetInnerHTML={createMarkup1()}></div> : null}
//       </div>

//       <div className="border-gray-700 border-t-1 my-1" />

//       {currentNews && currentNews.links
//         ? <div className="flex flex-col items-start px-4 py-4">
//           <span className="text-gray-200 font-semibold">Links</span>
//           {currentNews.links.map(link => (
//             <a href={link} target="_blank" rel="noreferrer" className="underline hover:text-primary cursor-pointer text-gray-400 my-2">{link}</a>
//           ))}
//         </div>
//         : <></>}

//       {currentNews &&
//         <div className="flex flex-wrap justify-end items-center pt-5 mr-5 mb-5">
//           {currentNews.twitterUrl &&
//             <a href={currentNews.twitterUrl} target="_blank" rel="norefer noreferrer">
//               <BsTwitter className={`mx-2 cursor-pointer text-gray-400 hover:scale-110 duration-300 ${isMobileView ? "w-5 h-5" : "w-6 h-6"}`} />
//             </a>
//           }

//           {currentNews.facebookUrl &&
//             <a href={currentNews.facebookUrl} target="_blank" rel="norefer noreferrer">
//               <BsFacebook className={`mx-2 cursor-pointer text-gray-400 hover:scale-110 duration-300 ${isMobileView ? "w-5 h-5" : "w-6 h-6"}`} />
//             </a>
//           }

//           {currentNews.instagramUrl &&
//             <a href={currentNews.instagramUrl} target="_blank" rel="norefer noreferrer">
//               <BsInstagram className={`mx-2 cursor-pointer text-gray-400 hover:scale-110 duration-300 ${isMobileView ? "w-5 h-5" : "w-6 h-6"}`} />
//             </a>
//           }

//           {currentNews.youtubeUrl &&
//             <a href={currentNews.youtubeUrl} target="_blank" rel="norefer noreferrer">
//               <BsYoutube className={`mx-2 cursor-pointer text-gray-400 hover:scale-110 duration-300 ${isMobileView ? "w-5 h-5" : "w-6 h-6"}`} />
//             </a>
//           }

//           {currentNews.websiteUrl &&
//             <a href={currentNews.websiteUrl} target="_blank" rel="norefer noreferrer">
//               <BsGlobe className={`mx-2 cursor-pointer text-gray-400 hover:scale-110 duration-300 ${isMobileView ? "w-5 h-5" : "w-6 h-6"}`} />
//             </a>
//           }
//         </div>
//       }
//     </div>
//   </div>
// </div> */}