import React from 'react'

function MoreProductsPage() {
    return (
        <main className="min-h-screen bg-gray-900 flex-grow">

        </main>
    )
}

export default MoreProductsPage