import { Link, useLocation } from "react-router-dom";
import { RWebShare } from "react-web-share";

//icons
import { FaUserCheck } from "react-icons/fa";
import { FaShareSquare } from "react-icons/fa";
import { BsStarFill } from "react-icons/bs";

import { TextAtom } from "../view/atoms";

import { useIsMobileViewContext } from "../context/isMobileView";

import bookmark from "../assets/images/bookmark.png";
import { Modal } from "@mui/material";

function NewsCard({ list }) {
  const location = useLocation();

  const { isMobileView } = useIsMobileViewContext();

  return list.map((item, index) => (
    <div key={item.id}>
      {item.banner_url !== undefined && item.banner_url ?
        <div className="lg:col-span-3 py-2">
          <a href={item.link} target="_blank" rel="noreferrer">
            <div
              className={`w-full rounded-tr-lg  rounded-tl-lg ${isMobileView ? "h-32" : "h-56"
                }`}
              style={{
                backgroundImage: `url(${item.banner_url})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
            </div>
          </a>
        </div>
        :
        <div className="border border-gray-700 m-1 rounded-lg">
          <div>
            <Link to={`/news/${item.id + `-` + (index + 1)}`}>
              <div className="rounded-lg w-full">
                <div
                  className={`w-full rounded-tr-lg rounded-tl-lg ${isMobileView ? "h-56 w-full" : "h-56"
                    }`}
                  style={{
                    backgroundImage: `url(${item.cover_url})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "top",
                  }}
                >
                  <div className="ml-1 items-center w-10 justify-center ">
                    <div
                      className="w-full h-12  flex justify-center items-center"
                      style={{
                        backgroundImage: `url(${bookmark})`,
                        backgroundRepeat: "no-repeat",
                      }}
                    >
                      <TextAtom
                        div_style="-mt-4 mr-1"
                        text_style="text-white text-sm"
                        text={`#${index + 1}`}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <h1
                className={`text-gray-200 py-1 font-sans w-full line-clamp-1 mx-1 pt-1 ${isMobileView ? "text-sm" : "text-md"}`}
              >
                {item.title}
              </h1>
            </Link>
            <div className="flex justify-between py-2 px-2">
              <div className="flex items-center">
                <div className=" py-1 px-2 rounded-sm bg-gray-700 flex items-center mx-1">
                  <BsStarFill className={`text-gray-200 ${isMobileView ? "w-3 h-3" : "w-5 h-5"}`} />
                  <TextAtom
                    div_style="flex mx-1 "
                    text_style={`text-gray-200 ${isMobileView ? "text-xs" : "text-sm"}`}
                    text={item.rating || 0}
                  />
                </div>
                <div className=" py-1 px-2 rounded-sm bg-gray-700 flex items-center mx-1">
                  <FaUserCheck className={`text-gray-200 ${isMobileView ? "w-3 h-3" : "w-5 h-5"}`} />
                  <TextAtom
                    div_style="flex mx-1 "
                    text_style={`text-gray-200 ${isMobileView ? "text-xs" : "text-sm"}`}
                    text={item.reviewCount || 0}
                  />
                </div>
              </div>

              <div className=" flex items-center cursor-pointer">
                <RWebShare
                  data={{
                    text: "StarPot",
                    url: `https://starpot.in${location.pathname}/${item.id}`,
                    title: `${item.title}`
                  }}
                >
                  <FaShareSquare className={`text-primary ${isMobileView ? "w-5 h-5" : "w-6 h-6"}`} />
                </RWebShare>
              </div>
            </div>
          </div>
        </div>}
    </div>
  ));
}

export default NewsCard;
