import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import TimeAgo from 'react-timeago';
import ReactPlayer from 'react-player';
import { RWebShare } from "react-web-share";

//icons
import { FaShareSquare } from "react-icons/fa";
import { BsStarFill } from "react-icons/bs";
import { AiOutlineStar } from "react-icons/ai";

import { useAuthContext } from "../context/auth";
import { useIsMobileViewContext } from "../context/isMobileView";

import Modal from '../components/Modal';

import { TextAtom } from "../view/atoms";
import TextMarquee from "../view/atoms/TextMarquee";

import bookmark from '../assets/images/bookmark.png';
import dummyuserProfile from "../assets/images/dummyUser.png"

import { useAppSelector, useAppDispatch, addReviewRating, updateReviewRating, updateUserRatingCount } from '../redux';

import { hasUserAlreadyRated } from "../service/review.service";

function ReviewCard({ review, index }) {
  const { isMobileView } = useIsMobileViewContext();
  const { isUserLoggedIn } = useAuthContext();

  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const user = useAppSelector(state => state.user.profile);

  const [openModal, setOpenModal] = useState(false);
  const [rating, setRating] = useState(review.rating);
  const [userRatingStatus, setUserRatingStatus] = useState(false);
  const [userOldRating, setUserOldRating] = useState();

  useEffect(() => {
    const getUserRatingStatus = async () => {
      hasUserAlreadyRated(review.sourceType, review.id, user.id)
        .then(result => {
          setUserOldRating(result);
          setUserRatingStatus(result && result > 0);
        })
        .catch(e => {
          setUserRatingStatus(false)
        });
    }

    if (review.sourceType && user.id) {
      getUserRatingStatus();
    }
  }, [review.id, review.sourceType, user.id]);

  const handleRating = (newRating) => {
    if (newRating) {
      const data = {
        newUserRating: newRating,
        reviewId: review.id,
        userId: user.id,
        oldRating: review.rating,
        oldUserRating: userOldRating,
        newRating: review.rating + newRating,
        sourceType: review.sourceType
      };

      if (userRatingStatus) {
        const ratingDifference =
          Math.max(newRating, userOldRating) -
          Math.min(newRating, userOldRating);

        if (ratingDifference > 0) {
          if (newRating > userOldRating) {
            setRating(rating + ratingDifference);
            dispatch(updateUserRatingCount(ratingDifference));
          } else {
            setRating(rating - Math.abs(ratingDifference));
            dispatch(updateUserRatingCount(-Math.abs(ratingDifference)));
          }
        } else {
          setRating(newRating);
        }

        dispatch(updateReviewRating(data));
      }
      else {
        setRating(rating + newRating);
        dispatch(addReviewRating(data))
      }

      setOpenModal(false)
      setUserRatingStatus(true)
    }
  }

  const handleStarClick = () => {
    if (isUserLoggedIn) setOpenModal(true)
    else navigate("/login");
  };

  const closeModal = () => {
    setOpenModal(false)
  }

  return (
    <div className="bg-gray-800 rounded-md my-2 mx-1 border-1 border-gray-600 ">
      <div>
        <Modal defaultRating={userOldRating} handleRating={handleRating} closeModal={closeModal} openModal={openModal} />

        <div className="flex justify-between">
          <div className="m-2 flex items-center">
            {review.userCoverUrl
              ? <img src={review.userCoverUrl} alt="" className="rounded-full w-10 h-10 bg-gray-400" />
              : <img src={dummyuserProfile} alt="" className="rounded-full w-10 h-10 bg-gray-400" />
            }
            <div className="mx-2">
              <TextAtom text_style={`font-semibold text-white ${isMobileView ? "text-sm" : "text-md"}`} text={review.userName} />
              <TimeAgo
                className={`text-gray-400 ${isMobileView ? "text-xs" : "text-sm"}`}
                date={review.lastUpdatedAt ? review.lastUpdatedAt.toDate() : new Date()} />
            </div>
          </div>

          {index && index > 0
            ? <div className="mx-5  items-center  w-10 justify-center ">
              <div className="w-full h-full  flex justify-center items-center" style={{ backgroundImage: `url(${bookmark})`, backgroundRepeat: 'no-repeat' }}>
                <TextAtom div_style="-mt-5 mr-1" text_style="text-white " text={`#${index}`} />
              </div>
            </div>
            : <></>
          }
        </div>

        <div className="border-gray-700 border-t-1" />

        <div className="flex bg-gray-900 py-1">
          <div className={`w-8/12 px-2 ${isMobileView ? "h-40" : "h-66"} ${review.link || (review.review && review.review.length > 450) ? "overflow-scroll" : ""}`}>
            <p className={`text-gray-500 md:text-center text-justify ${isMobileView ? "text-xs" : "text-sm"}`}>{review.review} </p>
            {review.link && <ReactPlayer width={`${isMobileView ? "220px" : "400px"}`} height={`${isMobileView ? "160px" : "270px"}`} controls url={review.link} />}
          </div>

          <Link to={`/${review.sourceType}/${review[`${review.sourceType}Id`]}-0`} className="bg-gray-800 w-4/12 mx-1 rounded-lg">
            <div
              className={`bg-gray-700 w-full rounded-lg ${isMobileView ? "h-32" : "h-56"
                }`}
              style={{
                backgroundImage: `url(${review.sourceCoverUrl})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>

            <TextMarquee
              title={review.sourceName}
              marqueeAfterLength={20}
              text_style={`text-gray-200 w-full text-center truncate ${isMobileView ? "text-xs" : "text-md"}`} />

            <h1
              className={`text-gray-500 w-full text-center truncate -mt-1 ${isMobileView ? "text-xss" : "text-sm"}`}>
              {review.sourceType}
            </h1>
          </Link>
        </div>

        <div className="border-gray-700 border-t-1" />

        <div className="flex justify-between py-2 px-2">
          <div className="flex items-center">
            <div
              onClick={handleStarClick}
              className="py-1 px-2 rounded-md bg-gray-700 flex items-center mx-1 border border-gray-600 cursor-pointer">
              {userRatingStatus ?
                <BsStarFill className={`text-primary ${isMobileView ? "w-3 h-3" : "w-4 h-4"}`} /> :
                <AiOutlineStar className="text-gray-400  w-5 h-5" />}
              <TextAtom div_style="flex mx-1" text_style={`text-gray-400 ${isMobileView ? "text-xs" : "text-sm"}`} text={review.rating || 0} />
            </div>
          </div>

          <div className="flex items-center">
            <RWebShare
              data={{
                text: "StarPot",
                url: `https://starpot.in${location.pathname}`,
                title: `${review.sourceName} `
              }}
            >
              <FaShareSquare className={`text-primary -mt-1 cursor-pointer ${isMobileView ? "w-5 h-5" : "w-6 h-6"}`} />
            </RWebShare>
          </div>
        </div>
      </div>
    </div>)
}

export default ReviewCard;
