import { useEffect, useRef, useState } from 'react';

import BannerSlider from "../../components/banner_slider";
import { CelebrityCard } from "../../components/CelebrityCard";

import { useAppSelector, useAppDispatch, loadCelebrity } from '../../redux'

import { useIsMobileViewContext } from '../../context/isMobileView';
import Slider from 'react-slick';
import { FaAngleLeft, FaAngleRight, FaUserCheck } from 'react-icons/fa';
import { BsStarFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { GetTheData } from '../../components/Api/FetchData';

function CelebrityTemplate() {
  const ref = useRef(null);
  const dispatch = useAppDispatch();
  const [adsFilter, setAdsFilter] = useState(null)

  const { isMobileView } = useIsMobileViewContext();

  const celebrity = useAppSelector(state => state.celebrity.celebrity);
  let temp = celebrity
  const isCelebrityEndReached = useAppSelector(state => state.celebrity.isEndReached);
  const advertisements = useAppSelector((state) => state.advertisement.advertisements);

  useEffect(() => {
    if (!isCelebrityEndReached && celebrity.length <= 0)
      dispatch(loadCelebrity())
  }, [dispatch])

  const CustomNextArrow = ({ onClick }) => {
    return (
      <button
        className="custom-prev-button"
        style={{
          position: 'absolute',
          top: '40%',
          right: '150px', /* Adjust as needed */
          transform: 'translateY(-50%)',
          backgroundColor: 'white', /* Adjust styles as needed */
          border: 'none', /* Adjust styles as needed */
          cursor: 'pointer',
          borderRadius: 100,
          zIndex:100
        }}
        onClick={onClick}
      >
        <FaAngleRight className="w-10 h-10 font-normal" />
      </button>
    );
  };
  const CustomPrevArrow = ({ onClick }) => {
    return (
      <button
        className="custom-prev-button"
        style={{
          position: 'absolute',
          top: '40%',
          zIndex: '99',
          left: '150px', 
          transform: 'translateY(-50%)',
          backgroundColor: 'white', 
          border: 'none', 
          cursor: 'pointer',
          borderRadius: 100
        }}
        onClick={()=>{
          onClick()
        }}
      >
        <FaAngleLeft className="w-10 h-10 font-normal" />
      </button>
    );
  };
  useEffect(() => {
    setAdsFilter(advertisements.filter(item => item.type === "celebrity" && item.orientation === "horizontal"))
  }, [advertisements])
  const [ImagePostion, setImagePostion] = useState(0)
  const initSettings = {
    infinite: true,
    lazyload: true,
    arrows: isMobileView ? false : true,
    speed: 1500,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: isMobileView ? 0 : "0px",
    autoplay: true,
    afterChange: current => setImagePostion(current),

  };

  const sliderRefClick = useRef(null)
  useEffect(() => {
    FetchingOverallData()
  }, [])
  const [CelebrityCategory, setCelebrityCategory] = useState([])
  const [celebritiesData, setCelebritiesData] = useState([])
  async function FetchingOverallData() {
    let temp1 = []
    let temp2 = []
    try {
      const response = await GetTheData('celebrityCategories')
      setCelebrityCategory(response.data)
      temp1 = [...response.data]
    } catch (error) {
      console.log(error.message);
    }
    try {
      const response = await GetTheData('celebrities')
      setCelebritiesData(response.data)
      temp2 = [...response.data]
    } catch (error) {
      console.log(error.message);
    }
    SegrigateByMovieCategory(temp1,temp2)
  }
  const [celebrityContents, setCelebrityContents] = useState([])
  function SegrigateByMovieCategory(cat, celebrity) {
    let temp2 = []
    for (let i = 0; i < cat.length; i++) {
      let temp = []
      for (let j = 0; j < celebrity.length; j++) {
        if (celebrity[j].categoryId != null) {
          if (JSON.parse(celebrity[j].categoryId).findIndex(e => e == cat[i].id) != -1) {
            temp.push(celebrity[j])
          }
        }
      }
      temp2.push(temp)
    }
    setCelebrityContents(temp2)
  }


  const navigate = useNavigate();
  return <div className="bg-gray-900">
    <div className={`w-full mx-auto ${adsFilter && adsFilter.length > 1 ? isMobileView ? "px-6" : "px-10" : ""}`}>
      {/* {adsFilter && <BannerSlider ads={adsFilter} />} */}
    </div>
    <div className='w-full sm:pt-2 mb-28 sm:mb-0'>
      {<img src={celebrity[ImagePostion]?.gallery[0].url} className="w-full h-[300px] sm:h-[630px] brightness-150 sm:brightness-95 absolute blur-2xl"></img>}
      <Slider prevArrow={<CustomPrevArrow />} nextArrow={<CustomNextArrow />} ref={sliderRefClick} {...initSettings}>
        {celebrity.map((e, index) =>
          <div  key={index} className='overflow-hidden rounded-md '>
            <div className='md:hidden block'>
              {e.gallery[0].url != '' ?
                <img className="object-cover hidden brightness-95 cursor-pointer w-full h-[350px] sm:h-[600px] object-top rounded-md" src={e.gallery[0].url} /> : null}
            </div>
            {e.cover_url != '' ?
              <div className='h-[600px] hidden w-full bg-[#f2f4f5] overflow-hidden md:flex flex-row items-center'>
                <div className='w-[60%] h-full bg-[#f2f4f5]'></div>
                <div className=' w-[40%] relative h-full '>
                  <div className=' absolute overflow-hidden w-full -top-[100px] h-[200vh] rotate-18 '>
                    <img className=' absolute  w-full h-full ' src={e.cover_url} />
                    <img className=' -rotate-18' src={e.cover_url} />
                  </div>
                </div>
              </div> : null}

            <div className='sm:w-[200vh] sm:ml-10 absolute top-[30px] sm:top-1/2 flex flex-row justify-between'>
              <div class="sm:w-[30%] w-full px-5 ">
                <h1 className='lg:text-5xl text-3xl md:text-[#0f172a] line-clamp-1 text-white font-bold'>{e.name}</h1>
                <div className='md:block hidden'>
                  <h3 class="text-[#0f172a] line-clamp-4 font-bold "> {e.info[0]?.description}</h3>
                </div>
              </div>
            </div>
          </div>
        )}
      </Slider>
      <div className='flex absolute sm:top-[350px] top-[350px] px-4 sm:px-0 right-0 flex-row sm:justify-center sm:w-[60%] gap-x-5 sm:overflow-hidden overflow-x-auto'>
        {
          celebrity[ImagePostion]?.gallery.slice(0, 5).map((e1, index1) =>
            <img key={index1} className={`sm:w-[200px] w-[120px] border-2 border-primary rounded-lg  object-cover object-top h-[200px] sm:h-[300px]`} src={e1.url}
              onClick={() => {
                sliderRefClick.current.slickGoTo(index1)
              }
              } />
          )
        }
      </div>
    </div>
    <div className="xl:max-w-[1700px] w-full mx-auto lg:mb-20 px-5 sm:px-0">
      <h1 className='text-white sm:text-3xl text-lg font-serif sm:my-5 my-3 '>Trending Stars</h1>
      <div className=" hidden sm:grid sm:grid-cols-6 gap-4">
        <div onClick={()=>navigate('/AboutCelebrity',{state:{data:celebritiesData[0]}})} className="col-span-2 cursor-pointer relative">
          {celebritiesData.length>0&&celebritiesData[0].profilePictureUrls!=null?<img
            className="h-[400px] w-full object-cover rounded-lg"
            src={'https://storage.googleapis.com/cinimini-console/celebrities/' + celebritiesData[0].id + '/' + (JSON.parse(celebritiesData[0].profilePictureUrls)[0])}
            alt=""
          />:null}
          <div className='bg-transparent/75 h-20 absolute bottom-0 w-full rounded-b-lg rounded-t-2xl flex flex-row items-center justify-between px-5'>
            <div className='w-[70%] h-full  flex flex-col justify-center'>
              <h1 className='text-white font-bold text-3xl break-all line-clamp-2 w-full'>{celebritiesData[0]?.name}</h1>
            </div>
            <div className="flex cursor-default justify-between gap-x-5 items-center mt-1">
              <div className="flex-1 py-1 px-0.5 gap-x-2 flex items-center justify-center mr-0.5 rounded-bl-lg">
                <BsStarFill className={`text-primary ${isMobileView ? "w-3 h-3" : "w-5 h-5"}`} />
                <h1 className='text-white text-base'>{0}</h1>
              </div>
              <div className="flex-1 py-1 px-0.5 gap-x-2 flex items-center justify-center ml-0.5 rounded-br-lg">
                <FaUserCheck className={`text-primary ${isMobileView ? "w-3 h-3" : "w-5 h-5"}`} />
                <h1 className='text-white text-base'>{0}</h1>
              </div>
            </div>
          </div>
        </div>
        {celebritiesData.length>0&&celebritiesData.slice(1, 5).map((e, index) => (
          <div onClick={()=>navigate('/AboutCelebrity',{state:{data:e}})} key={index} className="sm:col-span-1 cursor-pointer relative col-span-2">
            <img
              className="h-[400px] w-full object-cover rounded-lg"
              src={'https://storage.googleapis.com/cinimini-console/celebrities/' +e.id + '/' + (JSON.parse(e.profilePictureUrls)[0])}
              alt=''
            />
            <div className='bg-transparent/75 h-20 absolute bottom-0 w-full rounded-b-lg rounded-t-2xl flex flex-row items-center justify-between px-5'>
              <div className='w-[60%] h-full  flex flex-col justify-center'>
                <h1 className='text-white font-bold text-lg break-all line-clamp-1 w-full'>{e.name}</h1>
              </div>
              <div className="flex cursor-default justify-between gap-x-3 items-center mt-1">
                <div className="flex-1 py-1 px-0.5 gap-x-2 flex items-center justify-center mr-0.5 rounded-bl-lg">
                  <BsStarFill className={`text-primary ${isMobileView ? "w-3 h-3" : "w-5 h-5"}`} />
                  <h1 className='text-white text-base'>{0}</h1>
                </div>
                <div className="flex-1 py-1 px-0.5 gap-x-2 flex items-center justify-center ml-0.5 rounded-br-lg">
                  <FaUserCheck className={`text-primary ${isMobileView ? "w-3 h-3" : "w-5 h-5"}`} />
                  <h1 className='text-white text-base'>{ 0}</h1>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* mobile */}
      <div className='flex flex-row  sm:hidden overflow-x-auto  gap-5'>
        {
          celebritiesData.slice(0, 5).map((e, index) =>
            <div key={index} onClick={()=>navigate('/AboutCelebrity',{state:{data:e}})} className='flex-none relative'>
              <img
                className="h-[300px] w-[200px] object-cover rounded-lg"
                src={'https://storage.googleapis.com/cinimini-console/celebrities/' +e.id + '/' + (JSON.parse(e.profilePictureUrls)[0])}
                alt=''
              />
              <div className='bg-transparent/75 h-16 absolute bottom-0 w-full rounded-b-lg rounded-t-2xl flex flex-row items-center justify-between px-2'>
                <div className='w-[70%] h-full  flex flex-col justify-center'>
                  <h1 className='text-white font-bold text-base break-all line-clamp-1 w-full'>{e.name}</h1>
                </div>
                <div className="flex flex-col justify-between gap-x-3 items-center mt-1">
                  <div className="flex-1 py-1 px-0.5 gap-x-2 flex items-center justify-center mr-0.5 rounded-bl-lg">
                    <BsStarFill className={`text-primary ${isMobileView ? "w-4 h-4" : "w-5 h-5"}`} />
                    <h1 className='text-white text-xs'>{0}</h1>
                  </div>
                  {/* <div className="flex-1 py-1 px-0.5 gap-x-2 flex items-center justify-center ml-0.5 rounded-br-lg">
                    <FaUserCheck className={`text-primary ${isMobileView ? "w-4 h-4" : "w-5 h-5"}`} />
                    <h1 className='text-white text-xs'>{e.reviewCount || 0}</h1>
                  </div> */}
                </div>
              </div>
            </div>
          )
        }
      </div>
    </div>
    {celebrityContents.length>0 && <CelebrityCard list={celebrityContents} category={CelebrityCategory}/>}
  </div>;
}

export { CelebrityTemplate };
{/* <div className='flex flex-row items-center gap-x-3 mb-2'>
                <span class="text-white">2016</span>
                <span className='text-white'>|</span>
                <span class="text-white">Action, Animation, Family</span>
                <span className='text-white'>|</span>
                <span class="text-white">2hr 13 mins</span>
              </div> */}