import { useEffect } from "react";
import { Link } from "react-router-dom";

import { useAppSelector, useAppDispatch, loadUserReviews } from "../redux";

import { useAuthContext } from "../context/auth";

import ReviewCard from "../components/ReviewCard";

function MyReviewsPage() {
    const dispatch = useAppDispatch();

    const { isUserLoggedIn } = useAuthContext();

    const user = useAppSelector(state => state.user.profile);
    const userReviews = useAppSelector(state => state.user.reviews);

    useEffect(() => {
        if (userReviews.length <= 0 && user.id)
            dispatch(loadUserReviews(user.id));
    }, [dispatch, user.id]);

    return (
        <main className="min-h-screen bg-gray-900">
            {isUserLoggedIn
                ? <div className="py-4">
                    {userReviews && userReviews.length > 0
                        ? userReviews.map(review => (<ReviewCard review={review} />))
                        : <div className="flex items-center">
                            <span className="text-gray-200 text-center w-full">No records found, start giving some reviews to view them here</span>
                        </div>
                    }
                </div>
                : <div className="flex flex-col items-center py-10">
                    <span className="text-gray-200">Login to view this page</span>
                    <Link to="/login" className="bg-primary font-semibold text-white text-sm p-1 rounded-md my-2">Login now</Link>
                </div>}
        </main>
    )
}

export default MyReviewsPage;
