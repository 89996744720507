import React, { useEffect, useState } from 'react'
import BannerSlider from '../../components/banner_slider'
import BackArrowNavigation from '../../components/BackArrowNavigation'
import Footer from '../../components/Footer'
import { useLocation, useNavigate } from 'react-router-dom'
import { useIsMobileViewContext } from '../../context/isMobileView'
import bookmark from "../../assets/images/small_bookmark.png";
import { TextAtom } from '../atoms'
import { useAppSelector } from '../../redux'
import { RWebShare } from 'react-web-share'
import { FaFileVideo, FaRegImages, FaShareSquare, FaUserCheck } from 'react-icons/fa'
import { GetTheData } from '../../components/Api/FetchData'
import ReactPlayer from 'react-player'
import { BsStarFill } from 'react-icons/bs'
import { AiOutlineStar } from 'react-icons/ai'
import { useAuthContext } from '../../context/auth'
import ModalGallery from "../../components/ModalGallery";
import MySlider from '../../components/MySlider'
import CastCard from '../../components/CastCard'
import axios from 'axios'
import Actors from '../../components/Actors'
import MovieNews from '../../components/MovieNews'
function MovieDetailPage() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'auto' });
    }, []);
    const location = useLocation()
    const navigate = useNavigate();
    const datas = location.state.array
    const { isMobileView } = useIsMobileViewContext();
    const advertisements = useAppSelector((state) => state.advertisement.advertisements);
    const [adsFilter, setAdsFilter] = useState(null);
    useEffect(() => {
        setAdsFilter(advertisements.filter(item => item.type === "movies" && item.orientation === "horizontal"));
        FetchingGenreData()

    }, [advertisements]);
    const [movieCelebrities, setMovieCelebrities] = useState([])
    useEffect(() => {
        FetchingCelebrities()
        celebrityMovies()
        FetchMovieNews()
    }, [])

    function celebrityMovies() {
        try {
            axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/celebrityMovies/${datas.id}`)
                .then((response) => {
                    if (response.status == 200) {
                        setMovieCelebrities(response.data)
                    }
                }).catch((error) => {
                    console.log(error.message);
                })
        } catch (error) {

        }
    }
    const [MovieNews1, setMovieNews1] = useState([])
    function FetchMovieNews() {
        try {
            axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/topicNews/movies/${datas.id}`)
                .then((response) => {
                    if (response.status == 200) {
                        setMovieNews1(response.data)
                    }
                }).catch((error) => {
                    console.log(error.message);
                })
        } catch (error) {

        }
    }
    const { isUserLoggedIn } = useAuthContext();
    const [openModal, setOpenModal] = useState(false);
    const [openGalleryModal, setOpenGalleryModal] = useState(false);
    const handleStarClick = () => {
        if (isUserLoggedIn) setOpenModal(true)
        else navigate("/login");
    };
    const handleReviewClick = () => {
        if (isUserLoggedIn) navigate(`/review/movie/${datas.id}`);
        else navigate("/login");
    };

    const [GenreCollection, setGenreCollection] = useState([])
    async function FetchingGenreData() {
        try {
            const response = await GetTheData('movieGenres')
            setGenreCollection(response.data)
        } catch (error) {
            console.log(error.message);
        }
    }
    function FetchingGenreDataByID(params) {
        try {
            const data = GenreCollection.find(e => e.id === Number(params))
            if (data !== undefined)
                return data?.name;

        } catch (error) {
            console.log('error genre');
        }
    }
    const [userRatingStatus, setUserRatingStatus] = useState(false);
    const [userReviewStatus, setUserReviewStatus] = useState(false);
    const handleGalleryModalOpen = () => {
        setOpenGalleryModal(true);
    }
    const handleGalleryModalClose = () => {
        setOpenGalleryModal(false);
    }
    const [overview, setoverview] = useState(true)
    const [castdetail, setcastdetail] = useState(false)
    const [reviews, setreviews] = useState(false)
    const [behindscene, setbehindscene] = useState(false)
    const [news, setnews] = useState(false)

    function SelectedFieldFunction(params) {
        switch (params) {
            case 'Overview':
                setoverview(true)
                setcastdetail(false)
                setreviews(false)
                setbehindscene(false)
                setnews(false)
                break;
            case 'Cast':
                setoverview(false)
                setcastdetail(true)
                setreviews(false)
                setbehindscene(false)
                setnews(false)
                break;
            case 'Reviews':
                setoverview(false)
                setcastdetail(false)
                setreviews(true)
                setbehindscene(false)
                setnews(false)
                break;
            case 'Behindscene':
                setoverview(false)
                setcastdetail(false)
                setreviews(false)
                setbehindscene(true)
                setnews(false)
                break;
            case 'news':
                setoverview(false)
                setcastdetail(false)
                setreviews(false)
                setbehindscene(false)
                setnews(true)
                break;
            default:
                break;
        }
    }
    async function FetchingCelebrities() {
        try {
            const response = await GetTheData('celebrities')
            if (response.status == 200) {
                CelebritiesFetchFunction(response.data, "dir")
                CelebritiesFetchFunction(response.data, "scr")
                CelebritiesFetchFunction(response.data, "pro")
                CelebritiesFetchFunction(response.data, "wri")
                CelebritiesFetchFunction(response.data, "cin")
                CelebritiesFetchFunction(response.data, "edi")
                CelebritiesFetchFunction(response.data, "sto")
                CelebritiesFetchFunction(response.data, "mus")
            }
        } catch (error) {
            console.log(error.message);
        }
    }
    const [director, setDirector] = useState([])
    const [screenPlay, setScreenPlay] = useState([])
    const [producer, setProducer] = useState([])
    const [writter, setWritter] = useState([])
    const [cinimograpy, setCinimograpy] = useState([])
    const [editor, setEditor] = useState([])
    const [storyBy, setStoryBy] = useState([])
    const [Music, setMusic] = useState([])
    function CelebritiesFetchFunction(_celebrities, _role) {
        const data = _celebrities.find(e => e.name === (JSON.parse(datas.castAndCrew)[_role]))
        switch (_role) {
            case 'dir':
                if (data !== undefined) {
                    setDirector(data)
                } else {
                    setDirector([])
                }
                break;
            case 'scr':
                if (data !== undefined) {
                    setScreenPlay(data)
                } else {
                    setScreenPlay([])
                }
                break;
            case 'pro':
                if (data !== undefined) {
                    setProducer(data)
                } else {
                    setProducer([])
                }
                break;
            case 'wri':
                if (data !== undefined) {
                    setWritter(data)
                } else {
                    setWritter([])
                }
                break;
            case 'cin':
                if (data !== undefined) {
                    setCinimograpy(data)
                } else {
                    setCinimograpy([])
                }
                break;
            case 'edi':
                if (data !== undefined) {
                    setEditor(data)
                } else {
                    setEditor([])
                }
                break;
            case 'sto':
                if (data !== undefined) {
                    setStoryBy(data)
                } else {
                    setStoryBy([])
                }
                break;
            case 'mus':
                if (data !== undefined) {
                    setMusic(data)
                } else {
                    setMusic([])
                }
                break;
            default:
                break;
        }

    }
    const UserEmpty = {
        "name": "Vijay",
        "categoryId": "[]",
        "description": "Chennai",
        "profilePictureUrl": "",
        "galleryPictureUrls": "",
        "movieIds": "[]",
        "awardsIds": "[]",
        "tagIds": "[]",
        "personalInformation": "{\"born\":\"\",\"alm\":\"\",\"occ\":\"\",\"yea\":\"\",\"wor\":\"\",\"spo\":\"\",\"chi\":\"\",\"fam\":\"\",\"hon\":\"\",\"fac\":\"\",\"ins\":\"\",\"you\":\"\"}",
        "newFeedIds": null,
        "webseriesIds": null
    }

    return (
        <div className="bg-gray-900 flex flex-col w-full ">
            <BackArrowNavigation />

            {/* <div className="pt-10">
                {adsFilter && <BannerSlider ads={adsFilter} />}
            </div> */}
            {/* { datas.bannersUrl!=null&&<ModalGallery
                gallery={JSON.parse(datas.bannersUrl)}
                openModal={openGalleryModal}
                handleCloseClick={handleGalleryModalClose} /> } */}
            {
                openGalleryModal && datas.bannersUrl !== null &&
                <div className='w-full flex flex-col items-center z-[999] px-3'>
                    <div className=' w-full lg:container lg:h-[700px] overflow-hidden bg-[#0f172a] px-3 pb-10 absolute mx-auto top-[30vh] lg:top-[12vh] '>
                        <div className="flex items-start justify-between p-5 border-b border-solid border-gray-700 rounded-t ">
                            <h3 className={`font-semibold text-gray-200 ${isMobileView ? "text-sm" : "text-md"}`}>Gallery</h3>
                            <button
                                className="border-0 float-right bg-gray-800 rounded-full cursor-pointer"
                                onClick={() => setOpenGalleryModal(false)}
                            >
                                <span className="text-gray-200 opacity-7 h-6 w-6 block py-0">
                                    x
                                </span>
                            </button>
                        </div>
                        {
                            datas.galleryPicturesUrl!=null?JSON.parse(datas.galleryPicturesUrl).map((e, index) =>
                                <img key={index} className='w-[90%] h-[90%] mx-auto mt-2 object-contain' src={'https://storage.googleapis.com/cinimini-console/movies/' + datas.id + '/' + e} />
                            ):null
                        }

                    </div>
                </div>
            }
            <div className="flex flex-col w-full justify-center mt-10">
                <div className="flex w-full">
                    <div className="w-full pt-2 pb-4 flex flex-col px-2 items-center">
                        <div className="bg-gray-800 pb-5 container flex flex-col items-center">
                            {/* <img className={` h-full w-full object-cover ${isMobileView ? 'brightness-[80%]' : 'brightness-[25%]'}  blur-2xl mix-blend-screen`} src={'https://storage.googleapis.com/cinimini-console/movies/' + datas.id + '/' + datas.thumbnailUrl} /> */}
                            <div className=" w-full ">
                                <div className="flex flex-col justify-between p-2 w-full">
                                    <div className="flex items-center  w-full justify-between flex-row">
                                        <TextAtom
                                            text_style={`text-gray-300 ${isMobileView ? 'text-xl' : 'text-5xl'} font-semibold`}
                                            text={datas.name}
                                        />
                                        <div className="cursor-pointer z-[9]  flex items-center">
                                            <RWebShare
                                                data={{
                                                    text: "Starpot",
                                                    url: `https://starpot.in${location.pathname}`,
                                                    title: `${datas.name}`,
                                                }}
                                            >
                                                <FaShareSquare
                                                    className="lg:w-6 h-5 text-primary"
                                                />
                                            </RWebShare>
                                        </div>
                                    </div>
                                    {/* <div className="flex items-center gap-x-3 px-2">
                                        <div className='flex flex-row items-center gap-x-3'>
                                            <TextAtom
                                                div_style=""
                                                text_style="text-gray-400 text-sm"
                                                text={new Date(datas.releaseDate).getUTCFullYear()}
                                            />
                                            <TextAtom
                                                div_style=""
                                                text_style="text-gray-400 text-sm"
                                                text={datas.certification}
                                            />
                                        </div>
                                        <div className="flex flex-row items-center flex-wrap">
                                            {JSON.parse(datas.genreIds) && JSON.parse(datas.genreIds).map((item) => (
                                                <TextAtom
                                                    div_style="mx-1 rounded px-2 py-1 border border-gray-700 flex items-center"
                                                    text_style={`text-gray-300 ${isMobileView ? "text-xss" : "text-xs"}`}
                                                    text={FetchingGenreDataByID(item)}
                                                />
                                            ))}
                                        </div>
                                    </div> */}
                                    <div className="my-2 relative px-2">
                                        <div className={`flex lg:flex-row gap-5 flex-col items-center justify-between rounded-lg `}>
                                            <div className='lg:w-[70%] w-full lg:h-[500px] h-[200px]'>
                                                {datas.youtubeLink && <ReactPlayer width={"100%"} height={'100%'} controls url={datas.youtubeLink} />}
                                            </div>
                                            {datas.thumbnailPictureUrl != null ? <img src={'https://storage.googleapis.com/cinimini-console/movies/' + datas.id + '/' + JSON.parse(datas.thumbnailPictureUrl)[0]} alt=""
                                                className={` object-cover object-top w-full lg:mt-0 mt-5 lg:w-[30%] lg:h-[500px] h-[400px]`} /> : null}
                                        </div>
                                    </div>

                                    <div className="flex lg:flex-row flex-col gap-y-5 lg:gap-y-0 z-50 justify-between items-center mt-2 py-4 px-1">
                                        <div className='flex flex-row'>
                                            <div
                                                className="bg-gray-700 flex items-center z-[999] justify-center py-1 px-12 mx-1 rounded-md border-1 border-gray-600 cursor-pointer"
                                                onClick={handleStarClick}
                                            >
                                                {userRatingStatus ?
                                                    <BsStarFill className={`text-primary ${isMobileView ? "w-3 h-3" : "w-5 h-5"}`} /> :
                                                    <AiOutlineStar className={`text-gray-400 ${isMobileView ? "w-3 h-3" : "w-5 h-5"}`} />}
                                                <TextAtom
                                                    div_style="flex mx-1"
                                                    text_style={`text-gray-400 ${isMobileView ? "text-xs font-semibold" : "text-sm font-semibold"}`}
                                                    text={0}
                                                />
                                            </div>

                                            <div
                                                className="bg-gray-700 flex items-center z-[999] justify-center py-1 px-12 mx-1 rounded-md border-1 border-gray-600 cursor-pointer"
                                                onClick={handleReviewClick}
                                            >
                                                <FaUserCheck className={`${userReviewStatus ? "text-primary" : "text-gray-400"} ${isMobileView ? "w-3 h-3" : "w-5 h-5"}`} />
                                                <TextAtom
                                                    div_style="flex mx-1"
                                                    text_style={`text-gray-400 ${isMobileView ? "text-xs font-semibold" : "text-sm font-semibold"}`}
                                                    text={0}
                                                />
                                            </div>
                                        </div>
                                        <div className='flex flex-row'>

                                            <div className=" flex bg-gray-700 items-center z-[999] justify-center py-1 px-5 lg:px-12 mx-1 rounded-md border-1 border-gray-600 hover:cursor-pointer"
                                                onClick={() => handleGalleryModalOpen()}
                                            >
                                                <FaRegImages className={` text-gray-400 ${isMobileView ? "w-3 h-3" : "w-5 h-5"}`} />
                                                <TextAtom
                                                    div_style="flex mx-1"
                                                    text_style={`text-gray-400 ${isMobileView ? "text-xs font-semibold" : "text-sm font-semibold"}`}
                                                    text={'Images'}
                                                />
                                            </div>

                                            <div className=" flex bg-gray-700 z-[999] items-center justify-center py-1 px-5 lg:px-12 mx-1 rounded-md border-1 border-gray-600 hover:cursor-pointer"
                                            >
                                                <FaFileVideo className={` text-gray-400 ${isMobileView ? "w-3 h-3" : "w-5 h-5"}`} />
                                                <TextAtom
                                                    div_style="flex mx-1"
                                                    text_style={`text-gray-400 ${isMobileView ? "text-xs font-semibold" : "text-sm font-semibold"}`}
                                                    text={'Videos'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* {datas.description && <TextAtom
                                            div_style="flex p-2"
                                            text_style={`text-white text-center font-serif tracking-wide text-justify ${isMobileView ? "text-xs" : "text-base"}`}
                                            text={datas.description}
                                        />} */}
                                    <div className="border-gray-700  border-t-1 my-3" />
                                    <div className='flex z-10 flex-row overflow-x-scroll md:overflow-x-auto min-[325px]:w-72 min-[375px]:w-[350px] sm:w-full gap-x-3'>
                                        <div onClick={() => SelectedFieldFunction('Overview')} className={`flex ${overview ? 'bg-primary' : 'text-gray-700'} ${overview ? 'border-primary' : 'border-gray-600'}  bg-gray-700 items-center duration-500 transition-all z-[999] justify-center py-1  px-12  mx-1 rounded-md border-1   hover:cursor-pointer`}>
                                            <TextAtom
                                                div_style="flex mx-1"
                                                text_style={`${overview ? 'text-black' : 'text-gray-400'} ${isMobileView ? "text-xs font-semibold" : "text-sm font-semibold"}`}
                                                text={'Overview'}
                                            />
                                        </div>
                                        <div onClick={() => SelectedFieldFunction('Cast')} className={`${castdetail ? 'bg-primary' : 'text-gray-700'} ${castdetail ? 'border-primary' : 'border-gray-600'} flex  bg-gray-700 duration-500 transition-all items-center z-[999] py-1 justify-center  px-12  mx-1 rounded-md border-1 border-gray-600 hover:cursor-pointer`}>
                                            <TextAtom
                                                div_style="flex mx-1"
                                                text_style={`${castdetail ? 'text-black' : 'text-gray-400'} ${isMobileView ? "text-xs font-semibold" : "text-sm font-semibold"}`}
                                                text={'Cast'}
                                            />
                                        </div>
                                        <div onClick={() => SelectedFieldFunction('Reviews')} className={`${reviews ? 'bg-primary' : 'text-gray-700'} ${reviews ? 'border-primary' : 'border-gray-600'} flex  bg-gray-700 duration-500 transition-all items-center py-1 z-[999] justify-center px-12 mx-1 rounded-md border-1 border-gray-600 hover:cursor-pointer`}>
                                            <TextAtom
                                                div_style="flex mx-1"
                                                text_style={`${reviews ? 'text-black' : 'text-gray-400'} ${isMobileView ? "text-xs font-semibold" : "text-sm font-semibold"}`}
                                                text={'Reviews'}
                                            />
                                        </div>

                                        <div onClick={() => SelectedFieldFunction('news')} className={`${news ? 'bg-primary' : 'text-gray-700'} ${news ? 'border-primary' : 'border-gray-600'} flex  duration-500 transition-all bg-gray-700 items-center z-[999] py-1 justify-center  px-12 mx-1 rounded-md border-1 border-gray-600 hover:cursor-pointer`}>
                                            <TextAtom
                                                div_style="flex mx-1"
                                                text_style={`${news ? 'text-black' : 'text-gray-400'} ${isMobileView ? "text-xs font-semibold" : "text-sm font-semibold"}`}
                                                text={'News'}
                                            />
                                        </div>

                                    </div>
                                    {
                                        overview &&
                                        <div className=' w-full flex flex-col gap-3 mt-5'>
                                            <h1 className='text-white hover:cursor-default  text-xl font-bold tracking-wider '>{datas.name}</h1>
                                            <div className='flex flex-row gap-x-3 '>
                                                <p className='text-gray-200 text-[12px] xl:text-[15px] font-normal hover:cursor-default '>{new Date(datas.releaseDate).getUTCFullYear()}</p>
                                                <p className='border-r-2 xl:block hidden border-gray-700 hover:cursor-default '></p>
                                                <p className='text-gray-200 text-[12px] xl:text-[15px] font-normal hover:cursor-default '>{datas.duration == 0 ? 'NA' : Math.floor(datas.duration / 60) + ' hr ' + datas.duration % 60 + ' mins'}</p>
                                                <p className='border-r-2 xl:block hidden border-gray-700 hover:cursor-default '></p>
                                                <p className='text-gray-200 text-[12px] xl:text-[15px] font-normal hover:cursor-default '>{datas.certification}</p>
                                                <div className="flex flex-row items-center flex-wrap">
                                                    {JSON.parse(datas.genreIds) && JSON.parse(datas.genreIds).map((item) => (
                                                        <TextAtom
                                                            div_style="mx-1 rounded px-2 py-1 border border-gray-700 flex items-center"
                                                            text_style={`text-gray-300 ${isMobileView ? "text-xss" : "text-xs"}`}
                                                            text={FetchingGenreDataByID(item)}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="border-gray-700  border-t-1 my-3" />
                                            <p className={`md:text-base text-sm hover:cursor-default text-gray-300 `}>{datas.description}</p>
                                            <div className="border-gray-700  border-t-1 my-3" />
                                            <div className='mb-2 flex flex-col lg:flex-row justify-start gap-x-10'>
                                                {JSON.parse(datas.castAndCrew).dir !== '-' ? <div className='space-y-1'>
                                                    <label onClick={() => director.length !== 0 ? navigate("/Actorinfo", { state: { data: director } }) : null} className='text-white  hover:text-white tracking-wide xl:text-base text-sm'>{JSON.parse(datas.castAndCrew).dir}</label>
                                                    <div style={{ fontSize: 13 }} className='text-gray-200 hover:cursor-default tracking-wider'>Director</div>
                                                </div> : null}
                                                {JSON.parse(datas.castAndCrew).scr !== '-' ? <div className='space-y-1'>
                                                    <label onClick={() => screenPlay.length !== 0 ? navigate("/Actorinfo", { state: { data: screenPlay } }) : null} className='text-white hover:text-white  tracking-wide  xl:text-base text-sm'> {JSON.parse(datas.castAndCrew).scr}</label>
                                                    <div style={{ fontSize: 13 }} className='text-gray-200 hover:cursor-default  tracking-wider'>Screenplay</div>
                                                </div> : null}
                                                {JSON.parse(datas.castAndCrew).pro !== '-' ? <div className='space-y-1'>
                                                    <label onClick={() => producer.length !== 0 ? navigate("/Actorinfo", { state: { data: producer } }) : null} className='text-white hover:text-white  tracking-wide  xl:text-base text-sm'> {JSON.parse(datas.castAndCrew).pro}</label>
                                                    <div style={{ fontSize: 13 }} className='text-gray-200 hover:cursor-default  tracking-wider'>Producer</div>
                                                </div> : null}
                                            </div>
                                            <div className="border-gray-700  border-t-1 my-3" />
                                            <div className=' space-y-6 mb-2 rounded-md'>
                                                {JSON.parse(datas.castAndCrew).wri !== '-' ? <p className='flex flex-row text-white w-[100%] justify-between  gap-x-2 text-sm hover:cursor-default lg:text-base'>Written by: <label className='text-white w-[85%] text-left break-all   text-sm lg:text-base' onClick={() => writter.length !== 0 ? navigate("/Actorinfo", { state: { data: writter } }) : navigate("/Actorinfo", { state: { data: UserEmpty, name1: JSON.parse(datas.castAndCrew).wri, desc: 'Writter' } })}>{JSON.parse(datas.castAndCrew).wri}</label> </p> : null}
                                                {JSON.parse(datas.castAndCrew).cin !== '-' ? <p className='flex flex-row text-white w-[100%] justify-between  gap-x-2 text-sm hover:cursor-default lg:text-base'>Cinematography: <label className='text-white w-[85%] text-left  break-all text-sm lg:text-base' onClick={() => cinimograpy.length !== 0 ? navigate("/Actorinfo", { state: { data: cinimograpy } }) : navigate("/Actorinfo", { state: { data: UserEmpty, name1: JSON.parse(datas.castAndCrew).cin, desc: 'Cinematography' } })}>{JSON.parse(datas.castAndCrew).cin}</label></p> : null}
                                                {JSON.parse(datas.castAndCrew).edi !== '-' ? <p className='flex flex-row text-white w-[100%] justify-between  gap-x-2 text-sm hover:cursor-default lg:text-base'>Edited by: <label className='text-white w-[85%] text-sm text-left  break-all lg:text-base' onClick={() => editor.length !== 0 ? navigate("/Actorinfo", { state: { data: editor } }) : navigate("/Actorinfo", { state: { data: UserEmpty, name1: JSON.parse(datas.castAndCrew).edi, desc: 'Editor' } })}>{JSON.parse(datas.castAndCrew).edi}</label></p> : null}
                                                {JSON.parse(datas.castAndCrew).mus !== '-' ? <p className='flex flex-row text-white w-[100%] justify-between  gap-x-2 text-sm hover:cursor-default lg:text-base'>Music by: <label className='text-white w-[85%] text-sm text-left  break-all lg:text-base' onClick={() => Music.length !== 0 ? navigate("/Actorinfo", { state: { data: Music } }) : navigate("/Actorinfo", { state: { data: UserEmpty, name1: JSON.parse(datas.castAndCrew).mus, desc: 'Music Specialist' } })}>{JSON.parse(datas.castAndCrew).mus}</label></p> : null}
                                                {JSON.parse(datas.castAndCrew).sto !== '-' ? <p className='flex flex-row text-white w-[100%] justify-between  gap-x-2 text-sm hover:cursor-default lg:text-base'>Story by: <label className='text-white w-[85%] text-sm text-left  break-all lg:text-base' onClick={() => storyBy.length !== 0 ? navigate("/Actorinfo", { state: { data: storyBy } }) : navigate("/Actorinfo", { state: { data: UserEmpty, name1: JSON.parse(datas.castAndCrew).sto, desc: 'Story Writter' } })}>{JSON.parse(datas.castAndCrew).sto}</label></p> : null}
                                                {JSON.parse(datas.castAndCrew).dia !== '-' ? <p className='flex flex-row text-white w-[100%] justify-between  gap-x-2 text-sm  hover:cursor-default lg:text-base'>Dialogues by: <p className='text-white w-[85%] text-sm text-left  break-all lg:text-base'>{JSON.parse(datas.castAndCrew).dia}</p></p> : null}
                                                {JSON.parse(datas.castAndCrew).pro !== '-' ? <p className='flex flex-row text-white w-[100%] justify-between gap-x-2 text-sm hover:cursor-default lg:text-base'>Produced by: <p className='text-white w-[85%] text-sm text-left  break-all lg:text-base'>{JSON.parse(datas.castAndCrew).pro}</p></p> : null}
                                                <p className='flex flex-row w-[100%] justify-between  text-white gap-x-2 text-sm hover:cursor-default lg:text-base'>Production company:<p className='text-white w-[85%] text-left  break-all text-sm lg:text-base'>{JSON.parse(datas.castAndCrew).proc}</p></p>
                                                <p className='flex flex-row w-[100%] justify-between  text-white gap-x-2 text-sm hover:cursor-default lg:text-base'>Release Year: <p className='text-white w-[85%] text-sm text-left  break-all lg:text-base'>{new Date(datas.releaseDate).getUTCFullYear()}</p></p>
                                                <p className='flex flex-row w-[100%] justify-between  text-white gap-x-2 text-sm hover:cursor-default lg:text-base'>Running Time: <p className='text-white w-[85%] text-sm text-left  break-all lg:text-base'>{datas.duration == 0 ? 'NA' : Math.floor(datas.duration / 60) + ' hr ' + datas.duration % 60 + ' mins'}</p></p>
                                                <p className='flex flex-row w-[100%] gap-x-2 text-sm text-white justify-between hover:cursor-default lg:text-base'>Language:  <p className='text-white w-[85%] text-sm text-left  break-all lg:text-base'>{JSON.parse(datas.castAndCrew).lang}</p></p>
                                                {datas.budget !== 0 ? <p className='flex flex-row text-white w-[100%] justify-between gap-x-2 text-sm hover:cursor-default lg:text-base'>Budget: <p className='text-white w-[85%] text-sm text-left  break-all lg:text-base'>{datas.budget} cr</p></p> : null}
                                                <p className='flex flex-row w-[100%] justify-between text-white  gap-x-2 text-sm hover:cursor-default lg:text-base'>Release date: <p className='text-white w-[85%] text-left  break-all text-sm lg:text-base'>{new Date(datas.releaseDate).getUTCDate() + "-" + (Number(new Date(datas.releaseDate).getUTCMonth()) + 1) + "-" + new Date(datas.releaseDate).getUTCFullYear()}</p></p>
                                            </div>
                                        </div>
                                    }
                                    {castdetail
                                        ? <Actors props={{ movieCelebrities: movieCelebrities }} />
                                        : <></>}

                                    {
                                        reviews &&
                                        <div>reviews</div>
                                    }
                                    {/* {
                                        behindscene &&
                                        <div>behindscene</div>
                                    } */}
                                    {
                                        news ?
                                            <MovieNews props={{ movieNews: MovieNews1 }} /> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <Footer /> */}
        </div>
    )
}

export default MovieDetailPage
