import { useEffect, useState } from 'react';

import BannerSlider from "../../components/banner_slider";
import BrandCard from "../../components/BrandCard";

import { useAppSelector, useAppDispatch, loadBrands } from '../../redux'

import { useIsMobileViewContext } from '../../context/isMobileView';

function BrandTemplate() {
    const dispatch = useAppDispatch();
    const [adsFilter, setAdsFilter] = useState(null)

    const { isMobileView } = useIsMobileViewContext();

    const brands = useAppSelector(state => state.brand.brands);
    const isBrandEndReached = useAppSelector((state) => state.brand.isEndReached);
    const advertisements = useAppSelector((state) => state.advertisement.advertisements);

    useEffect(() => {
        if (!isBrandEndReached && brands.length <= 0)
            dispatch(loadBrands())
    }, [dispatch])

    useEffect(() => {
        setAdsFilter(advertisements.filter(item => item.type === "brands" && item.orientation === "horizontal"))
    }, [advertisements])

    return (
        <div className="bg-gray-900">
            <div className={`w-full mx-auto ${adsFilter && adsFilter.length > 1 ? isMobileView ? "px-6" : "px-10" : ""}`}>
                {adsFilter && <BannerSlider ads={adsFilter} />}
            </div>

            {brands && <BrandCard list={brands} />}
        </div>
    );
}

export default BrandTemplate