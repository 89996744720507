import { db, doc, getDoc } from "../firebase";

const loadBrandWithId = (id) => {
  return new Promise(async (resolve, reject) => {
    if (id !== undefined && id !== null && id.length > 0) {
      let docRef = doc(db, "brand", id);
      let docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) resolve(docSnapshot.data());
      else reject("Data not found");
    } else {
      reject("Invalid ID");
    }
  });
};

const hasUserAlreadyRated = (brandId, userId) => {
  return new Promise(async (resolve, reject) => {
    if (brandId && userId) {
      const docRef = doc(db, "brand", brandId, "rating", userId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) resolve(docSnap.data().rating || 0);
      else reject("User Rating not found");
    } else {
      reject("Brand not found");
    }
  });
};

export { loadBrandWithId, hasUserAlreadyRated };
