import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { getApp } from "firebase/app";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { TextAtom } from "../view/atoms";
import axios from 'axios';
import { SlSocialYoutube } from "react-icons/sl";
import { FaFacebook, FaGooglePlusG, FaInstagram, FaYoutube } from 'react-icons/fa';
import { LiaFacebookF } from "react-icons/lia";
import logo from "../assets/icons/logo.svg";
const linkStyle = "cursor-pointer hover:underline underline-offset-2 hover:text-white";
function DesktopFooter() {
  useEffect(() => {
    FetchingCelCategoryData()
    FetchingGenreData()
  }, [])
  const [GenreCollection, setGenreCollection] = useState([])
  async function FetchingGenreData() {
    try {
      const response = await axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/movieGenres?limit=20&offset=0`)
      setGenreCollection(response.data)
    } catch (error) {
      console.log(error.message);
    }
  }
  const [CelebrityCollection, setCelebrityCollection] = useState([])
  async function FetchingCelCategoryData() {
    try {
      const response = await axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/celebrityCategories?limit=20&offset=0`)
      setCelebrityCollection(response.data)
    } catch (error) {
      console.log(error.message);
    }
  }
  const navigate = useNavigate()
  return (
    <div className='flex flex-col w-full bg-[#0a0d14] border-t-[1px]  border-t-gray-700'>
      <div className='container  hidden mx-auto md:flex flex-col border-b-[1px] px-5 border-b-gray-700 md:flex-row md:justify-between items-center gap-y-5 py-5 justify-center'>
        <div onClick={() => navigate('/')} className=' hover:cursor-pointer'>
          <img className='w-[60px] object-contain h-[60px]' src={logo} />
        </div>
        <div className='md:flex flex-row grid grid-cols-2 gap-y-5 lg:gap-y-0 gap-x-5 lg:gap-x-8 items-center overflow-x-auto justify-center'>
          <a target='_blank' href='https://www.facebook.com/cinimini.review.14' ><div className='flex flex-row hover:cursor-pointer justify-center md:justify-end gap-x-2 items-center '>
            <LiaFacebookF className='text-white' />
            <h5 className='text-gray-500 text-[14px] hover:text-[#ffffff]  hover:cursor-pointer font-normal'>Facebook</h5>
          </div></a>
          <a target='_blank' href='https://www.youtube.com/channel/UCRnhf3cHymPJPr2IPBZvCzQ'><div className='flex flex-r ow hover:cursor-pointer gap-x-2 justify-center md:justify-end items-center'>
            <SlSocialYoutube className='text-white' />
            <h5 className='text-gray-500 text-[14px] hover:text-[#ffffff]  hover:cursor-pointer font-normal'>YouTube</h5>
          </div></a>
          <a target='_blank' href='https://www.instagram.com/cinimini_review/'><div className='flex flex-row hover:cursor-pointer gap-x-2 items-center  justify-center md:justify-end'>
            <FaInstagram className='text-white' />
            <h5 className='text-gray-500 text-[14px]  hover:text-[#ffffff]   font-normal'>Instagram</h5>
          </div></a>
        </div>
      </div>
      <div className=' lg:gap-x-5 md:grid grid-cols-1 hidden container mx-auto px-5  md:grid-cols-3 lg:grid-cols-3'>
        <div className='py-4 '>
          <h4 className='text-white text-[16px] font-normal hover:cursor-default tracking-wide'>Movie Categories</h4>
          <div className='grid grid-cols-2 my-5 gap-y-3'>
            {
              GenreCollection.map((e, index) =>
                <label onClick={()=>navigate('/movies')} key={index} className='text-[14px] hover:text-white cursor-pointer text-gray-500 w-max'>{e.name}</label>
              )
            }
          </div>
        </div>
        <div className=' py-4 '>
          <h4 className='text-white font-normal text-[16px] hover:cursor-default tracking-wide'>Celebrity Categories</h4>
          <div className='grid grid-cols-2 my-5 gap-y-3'>
            {
              CelebrityCollection.map((e, index) =>
                <label onClick={()=>navigate('/celebrity')} key={index} className='text-[14px] hover:text-white cursor-pointer text-gray-500 w-max'>{e.name}</label>
              )
            }
          </div>
        </div>

        <div className='  py-4  border-l-gray-600 pl-5 '>
          <h4 className='text-white text-[16px] font-normal hover:cursor-default tracking-wide'>Support</h4>
          <div className='flex flex-col my-5 gap-y-3'>
            <label className='text-[14px] hover:text-white text-gray-500 w-max'>My Account</label>
            <label className='text-[14px] hover:text-white text-gray-500 w-max'>FAQ</label>
            <label className='text-[14px] hover:text-white text-gray-500 w-max'>Help Center</label>
            <label onClick={() => navigate('/ContactUsPage')} className='text-[14px] hover:text-white text-gray-500 w-max'>Contact</label>
          </div>
        </div>
      </div>
      <div className='w-full bg-[#05090f]'>
      <div className=" py-3 px-6 flex flex-col md:flex-row justify-between container mx-auto text-sm  text-gray-400">
        <div className="flex flex-row items-center">
          <TextAtom text="Copyright&nbsp;©&nbsp;" />
          <TextAtom text={`${new Date().getFullYear()}`} />
          <TextAtom text="&nbsp;StarPot, All rights reserved." />
        </div>
        <div className="flex flex-row justify-center items-center flex-wrap">
          <a href="https://firebasestorage.googleapis.com/v0/b/starpot-d121c.appspot.com/o/app-release.apk?alt=media&token=1ec9e86b-5bf3-463b-9b22-10d52d383a32" className="text-gray-200 opacity-7 h-25 w-25 block py-0">
            <span >
              Google Playstore
            </span>
          </a>
          <TextAtom text="&nbsp;|&nbsp;" />
          <Link to="/terms">
            <TextAtom text="Terms &amp; Conditions" textStyle={linkStyle} />
          </Link>
          <TextAtom text="&nbsp;|&nbsp;" />
          <Link to="/privacy">
            <TextAtom text="Privacy Policy" textStyle={linkStyle} />
          </Link>
        </div>
      </div>
      </div>
    </div>
  )
}

function DownloadApplication() {


  const firebaseApp = getApp();
  const storage = getStorage(firebaseApp, "gs://starpot-d121c.appspot.com");
  getDownloadURL(ref(storage, 'app-release.apk'))
    .then((url) => {
      console.log(url);
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        const blob = xhr.response;
      };
      xhr.open('GET', url);
      xhr.send();
      fetch('https://cors-anywhere.herokuapp.com/' + url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/apk',
          'Acces-Control-Allow-Origin': '*'

        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(
            new Blob([blob]),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Starpot.apk`,
          );

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
        });
      // Or inserted into an <img> element
      // const img = document.getElementById('myimg');
      // img.setAttribute('src', url);
    })
    .catch((error) => {
      // Handle any errors
    });


}
export default DesktopFooter;
