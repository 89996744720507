import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import TimeAgo from 'react-timeago';
import ReactPlayer from 'react-player';
import { RWebShare } from "react-web-share";
import { BsFillTrashFill, BsTrashFill } from "react-icons/bs";

import { TextAtom } from "../view/atoms";

//icons
import { FaShareSquare } from "react-icons/fa";
import { BsStarFill } from "react-icons/bs";
import { BiCommentEdit } from "react-icons/bi";
import { AiOutlineStar } from "react-icons/ai";

import dummyuserProfile from "../assets/images/dummyUser.png"

import Modal from '../components/Modal';

import { useAuthContext } from "../context/auth";
import { useIsMobileViewContext } from "../context/isMobileView";

import { useAppSelector, useAppDispatch, addReviewRating, updateReviewRating, updateUserRatingCount } from "../redux";

import { hasUserAlreadyRated } from "../service/review.service";

function UserReviewCard({ showActionButtons, review, handleEditClick, handleRemoveClick }) {
  const { isMobileView } = useIsMobileViewContext();
  const { isUserLoggedIn } = useAuthContext();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const user = useAppSelector(state => state.user.profile);

  const [openModal, setOpenModal] = useState(false);
  const [rating, setRating] = useState(review.rating);
  const [userRatingStatus, setUserRatingStatus] = useState(false);
  const [userOldRating, setUserOldRating] = useState();

  useEffect(() => {
    const getUserRatingStatus = async () => {
      hasUserAlreadyRated(review.sourceType, review.id, user.id)
        .then(result => {
          setUserOldRating(result);
          setUserRatingStatus(result && result > 0);
        })
        .catch(e => {
          setUserRatingStatus(false)
        });
    }

    if (review.sourceType && user.id) {
      getUserRatingStatus();
    }
  }, [review.id, review.sourceType, user.id]);

  const handleRating = (newRating) => {
    if (newRating) {
      const data = {
        newUserRating: newRating,
        reviewId: review.id,
        userId: user.id,
        oldRating: review.rating,
        oldUserRating: userOldRating,
        newRating: review.rating + newRating,
        sourceType: review.sourceType
      };

      if (userRatingStatus) {
        const ratingDifference =
          Math.max(newRating, userOldRating) -
          Math.min(newRating, userOldRating);

        if (ratingDifference > 0) {
          if (newRating > userOldRating) {
            setRating(rating + ratingDifference);
            dispatch(updateUserRatingCount(ratingDifference));
          } else {
            setRating(rating - Math.abs(ratingDifference))
            dispatch(updateUserRatingCount(-Math.abs(ratingDifference)));
          }
        } else {
          setRating(newRating);
          dispatch(updateUserRatingCount(newRating));
        }

        dispatch(updateReviewRating(data));
      }
      else {
        setRating(rating + newRating);
        dispatch(updateUserRatingCount(newRating));
        dispatch(addReviewRating(data));
      }

      setOpenModal(false)
      setUserRatingStatus(true)
    }
  }

  const handleStarClick = () => {
    if (isUserLoggedIn) setOpenModal(true)
    else navigate("/login");
  };

  const closeModal = () => {
    setOpenModal(false)
  }

  return (
    <div className="bg-gray-800 rounded-md my-2 mx-1 border-1 border-gray-600 ">
      <Modal defaultRating={userOldRating} handleRating={handleRating} closeModal={closeModal} openModal={openModal} />
      <div className="mx-2 my-1 flex items-center">
        {review.userCoverUrl
          ? <img src={review.userCoverUrl} alt="" className="rounded-full w-10 h-10 bg-gray-400" />
          : <img src={dummyuserProfile} alt="" className="rounded-full w-10 h-10 bg-gray-400" />
        }
        <div className="mx-2">
          <TextAtom
            text_style={`font-semibold text-white ${isMobileView ? "text-sm" : "text-md"}`}
            text={review.userName} />
          <TimeAgo
            className={`text-gray-400 ${isMobileView ? "text-xs" : "text-sm"}`}
            date={review.lastUpdatedAt ? review.lastUpdatedAt.toDate() : new Date()} />
        </div>
      </div>

      <div className="border-gray-700 border-t-1" />

      <div className="flex bg-gray-900 py-2">
        <div className="px-2">
          <p className={`text-gray-500 ${isMobileView ? "text-xs" : "text-sm"}`}>{review.review}</p>
          {review.link && <ReactPlayer width={`${isMobileView ? "300px" : "520px"}`} height={`${isMobileView ? "180px" : "280px"}`} controls url={review.link} />}
        </div>
      </div>

      <div className="border-gray-700 border-t-1" />

      <div className="flex justify-between py-2 px-2">
        <div className="flex items-center">
          <div
            onClick={handleStarClick}
            className="py-1 px-2 rounded-md bg-gray-700 flex items-center mx-1 border border-gray-600 cursor-pointer">
            {userRatingStatus ?
              <BsStarFill className={`text-primary ${isMobileView ? "w-3 h-3" : "w-4 h-4"}`} /> :
              <AiOutlineStar className="text-gray-400  w-5 h-5" />}
            <TextAtom div_style="flex mx-1" text_style={`text-gray-400 ${isMobileView ? "text-xs" : "text-sm"}`} text={rating || 0} />
          </div>
        </div>

        <div className="flex items-center">
          {showActionButtons ? <BiCommentEdit onClick={() => handleEditClick()} className={`text-primary mr-2 cursor-pointer ${isMobileView ? "w-5 h-5" : "w-6 h-6"}`} /> : <></>}
          <RWebShare
            data={{
              text: "StarPot",
              url: `https://starpot.in${location.pathname}`,
              title: `${review.sourceName} `
            }}
          >
            <FaShareSquare className={`text-primary -mt-1 cursor-pointer ${isMobileView ? "w-5 h-5" : "w-6 h-6"}`} />
          </RWebShare>
        </div>
      </div>
    </div>)
}

UserReviewCard.defaultProps = {
  showActionButtons: false
}

export default UserReviewCard;
