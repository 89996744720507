import { NewsDetailsTemplate } from "../view/templates";

function NewsDetailsPage() {
  return (
    <main className="flex-grow min-h-screen bg-gray-900">
      <NewsDetailsTemplate />
    </main>
  );
}

export default NewsDetailsPage;
