import {
  db,
  collection,
  doc,
  setDoc,
  getDocs,
  getDoc,
  updateDoc,
  deleteDoc,
  query,
  limit,
  ref,
  getDownloadURL,
  storage,
  startAfter,
  orderBy,
  where,
  serverTimestamp,
} from "../firebase";

const loadReviews = (source, sourceId, userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const collectionRef = collection(db, `${source}_review`);
      const queryReq = query(
        collectionRef,
        where(`${source}Id`, "==", sourceId),
        where("userId", "!=", userId),
        orderBy("userId", "asc"),
        orderBy("rating", "desc"),
        limit(10)
      );

      const querySnapshot = await getDocs(queryReq);

      if (querySnapshot.size > 0) {
        loadProfileImages(
          0,
          querySnapshot.docs.map((doc) => doc.data()),
          querySnapshot,
          resolve
        );
      } else {
        resolve(null);
      }
    } catch (e) {
      reject(e.message);
    }
  });
};

const loadMoreReviews = (source, sourceId, userId, lastDocSnapshot) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (lastDocSnapshot !== null) {
        const collectionRef = collection(db, `${source}_review`);
        const queryReq = query(
          collectionRef,
          where(`${source}Id`, "==", sourceId),
          where("userId", "!=", userId),
          orderBy("userId", "asc"),
          orderBy("rating", "desc"),
          startAfter(lastDocSnapshot),
          limit(10)
        );

        const querySnapshot = await getDocs(queryReq);
        if (querySnapshot.size > 0) {
          loadProfileImages(
            0,
            querySnapshot.docs.map((doc) => doc.data()),
            querySnapshot,
            resolve
          );
        } else {
          resolve(null);
        }
      } else resolve(null);
    } catch (e) {
      reject(e.message);
    }
  });
};

const loadProfileImages = async (index, list, querySnapshot, resolve) => {
  if (index < list.length) {
    try {
      let data = list[index];
      let fileName = `user/${data.userId}.jpg`;
      const storageRef = ref(storage, fileName);
      let downloadUrl = await getDownloadURL(storageRef);
      list[index].userCoverUrl = downloadUrl;
      loadProfileImages(index + 1, list, querySnapshot, resolve);
    } catch (err) {
      loadProfileImages(index + 1, list, querySnapshot, resolve);
    }
  } else {
    resolve({
      data: list,
      lastDocSnapshot: querySnapshot.docs[querySnapshot.size - 1],
    });
  }
};

const loadUserReview = (source, sourceId, userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const collectionRef = collection(db, `${source}_review`);
      const queryReq = query(
        collectionRef,
        where(`${source}Id`, "==", sourceId),
        where("userId", "==", userId)
      );

      const querySnapshot = await getDocs(queryReq);
      resolve(querySnapshot.size > 0 ? querySnapshot.docs[0].data() : null);
    } catch (e) {
      reject(e.mesage);
    }
  });
};

const addReview = (source, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const collectionRef = collection(db, `${source}_review`);
      const docRef = doc(collectionRef);

      data["id"] = docRef.id;
      data["createdAt"] = serverTimestamp();
      data["lastUpdatedAt"] = serverTimestamp();
      data["rating"] = 0;

      await setDoc(docRef, data);
      data.createdAt = null;
      data.lastUpdatedAt = null;
      resolve(data);
    } catch (e) {
      reject(e.message);
    }
  });
};

const updateReview = (source, data, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, `${source}_review`, id);

      data["lastUpdatedAt"] = serverTimestamp();

      await updateDoc(docRef, data);
      data.lastUpdatedAt = null;
      resolve(data);
    } catch (e) {
      reject(e.message);
    }
  });
};

const deleteReview = (source, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, `${source}_review`, id);

      await deleteDoc(docRef);
      resolve(id);
    } catch (e) {
      reject(e.message);
    }
  });
};

const hasUserAlreadyRated = (sourceType, id, userId) => {
  return new Promise(async (resolve, reject) => {
    if (sourceType && id && userId) {
      const docRef = doc(db, `${sourceType}_review`, id, "rating", userId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) resolve(docSnap.data().rating || 0);
      else reject("User Rating not found");
    } else {
      reject("Document not found");
    }
  });
};

export {
  loadReviews,
  loadMoreReviews,
  loadUserReview,
  addReview,
  updateReview,
  deleteReview,
  hasUserAlreadyRated,
};
