import React, { useEffect, useState, useRef } from "react";
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { MdMenu, MdOutlineNotificationsActive } from "react-icons/md";
import { BsPlus } from "react-icons/bs";

import favourite from "../assets/icons/favourite.svg";
import favouriteActive from "../assets/images/favourite.png";
import packageIcon from "../assets/icons/package.svg";
import packageActive from "../assets/images/package.png";

import { ImageAtom } from "../view/atoms";

import { useIsMobileViewContext } from "../context/isMobileView";

const bottomSheetItems = [
  {
    title: "Create a Review",
    // icon: CameraIcon,
    link: "create-review",
  },
  // {
  //   title: "Upgrade to Pro",
  //   // icon: CameraIcon,
  //   link: "upgrade",
  // },
]

function Footer() {
  const { isMobileView } = useIsMobileViewContext();

  const location = useLocation();

  const prevScrollPosRef = useRef(0);

  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const [isBottomSheetVisible, setIsBottomSheetVisible] = useState(false);

  useEffect(() => {
    const listenToScroll = (e) => {
      const window = e.currentTarget;
      if (prevScrollPosRef.current > window.scrollY) {
        setIsFooterVisible(true);
      } else {
        setIsFooterVisible(false);
      }
      prevScrollPosRef.current = window.scrollY;
    };

    window.addEventListener("scroll", listenToScroll);

    return () => {
      window.removeEventListener("scroll", listenToScroll);
    };
  }, []);

  const handleBottomSheetClose = () => {
    setIsBottomSheetVisible(false);
  }

  return isMobileView ? (
    <footer className="sticky bottom-0 z-20">
      {location.pathname !== "/login" &&
        location.pathname !== "/register" &&
        location.pathname !== "/forgot-password" &&
        isFooterVisible ? (
        <>
          <BottomSheet
            isVisible={isBottomSheetVisible}
            items={bottomSheetItems}
            onClose={handleBottomSheetClose}
          />
          <div
            className={`flex flex-row justify-between items-center px-4 py-1 h-12 bg-gray-800 transition duration-500 ease-in-out 
          ${isFooterVisible ? "translate-y-0" : "translate-y-12"}`}
          >
            <Link to="/my-reviews">
              {location.pathname === "/my-reviews"
                ? <ImageAtom img={favouriteActive} imageStyle="w-6 h-6" />
                : <ImageAtom img={favourite} imageStyle="w-6 h-6" />
              }
            </Link>
            <Link to="/points">
              {location.pathname === "/points"
                ? <ImageAtom img={packageActive} imageStyle={`w-6 h-6`} />
                : <ImageAtom img={packageIcon} imageStyle={`w-6 h-6`} />
              }
            </Link>
            <div onClick={() => setIsBottomSheetVisible(!isBottomSheetVisible)}>
              <BsPlus className="w-8 h-8 rounded-full m-1 bg-white text-black" />
            </div>
            <Link to="/notification"><MdOutlineNotificationsActive className={`w-6 h-6 self-center ${location.pathname === "/notification" ? "text-primary" : "text-white"}`} /></Link>
            <Link to="/more"><MdMenu className={`w-6 h-6 self-center ${location.pathname === "/more" ? "text-primary" : "text-white"}`} /></Link>
          </div>
        </>
      ) : null}
    </footer>
  ) : null;
}

function BottomSheet({ isVisible, items, onClose }) {
  const navigate = useNavigate();

  const handleItemClick = (link) => {
    onClose();
    navigate(link);
  }

  return (
    <div
      className={`relative bg-gray-800 rounded-t-md border-b border-gray-600 p-2 gap-1 grid grid-cols-1 transition duration-500 ease-in-out 
        ${isVisible
          ? "translate-y-0 opacity-1"
          : "translate-y-96 opacity-0 hidden"
        }
      `}
    >
      <div className="absolute top-0 right-0 mr-3" onClick={() => onClose()}><span className="text-xl text-gray-300">x</span></div>
      <div className="pt-6 flex items-center justify-center">
        {items &&
          items.map((item, index) => (
            <div
              key={`bottom_sheet_item_${index}`}
              to={item.link}
              className="flex items-center justify-center w-3/4 m-1 rounded-sm py-1 border border-primary "
              onClick={() => handleItemClick(item.link)}
            >
              {/* <item.icon className="h-4 w-4 mx-2" /> */}
              <span className="text-sm text-gray-500 font-semibold">{item.title}</span>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Footer;
