import { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

//icons
import { FaAngleLeft, FaAngleRight, FaPlay, FaPlus, FaTimes, FaUserCheck } from "react-icons/fa";
import { TextAtom } from "../view/atoms";
import { BsStarFill } from "react-icons/bs";

import bookmark from "../assets/images/small_bookmark.png";
import Close from '../assets/images/Close.png'
import { useIsMobileViewContext } from "../context/isMobileView";
import MySlider from "../components/MySlider";
import Star from "../assets/images/star.png";
import TextMarquee from "../view/atoms/TextMarquee";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GetTheData } from './Api/FetchData'
import { useAppSelector } from '../redux'
import starnotfill from '../assets/images/starnotfill.png'
import axios from "axios";
import Footer from "./Footer";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
function MovieCardList({ list }) {

  const { isMobileView } = useIsMobileViewContext();
  const navigate = useNavigate()
  // const [categorizedMovies, setCategorizedMovies] = useState([]);
  // useEffect(() => {
  //   if (list) {
  //     let newCategorizedMovies = [];
  //     list
  //       .slice(0, list.length)
  //       .forEach(item => {
  //         if (item.type && item.type.length >= 0) {
  //           item.type.forEach(type => {
  //             if (newCategorizedMovies[type] && newCategorizedMovies[type].length < 10) {
  //               newCategorizedMovies[type].push(item);
  //             } else {
  //               newCategorizedMovies[type] = [];
  //               newCategorizedMovies[type].push(item);
  //             }
  //           });
  //         }
  //       });
  //     setCategorizedMovies(newCategorizedMovies);
  //   }
  // }, [list]);
  const responsive1 = {
    superLargeDesktop: {
      breakpoint: { max: 2560, min: 1440 },
      items: 1,

    },
    desktop: {
      breakpoint: { max: 1439, min: 901 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 900, min: 400 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 645, min: 0 },
      items: 1
    }
  };
  const CustomDot = ({
    index,
    active,
    onClick
  }) => {
    return (
      <div className='flex flex-col justify-center mr-[10px] hover:cursor-pointer items-center' onClick={e => {
        onClick();
        e.preventDefault();
      }}>
        {active ? <div className='w-10 h-2 duration-300 bg-primary rounded-full' /> : <div className='w-2 h-2 duration-300 bg-white rounded-full' />}
      </div>
    );
  };
  const [ImagePostion, setImagePostion] = useState(0)
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "250px",
    slidesToShow: 1,
    speed: 500,
    dots: true,
    autoplay: true,

    afterChange: current => setImagePostion(current),
    appendDots: dots => (
      <div
        style={{
          backgroundColor: "transparent"
        }}
      >
        <ul style={{ marginTop: "10px", marginTop: '10px' }}> {dots} </ul>
      </div>
    ),
    customPaging: i => (
      <div
        style={{
          width: '10px',
          height: '10px',
          marginTop: '10px',
          backgroundColor: ImagePostion == i ? '#00c1f8' : 'white',
          borderRadius: 10
        }}
      >
      </div>
    )
  };
  const settings1 = {
    className: "center",
    centerMode: isMobileView ? false : true,
    infinite: true,
    centerPadding: "250px",
    slidesToShow: 1,
    speed: 500,
    dots: false,
    autoplay: true,
    arrows: false,
    afterChange: current => setImagePostion(current),
    appendDots: dots => (
      <div
        style={{
          backgroundColor: "transparent"
        }}
      >
        <ul style={{ marginTop: "10px", marginTop: '10px' }}> {dots} </ul>
      </div>
    ),
    customPaging: i => (
      <div
        style={{
          width: '10px',
          height: '10px',
          marginTop: '10px',
          backgroundColor: ImagePostion == i ? '#00c1f8' : 'white',
          borderRadius: 10
        }}
      >
      </div>
    )
  };

  const sliderRef = useRef(null);
  const CustomNextArrow = ({ onClick }) => {
    return (
      <button
        className="custom-prev-button"
        style={{
          position: 'absolute',
          top: '50%',
          right: '150px', /* Adjust as needed */
          transform: 'translateY(-20%)',
          backgroundColor: 'white', /* Adjust styles as needed */
          border: 'none', /* Adjust styles as needed */
          cursor: 'pointer',
          borderRadius: 100
        }}
        onClick={onClick}
      >
        <FaAngleRight className="w-10 h-10 font-normal" />
      </button>
    );
  };
  const CustomPrevArrow = ({ onClick }) => {
    return (
      <button
        className="custom-prev-button"
        style={{
          position: 'absolute',
          top: '50%',
          zIndex: '99',
          left: '150px', /* Adjust as needed */
          transform: 'translateY(-50%)',
          backgroundColor: 'white', /* Adjust styles as needed */
          border: 'none', /* Adjust styles as needed */
          cursor: 'pointer',
          borderRadius: 100
        }}
        onClick={onClick}
      >
        <FaAngleLeft className="w-10 h-10 font-normal" />
      </button>
    );
  };
  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };
  const Images = ['https://akamaividz2.zee5.com/image/upload/w_1420,h_569,c_scale,f_webp,q_auto:eco/resources/0-101-10z5531943/cover/1920x770b28969a3ed2b40ceb76c6524cdd8b4bd.jpg', 'https://akamaividz2.zee5.com/image/upload/w_1420,h_569,c_scale,f_webp,q_auto:eco/resources/0-0-1z5535878/cover/1920x770368b976fe8b142f6aa80e0e002bb5be934166a7d3a4d447f9b61aaab4dff26a7f8b60f75c12f4e07afde4b6e0e4476e7.jpg', 'https://akamaividz2.zee5.com/image/upload/w_1420,h_569,c_scale,f_webp,q_auto:eco/resources/0-101-10z5513052/cover/1920x7708e5048d43e6d457eb476f6a9b19814d4.jpg']
  const banners = ['https://assets-in.bmscdn.com/promotions/cms/creatives/1706382336630_web.jpg', 'https://assets-in.bmscdn.com/promotions/cms/creatives/1715840680583_u1webmay.jpg', 'https://assets-in.bmscdn.com/promotions/cms/creatives/1716365761221_1240x300.jpg']
  useEffect(() => {
    FtechingOverallData()
  }, [])
  const [movies, setMovies] = useState([])
  const [movieGenre, setMovieGenre] = useState([])
  async function FtechingOverallData() {
    let temp1 = []
    let temp2 = []
    try {
      const response = await GetTheData('movies')
      setMovies(response.data)
      temp1 = [...response.data]
    } catch (error) {
      console.log(error.message);
    }
    try {
      const response = await GetTheData('movieGenres')
      setMovieGenre(response.data)
      temp2 = [...response.data]
    } catch (error) {
      console.log(error.message);
    }
    SegrigateByGenre(temp1, temp2)
  }

  const [movieContents, setMovieContents] = useState([])
  function SegrigateByGenre(movie, genre) {
    let temp2 = []
    for (let i = 0; i < genre.length; i++) {
      let temp = []
      for (let j = 0; j < movie.length; j++) {
        if (movie[j].genreIds != null) {
          if (JSON.parse(movie[j].genreIds).findIndex(e => e == genre[i].id) != -1) {
            temp.push(movie[j])
          }
        }
      }
      temp2.push(temp)
    }
    setMovieContents(temp2)

  }
  const user = useAppSelector(state => state.user.profile)
  console.log(user.id);
  
  const [selectedMovie, setSelectedMovie] = useState(0)
  const [starCountSelected, setStarCountSelected] = useState(-1);
  const [showStarRating, setShowStarRating] = useState(false)
  const [starCount, setStarCount] = useState(-1)
  function MovieRatingFunction(array) { 
    if (user.id === null||user.id==undefined) {
      alert('Login Do First')
    }
    else {
      setSelectedMovie(array.id)
      setStarCountSelected(array.rating != null ? JSON.parse(array.rating).find(e => e.i == user.id).r - 1 : -1)
      setShowStarRating(true)
    }
  }
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, [])

  const notify = async (star) => {
    let movieRating = movies.find(e => e.id == selectedMovie)
    try {
      let temp = []
      if (movieRating.rating === null) {
        temp.push({
          i: user.id,
          r: star
        })
      }
      else if (movieRating.rating !== null) {
        temp = [...JSON.parse(movieRating.rating)]
        const result = temp.findIndex(e => e.i === user.id)
        if (result === -1) {
          temp.push({
            i: user.id,
            r: star
          })
        } else {
          temp.splice(result, 1)
          temp.push({
            i: user.id,
            r: star
          })
        }
      }
      await axios.patch(`https://cinimini-apis-3nugbllihq-el.a.run.app/movies/${selectedMovie}`, {
        rating: JSON.stringify(temp)
      }).then((res) => {
        console.log(res.data);
        UpdateMovieLocally(selectedMovie, JSON.stringify(temp))
      }).catch((error) => {
        console.log(error.message);
      })

    } catch (error) {
      console.log(error.message);
    }
    setShowStarRating(false)
  };

  function UpdateMovieLocally(_id, _obj) {
    let tempMovies = [...movies]
    tempMovies.find(e => e.id == _id).rating = _obj
    setMovies(tempMovies)
  }

  // useEffect(() => {
  //   FetchingMovieBanners()
  // }, [])

  // async function FetchingMovieBanners() {
  //   const querySnapshot = await getDocs(collection(db, "VideoBanners"));
  //   if (querySnapshot.docs[0].data().url != undefined) {
  //     setVideoBanner(querySnapshot.docs[0].data().url)
  //   }
  // }
  console.log(list);
  const stars = [starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill]
  return (
    <div className={`flex flex-col w-full h-[100vh] items-center overflow-auto`}>
      {
        showStarRating &&
        <div className='w-full md:w-1/2 lg:w-1/3 absolute z-50 top-[30vh] bg-[#1c212e] rounded-md h-[300px] flex flex-col  items-center'>
          <img className='w-[80px] h-[80px] hover:scale-125 hover:cursor-pointer duration-300 object-contain mt-2 absolute -top-[50px]' src={Star} />
          <h2 className='text-white absolute -mt-4 z-50'>{starCountSelected > -1 ? starCountSelected + 1 : 0}</h2>
          <img onClick={() => setShowStarRating(false)} className='w-[6%] h-[6%]  mx-2 hover:scale-125 hover:cursor-pointer duration-300 object-contain mt-2 absolute right-0' src={Close} />
          <h4 className='text-white text-center md:mt-[15%] mt-[20%]'>Rate This</h4>
          <div id='Rating' className='flex flex-row justify-center items-center gap-x-2 mx-5 mt-[5%] ' onMouseLeave={() => setStarCount(-1)}>
            {
              stars.map((e, index) =>
                <img id='Rating' onPointerLeave={() => setStarCount(-1)} onMouseEnter={() => setStarCount(index)} onClick={() => setStarCountSelected(index)} key={index}
                  className={`cursor-pointer w-1/12 xl:w-[5%]`} src={starCount > -1 ? (index <= starCount ? Star : e) : (index <= starCountSelected ? Star : e)} />
              )
            }
          </div>
          <div id='Rating' onClick={() => notify(starCountSelected + 1)} style={{ borderWidth: 1 }}
            className='hover:text-black text-white hover:bg-[#24baef] duration-300 p-1 hover:cursor-pointer w-[80%] hover:border-0 lg:bg-transparent lg:w-[40%] mt-[5%] rounded-md flex flex-col justify-center items-center'>
            <h4 className=' tracking-wide text-base'>Rate</h4>
          </div>
        </div>
      }
      <div className={`flex flex-col w-full sm:bg-[#0f172a] bg-gradient-to-t relative from-[#0f172a] via-[#0f172a] to-transparent pt-4`}>
        {list.length > 0 && <img src={list[ImagePostion].banner_url} className="w-full h-screen brightness-150 sm:brightness-95 blur-3xl transition-all absolute"></img>}
        <div className="hidden self-center w-[95%] flex-col lg:block  z-[9] mb-10">
          <Slider ref={sliderRef} prevArrow={<CustomPrevArrow onClick={handlePrev} />} nextArrow={<CustomNextArrow onClick={handleNext} />} {...settings}>
            {list.length > 0 && list.map((e, index) =>
              <div key={index} className='overflow-hidden xl:h-[520px] h-[400px] relative rounded-md '>
                <div className="h-[160px] absolute gap-2 bottom-0 z-10 p-10 w-full flex flex-col justify-end bg-gradient-to-t from-black to-transparent">
                  <h1 className="text-white font-semibold text-lg">{e.title}</h1>
                  <a href={e.link}>
                    <div className=" p-2 cursor-pointer max-w-28 bg-white text-black hover:text-white hover:bg-transparent border-white border-2 duration-300 rounded-md  flex flex-row gap-x-1 items-center justify-center">
                      <FaPlay className="h-3 w-4" />
                      <div className="text-sm max-w-sm font-bold text-center">Watch</div>
                    </div>
                  </a>
                </div>

                <img className="object-cover absolute h-full w-full object-top rounded-md" src={e.banner_url} />
              </div>
            )}
          </Slider>
        </div>
        <div className="flex flex-col items-center lg:hidden ">
          <Carousel autoPlay={true} afterChange={(previousSlide, { currentSlide, onMove }) => { setImagePostion(currentSlide) }} customDot={<CustomDot />} showDots={true} responsive={responsive1}
            className=" my-2 self-center flex-row  min-[320px]:w-[300px] min-[375px]:w-[330px] min-[425px]:w-[350px] md:w-[700px] lg:w-[800px] rounded-md min-[320px]:h-[450px] min-[375px]:h-[520px] " arrows={false}>
            {list.map((e, index) =>
              <div onClick={() => navigate(`/movie/${e.id + (`-${index + 1}`)}`)} key={index} className='w-full relative brightness-90 min-[320px]:h-[400px] min-[375px]:h-[480px] rounded-md flex flex-col justify-center'>
                <img className='w-full h-full cursor-pointer absolute object-cover border-[1px] sm:border-4 border-white object-top rounded-xl' src={e.banner_url} />
                <div className="flex flex-col w-full gap-3 bg-gradient-to-t from-black to-transparent absolute px-5 bottom-0 pb-[10px]">
                  <div className="w-1/2 py-2  rounded-md  flex flex-row gap-x-1 items-center justify-center">
                    {/* <FaPlus className="text-white" /> */}
                    <div className="text-sm font-bold text-white text-center">{e.title}</div>
                  </div>
                  <div className="w-1/2 py-2 bg-white rounded-md flex flex-row gap-x-1 items-center justify-center">
                    <FaPlay />
                    <div className="text-sm font-bold text-center">Trailer</div>
                  </div>
                </div>
              </div>
            )}
          </Carousel>
        </div>
        {/* </div> */}
        {
          movies &&
          <>
            <span className={`text-white font-bold mx-4 z-[9] ${isMobileView ? "text-sm" : "text-xl"}`}>Recently Added Movies</span>
            <MySlider isScrollActionEnabled={true} isMobileView={isMobileView}>
              {movies
                .slice(0, 15)
                .map((item, index) => (
                  <div className="mr-2 m-2 z-10 cursor-pointer bg-white rounded-lg drop-shadow-md" key={index}>
                    <div className={`overflow-hidden relative rounded-tr-lg rounded-tl-lg ${isMobileView ? "w-28 h-32" : "w-72 h-[400px]"}`}>
                      <img
                        onClick={() => navigate('/MovieDetailPage', { state: { array: item } })}
                        alt=""
                        className={`hover:scale-110 transistion duration-500 object-cover rounded-tr-lg rounded-tl-lg ${isMobileView ? "w-28 h-32" : "w-72 h-[400px]"}`}
                        src={'https://storage.googleapis.com/cinimini-console/movies/' + item.id + '/' + JSON.parse(item.thumbnailPictureUrl)[0]}
                      />
                    </div>
                    <div onClick={() => navigate('/MovieDetailPage', { state: { array: item } })}>
                      <TextMarquee
                        title={item.name}
                        marqueeAfterLength={isMobileView ? 18 : 30}
                        text_style={`text-black font-bold w-full  text-center pt-1 ${isMobileView ? "text-xs" : "text-md"}`}
                        div_style={`${isMobileView ? "w-28" : "w-72"}`} />
                    </div>
                    <h1
                      className={`text-black font-semibold w-full text-center truncate -mt-1 ${isMobileView ? "w-28 text-xss" : "w-72 text-sm"}`}>
                      {new Date(item.releaseDate).getUTCFullYear()}
                    </h1>

                    <div className="flex justify-between items-center mt-1 border-t-[1px]">
                      <div className="flex-1 py-1 px-0.5 bg-white flex items-center justify-center mr-0.5 rounded-bl-lg">
                        <BsStarFill  className={`text-primary mr-1 ${isMobileView ? "w-3 h-3" : "w-4 h-4"}`} />
                        {item.rating != null ? <TextAtom
                          div_style="flex mx-1"
                          text_style={`text-black ${isMobileView ? "text-xs" : "text-sm"}`}
                          text={JSON.parse(JSON.parse(item.rating).findIndex(e => e.i == user.id) != -1 ? JSON.parse(item.rating).find(e => e.i == user.id).r : 0)}

                        /> : 0}
                      </div>
                      <div className="flex-1 py-1 px-0.5 bg-white  flex items-center justify-center ml-0.5 rounded-br-lg">
                        <FaUserCheck className={`text-primary ${isMobileView ? "w-3 h-3" : "w-4 h-4"}`} />
                        <TextAtom
                          div_style="flex mx-1"
                          text_style={`text-black ${isMobileView ? "text-xs" : "text-sm"}`}
                          text={0}
                        />
                      </div>
                    </div>
                  </div>
                ))}

            </MySlider>
          </>
        }
        {
          movieContents &&
          <>
            {
              movieContents.map((e, index) => e.length > 0 ?
                <>
                  <span className={`text-white font-bold mx-4 z-[9] ${isMobileView ? "text-sm" : "text-xl"}`}>{movieGenre[index]?.name}</span>
                  <MySlider isScrollActionEnabled={list.length > (isMobileView ? 3 : 6)} isMobileView={isMobileView}>
                    {e.map((item, index1) =>
                      <div className="mr-2 m-2 z-10 cursor-pointer bg-white rounded-lg drop-shadow-md" key={index1}>
                        <div className={`overflow-hidden relative rounded-tr-lg rounded-tl-lg ${isMobileView ? "w-28 h-32" : "w-72 h-[400px]"}`}>
                          <img
                            onClick={() => navigate('/MovieDetailPage', { state: { array: item } })}
                            alt=""
                            className={`hover:scale-110 transistion duration-500 object-cover rounded-tr-lg rounded-tl-lg ${isMobileView ? "w-28 h-32" : "w-72 h-[400px]"}`}
                            src={'https://storage.googleapis.com/cinimini-console/movies/' + item.id + '/' + JSON.parse(item.thumbnailPictureUrl)[0]}
                          />
                        </div>
                        <div onClick={() => navigate('/MovieDetailPage', { state: { array: item } })}>
                          <TextMarquee
                            title={item.name}
                            marqueeAfterLength={isMobileView ? 18 : 30}
                            text_style={`text-black font-bold w-full  text-center pt-1 ${isMobileView ? "text-xs" : "text-md"}`}
                            div_style={`${isMobileView ? "w-28" : "w-72"}`} />
                        </div>
                        <h1
                          className={`text-black font-semibold w-full text-center truncate -mt-1 ${isMobileView ? "w-28 text-xss" : "w-72 text-sm"}`}>
                          {new Date(item.releaseDate).getUTCFullYear()}
                        </h1>

                        <div className="flex justify-between items-center mt-1 border-t-[1px]">
                          <div className="flex-1 py-1 px-0.5 bg-white flex items-center justify-center mr-0.5 rounded-bl-lg">
                            <BsStarFill onClick={() => MovieRatingFunction(item)} className={`text-primary mr-1 ${isMobileView ? "w-3 h-3" : "w-4 h-4"}`} />
                            {item.rating != null ? <TextAtom
                              div_style="flex mx-1"
                              text_style={`text-black ${isMobileView ? "text-xs" : "text-sm"}`}
                              text={JSON.parse(JSON.parse(item.rating).findIndex(e => e.i == user.id) != -1 ? JSON.parse(item.rating).find(e => e.i == user.id).r : 0)}

                            /> : 0}
                          </div>
                          <div className="flex-1 py-1 px-0.5 bg-white  flex items-center justify-center ml-0.5 rounded-br-lg">
                            <FaUserCheck className={`text-primary ${isMobileView ? "w-3 h-3" : "w-4 h-4"}`} />
                            <TextAtom
                              div_style="flex mx-1"
                              text_style={`text-black ${isMobileView ? "text-xs" : "text-sm"}`}
                              text={0}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </MySlider>
                  <div className="w-full">
                    {index % 2 == 0 ?
                      <Slider  {...settings1}>
                        {banners.length > 0 && banners.map((e, index) =>
                          <div key={index} className=' overflow-hidden rounded-md w-full'>
                            <img className="object-cover object-top w-full lg:h-[200px] rounded-md" src={e} />
                          </div>
                        )}
                      </Slider>
                      : null}
                  </div>
                </> : null
              )
            }

          </>
        }
        {/* {list &&
        <>
          <span className={`text-black font-bold mx-4 ${isMobileView ? "text-sm" : "text-xl"}`}>Top 15 Movies</span>
          <MySlider isScrollActionEnabled={list.length > (isMobileView ? 3 : 6)} isMobileView={isMobileView}>
            {list
              .slice(0, 15)
              .map((item, index) => (
                <MyCardItem
                  key={`item_${index}`}
                  index={item.rank}
                  title={item.name}
                  img={item.cover_url}
                  link={item.id + (`-${index + 1}`)}
                  rating={item.rating}
                  reviews={item.reviewCount}
                  isMobileView={isMobileView}
                  year={item.releaseYear}
                />
              ))}
          </MySlider>
        </>
      } */}
        {/* 
     {categorizedMovies &&
        Object.entries(categorizedMovies)
          .map(([key, value]) => (
            <>
              {value &&
                <>
                  <div key={key} className="flex items-center justify-between px-2">
                    <span className={`text-white font-bold z-[99] mx-2 my-2 ${isMobileView ? "text-sm" : "text-xl"}`}>{key}</span>
                    {value.length === 10 ? <Link to={`movie/more/${key}`} className={`font-semibold text-primary mx-2 ${isMobileView ? "text-sm" : "text-md"}`}>More &gt;</Link> : <></>}
                  </div>
                  <MySlider isScrollActionEnabled={value.length > (isMobileView ? 3 : 6)} isMobileView={isMobileView}>
                    {value
                      .map((item, index) => (
                        <MyCardItem
                          key={`item_${index}`}
                          index={index + 1}
                          title={item.name}
                          img={item.cover_url}
                          link={item.id}
                          rating={item.rating}
                          reviews={item.reviewCount}
                          isMobileView={isMobileView}
                          year={item.releaseYear}
                        />
                      ))}
                  </MySlider>
                </>
              }
            </>
          ))}  */}
      </div >
    </div >
  );
}

function MyCardItem({
  index,
  id,
  array,
  title,
  img,
  link,
  rating,
  reviews,
  isMobileView,
  year,
}) {
  const navigate = useNavigate()



  return (
    <div className="mr-2 m-2 z-10 cursor-pointer bg-white rounded-lg drop-shadow-md" key={title}>

      <div className={`overflow-hidden relative rounded-tr-lg rounded-tl-lg ${isMobileView ? "w-28 h-32" : "w-72 h-[400px]"}`}>
        <img
          onClick={() => navigate('/MovieDetailPage', { state: { array: array } })}
          alt=""
          className={`hover:scale-110 transistion duration-500 object-cover rounded-tr-lg rounded-tl-lg ${isMobileView ? "w-28 h-32" : "w-72 h-[400px]"}`}
          src={'https://storage.googleapis.com/cinimini-console/movies/' + id + '/' + img}
        />
      </div>
      <div onClick={() => navigate('/MovieDetailPage', { state: { array: array } })}>
        <TextMarquee
          title={title}
          marqueeAfterLength={isMobileView ? 18 : 30}
          text_style={`text-black font-bold w-full  text-center pt-1 ${isMobileView ? "text-xs" : "text-md"}`}
          div_style={`${isMobileView ? "w-28" : "w-72"}`} />
      </div>
      <h1
        className={`text-black font-semibold w-full text-center truncate -mt-1 ${isMobileView ? "w-28 text-xss" : "w-72 text-sm"}`}>
        {year}
      </h1>

      <div className="flex justify-between items-center mt-1 border-t-[1px]">
        <div className="flex-1 py-1 px-0.5 bg-white flex items-center justify-center mr-0.5 rounded-bl-lg">
          <BsStarFill className={`text-primary ${isMobileView ? "w-3 h-3" : "w-4 h-4"}`} />
          <TextAtom
            div_style="flex mx-1"
            text_style={`text-black ${isMobileView ? "text-xs" : "text-sm"}`}
            text={0}
          />
        </div>
        <div className="flex-1 py-1 px-0.5 bg-white  flex items-center justify-center ml-0.5 rounded-br-lg">
          <FaUserCheck className={`text-primary ${isMobileView ? "w-3 h-3" : "w-4 h-4"}`} />
          <TextAtom
            div_style="flex mx-1"
            text_style={`text-black ${isMobileView ? "text-xs" : "text-sm"}`}
            text={0}
          />
        </div>
      </div>
    </div>
  );
}

function CardItem({
  index,
  title,
  img,
  link,
  rating,
  reviews,
  isMobileView,
  year,
}) {
  return (
    <div className={`bg-gray-800 rounded-lg drop-shadow-md  hover:scale-105 transistion duration-500 `} key={title}>
      <Link to={`/movie/${link}`}>
        {img ? (
          <div
            className={`w-full  rounded-tr-xl rounded-tl-xl ${isMobileView ? "h-32" : "h-80"}`}
            style={{
              backgroundImage: `url(${img})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            {index &&
              <div
                className={`${isMobileView ? "w-6 h-8" : "w-8 h-16"} ml-2 text-center`}
                style={{
                  backgroundImage: `url(${bookmark})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <span className={`text-gray-200 ${isMobileView ? "text-xs" : "text-md"}`}>{`#${index}`}</span>
              </div>
            }
          </div>
        ) : (
          <div className="h-28"></div>
        )}

        <TextMarquee
          title={title}
          marqueeAfterLength={isMobileView ? 18 : 30}
          text_style={`text-gray-200 w-full text-center pt-1 ${isMobileView ? "text-xs" : "text-md"}`} />

        <h1
          className={`text-black w-full text-center truncate -mt-1 ${isMobileView ? "text-xss" : "text-sm"}`}>
          {year}
        </h1>

        <div className="flex justify-between items-center mt-1 ">
          <div className="flex-1 py-1 px-0.5 bg-gray-700 flex items-center justify-center mr-0.5 rounded-bl-lg">
            <BsStarFill className={`text-primary ${isMobileView ? "w-3 h-3" : "w-4 h-4"}`} />
            <TextAtom
              div_style="flex mx-1"
              text_style={`text-black ${isMobileView ? "text-xs" : "text-sm"}`}
              text={rating || 0}
            />
          </div>
          <div className="flex-1 py-1 px-0.5 bg-gray-700  flex items-center justify-center ml-0.5 rounded-br-lg">
            <FaUserCheck className={`text-primary ${isMobileView ? "w-3 h-3" : "w-4 h-4"}`} />
            <TextAtom
              div_style="flex mx-1"
              text_style={`text-black ${isMobileView ? "text-xs" : "text-sm"}`}
              text={reviews || 0}
            />
          </div>
        </div>
      </Link>
    </div>
  );
}

export { MovieCardList, CardItem };
