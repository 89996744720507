import { db, doc, getDoc } from "../firebase";

const loadNewsWithId = (id) => {
  return new Promise(async (resolve, reject) => {
    if (id !== undefined && id !== null && id.length > 0) {
      let docRef = doc(db, "news", id);
      let docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) resolve(docSnapshot.data());
      else reject("Data not found");
    } else {
      reject("Invalid ID");
    }
  });
};

const hasUserAlreadyRated = (newsId, userId) => {
  return new Promise(async (resolve, reject) => {
    if (newsId && userId) {
      const docRef = doc(db, "news", newsId, "rating", userId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) resolve(docSnap.data().rating || 0);
      else reject("User Rating not found");
    } else {
      reject("News not found");
    }
  });
};

export { loadNewsWithId, hasUserAlreadyRated };
