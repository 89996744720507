import { CelebrityDetailsTemplate } from "../view/templates/CelebrityDetailsTemplate";

function CelebrityDetailsPage() {
  return (
    <main className="flex-grow min-h-screen bg-gray-900">
      <CelebrityDetailsTemplate />
    </main>
  )
}

export default CelebrityDetailsPage