import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

//icons
import { FaAngleLeft, FaAngleRight, FaUserCheck } from "react-icons/fa";
import { BsStarFill } from "react-icons/bs";

import bookmark from '../assets/images/small_bookmark.png'

import { TextAtom } from '../view/atoms'
import TextMarquee from '../view/atoms/TextMarquee';
import MySlider from "../components/MySlider";

import { useIsMobileViewContext } from "../context/isMobileView";
import Slider from "react-slick";

function CelebrityCard({ list, category }) {
  // const divRef = useRef(null);
  const { isMobileView } = useIsMobileViewContext();

  const [categorizedCelebrities, setCategorizedCelebrities] = useState([]);

  // useEffect(() => {
  //   if (list) {
  //     let newCategorizedCelebrities = [];
  //     list.forEach(item => {
  //       if (item.type && item.type.length > 0) {
  //         item.type.forEach(type => {
  //           if (newCategorizedCelebrities[type]) {
  //             newCategorizedCelebrities[type].push(item);
  //           } else {
  //             newCategorizedCelebrities[type] = [];
  //             newCategorizedCelebrities[type].push(item);
  //           }
  //         });
  //       }
  //     });
  //     setCategorizedCelebrities(newCategorizedCelebrities);
  //   }
  // }, [list]);

  const navigate = useNavigate();

  return <>
    <div className="flex flex-col w-full">
      {list.map((e, index) =>e.length>0?
        <div className="w-full bg-[#131721] ">
          <div className="xl:w-[1700px] relative w-full mx-auto py-5">
            <div className="flex items-center justify-center mb-6">
              <span className={`text-gray-300 font-bold font-serif sm:my-2 ${isMobileView ? "text-xl" : "text-4xl"}`}>{category[index].name}</span>
            </div>
            <div className="w-full hidden sm:block">
              <MySlider isScrollActionEnabled={e.length > (isMobileView ? 0 : 6)}>
                {e
                  .map((item, index) => (
                    <div onClick={()=>navigate('/AboutCelebrity',{state:{data:item}})} key={index} className={`sm:mx-3 flex-none cursor-pointer  bg-transparent/25 rounded-b-lg  
                 ${isMobileView ? "w-full h-[350px] overflow-hidden" : "w-[264px] h-[400px]"}`}>
                      <div className="">
                        <img
                          src={'https://storage.googleapis.com/cinimini-console/celebrities/' +item.id + '/' + (JSON.parse(item.profilePictureUrls)[0])}
                          alt=""
                          className={`rounded-t-lg transistion duration-500 object-cover object-top ${isMobileView ? "w-full h-[300px]" : "w-[264px] h-[350px]"}`}
                        />
                      </div>
                      <h1 className="text-white w-full text-sm sm:text-lg line-clamp-1 my-3 px-2 font-bold ">{item?.name}</h1>
                    </div>
                  ))}
              </MySlider>
            </div>
            <div className="w-full sm:hidden flex flex-row items-center overflow-x-auto sm:gap-x-5">
              {e
                .map((item, index) => (
                  <div onClick={()=>navigate('/AboutCelebrity',{state:{data:item}})}  key={index} className={`flex-none cursor-pointer relative bg-transparent/25 rounded-b-lg   ${isMobileView ? "w-[150px] h-auto mx-3" : "w-[250px] h-auto"}`}>
                    <div className="  overflow-hidden">
                      <img
                        src={'https://storage.googleapis.com/cinimini-console/celebrities/' +item.id + '/' + (JSON.parse(item.profilePictureUrls)[0])}
                        alt=""
                        className={`rounded-t-lg  transistion duration-500 object-cover  ${isMobileView ? "w-[180px] h-[200px]" : "w-[250px] h-[300px]"}`}
                      />
                    </div>
                    <h1 className="text-white w-full text-sm sm:text-lg line-clamp-1 my-3 px-2 font-bold ">{item?.name}</h1>
                  </div>
                ))}
            </div>
          </div>
        </div>:null
      )}
    </div>
  </>;
}

function CardItem({ title, index, img, link, rating, reviews, isMobileView, year, id }) {
  return (
    <div className="bg-gray-800 rounded-lg drop-shadow-md mr-2 m-2 mx-1" key={id}>
      <Link to={`/celebrity/${link}`}>
        <div className={`overflow-hidden relative bg-gray-700 rounded-tr-lg rounded-tl-lg ${isMobileView ? "w-36 h-40" : "w-56 h-56"}`}>
          {img &&
            <img
              src={img}
              alt=""
              className={`hover:scale-110 transistion duration-500 object-cover rounded-tr-lg rounded-tl-lg ${isMobileView ? "w-36 h-40" : "w-56 h-56"}`}
            />
          }

          {index &&
            <div
              className={`absolute top-0 left-5 ${isMobileView ? "w-6 h-8" : "w-8 h-16"} ml-2 text-center`}
              style={{
                backgroundImage: `url(${bookmark})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            >
              <span className={`text-gray-200 ${isMobileView ? "text-xs" : "text-md"}`}>{`#${index}`}</span>
            </div>
          }
        </div>

        <TextMarquee
          title={title}
          marqueeAfterLength={isMobileView ? 16 : 30}
          text_style={`text-gray-200 w-full text-center pt-1 ${isMobileView ? "text-xs" : "text-md"}`}
          div_style={`${isMobileView ? "w-36" : "w-56"}`}
        />

        <h1
          className={`text-gray-400 w-full text-center truncate ${isMobileView ? "text-xss -mt-1" : "text-xs"}`}
        >
          {year}
        </h1>

        <div className="flex justify-between items-center mt-1">
          <div className="flex-1 py-1 px-0.5 bg-gray-700 flex items-center justify-center mr-0.5 rounded-bl-lg">
            <BsStarFill className={`text-primary ${isMobileView ? "w-3 h-3" : "w-4 h-4"}`} />
            <TextAtom div_style="flex mx-1"
              text_style={`text-gray-400 ${isMobileView ? "text-xs" : "text-sm"}`}
              text={rating || 0} />
          </div>
          <div className="flex-1 py-1 px-0.5 bg-gray-700  flex items-center justify-center ml-0.5 rounded-br-lg">
            <FaUserCheck className={`text-primary ${isMobileView ? "w-3 h-3" : "w-4 h-4"}`} />
            <TextAtom div_style="flex mx-1"
              text_style={`text-gray-400 ${isMobileView ? "text-xs" : "text-sm"}`}
              text={reviews || 0} />
          </div>
        </div>
      </Link >
    </div >
  );
}

export { CelebrityCard };

{/* {list &&
        <>
          <span className={`text-gray-300 font-bold mx-2 ${isMobileView ? "text-sm" : "text-xl"}`}>Top 15 Stars</span>
          <MySlider isScrollActionEnabled={list.length > (isMobileView ? 3 : 6)} isMobileView={isMobileView}>
            {list
              .slice(0, 15)
              .map((item, index) => (
                <CardItem
                  key={`item_${index}`}
                  index={item.rank}
                  title={item.name}
                  img={item.cover_url}
                  link={item.id + (`-${item.rank}`)}
                  rating={item.rating}
                  reviews={item.reviewCount}
                  isMobileView={isMobileView}
                  year={item.year}
                  id={item.id}
                />
              ))}
          </MySlider>
        </>
      } */}

// {categorizedCelebrities &&
//   Object.entries(categorizedCelebrities)
//     .map(([key, value]) => (
//       <>
//         {value &&
//           <div className="w-full bg-[#131721] mb-5">
//             <div className="xl:w-[1700px] relative w-full mx-auto py-5">
//               <div key={key} className="flex  items-center justify-center mb-6">
//                 <span className={`text-gray-300 font-bold font-serif my-2 ${isMobileView ? "text-xl" : "text-4xl"}`}>{key}</span>
//                 {/* {value.length === 10 ? <Link to={`more/${key}`} className={`font-semibold text-primary  ${isMobileView ? "text-sm" : "text-md"}`}>More &gt;</Link> : <></>} */}
//               </div>
//               <div className=" flex flex-row items-center justify-center gap-x-3">
//                 {/* <div onClick={() => previousCards()} className="hover:bg-primary sm:block hidden border-2 cursor-pointer p-3 z-50  rounded-full">
//                   <FaAngleLeft className="w-6 h-6 text-white" />
//                 </div> */}
//                 <div ref={scrollDivRef} className="w-full flex flex-row items-center overflow-x-auto sm:gap-x-5">
//                   {value
//                     .map((item, index) => (
//                       // <CardItem
//                       //   key={`item_${index}`}
//                       //   index={index + 1}
//                       //   title={item.name}
//                       //   img={item.cover_url}
//                       //   link={item.id + (`-${index + 1}`)}
//                       //   rating={item.rating}
//                       //   reviews={item.reviewCount}
//                       //   isMobileView={isMobileView}
//                       //   year={item.year}
//                       //   id={item.id}
//                       // />
//                       <div onClick={() => navigate('/celebrity/' + item.id + '-' + (index + 1))} key={index} className={`flex-none cursor-pointer relative bg-transparent/25 rounded-b-lg   ${isMobileView ? "w-[150px] h-auto mx-3" : "w-[250px] h-auto"}`}>
//                         <div className="  overflow-hidden">
//                           <img
//                             src={item.cover_url}
//                             alt=""
//                             className={`rounded-t-lg  transistion duration-500 object-cover  ${isMobileView ? "w-[180px] h-[200px]" : "w-[250px] h-[300px]"}`}
//                           />
//                         </div>
//                         <h1 className="text-white w-full text-sm sm:text-lg line-clamp-1 my-3 px-2 font-bold ">{item?.name}</h1>
//                       </div>
//                     ))}

//                 </div>
//                 {/* <div onClick={() => nextCards()} className="hover:bg-primary border-2 sm:block hidden cursor-pointer p-3 z-50  rounded-full">
//                   <FaAngleRight className="w-6 h-6 text-white" />
//                 </div> */}
//               </div>
//             </div>
//           </div>
//         }
//       </>
//     ))
// }