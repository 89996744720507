import BrandTemplate from "../view/templates/BrandTemplate";

import { useIsMobileViewContext } from '../context/isMobileView';

function BrandPage() {
    const { isMobileView } = useIsMobileViewContext();

    return (
        <main className={`flex-grow min-h-screen bg-gray-900 ${isMobileView ? "pt-24" : ""}`}>
            <BrandTemplate />
        </main>
    );
}

export default BrandPage