import { useState, useEffect } from 'react'
import { FiEdit2 } from "react-icons/fi";
import { TextAtom } from "../view/atoms";

import { useAppDispatch, useAppSelector, uploadUser, uploadImageWithUser } from '../redux'

import LoadingIndicator from "../components/LoadingIndicator";

const dummyuserProfile = require("../assets/images/dummyUser.png")

function UserProfileEdit() {
  const dispatch = useAppDispatch()

  const user = useAppSelector(state => state.user.profile);
  const userError = useAppSelector(state => state.user.error);
  const isUserLoading = useAppSelector(state => state.user.isLoading);

  const [profileImage, setProfileImage] = useState(null)
  const [profileUrl, setProfileUrl] = useState("")
  const [userName, setUserName] = useState("")
  const [userEmail, setUserEmail] = useState("")
  const [formError, setFormError] = useState("");

  useEffect(() => {
    if (user) {
      if (user.name) setUserName(user.name);
      if (user.email) setUserEmail(user.email);
      if (user.cover_url) setProfileUrl(user.cover_url);
    }
  }, [user]);


  const handleUpdate = () => {
    setFormError("");
    if (profileImage) {
      dispatch(uploadImageWithUser({ userId: user.id, image: profileImage, name: userName, email: userEmail }))
    } else if (userName) {
      dispatch(uploadUser({ userId: user.id, name: userName, email: userEmail }))
    } else {
      setFormError("Name cannot be empty");
    }
  }

  const handleImage = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setProfileImage(file);
      setProfileUrl(reader.result);
    };
    reader.readAsDataURL(file);
  }

  return (
    user &&
    <div className="h-screen flex flex-col items-center p-6 w-full  bg-gray-900">
      <label for="profile">
        {profileUrl
          ? <img className="w-28 h-28 mb-2 object-cover rounded-full" src={profileUrl} alt="" />
          : <img className="w-28 h-28 mb-2 object-cover rounded-full" src={dummyuserProfile} alt="" />}
        <div className="flex items-center justify-center">
          <FiEdit2 className=" mr-2 w-4 h-4 text-gray-700" />
          <TextAtom text_style={"text-gray-500 "} text="EDIT" />
        </div>
      </label>

      <input id="profile" onChange={(e) => handleImage(e)} name="profile" accept="image/png, image/jpeg" className="hidden" type="file" />
      <input
        onChange={(e) => setUserName(e.target.value)}
        className="outline-none bg-gray-900 border-b border-gray-500 mt-6 text-gray-300 placeholder-gray-600"
        placeholder="Name"
        value={userName}
        type="text" />
      <input
        onChange={(e) => setUserEmail(e.target.value)}
        className="outline-none bg-gray-900 border-b border-gray-500 mt-6 text-gray-300 placeholder-gray-600"
        placeholder="Email"
        value={userEmail}
        type="text" />

      <span className="text-red-500 text-sm mt-2">{formError}</span>
      <span className="text-red-500 text-sm mt-2">{userError}</span>

      {isUserLoading
        ? <LoadingIndicator />
        : <button
          onClick={() => handleUpdate()}
          className="bg-primary rounded-sm py-1 px-2 mt-6 mb-2 text-sm text-gray-900"
        >
          UPLOAD
        </button>
      }
    </div>
  )
}

export default UserProfileEdit