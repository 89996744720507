import React from "react";
import {render} from "react-dom";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";

import "./index.css";

import App from "./App";

import {IsMobileViewProvider} from "./context/isMobileView";
import {AuthProvider} from "./context/auth";

import {store} from "./redux";

render(
  <React.StrictMode>
    <Provider store={store}>
      <IsMobileViewProvider>
        <AuthProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AuthProvider>
      </IsMobileViewProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
