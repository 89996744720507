import { useEffect, useState } from "react";

import { MovieCardList } from "../../components/MovieCardList";

import { useAppDispatch, useAppSelector, loadMovies, loadAdvertisement } from "../../redux";

import { useIsMobileViewContext } from '../../context/isMobileView';


function MoviesTemplate() {
  const dispatch = useAppDispatch();

  const { isMobileView } = useIsMobileViewContext();

  const [adsFilter, setAdsFilter] = useState(null)

  const movies = useAppSelector((state) => state.movie.movies);
  const celebrities = useAppSelector((state) => state.celebrity.celebrities);
  const isMovieEndReached = useAppSelector((state) => state.movie.isEndReached);
  const advertisements = useAppSelector((state) => state.advertisement.advertisements);

  useEffect(() => {
    if (!isMovieEndReached && movies.length <= 0)
      dispatch(loadMovies());

    if (advertisements.length <= 0)
      dispatch(loadAdvertisement())
  }, [dispatch]);

  useEffect(() => {
    setAdsFilter(advertisements.filter(item => item.type === "movies" && item.orientation === "horizontal"))
  }, [advertisements]);

  return (
    <div className="flex-grow bg-[#f5f5f5] ">
      <div className={`w-full mx-auto ${adsFilter && adsFilter.length > 1 ? isMobileView ? "px-6" : "px-10" : ""}`}>
        {/* {adsFilter && <BannerSlider ads={adsFilter} />} */}
      </div>
      {adsFilter && <MovieCardList list={adsFilter} />}
     
    </div>
  );
}

export { MoviesTemplate };
