import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

//icons
import { FaUserCheck } from "react-icons/fa";
import { TextAtom } from '../view/atoms'
import { BsStarFill } from "react-icons/bs";

import bookmark from '../assets/images/small_bookmark.png'

import { useIsMobileViewContext } from "../context/isMobileView";

import TextMarquee from "../view/atoms/TextMarquee";
import MySlider from "../components/MySlider";

function ShowsCard({ list }) {
  const { isMobileView } = useIsMobileViewContext();

  const [categorizedShows, setCategorizedShows] = useState([]);

  useEffect(() => {
    if (list) {
      let newCategorizedShows = [];
      list
        .slice(15, list.length)
        .forEach(item => {
          if (item.type && item.type.length > 0) {
            item.type.forEach(type => {
              if (newCategorizedShows[type] && newCategorizedShows[type].length < 10) {
                newCategorizedShows[type].push(item);
              } else {
                newCategorizedShows[type] = [];
                newCategorizedShows[type].push(item);
              }
            });
          }
        });
      setCategorizedShows(newCategorizedShows);
    }
  }, [list]);

  return <>
    <div className="flex flex-col w-full mt-2">
      {list &&
        <>
          <span className={`text-gray-300 font-bold mx-2 ${isMobileView ? "text-sm" : "text-xl"}`}>Top 15 Shows</span>
          <MySlider>
            {list
              .slice(0, 15)
              .map((item, index) => (
                <CardItem
                  key={`item_${index}`}
                  index={item.rank}
                  title={item.title}
                  img={item.cover_url}
                  link={item.id}
                  rating={item.rating}
                  reviews={item.reviewCount}
                  isMobileView={isMobileView}
                  year={item.releaseYear}
                  id={item.id}
                />
              ))}
          </MySlider>
        </>
      }

      {categorizedShows &&
        Object.entries(categorizedShows)
          .map(([key, value]) => (
            <>
              {value &&
                <>
                  <div key={key} className="flex items-center justify-between px-2">
                    <span className={`text-gray-300 font-bold mx-2 my-2 ${isMobileView ? "text-sm" : "text-xl"}`}>{key}</span>
                    {value.length === 10 ? <Link to={`shows/more/${key}`} className={`font-semibold text-primary mx-2 ${isMobileView ? "text-sm" : "text-md"}`}>More &gt;</Link> : <></>}
                  </div>
                  <MySlider>
                    {value
                      .map((item, index) => (
                        <CardItem key={`item_${item.rank}`}
                          index={index + 1}
                          title={item.title}
                          img={item.cover_url}
                          link={item.id}
                          rating={item.rating}
                          reviews={item.reviewCount}
                          isMobileView={isMobileView}
                          year={item.releaseYear}
                          id={item.id} />
                      ))}
                  </MySlider>
                </>
              }
            </>
          ))
      }
    </div>
  </>;
}

function CardItem({ index, title, img, link, rating, reviews, isMobileView, year, id }) {
  return (
    <div className="mr-2 m-2 mx-1 bg-gray-800 rounded-lg drop-shadow-md" key={id}>
      <Link to={`/shows/${link}-${index}`}>
        <div className={`overflow-hidden relative bg-gray-700 rounded-tr-lg rounded-tl-lg ${isMobileView ? "w-36 h-32" : "w-56 h-56"}`}>
          {img &&
            <img
              src={img}
              className={`hover:scale-110 transistion duration-500 object-cover rounded-tr-lg rounded-tl-lg ${isMobileView ? "w-36 h-32" : "w-56 h-56"}`}
              alt=""
            />
          }

          {index &&
            <div
              className={`absolute top-0 left-5 ${isMobileView ? "w-6 h-8" : "w-8 h-16"} ml-2 text-center`}
              style={{
                backgroundImage: `url(${bookmark})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            >
              <span className={`text-gray-200 ${isMobileView ? "text-xs" : "text-md"}`}>{`#${index}`}</span>
            </div>
          }
        </div>

        <TextMarquee
          title={title}
          marqueeAfterLength={isMobileView ? 16 : 30}
          text_style={`text-gray-200 w-full text-center pt-1 truncate ${isMobileView ? "text-xs" : "text-md"}`}
          div_style={`${isMobileView ? "w-36" : "w-56"}`} />

        <h1
          className={`text-gray-500 w-full text-center truncate -mt-1 ${isMobileView ? "text-xss" : "text-sm"
            }`}
        >
          {year}
        </h1>

        <div className="flex justify-between items-center mt-1">
          <div className="flex-1 py-1 px-0.5 bg-gray-700 flex items-center justify-center mr-0.5 rounded-bl-lg">
            <BsStarFill className={`text-primary ${isMobileView ? "w-3 h-3" : "w-4 h-4"}`} />
            <TextAtom div_style="flex mx-1"
              text_style={`text-gray-400 ${isMobileView ? "text-xs" : "text-sm"}`}
              text={rating || 0} />
          </div>
          <div className="flex-1 py-1 px-0.5 bg-gray-700  flex items-center justify-center ml-0.5 rounded-br-lg">
            <FaUserCheck className={`text-primary ${isMobileView ? "w-3 h-3" : "w-4 h-4"}`} />
            <TextAtom div_style="flex mx-1"
              text_style={`text-gray-400 ${isMobileView ? "text-xs" : "text-sm"}`}
              text={reviews || 0} />
          </div>
        </div>
      </Link>
    </div>
  );
}
export default ShowsCard;
