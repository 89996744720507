import { Link } from 'react-router-dom';

//icons
import { TextAtom } from "../view/atoms";
import TextMarquee from "../view/atoms/TextMarquee";

import { useIsMobileViewContext } from "../context/isMobileView";

function MovieBrandCard({ brand }) {
  const { isMobileView } = useIsMobileViewContext();

  return (
    <div className="mt-2">
      {brand
        ? <>
          <TextAtom
            text_style={`px-2 text-gray-300 ${isMobileView ? "text-sm" : "text-md"}`}
            text="Banner &amp; Production"
          />
          <div className="flex flex-wrap mx-4">
            {brand
              .map((item, index) => (
                <CardItem
                  key={`item_${index}`}
                  title={item.name}
                  img={item.cover_url}
                  tag={item.brandTag}
                  link={item.id}
                  isMobileView={isMobileView}
                />
              ))}
          </div>
        </>
        : <></>
      }
    </div>
  )
}

function CardItem({ title, img, tag, link, isMobileView }) {
  return (
    <Link to={`/brand/${link}`} className={`bg-gray-700 rounded-md drop-shadow-sm my-1 mx-1 ${isMobileView ? "w-20" : "w-32"}`}>
      {img ? (
        <div className={`w-full rounded-tr-md rounded-tl-md ${isMobileView ? "h-24 w-20" : "h-40 w-32"}`}
          style={{
            backgroundImage: `url(${img})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
        </div>
      ) : (
        <div className="h-20 bg-gray-700 rounded-tr-md rounded-tl-md"></div>
      )}
      <h1
        className={`w-full text-gray-200 text-center py-1 truncate -mb-3 ${isMobileView ? "text-xs" : "text-sm"}`}>
        {title}
      </h1>
      <TextMarquee
        title={tag?.toString()}
        marqueeAfterLength={15}
        text_style={`text-gray-400 ${isMobileView ? "text-xs" : "text-sm"}`}>
        {tag?.toString()}
      </TextMarquee>
    </Link>
  );
}

export default MovieBrandCard