import { ShowsDetailsTemplate } from '../view/templates/ShowsDetailsTemplate'

function ShowsDetailsPage() {
  return (
    <main className="flex-grow min-h-screen bg-gray-900">
      <ShowsDetailsTemplate />
    </main>
  )
}

export default ShowsDetailsPage