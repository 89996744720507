import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { RWebShare } from "react-web-share";

import Modal from '../../components/Modal';
import ModalGallery from "../../components/ModalGallery";

//Atom
import { TextAtom } from "../atoms";

import CastCard from "../../components/CastCard";

//image
import bookmark from "../../assets/images/small_bookmark.png";

//icons
import { FaShareSquare } from "react-icons/fa";
import { BsStarFill } from "react-icons/bs";
import { AiOutlineStar } from "react-icons/ai";
import { FaUserCheck } from "react-icons/fa";
import { GiClapperboard } from 'react-icons/gi';
import { BsCameraReels, BsTwitter, BsFacebook, BsInstagram, BsYoutube, BsGlobe } from 'react-icons/bs';

import { useAuthContext } from "../../context/auth";
import { useIsMobileViewContext } from "../../context/isMobileView";

import { useAppDispatch, useAppSelector, addShowRating, updateShowRating, updateUserRatingCount } from "../../redux";

import { hasUserAlreadyRated } from "../../service/show.service";
import { loadUserReview } from "../../service/review.service";

function ShowsDetails({ rank, show_id, show, categories, info, cast }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const { isUserLoggedIn } = useAuthContext();
  const { isMobileView } = useIsMobileViewContext();

  const [openModal, setOpenModal] = useState(false);
  const [openGalleryModal, setOpenGalleryModal] = useState(false);
  const [userReviewStatus, setUserReviewStatus] = useState(false);
  const [rating, setRating] = useState(show.rating);
  const [userRatingStatus, setUserRatingStatus] = useState(false)
  const [userOldRating, setUserOldRating] = useState();

  const user = useAppSelector(state => state.user.profile)

  useEffect(() => {
    const getUserRatingStatus = async () => {
      hasUserAlreadyRated(show_id, user.id)
        .then(result => {
          setUserOldRating(result);
          setUserRatingStatus(result && result > 0);
        })
        .catch(_ => setUserRatingStatus(false));
    }

    const getUserReviewStatus = async () => {
      loadUserReview("show", show_id, user.id)
        .then(result => {
          if (result !== null) {
            setUserReviewStatus(true);
          } else {
            setUserReviewStatus(false);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    if (show_id && user.id) {
      getUserRatingStatus();
      getUserReviewStatus();
    }
  }, [show_id, user.id]);

  const handleStarClick = () => {
    if (isUserLoggedIn) setOpenModal(true)
    else navigate("/login");
  };

  const handleRating = (newRating) => {
    if (newRating) {
      const data = {
        newUserRating: newRating,
        showId: show_id,
        userId: user.id,
        oldRating: rating,
        oldUserRating: userOldRating,
        newRating: rating + newRating
      };

      if (userRatingStatus) {
        const ratingDifference =
          Math.max(newRating, userOldRating) -
          Math.min(newRating, userOldRating);

        if (ratingDifference > 0) {
          if (newRating > userOldRating) {
            setRating(rating + ratingDifference);
            dispatch(updateUserRatingCount(ratingDifference));
          } else {
            setRating(rating - Math.abs(ratingDifference));
            dispatch(updateUserRatingCount(-Math.abs(ratingDifference)));
          }
        } else {
          setRating(newRating);
          dispatch(updateUserRatingCount(newRating));
        }

        dispatch(updateShowRating(data));
      }
      else {
        setRating(rating + newRating);
        dispatch(updateUserRatingCount(newRating));
        dispatch(addShowRating(data));
      }

      setOpenModal(false)
      setUserRatingStatus(true)
    }
  }

  const closeModal = () => {
    setOpenModal(false)
  }

  const handleGalleryModalOpen = () => {
    setOpenGalleryModal(true);
  }

  const handleGalleryModalClose = () => {
    setOpenGalleryModal(false);
  }

  const handleReviewClick = () => {
    if (isUserLoggedIn) navigate(`/review/shows/${show.id}`);
    else navigate("/login");
  };

  return (
    <>
      <Modal defaultRating={userOldRating} handleRating={handleRating} closeModal={closeModal} openModal={openModal} />

      <ModalGallery
        gallery={show.gallery}
        openModal={openGalleryModal}
        handleCloseClick={handleGalleryModalClose} />

      <div className="bg-gray-800 pt-1 pb-5">
        <div className="mx-2 bg-gray-800 rounded-md">
          <div className="flex justify-between p-2">
            <div className="flex items-center">
              {rank > 0 ?
                <div
                  className={`${isMobileView ? "w-6 h-8" : "w-10 h-12"} text-center`}
                  style={{
                    backgroundImage: `url(${bookmark})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <span className={`text-gray-200 ${isMobileView ? "text-xs" : "text-md"}`}>{`#${rank}`}</span>
                </div>
                : <></>}

              <div className="ml-2 -mt-1">
                <TextAtom
                  text_style="text-gray-300 text-lg font-semibold"
                  text={show.title}
                />
                <TextAtom
                  div_style="-mt-2"
                  text_style="text-gray-400 text-xs"
                  text={show.releaseYear}
                />
              </div>
            </div>

            <div className="cursor-pointer">
              <RWebShare
                data={{
                  text: "StarPot",
                  url: `https://starpot.in${location.pathname}`,
                  title: `${show.title}`,
                }}
              >
                <FaShareSquare
                  className="w-6 h-6 text-primary"
                />
              </RWebShare>
            </div>
          </div>

          <div className="flex flex-wrap justify-between mx-1">
            <div className="flex flex-row flex-wrap mt-1">
              {categories && categories.map((item) => (
                <TextAtom
                  div_style="mx-1 rounded px-2 py-1 border border-gray-700 flex items-center"
                  text_style={`text-gray-300 ${isMobileView ? "text-xss" : "text-xs"}`}
                  text={item}
                />
              ))}
            </div>

            <div className="flex mt-1">
              {show.trailerLink &&
                <a className="flex items-center border-1 border-gray-700 rounded-md px-2 mx-1 bg-gray-900 cursor-pointer"
                  href={show.trailerLink}
                  target="_blank" rel="noreferrer"
                >
                  <GiClapperboard className="text-gray-400" />
                  <TextAtom
                    div_style="mx-2"
                    text_style={`text-gray-400 font-semibold ${isMobileView ? "text-xs" : "text-sm"}`}
                    text="Play Trailer"
                  />
                </a>
              }
              {show.watchLink &&
                <a className="flex items-center border-1 border-gray-700 rounded-md px-2 mx-1 bg-gray-900 cursor-pointer"
                  href={show.watchLink}
                  target="_blank" rel="noreferrer"
                >
                  <BsCameraReels className="text-gray-400" />
                  <TextAtom
                    div_style="mx-2"
                    text_style={`text-gray-400 font-semibold ${isMobileView ? "text-xs" : "text-sm"}`}
                    text="Watch On"
                  />
                </a>
              }
            </div>
          </div>

          <div className="relative my-2 flex flex-col items-center">
            <img src={show.cover_url} alt="" className={`rounded-lg ${isMobileView ? "w-full" : "h-[38rem]"}`} />
            {show.gallery && show.gallery.length > 0 &&
              <span className={`absolute bottom-0 right-0 m-2 p-1 rounded bg-gray-900 
              text-gray-100 border border-primary cursor-pointer ${isMobileView ? "text-xs" : "text-md"}`}
                onClick={handleGalleryModalOpen}>
                More Images
              </span>
            }
          </div>

          <div className="flex justify-center items-center mt-2">
            <div
              className="bg-gray-700 flex items-center justify-center py-1 px-12 mx-1 rounded-md border-1 border-gray-600 cursor-pointer"
              onClick={handleStarClick}
            >
              {userRatingStatus ?
                <BsStarFill className={`text-primary ${isMobileView ? "w-3 h-3" : "w-5 h-5"}`} /> :
                <AiOutlineStar className={`text-gray-400 ${isMobileView ? "w-3 h-3" : "w-5 h-5"}`} />}
              <TextAtom
                div_style="flex mx-1"
                text_style={`text-gray-400 ${isMobileView ? "text-xs font-semibold" : "text-sm font-semibold"}`}
                text={rating || 0}
              />
            </div>

            <div
              className="bg-gray-700  flex items-center justify-center py-1 px-12 mx-1 rounded-md border-1 border-gray-600 cursor-pointer"
              onClick={handleReviewClick}
            >
              <FaUserCheck className={`${userReviewStatus ? "text-primary" : "text-gray-400"} ${isMobileView ? "w-3 h-3" : "w-5 h-5"}`} />
              <TextAtom
                div_style="flex mx-1"
                text_style={`text-gray-400 ${isMobileView ? "text-xs font-semibold" : "text-sm font-semibold"}`}
                text={show.reviewCount || 0}
              />
            </div>
          </div>

          {show.description && <TextAtom
            div_style="flex justify-center p-2"
            text_style={`text-gray-500 text-center text-justify ${isMobileView ? "text-xs" : "text-sm"}`}
            text={show.description}
          />}

          <div className="border-gray-700 border-t-1" />

          <TextAtom
            div_style="pt-2"
            text_style={`px-2 text-gray-300 ${isMobileView ? "text-sm" : "text-md"}`}
            text="Film Details"
          />

          {show.seasons && <DetailText qus="Seasons" ans={show.seasons} isMobileView={isMobileView} />}
          {show.type && <DetailText qus="Type" ans={show.type.toString()} isMobileView={isMobileView} />}
          {info && info.length > 0 ? (
            <>
              {info.map((item) => (
                <DetailText qus={item.title} ans={item.description} isMobileView={isMobileView} />
              ))}

              <div className="border-gray-700 border-t-1 mt-2" />
            </>
          ) : (
            <></>
          )}

          {cast && cast.length > 0 && <CastCard cast={cast} />}

          <div className="flex flex-wrap justify-end items-center pt-5 mr-5">
            {show.twitterUrl &&
              <a href={show.twitterUrl} target="_blank" rel="norefer noreferrer">
                <BsTwitter className={`mx-2 cursor-pointer text-gray-400 hover:scale-110 duration-300 ${isMobileView ? "w-5 h-5" : "w-6 h-6"}`} />
              </a>
            }

            {show.facebookUrl &&
              <a href={show.facebookUrl} target="_blank" rel="norefer noreferrer">
                <BsFacebook className={`mx-2 cursor-pointer text-gray-400 hover:scale-110 duration-300 ${isMobileView ? "w-5 h-5" : "w-6 h-6"}`} />
              </a>
            }

            {show.instagramUrl &&
              <a href={show.instagramUrl} target="_blank" rel="norefer noreferrer">
                <BsInstagram className={`mx-2 cursor-pointer text-gray-400 hover:scale-110 duration-300 ${isMobileView ? "w-5 h-5" : "w-6 h-6"}`} />
              </a>
            }

            {show.youtubeUrl &&
              <a href={show.youtubeUrl} target="_blank" rel="norefer noreferrer">
                <BsYoutube className={`mx-2 cursor-pointer text-gray-400 hover:scale-110 duration-300 ${isMobileView ? "w-5 h-5" : "w-6 h-6"}`} />
              </a>
            }

            {show.websiteUrl &&
              <a href={show.websiteUrl} target="_blank" rel="norefer noreferrer">
                <BsGlobe className={`mx-2 cursor-pointer text-gray-400 hover:scale-110 duration-300 ${isMobileView ? "w-5 h-5" : "w-6 h-6"}`} />
              </a>
            }
          </div>
        </div>
      </div>
    </>

  );
}

function DetailText({ qus, ans, isMobileView }) {
  return (
    <div className="flex flex-wrap items-center mx-4">
      <TextAtom text_style={`text-gray-300 ${isMobileView ? "text-xs" : "text-sm"}`} text={qus} />
      <TextAtom text_style="text-gray-300 text-xs" text="&nbsp;:&nbsp;" />
      <TextAtom
        text_style={`text-gray-500 font-semibold ${isMobileView ? "text-xs" : "text-sm"}`}
        text={ans}
      />
    </div>
  );
}
export { ShowsDetails }