import BackArrowNavigation from "../components/BackArrowNavigation";

function CreateReviewPage() {
    return (
        <main className="min-h-screen bg-gray-900">
            <BackArrowNavigation />

            <div className="text-gray-200 pt-12 px-2 flex flex-col items-center">
                <div className="flex flex-col items-center">
                    <span className="text-sm text-primary">Coming Soon...</span>
                </div>
            </div>
        </main>
    )
}

export default CreateReviewPage