import React from 'react'

function LoadingIndicator() {
    return (
        <div className="flex flex-row m-4">
            <div className="w-2 h-2 m-1 bg-gray-200 animate-spin"></div>
            <div className="w-2 h-2 m-1 bg-gray-200 animate-spin"></div>
            <div className="w-2 h-2 m-1 bg-gray-200 animate-spin"></div>
        </div>
    )
}

export default LoadingIndicator 