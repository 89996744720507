import { Link } from "react-router-dom";

//icons
import { TextAtom } from "../view/atoms";
import TextMarquee from "../view/atoms/TextMarquee";

import { useIsMobileViewContext } from "../context/isMobileView";

function CastCard({ cast }) {
  const { isMobileView } = useIsMobileViewContext();

  return (
    <div className="mt-2">
      {cast
        .filter(item => item.castType === "crew").length > 0
        ? <>
          <TextAtom
            text_style={`px-2 text-gray-300 ${isMobileView ? "text-sm" : "text-md"}`}
            text="Crew"
          />
          <div className="flex flex-wrap mx-4">
            {cast
              .filter(item => item.castType === "crew")
              .map((item, index) => (
                <CardItem
                  key={`item_${index}`}
                  title={item.name}
                  img={item.cover_url}
                  tag={item.castTag}
                  link={item.id}
                  isMobileView={isMobileView}
                />
              ))}
          </div>
        </>
        : <></>}

      {cast
        .filter(item => item.castType === "cast").length > 0
        ?
        <>
          <TextAtom
            text_style={`px-2 text-gray-300 ${isMobileView ? "text-sm" : "text-md"}`}
            text="Cast"
          />
          <div className="flex flex-wrap mx-4">
            {cast
              .filter(item => item.castType === "cast")
              .map((item, index) => (
                <CardItem
                  key={`item_${index}`}
                  title={item.name}
                  img={item.cover_url}
                  tag={item.castTag}
                  link={item.id}
                  isMobileView={isMobileView}
                />
              ))}
          </div>
        </>
        : <></>}
    </div>
  );
}

function CardItem({ title, img, tag, link, isMobileView }) {
  return (
    <Link to={`/celebrity/${link}`} className={`bg-gray-700 rounded-xl my-1 relative mx-1 ${isMobileView ? "w-20" : "w-72"}`}>
      {img ? (
        <div className={`w-full rounded-tr-xl rounded-tl-xl ${isMobileView ? "h-24 w-20" : "h-[350px] w-72"}`}
          style={{
            backgroundImage: `url(${img})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "top",
          }}
        >
        </div>
      ) : (
        <div className="h-20 bg-gray-700 rounded-tr-md rounded-tl-md"></div>
      )}
      <h1
        className={`w-full text-gray-200 text-center py-1 truncate -mb-3 ${isMobileView ? "text-xs" : "text-sm"}`}>
        {title}
      </h1>
      <TextMarquee
        title={tag?.toString()}
        marqueeAfterLength={15}
        text_style={`text-gray-400 ${isMobileView ? "text-xs" : "text-sm"}`}>
        {tag?.toString()}
      </TextMarquee>
      {/* <div className="absolute hover:backdrop-blur-lg  duration-500 rounded-xl w-full h-full top-0 ">
          <h1 className="text-white">Name</h1>
      </div> */}
    </Link >
  );
}

export default CastCard;
