import { ShowsTemplate } from "../view/templates";

import { useIsMobileViewContext } from '../context/isMobileView';

function ShowsPage() {
    const { isMobileView } = useIsMobileViewContext();

    return (
        <main className={`flex-grow min-h-screen bg-gray-900 ${isMobileView ? "pt-24" : ""}`}>
            <ShowsTemplate />
        </main>
    );
}

export default ShowsPage;
