import { Link } from "react-router-dom";

import BackArrowNavigation from "../components/BackArrowNavigation";

function MorePage() {
  return (
    <main className="min-h-screen bg-gray-900">
      <BackArrowNavigation />

      <div className="flex flex-col items-center pt-12">
        {/* <Link to="/settings" className="w-full border-t border-gray-700 py-3 px-6">
          <span className="text-gray-100 text-md">Settings</span>
        </Link> */}
        <Link to="/terms" className="w-full border-t border-gray-700 py-3 px-6">
          <span className="text-gray-100 text-md">Terms &amp; Conditions</span>
        </Link>
        <Link to="/privacy" className="w-full border-t border-gray-700 py-3 px-6">
          <span className="text-gray-100 text-md">Privacy Policy</span>
        </Link>
        {/* <Link to="/ref-earn" className="w-full border-t border-b border-gray-700 py-3 px-6">
          <span className="text-gray-100 text-md">Ref &amp; Earn</span>
        </Link> */}
        <Link to="/advertise" className="w-full border-t border-b border-gray-700 py-3 px-6">
          <span className="text-gray-100 text-md">Advertise with us</span>
        </Link>
        <Link to="/help" className="w-full border-t border-b border-gray-700 py-3 px-6">
          <span className="text-gray-100 text-md">Help</span>
        </Link>
        {/* <a href="https://firebasestorage.googleapis.com/v0/b/starpot-d121c.appspot.com/o/app-release.apk?alt=media&token=1ec9e86b-5bf3-463b-9b22-10d52d383a32" className="w-full border-t border-b border-gray-700 py-3 px-6">
          <span className="text-gray-100 text-md">Google Playstore</span>
        </a> */}
      </div>
    </main>
  )
}

export default MorePage