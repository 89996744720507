import { Routes, Route, useLocation } from "react-router-dom";

import "./App.css";

import MovieDetailsPage from "./pages/MovieDetailsPage";
import NewsDetailsPage from "./pages/NewsDetailsPage";
import Login from "./pages/Login";
import Register from "./pages/Register";
import NotExistPage from "./pages/NotExistPage";
import CelebrityDetailsPage from "./pages/CelebrityDetailsPage";
import ReviewPage from "./pages/ReviewPage";
import ProductDetails from "./pages/ProductDetails";
import UserProfile from "./pages/UserProfile";
import UserProfileEdit from "./pages/UserProfileEdit";
import ShowsDetailsPage from "./pages/ShowsDetailsPage";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CelebrityPage from "./pages/CelebrityPage";
import ShowsPage from "./pages/ShowsPage";
import TopNewsPage from "./pages/TopNewsPage";
import MoviesPage from "./pages/MoviesPage";
import TopReviewPage from "./pages/TopReviewPage";
import ProductPage from "./pages/ProductPage";
import BrandPage from "./pages/BrandPage";
import BrandDetailPage from "./pages/BrandDetailsPage";
import MyReviewsPage from "./pages/MyReviewsPage";
import MyPointsPage from "./pages/MyPointsPage";
import NotificationsPage from "./pages/NotificationsPage";
import MorePage from "./pages/MorePage";
import SettingsPage from "./pages/SettingsPage";
import RefEarnPage from "./pages/RefEarnPage";
import AdvertisePage from "./pages/AdvertisePage";
import UpgradePage from "./pages/UpgradePage";
import HelpPage from "./pages/HelpPage";
import CreateReviewPage from "./pages/CreateReviewPage";
import SearchPage from "./pages/SearchPage";
import MoreMoviesPage from "./pages/MoreMoviesPage";
import MoreBrandsPage from "./pages/MoreBrandsPage";
import MoreProductsPage from "./pages/MoreProductsPage";
import MoreCelebritiesPage from "./pages/MoreCelebritiesPage";
import MoreShowsPage from "./pages/MoreShowsPage";
import HomePage from './pages/HomePage';
import Header from "./components/Header";
import Footer from "./components/Footer";
import DesktopFooter from "./components/DesktopFooter";

import { useAuthContext } from "./context/auth";
import { useIsMobileViewContext } from "./context/isMobileView";

import NavigationMobile from "./components/NavigationMobile";

import { InputWithSearchIcon } from "./view/molecules";
import { useEffect, useState } from "react";
import Offers from "./pages/Offers";
import JobPage from "./pages/JobPage";
import JobpageDescription from "./pages/JobpageDescription";
import MovieDetailPage from "./view/templates/MovieDetailPage";
import OffersPostingPage from "./pages/OffersPostingPage";

function App() {
  const { isUserLoggedIn } = useAuthContext();

  const { isMobileView } = useIsMobileViewContext();

  const location = useLocation();

  const [HeaderBackground, setHeaderBackground] = useState('bg-transparent')
  const [HeaderText, setHeaderText] = useState('text-white')
  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      window.scrollBy(0, 0)
      let y = window.scrollY;
      // console.log(y);
      if (y > 10) {
        setHeaderText('text-white')
        setHeaderBackground('bg-[#1a202c]')
      } else {
        setHeaderBackground('bg-transparent')
        setHeaderText('text-white')
      }
    })

    

  }, [])
  return (
    <>
      {location.pathname.slice(
        location.pathname.lastIndexOf("/") + 1,
        location.pathname.length
      ) === "login" ||
        location.pathname.slice(
          location.pathname.lastIndexOf("/") + 1,
          location.pathname.length
        ) === "register" ||
        location.pathname.slice(
          location.pathname.lastIndexOf("/") + 1,
          location.pathname.length
        ) === "not-exist" ||
        location.pathname.slice(
          location.pathname.lastIndexOf("/") + 1,
          location.pathname.length
        ) === "more" ||
        location.pathname.slice(
          location.pathname.lastIndexOf("/") + 1,
          location.pathname.length
        ) === "settings" ||
        location.pathname.slice(
          location.pathname.lastIndexOf("/") + 1,
          location.pathname.length
        ) === "ref-earn" ||
        location.pathname.slice(
          location.pathname.lastIndexOf("/") + 1,
          location.pathname.length
        ) === "advertise" ||
        location.pathname.slice(
          location.pathname.lastIndexOf("/") + 1,
          location.pathname.length
        ) === "create-review" ||
        location.pathname.slice(
          location.pathname.lastIndexOf("/") + 1,
          location.pathname.length
        ) === "upgrade" ||
        location.pathname.slice(
          location.pathname.lastIndexOf("/") + 1,
          location.pathname.length
        ) === "help" ? (
        <></>
      ) : (
        <>
          <Header HeaderBackground={HeaderBackground} HeaderText={HeaderText}/>
          {location.pathname.slice(
            location.pathname.lastIndexOf("/") + 1,
            location.pathname.length
          ) !== "profile" &&
            location.pathname.slice(
              location.pathname.lastIndexOf("/") + 1,
              location.pathname.length
            ) !== "profile_edit" &&
            location.pathname.slice(
              location.pathname.lastIndexOf("/") + 1,
              location.pathname.length
            ) !== "my-reviews" &&
            location.pathname.slice(
              location.pathname.lastIndexOf("/") + 1,
              location.pathname.length
            ) !== "points" &&
            location.pathname.slice(
              location.pathname.lastIndexOf("/") + 1,
              location.pathname.length
            ) !== "notification" &&
            location.pathname.slice(
              location.pathname.lastIndexOf("/") + 1,
              location.pathname.length
            ) !== "settings" &&
            location.pathname.slice(
              location.pathname.lastIndexOf("/") + 1,
              location.pathname.length
            ) !== "ref-earn" &&
            location.pathname.slice(
              location.pathname.lastIndexOf("/") + 1,
              location.pathname.length
            ) !== "advertise" &&
            location.pathname.slice(
              location.pathname.lastIndexOf("/") + 1,
              location.pathname.length
            ) !== "create-review" &&
            location.pathname.slice(
              location.pathname.lastIndexOf("/") + 1,
              location.pathname.length
            ) !== "terms" &&
            location.pathname.slice(
              location.pathname.lastIndexOf("/") + 1,
              location.pathname.length
            ) !== "privacy" &&
            location.pathname.slice(
              location.pathname.lastIndexOf("/") + 1,
              location.pathname.length
            ) !== "search" &&
            location.pathname.split("/").length <= 2 &&
            isMobileView ? (
            <div className="fixed top-10 w-full z-[99]  bg-transparent">
              <InputWithSearchIcon />
              {HeaderBackground!=='bg-[#1a202c]'?<NavigationMobile />:null}
            </div>
          ) : (
            <></>
          )}
        </>
      )}
      <Routes>
        {!isUserLoggedIn ? (
          <>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/not-exist" element={<NotExistPage />} />
          </>
        ) : (
          <></>
        )}
        <Route path="/movies" element={<MoviesPage />} />
        <Route path="/movie/more/:id" element={<MoreMoviesPage />} />
        <Route path="/movie/:id" element={<MovieDetailsPage />} />
        <Route path="/top-news" element={<TopNewsPage />} />
        <Route path="/news/:id" element={<NewsDetailsPage />} />
        <Route path="/celebrity/more/:id" element={<MoreCelebritiesPage />} />
        <Route path="/AboutCelebrity" element={<CelebrityDetailsPage />} />
        <Route path="/celebrity" element={<CelebrityPage />} />
        <Route path="/product/more/:id" element={<MoreProductsPage />} />
        <Route path="/product/:id" element={<ProductDetails />} />
        <Route path="/product" element={<ProductPage />} />
        <Route path="/brand/more/:id" element={<MoreBrandsPage />} />
        <Route path="/brand/:id" element={<BrandDetailPage />} />
        <Route path="/brand" element={<BrandPage />} />
        <Route path="/shows/more/:id" element={<MoreShowsPage />} />
        <Route path="/shows/:id" element={<ShowsDetailsPage />} />
        <Route path="/shows" element={<ShowsPage />} />
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/profile_edit" element={<UserProfileEdit />} />
        <Route path="/top-review" element={<TopReviewPage />} />
        <Route path="/review/:type/:id" element={<ReviewPage />} />
        <Route path="/terms" element={<TermsConditions />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/Offers" element={<Offers />} />
        <Route path="/JobPage" element={<JobPage />} />
        <Route path="/JobpageDescription" element={<JobpageDescription />} />
        <Route path="/MovieDetailPage" element={<MovieDetailPage />} />
        <Route path="/OffersPostingPage" element={<OffersPostingPage />} />
        {isMobileView ? (
          <>
            <Route path="/my-reviews" element={<MyReviewsPage />} />
            <Route path="/points" element={<MyPointsPage />} />
            <Route path="/notification" element={<NotificationsPage />} />
            <Route path="/more" element={<MorePage />} />
            <Route path="/create-review" element={<CreateReviewPage />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/ref-earn" element={<RefEarnPage />} />
            <Route path="/advertise" element={<AdvertisePage />} />
            <Route path="/upgrade" element={<UpgradePage />} />
            <Route path="/help" element={<HelpPage />} />
          </>
        ) : (
          <></>
        )}
        <Route path="/" element={<HomePage />} />
      </Routes>

      {isMobileView ? <Footer /> : window.location.href==='http://localhost:3000/'?null:<DesktopFooter />}
    </>
  );
}

export default App;
