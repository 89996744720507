import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";

//icons
import { FaUserCheck } from "react-icons/fa";
import { BsStarFill } from "react-icons/bs";

import bookmark from '../assets/images/small_bookmark.png'

import { useAppDispatch, useAppSelector, loadCelebrityForCategory } from "../redux";

import { useIsMobileViewContext } from '../context/isMobileView';

import BackArrowNavigation from "../components/BackArrowNavigation";

import { TextAtom } from '../view/atoms'
import TextMarquee from '../view/atoms/TextMarquee';

function MoreCelebritiesPage() {
  const { id } = useParams();

  const dispatch = useAppDispatch();

  const { isMobileView } = useIsMobileViewContext();

  const categorizedCelebrities = useAppSelector(state => state.celebrity.categorizedCelebrities);

  useEffect(() => {
    if (id && !categorizedCelebrities[id]) {
      dispatch(loadCelebrityForCategory(id));
    }
  }, [id]);

  return (
    <main className="min-h-screen bg-gray-900 flex-grow py-2 px-3">
      <BackArrowNavigation />

      <div className="flex flex-col items-center">
        <div className="max-w-3xl grid grid-cols-3 gap-2">
          {categorizedCelebrities && id && categorizedCelebrities[id] &&
            categorizedCelebrities[id].map(((item, index) => (
              <CardItem key={`item_${index}`}
                index={index + 1}
                title={item.name}
                img={item.cover_url}
                link={item.id + (`-${index + 1}`)}
                rating={item.rating}
                reviews={item.reviewCount}
                isMobileView={isMobileView}
                year={item.year}
                id={item.id} />
            )))
          }
        </div>
      </div>
    </main>
  )
}

function CardItem({ title, index, img, link, rating, reviews, isMobileView, year, id }) {
  return (
    <div className="bg-gray-800 rounded-lg drop-shadow-md" key={id}>
      <Link to={`/celebrity/${link}`}>
        <div className={`overflow-hidden relative bg-gray-700 rounded-tr-lg rounded-tl-lg ${isMobileView ? "h-40" : "h-56"}`}>
          {img &&
            <img
              src={img}
              alt=""
              className={`w-full hover:scale-110 transistion duration-500 object-cover rounded-tr-lg rounded-tl-lg ${isMobileView ? "h-40" : "h-56"}`}
            />
          }

          {index &&
            <div
              className={`absolute top-0 left-5 ${isMobileView ? "w-6 h-8" : "w-8 h-16"} ml-2 text-center`}
              style={{
                backgroundImage: `url(${bookmark})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            >
              <span className={`text-gray-200 ${isMobileView ? "text-xs" : "text-md"}`}>{`#${index}`}</span>
            </div>
          }
        </div>

        <TextMarquee
          title={title}
          marqueeAfterLength={isMobileView ? 16 : 30}
          text_style={`text-gray-200 w-full text-center pt-1 ${isMobileView ? "text-xs" : "text-md"}`}
        />

        <h1
          className={`text-gray-400 w-full text-center truncate ${isMobileView ? "text-xss -mt-1" : "text-xs"
            }`}
        >
          {year}
        </h1>

        <div className="flex justify-between items-center mt-1">
          <div className="flex-1 py-1 px-0.5 bg-gray-700 flex items-center justify-center mr-0.5 rounded-bl-lg">
            <BsStarFill className={`text-primary ${isMobileView ? "w-3 h-3" : "w-4 h-4"}`} />
            <TextAtom div_style="flex mx-1"
              text_style={`text-gray-400 ${isMobileView ? "text-xs" : "text-sm"}`}
              text={rating || 0} />
          </div>
          <div className="flex-1 py-1 px-0.5 bg-gray-700  flex items-center justify-center ml-0.5 rounded-br-lg">
            <FaUserCheck className={`text-primary ${isMobileView ? "w-3 h-3" : "w-4 h-4"}`} />
            <TextAtom div_style="flex mx-1"
              text_style={`text-gray-400 ${isMobileView ? "text-xs" : "text-sm"}`}
              text={reviews || 0} />
          </div>
        </div>
      </Link >
    </div >
  );
}

export default MoreCelebritiesPage