import { MoviesTemplate } from "../view/templates";

import { useIsMobileViewContext } from '../context/isMobileView';
import { useEffect } from "react";

function MoviesPage() {
    const { isMobileView } = useIsMobileViewContext();
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'auto' });
    }, []);

    return (
        <main className={`flex-grow min-h-screen bg-gray-900 ${isMobileView ? "" : ""}`}>
            <MoviesTemplate/>
        </main>
    );
}

export default MoviesPage;
