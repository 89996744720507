import { useState, useEffect } from 'react'
import { TextAtom } from "../view/atoms";
import { AiOutlineClose } from "react-icons/ai";

import Rating from '@mui/material/Rating';

function Modal({ defaultRating, openModal, closeModal, handleRating }) {
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(defaultRating);
  }, [defaultRating]);

  return (
    openModal ?
      <div className="flex justify-center ">
        <div className=" bg-opacity-5 fixed top-0 z-50 p-5 sm:w-full md:w-144 h-screen">
          <div className=" h-full flex justify-center items-center opacity-100 ">
            <div className="bg-gray-700 w-full py-2 rounded-lg">
              <div className="flex items-center justify-between mx-5">
                <TextAtom text_style="text-gray-400" text="Review" />
                <AiOutlineClose onClick={() => closeModal()} className="text-white cursor-pointer" />
              </div>

              <div className="flex py-2 justify-around mx-10">
                <Rating
                  className="px-2 text-gray-200"
                  size="large"
                  name="simple-controlled"
                  value={value}
                  onChange={(_, newValue) => {
                    setValue(newValue);
                  }}
                  style={{color:'#00c1f8'}}
                />
              </div>

              <div className="flex justify-end mx-10">
                <div onClick={() => handleRating(value)}>
                  <TextAtom text_style="text-gray-400 text-sm cursor-pointer" text="OK" /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      : null
  )
}

export default Modal