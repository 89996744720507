import { configureStore } from "@reduxjs/toolkit";

import { rootReducer } from "./slices";

export const store = configureStore({
  devTools: true,
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "movie/loadMovies/fulfilled",
          "product/loadProduct/fulfilled",
          "celebrity/loadCelebrity/fulfilled",
          "topReview/loadTopReview/fulfilled",
          "advertisement/loadAdvertisement/fulfilled",
          "advertisement/loadAdvertisement/pending",
          "user/loadUser/fulfilled",
          "user/loadUser/pending",
          "topReview/loadTopReview/pending",
          "topReview/loadTopReview/fulfilled",
          "news/loadNews/fulfilled",
          "news/loadNews/pending",
          "user/uploadImageWithUser/fulfilled",
          "celebrity/loadCelebrityForCategory/fulfilled",
        ],
      },
    }),
});
