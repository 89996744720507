import { useEffect, useState } from "react";

import BannerSlider from "../../components/banner_slider";
import NewsCard from "../../components/NewsCard";
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector, loadNews, updateUserRatingCount, updateNewsRating } from "../../redux";
import { doc, updateDoc, onSnapshot, getDoc } from "firebase/firestore";
import { useIsMobileViewContext } from '../../context/isMobileView';
import Modal from "../../components/Modal";
import { db } from "../../firebase";
import headerphoto1 from '../../assets/images/webbann.png'
import leftbanner from '../../assets/images/leftbanner.jpg'
import { AiOutlineStar } from "react-icons/ai";
import ad2 from '../../assets/images/ad2.jpg'
import { FaRegCommentAlt, FaUserCheck } from "react-icons/fa";
import { BiSolidLike } from "react-icons/bi";
import { BiLike } from "react-icons/bi";
import sharestr from '../../assets/images/sharestr.png'
import likefill from '../../assets/images/likefill.png'
import { RWebShare } from "react-web-share";
function NewsTemplate() {
  const dispatch = useAppDispatch();

  const { isMobileView } = useIsMobileViewContext();

  const [adsList, setAdsList] = useState(null)

  const news = useAppSelector((state) => state.news.news);
  const [TamilNewsArray, setTamilNewsArray] = useState([])
  const [FilterArray, setFilterArray] = useState([])
  const [EnglishNewsArray, setEnglishNewsArray] = useState([])
  const [EnglishFilterArray, setEnglishFilterArray] = useState([])

  const advertisements = useAppSelector((state) => state.advertisement.advertisements);

  const [SelectedCategory, setSelectedCategory] = useState('All')
  const [SelectedEnglishCategory, setSelectedEnglishCategory] = useState('All')
  const [LatestNewsMore, setLatestNewsMore] = useState(false)
  const [EnglishNewsMore, setEnglishNewsMore] = useState(false)
  useEffect(() => {
    if (news.length > 0) {
      if (news.findIndex(e => e.languageCode == 1) !== -1) {
        const value = news.filter(e => e.languageCode == 1)
        setTamilNewsArray(value);
        FilterTamilNewsFunction(value, 'All');
      }
      if (news.findIndex(e => e.languageCode == 6) !== -1) {
        const value1 = news.filter(e => e.languageCode == 6)
        let temp1 = value1
        setEnglishNewsArray(temp1)
        FilterEnglishNewsFunction(temp1, 'All')
      }
    }
  }, [news])


  function FilterTamilNewsFunction(array, _type) {
    if (_type !== 'All') {
      let temp = []
      for (let i = 0; i < array.length; i++) {
        if (array[i].type.findIndex(e => e.trim() === _type.trim()) !== -1) {
          temp.push(array[i])
        }
      }
      setFilterArray(temp)
      setSelectedCategory(_type)
    } else {
      let temp = []
      for (let i = 0; i < array.length; i++) {
        temp.push(array[i])
      }
      setFilterArray(temp)
      setSelectedCategory(_type)
    }
  }
  function FilterEnglishNewsFunction(array, _type) {
    if (_type !== 'All') {
      let temp = []
      for (let i = 0; i < array.length; i++) {
        if (array[i].type.findIndex(e => e.trim() === _type.trim()) !== -1) {
          temp.push(array[i])
        }
      }
      setEnglishFilterArray(temp)
      setSelectedEnglishCategory(_type)
    } else {
      let temp = []
      for (let i = 0; i < array.length; i++) {
        temp.push(array[i])
      }
      setEnglishFilterArray(temp)
      setSelectedEnglishCategory(_type)
    }
  }
  useEffect(() => {
    if (news.length <= 0)
      dispatch(loadNews());
  }, [dispatch]);

  useEffect(() => {
    const ads = advertisements.filter(item => item.type === "news" && item.orientation === "horizontal")
    setAdsList(ads)
  }, [advertisements])

  const [openModalpage, setopenModalpage] = useState(false)
  function openModal() {
    setopenModalpage(true)
  }
  function closeModal() {
    setopenModalpage(false)
  }
  const [SelectedNewsId, setSelectedNewsId] = useState(null)
  function SelectedNewsIdFunction(params) {
    setSelectedNewsId(params)
  }

  const handleRating = async (newRating) => {
    const docRef = doc(db, "news", SelectedNewsId);
    const docSnap = await getDoc(docRef);
    const reference = doc(db, 'news', SelectedNewsId)
    await updateDoc(reference, {
      rating: newRating + docSnap.data().rating
    }).then((res) => {
      setopenModalpage(false)
      dispatch(loadNews());
    }).catch((error) => {
      console.log(error);
    })

    // if (newRating) {
    //   const data = {
    //     newUserRating: newRating,
    //     reviewId: review.id,
    //     userId: user.id,
    //     oldRating: review.rating,
    //     oldUserRating: userOldRating,
    //     newRating: review.rating + newRating,
    //     sourceType: review.sourceType
    //   };

    //   if (userRatingStatus) {
    //     const ratingDifference =
    //       Math.max(newRating, userOldRating) -
    //       Math.min(newRating, userOldRating);

    //     if (ratingDifference > 0) {
    //       if (newRating > userOldRating) {
    //         setRating(rating + ratingDifference);
    //         dispatch(updateUserRatingCount(ratingDifference));
    //       } else {
    //         setRating(rating - Math.abs(ratingDifference));
    //         dispatch(updateUserRatingCount(-Math.abs(ratingDifference)));
    //       }
    //     } else {
    //       setRating(newRating);
    //     }

    //     dispatch(updateReviewRating(data));
    //   }
    //   else {
    //     setRating(rating + newRating);
    //     dispatch(addReviewRating(data))
    //   }

    //   setOpenModal(false)
    //   setUserRatingStatus(true)
    // }
  }
  const navigate = useNavigate()
  const [NewsFeedLike, setNewsFeedLike] = useState(false)
  return (
    <>
      <div className="flex flex-col items-center bg-white">
        <div className=" w-full flex flex-col">
          <img className="w-full h-24 my-2 object-contain" src={headerphoto1} />
        </div>
        {/* <div className={`w-full mx-auto ${adsList && adsList.length > 1 ? isMobileView ? "mt-5" : "px-10" : ""}`}>
          {adsList && <BannerSlider ads={adsList} />}
        </div> */}
        {/* <div className={`${isMobileView ? "w-full" : "w-9/12"} `}>
          <div className="flex lg:flex-row flex-col py-2 justify-between mb-1">
            <span className={`text-black px-2 w-[70%] font-bold  ${isMobileView ? "text-sm" : "text-xl"}`}>Recent News</span>
            <span className={`text-black lg:block hidden w-[29%] font-bold  ${isMobileView ? "text-sm" : "text-xl"}`}>Top 10 News</span>
          </div>
          <div className="flex lg:flex-row lg:px-0 flex-col px-2 gap-x-4 ">

            <div className="grid grid-cols-1 md:grid-cols-3  pb-10 gap-5 md:w-[70%] w-full">
              {news && <NewsCard2 list={news} openModal={openModal} SelectedNewsIdFunction={SelectedNewsIdFunction} />}
            </div>
            <span className={`text-black lg:hidden block  font-bold  ${isMobileView ? "text-sm" : "text-xl"}`}>Top 5 Movie Star Rating</span>
            <div className=" lg:w-[28%] w-full p-1 rounded-lg  flex flex-col gap-2">
              {news && <NewsCard1 list={news} />}
            </div>
          </div>
        </div> */}
        <div className="w-full flex flex-row justify-center gap-x-4">
          <div className="sm:block relative hidden w-[160px]">
            <img className="" src={leftbanner} />
          </div>
          <div className={`${isMobileView ? "w-full" : "w-9/12 mt-5"} px-2 sm:px-0`}>
            <div className="flex flex-col xl:flex-row xl:items-center justify-between">
              <span className={`text-black font-Tamil font-bold sm:my-0 my-2 whitespace-nowrap ${isMobileView ? "text-xl" : "text-3xl"}`}>தமிழ்</span>
              <div className=" w-[55%] xl:block hidden border-b-[1px] border-b-gray-800"></div>
              <div className="w-full sm:w-auto overflow-x-auto flex flex-row gap-x-4 ">
                <span onClick={() => FilterTamilNewsFunction(TamilNewsArray, 'All')} className={`hover:cursor-pointer font-Tamil ${SelectedCategory == 'All' ? 'text-primary' : 'text-black'} font-normal ${isMobileView ? "text-sm" : "text-lg"}`}>தொகுப்பு</span>
                <span onClick={() => FilterTamilNewsFunction(TamilNewsArray, 'Kollywood')} className={` font-semibold font-Tamil hover:cursor-pointer ${SelectedCategory == 'Kollywood' ? 'text-primary' : 'text-black'} font-normal ${isMobileView ? "text-sm" : "text-lg"}`}>தமிழ்</span>
                <span onClick={() => FilterTamilNewsFunction(TamilNewsArray, 'Hollywood')} className={` font-semibold font-Tamil hover:cursor-pointer ${SelectedCategory == 'Hollywood' ? 'text-primary' : 'text-black'} font-normal ${isMobileView ? "text-sm" : "text-lg"}`}>ஆங்கிலம்</span>
                <span onClick={() => FilterTamilNewsFunction(TamilNewsArray, 'Tollywood')} className={` font-semibold font-Tamil hover:cursor-pointer ${SelectedCategory == 'Tollywood' ? 'text-primary' : 'text-black'} font-normal ${isMobileView ? "text-sm" : "text-lg"}`}>தெலுங்கு</span>
                <span onClick={() => FilterTamilNewsFunction(TamilNewsArray, 'Mollywood')} className={` font-semibold font-Tamil hover:cursor-pointer ${SelectedCategory == 'Mollywood' ? 'text-primary' : 'text-black'} font-normal ${isMobileView ? "text-sm" : "text-lg"}`}>மலையாளம்</span>
                <span onClick={() => FilterTamilNewsFunction(TamilNewsArray, 'Sandalwood')} className={` font-semibold font-Tamil hover:cursor-pointer ${SelectedCategory == 'Sandalwood' ? 'text-primary' : 'text-black'} font-normal ${isMobileView ? "text-sm" : "text-lg"}`}>கன்னடம்</span>
                <span onClick={() => FilterTamilNewsFunction(TamilNewsArray, 'Bollywood')} className={` font-semibold font-Tamil hover:cursor-pointer ${SelectedCategory == 'Bollywood' ? 'text-primary' : 'text-black'} font-normal ${isMobileView ? "text-sm" : "text-lg"}`}>ஹிந்தி</span>
                {/* <span onClick={() => FilterTamilNewsFunction(TamilNewsArray, 'Weather')} className={` font-semibold font-Tamil hover:cursor-pointer ${SelectedCategory == 'Weather' ? 'text-primary' : 'text-black'} font-normal ${isMobileView ? "text-sm" : "text-lg"}`}>பஞ்சாபி</span>
                <span onClick={() => FilterTamilNewsFunction(TamilNewsArray, 'Weather')} className={` font-semibold font-Tamil hover:cursor-pointer ${SelectedCategory == 'Weather' ? 'text-primary' : 'text-black'} font-normal ${isMobileView ? "text-sm" : "text-lg"}`}>மராத்தி</span> */}
              </div>
            </div>
            <div className="grid xl:grid-cols-4 grid-cols-2 gap-3 mt-5">
              {
                
                FilterArray.length>0? 
                FilterArray.map((e, index) => index < (LatestNewsMore ? FilterArray.length : 5) ?
                  <div key={index} className={` ${index === 0 ? 'row-span-2 col-span-2' : ''} `}>
                    <div className='rounded-t-xl h-full flex flex-col shadow-sm'>
                      <div className='h-full w-full rounded-t-xl relative overflow-hidden'>
                        <img onClick={() => navigate(`/news/${e.id + `-` + (index + 1)}`, { state: { Lang: 'Tamil',array:FilterArray } })} className={`rounded-t-xl hover:scale-105 duration-500 w-full hover:cursor-pointer ${index === 0 ? 'sm:h-[420px] h-[220px]' : 'h-[190px] '} object-cover object-top `} src={e.cover_url} />
                        <div onClick={() => navigate(`/news/${e.id + `-` + (index + 1)}`, { state: { Lang: 'Tamil',array:FilterArray } })} className=" sm:h-[35%] h-[60%] bg-gradient-to-t from-black via-black to-transparent flex flex-col justify-end absolute bottom-0 w-full z-[9] px-3">
                          <h1 onClick={() => navigate(`/news/${e.id + `-` + (index + 1)}`, { state: { Lang: 'Tamil',array:FilterArray } })} className={`text-white font-Tamil text-sm tracking-wide hover:cursor-pointer hover:text-primary duration-200 ${index == 0 ? ' line-clamp-2 mb-2 sm:text-2xl' : 'min-[320px]:line-clamp-1 min-[375px]:line-clamp-2 mt-5'} `}>{e.title}</h1>
                        </div>
                      </div>
                      <div className="flex flex-row items-center rounded-b-xl justify-end px-5 sm:gap-x-3 py-2 bg-black gap-x-1">
                        <div className="flex flex-row items-center ">
                          {NewsFeedLike == false ? <BiLike onClick={() => setNewsFeedLike(!NewsFeedLike)} className={`sm:mx-2 text-white mx-1 cursor-pointer  ${isMobileView ? 'w-4 h-4' : 'w-5 h-5'}`} /> : <img onClick={() => setNewsFeedLike(!NewsFeedLike)} className={`sm:mx-2 text-white mx-1 cursor-pointer  ${isMobileView ? 'w-4 h-4' : 'w-5 h-5'}`} src={likefill} />}
                          <h5 className={` text-white ${isMobileView ? 'text-xs' : 'text-sm'}`}>50</h5>
                        </div>
                        <div className="flex flex-row items-center">
                          <FaRegCommentAlt className={`sm:mx-2 text-white mx-1 cursor-pointer  ${isMobileView ? 'w-3 h-3' : 'w-4 h-4'}`} />
                          <h5 className={`text-white ${isMobileView ? 'text-xs' : 'text-sm'}`}>5</h5>
                        </div>
                        <div className="flex flex-row items-center">
                          <RWebShare
                            data={{
                              text: "Starpot",
                              url: `https://starpot.in${'/news'}`,
                              title: `${e.name}`,
                            }}
                          >
                            <img src={sharestr} className={`sm:mx-2 text-white mx-1 cursor-pointer ${isMobileView ? 'w-5 h-5' : 'w-6 h-6'}`} />
                          </RWebShare>

                          <h5 className={`text-white ${isMobileView ? 'text-xs' : 'text-sm'}`}>20</h5>
                        </div>
                      </div>
                    </div>
                  </div>: null
                )
              :<h4 className="text-black font-semibold font-serif">No Recored Found</h4> }
            </div>
            <div className='flex flex-row items-center sm:my-8 mt-4 justify-center w-full '>
              <div className='w-full border-b-[1px] border-b-gray-700'></div>
              <div onClick={() => setLatestNewsMore(!LatestNewsMore)} className='w-max whitespace-nowrap hover:bg-primary hover:border-primary duration-300 cursor-pointer md:px-12 px-3 rounded-md md:py-3 py-[3px] text-black text-[12px] sm:text-base font-bold border-[2px] border-gray-700'>{LatestNewsMore ? '- View Less' : '+ View More'}</div>
              <div className='w-full border-b-[1px] border-b-gray-700'></div>
            </div>
          </div>
          <div className="sm:block hidden w-[160px]">
            <img src={leftbanner} />
          </div>
        </div>
        <div className="bg-gray-200 block my-4 sm:hidden px-5 py-5">
          <img src={ad2} />
        </div>
        <div className="w-full flex flex-row justify-center gap-x-4">
          <div className="sm:block hidden w-[160px]">
            <img className="hidden" src={leftbanner} />
          </div>
          <div className={`${isMobileView ? "w-full" : "w-9/12 mt-5"} px-2 sm:px-0`}>
            <div className="flex flex-col sm:flex-row sm:items-center justify-between">
              <span className={`text-black font-bold sm:my-0 my-2 whitespace-nowrap ${isMobileView ? "text-base" : "text-xl"}`}>English</span>
              <div className="w-[60%] sm:block hidden border-b-[1px] border-b-gray-500"></div>
              <div className="w-full sm:w-auto overflow-x-auto flex flex-row gap-x-4 ">
                <span onClick={() => FilterEnglishNewsFunction(EnglishNewsArray, 'All')} className={` font-bold hover:cursor-pointer ${SelectedEnglishCategory == 'All' ? 'text-primary' : 'text-black'}  ${isMobileView ? "text-sm" : "text-md"}`}>All</span>
                <span onClick={() => FilterEnglishNewsFunction(EnglishNewsArray, 'Kollywood')} className={` font-bold hover:cursor-pointer ${SelectedEnglishCategory == 'Kollywood' ? 'text-primary' : 'text-black'}  ${isMobileView ? "text-sm" : "text-md"}`}>Tamil</span>
                <span onClick={() => FilterEnglishNewsFunction(EnglishNewsArray, 'Hollywood')} className={` font-bold hover:cursor-pointer ${SelectedEnglishCategory == 'Hollywood' ? 'text-primary' : 'text-black'}  ${isMobileView ? "text-sm" : "text-md"}`}>English</span>
                <span onClick={() => FilterEnglishNewsFunction(EnglishNewsArray, 'Tollywood')} className={` font-bold hover:cursor-pointer ${SelectedEnglishCategory == 'Tollywood' ? 'text-primary' : 'text-black'}  ${isMobileView ? "text-sm" : "text-md"}`}>Telugu</span>
                <span onClick={() => FilterEnglishNewsFunction(EnglishNewsArray, 'Mollywood')} className={` font-bold hover:cursor-pointer ${SelectedEnglishCategory == 'Mollywood' ? 'text-primary' : 'text-black'}  ${isMobileView ? "text-sm" : "text-md"}`}>Malayalam</span>
                <span onClick={() => FilterEnglishNewsFunction(EnglishNewsArray, 'Sandalwood')} className={` font-bold hover:cursor-pointer ${SelectedEnglishCategory == 'Sandalwood' ? 'text-primary' : 'text-black'}  ${isMobileView ? "text-sm" : "text-md"}`}>Kannada</span>
                <span onClick={() => FilterEnglishNewsFunction(EnglishNewsArray, 'Bollywood')} className={` font-bold hover:cursor-pointer ${SelectedEnglishCategory == 'Bollywood' ? 'text-primary' : 'text-black'}  ${isMobileView ? "text-sm" : "text-md"}`}>Hindi</span>
                {/* <span onClick={() => FilterEnglishNewsFunction(EnglishNewsArray, 'Offitial')} className={` font-bold hover:cursor-pointer ${SelectedEnglishCategory == 'Offitial' ? 'text-primary' : 'text-black'}  ${isMobileView ? "text-sm" : "text-md"}`}>Punjabi</span>
                <span onClick={() => FilterEnglishNewsFunction(EnglishNewsArray, 'Weather')} className={` font-bold hover:cursor-pointer ${SelectedEnglishCategory == 'Weather' ? 'text-primary' : 'text-black'}  ${isMobileView ? "text-sm" : "text-md"}`}>Marathi</span> */}
              </div>
            </div>
            <div className="grid sm:grid-cols-4 grid-cols-2 gap-3 mt-5 pb-2">
              {
               EnglishFilterArray.length>0?EnglishFilterArray.map((e, index) => index < (EnglishNewsMore ? EnglishFilterArray.length : 5) ?
                  <div key={index} className={` ${index === 0 ? 'row-span-2 col-span-2 ' : ''} `}>
                    <div className='rounded-t-xl h-full sm:h-full flex  flex-col shadow-sm'>
                      <div className='h-full w-full rounded-t-xl relative overflow-hidden'>
                        <img onClick={() => navigate(`/news/${e.id + `-` + (index + 1)}`, { state: { Lang: 'English',array:EnglishFilterArray } })} className={`rounded-t-xl hover:scale-105 duration-500 w-full hover:cursor-pointer ${index === 0 ? 'sm:h-[420px] h-[220px]' : 'h-[190px] '} object-cover object-top `} src={e.cover_url} />
                        <div onClick={() => navigate(`/news/${e.id + `-` + (index + 1)}`, { state: { Lang: 'English',array:EnglishFilterArray } })} className=" sm:h-[35%] h-[55%] bg-gradient-to-t from-black via-black to-transparent flex flex-col justify-end absolute bottom-0 w-full z-[9] px-3">
                          <h1 onClick={() => navigate(`/news/${e.id + `-` + (index + 1)}`, { state: { Lang: 'English',array:EnglishFilterArray } })} className={`text-white leading-6  text-sm font-bold hover:cursor-pointer hover:text-primary duration-200 ${index == 0 ? 'line-clamp-2 mb-2 sm:text-2xl' : 'line-clamp-2 mt-5'} `}>{e.title}</h1>
                        </div>
                      </div>
                      <div className="flex flex-row items-center rounded-b-xl justify-end px-5 sm:gap-x-3 py-2 bg-black gap-x-1">
                        <div className="flex flex-row items-center ">
                          {NewsFeedLike == false ? <BiLike onClick={() => setNewsFeedLike(!NewsFeedLike)} className={`sm:mx-2 text-white mx-1 cursor-pointer  ${isMobileView ? 'w-4 h-4' : 'w-5 h-5'}`} /> : <img onClick={() => setNewsFeedLike(!NewsFeedLike)} className={`sm:mx-2 text-white mx-1 cursor-pointer  ${isMobileView ? 'w-4 h-4' : 'w-5 h-5'}`} src={likefill} />}
                          <h5 className={` text-white ${isMobileView ? 'text-xs' : 'text-sm'}`}>50</h5>
                        </div>
                        <div className="flex flex-row items-center">
                          <FaRegCommentAlt className={`sm:mx-2 text-white mx-1 cursor-pointer  ${isMobileView ? 'w-3 h-3' : 'w-4 h-4'}`} />
                          <h5 className={`text-white ${isMobileView ? 'text-xs' : 'text-sm'}`}>5</h5>
                        </div>
                        <div className="flex flex-row items-center">
                          <RWebShare
                            data={{
                              text: "Starpot",
                              url: `https://starpot.in${'/news'}`,
                              title: `${e.name}`,
                            }}
                          >
                            <img src={sharestr} className={`sm:mx-2 text-white mx-1 cursor-pointer ${isMobileView ? 'w-5 h-5' : 'w-6 h-6'}`} />
                          </RWebShare>

                          <h5 className={`text-white ${isMobileView ? 'text-xs' : 'text-sm'}`}>20</h5>
                        </div>
                      </div>
                    </div>
                  </div> : null
                )
              :<h4 className="text-black font-semibold font-serif">No Recored Found</h4>}
            </div>
            <div className='flex flex-row items-center my-8 justify-center w-full '>
              <div className='w-full border-b-[1px] border-b-gray-700'></div>
              <div onClick={() => setEnglishNewsMore(!EnglishNewsMore)} className='w-max whitespace-nowrap hover:bg-primary hover:border-primary duration-300 cursor-pointer md:px-12 px-3 text-[12px] sm:text-base rounded-md md:py-3 py-[3px] text-black font-bold border-[2px] border-gray-700'>{EnglishNewsMore ? '- View Less' : '+ View More'}</div>
              <div className='w-full border-b-[1px] border-b-gray-700'></div>
            </div>
          </div>
          <div className="sm:block hidden w-[160px]">
            <img className="hidden" src={leftbanner} />
          </div>
        </div>
        {
          openModalpage ? <Modal openModal={openModal} closeModal={closeModal} handleRating={handleRating} /> : null
        }
      </div>
    </>

  );
}

export { NewsTemplate };


// design2

{/* <div className="grid sm:grid-cols-4 grid-cols-2 gap-3 mt-5">
{
  FilterArray.map((e, index) => index < (LatestNewsMore ? FilterArray.length : 5) ?
    <div key={index} className={` ${index === 0 ? 'row-span-2 col-span-2' : ''} `}>
      <div style={{ backgroundImage: `url(${e.cover_url})` }} className='rounded-xl h-[98%] sm:h-full flex  flex-col shadow-sm'>
        <div className='backdrop-blur-2xl backdrop-brightness-50 h-full p-2 rounded-lg'>
          {/* {index == 0 ? <h5 className={`text-white bg-primary absolute z-[99] cursor-default font-normal w-[100px] rounded-sm -top-[10px] text-[12px] self-center text-center`}>Recent</h5> : null} */}
{/* <h1 onClick={() => navigate(`/news/${e.id + `-` + (index + 1)}`)} className='text-white leading-7 text-xl hover:cursor-pointer hover:text-primary duration-200 line-clamp-1 font-sans font-semibold'>{e.title}</h1> */ }
{/* <div className='flex flex-row items-center gap-x-3'>
          <h1 className='text-[14px] font-semibold text-primary'>News & Updates</h1>
          <h1 className='text-lg w-1 h-1 mb-1 self-end rounded-full bg-white'></h1>
          <h1 className='text-[12px] font-semibold self-end text-gray-500'>Published 1 hour ago</h1>
          </div> */}
//       <img onClick={() => navigate(`/news/${e.id + `-` + (index + 1)}`)} className={`rounded-lg w-full hover:cursor-pointer ${index === 0 ? 'sm:h-[400px]' : 'sm:h-[165px] h-[80px]'} object-cover object-top `} src={e.cover_url} />
//       <div className="flex pt-2 sm:gap-x-3 justify-between">
//         <h1 onClick={() => navigate(`/news/${e.id + `-` + (index + 1)}`)} className={`text-white leading-7 text-xs sm:text-lg hover:cursor-pointer hover:text-primary duration-200 ${index == 0 ? 'line-clamp-2' : 'line-clamp-1'} font-sans font-semibold`}>{e.title}</h1>
//         <div className="flex flex-row items-center gap-x-2">
//           <div className="flex items-center gap-x-2">
//             <div onClick={() => setopenModalpage(true)} className="py-1 px-2 bg-transparent/50 rounded-md flex items-center ">
//               <AiOutlineStar className={`text-gray-200 ${isMobileView ? "w-3 h-3" : "w-5 h-5 hover:cursor-pointer"}`} />
//               <TextAtom
//                 div_style="flex mx-1 "
//                 text_style={`text-gray-200 ${isMobileView ? "text-xs" : "text-sm"}`}
//                 text={e.rating || 0}
//               />
//             </div>
//             <div className=" py-1 px-2  bg-transparent/50 rounded-md flex items-center ">
//               <FaUserCheck className={`text-gray-200 ${isMobileView ? "w-3 h-3" : "w-5 h-5 hover:cursor-pointer"}`} />
//               <TextAtom
//                 div_style="flex mx-1 "
//                 text_style={`text-gray-200 ${isMobileView ? "text-xs" : "text-sm"}`}
//                 text={e.reviewCount || 0}
//               />
//             </div>
//           </div>
//           <div className=" flex items-center cursor-pointer">
//             <RWebShare
//               data={{
//                 text: "StarPot",
//                 url: `https://starpot.in${e.pathname}/${e.id}`,
//                 title: `${e.title}`
//               }}
//             >
//               <FaShareSquare className={`text-primary ${isMobileView ? "w-3 h-3" : "w-5 h-5"}`} />
//             </RWebShare>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </div> : null
//   )
// }
// </div> */}