import { useState, useEffect } from "react";
import Slider from "react-slick";
import "./style.css";

import { useIsMobileViewContext } from '../../context/isMobileView';

function BannerSlider({ ads }) {
  const { isMobileView } = useIsMobileViewContext();

  const initSettings = {
    infinite: true,
    lazyload: true,
    arrows: true,
    speed: 2500,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: isMobileView ? 0 : "0px",
    autoplay: true,
  };

  const [settings, setSettings] = useState(initSettings);

  useEffect(() => {
    setSettings({ ...initSettings, arrows: !isMobileView })
  }, [isMobileView]);

  return (
    <>
      {ads && <Slider {...settings}>
        {ads.map((item) => (
          <a key={item.link} className="" href={item.link} target="_blank" rel="noreferrer">
            <img
              className={`lg:rounded-md rounded-lg w-full object-center object-cover ${isMobileView ? "h-40" : "h-[120px]"}`}
              src={item.banner_url}
              alt=""
            />
          </a>
        ))}
      </Slider>}
    </>
  );
}

export default BannerSlider;


// import Slider from "react-slick";
// import "./style.css";

// import { useIsMobileViewContext } from '../../context/isMobileView';

// function BannerSlider({ ads }) {
//   const { isMobileView } = useIsMobileViewContext();

//   const settings = {
//     infinite: true,
//     lazyload: true,
//     arrows: false,
//     speed: 2500,
//     className: "lg:px-72 md:px-20",
//     slidesToShow: 1,
//     centerMode: true,
//     centerPadding: isMobileView ? 0 : "0px",
//     autoplay: true,
//   };

//   return (
//     <>
//       {ads && <Slider  {...settings}>
//         {ads.map((item) => (
//           <a className="mt-3" href={item.link} target="_blank" rel="noreferrer">
//             <img
//               className={`lg:rounded-md rounded-lg w-full ${isMobileView ? "h-32" : "h-64"}`}
//               src={item.banner_url}
//               alt=""
//             />
//           </a>
//         ))}
//       </Slider>}
//     </>
//   );
// }

// export default BannerSlider;
