const pageTitleStyle = "md:text-2xl text-lg font-bold text-primary";
const titleStyle = "md:text-lg text-sm font-bold text-primary";
const subtitleStyle = "md:text-sm text-xs font-semibold text-primary";
const contentStyle = "md:text-sm text-xs text-gray-400";

function PrivacyPolicy() {
    return (
        <main className="flex-grow min-h-screen bg-gray-900 p-4">
            <div className="flex justify-center">
                <div className="max-w-screen-lg self-center">
                    <h5 className={pageTitleStyle}>Privacy Policy</h5>
                    <span className={contentStyle}>Effective Feb 21, 2022</span>

                    <br />
                    <br />

                    <span className={contentStyle}>Thank you for visiting StarPot. Your privacy is important to us. To
                        better protect your privacy, we provide this notice explaining our
                        information practices and the choices you can make about the
                        collection and use of the personal information you submit on Web pages
                        or for services where this policy is posted. We may make changes to this Policy and will post the recent policy on
                        our site.</span>

                    <br />
                    <br />
                    <br />

                    <span className={titleStyle}>THE INFORMATION WE COLLECT</span>

                    <br />
                    <br />

                    <span className={subtitleStyle}>Registration : </span>
                    <span className={contentStyle}>At of our Web site, you can order products or purchase content, enter
                        contests, read critic and user reviews, vote in polls, rate movies, TV
                        shows and other content, or otherwise express an opinion, subscribe to
                        one of our services such as our online newsletters, or participate in
                        our online forums or communities. In the course of using the Services,
                        we ask you to create an account by providing us with certain
                        personally identifiable information that can be used to contact or
                        identify you, as well as to administer your account. The types of
                        personally identifiable information that may be collected include:
                        name, address, e-mail address, telephone number,credit card and
                        billing information, and information about your interests in and use
                        of various products, programs, and services.</span>

                    <br />
                    <br />

                    <span className={subtitleStyle}>Information about others/inviting friends : </span>
                    <span className={contentStyle}>You also can submit information about other people. We will provide
                        you a Invite button that will send a mail to your friend as invitation
                        to Join StarPot.</span>

                    <br />
                    <br />

                    <span className={contentStyle}>Some of our Services also allow you to invite your friends to join
                        our services and to connect with you through social media features.
                        Inviting friends is always optional. To do so, you may provide their
                        contact information or may enter your log-in credentials to import
                        email addresses from your address book from other sites that support
                        such functionality (e.g., Yahoo! and Gmail). You select the friends
                        you want to invite to share in this experience.</span>

                    <br />
                    <br />

                    <span className={subtitleStyle}>Information from other sources/social networking sites : </span>
                    <span className={contentStyle}>You can also engage with our content, such as video, applications,
                        and other offerings, on or through (1) third-party social networking
                        sites, such as Facebook, (2) third-party social media plug-ins and
                        applications, and (3) our social media plug-ins and applications that
                        may be available through third-party sites or our Services. You may
                        also choose to link your account with us to third party social
                        networking sites. When you link your account or engage with our
                        content on or through third party social networking sites, social
                        media plug-ins or applications, you may allow us to have ongoing
                        access to certain information from your social media profile (e.g.,
                        name, e-mail address, photo, gender, birthday, location, your list of
                        friends, people you follow and/or who follow you, the posts or the
                        'likes' you make). We may also maintain non-personally identifiable
                        information (e.g., content viewed, game performance, high scores, and
                        information about advertisements within the content you have been
                        shown or may have clicked on, etc.) from your interaction with our
                        content.</span>

                    <br />
                    <br />

                    <span className={contentStyle}>When you provide information from your social media profile, it can
                        help enable us to do things like (1) give you exclusive content, (2)
                        personalize your online experience with us within our websites, and
                        (3) contact you through the social networking sites or directly with
                        the latest news, special offerings, and rewards. By doing so, you
                        consent to the use of this information in accordance with this Privacy
                        Policy.</span>

                    <br />
                    <br />

                    <span className={contentStyle}>When you post information on blogs, public forums or similar
                        functions at our Services, or when you interact with our Services
                        through social networking sites (depending on your privacy settings),
                        social media plug-ins or similar applications, this information could
                        be seen by anyone on the Internet. Therefore, we cannot prevent
                        further use of this information. You can control what data you share
                        through privacy settings available on some social networking sites.
                        For more information about how you can customize your privacy settings
                        and how social networking sites handle your personal information,
                        please refer to their privacy help guides, privacy policies and terms
                        of use.</span>

                    <br />
                    <br />

                    <span className={subtitleStyle}>Information collection from mobile devices : </span>
                    <span className={contentStyle}>If you access our Services on your mobile device, we also collect
                        your unique device identifier and may collect location information. We
                        also may collect your telephone number and other information you
                        choose to provide, such as username or e-mail address (e.g., to
                        receive updates about movies and other content).</span>

                    <br />
                    <br />

                    <span className={subtitleStyle}>Technical and usage information : </span>
                    <span className={contentStyle}>We also may obtain certain technical information when you use our
                        Services, such as the type of browser you are using (e.g., Firefox,
                        Internet Explorer), the type of operating system you are using (e.g.,
                        Windows 7, Mac OS, iOS, and Android), and the name of your Internet
                        service provider or mobile carrier.&nbsp;</span>

                    <br />
                    <br />
                    <br />

                    <span className={titleStyle}>HOW WE USE THE INFORMATION</span>

                    <br />
                    <br />

                    <span className={contentStyle}>We use the information you provide about yourself to fulfill your
                        requests for our contests, to respond to your inquiries about our
                        offerings, and to offer you other products &amp; other services that
                        we believe may be of interest to you.</span>
                    <br />
                    <br />

                    <span className={contentStyle}>We use this information to communicate with you, such as to notify
                        you when you have won one of our contests or when we make changes to
                        our user agreements, to fulfill a request by you for an online
                        newsletter, or to contact you about your account with us.</span>
                    <br />
                    <br />

                    <span className={contentStyle}>We use the information that you provide about others to enable us to
                        send them your invitations, gifts or offers. From time to time, we
                        also may use this information to offer our products, programs, or
                        services to them.</span>
                    <br />
                    <br />

                    <span className={contentStyle}>The information we collect in connection with our movie forums is
                        used to provide an interactive experience. We use this information to
                        facilitate participation in these online forums and communities and,
                        from time to time, to offer you programs and services.</span>
                    <br />
                    <br />

                    <span className={contentStyle}>If you choose to submit content for publication, we may publish your
                        screen name and other information you have provided to us.</span>
                    <br />
                    <br />

                    <span className={contentStyle}>When you access our Services on a mobile device, we may use the
                        information collected to enable you to do things like watch trailers
                        and video clips; get entertainment news and updates; get information
                        about movies or TV shows (e.g., find show times for theaters near
                        you); buy movie tickets; play games; follow blogs; and read critic and
                        user reviews. We may also use this information to offer you products
                        and services we believe may be of interest to you and otherwise tailor
                        your experience.</span>
                    <br />
                    <br />

                    <span className={contentStyle}>We use the non-personally identifiable information that we collect to
                        improve the design and content of our Services and to enable us to
                        personalize your experience with our Services. We also may use this
                        information in the aggregate to analyze usage of our Services, as well
                        as for us and for other partners to offer you products, programs, or
                        services.</span>
                    <br />
                    <br />
                    <br />

                    <span className={titleStyle}>INFORMATION SHARING AND DISCLOSURE</span>

                    <br />
                    <br />

                    <span className={contentStyle}>We may disclose personally identifiable information in response to
                        legal process, for example, in response to a court order or a
                        subpoena. We also may disclose such information in response to a law
                        enforcement agency's request or where we believe it is necessary to
                        investigate, prevent, or take action regarding illegal activities,
                        suspected fraud, situations involving potential threats to the
                        physical safety of any person, violations of our terms of use, or as
                        otherwise required by law. In addition, we may transfer information
                        about you if we or one of our business units are acquired by, sold to,
                        or merged with another company.</span>

                    <br />
                    <br />

                    <span className={contentStyle}>Our agents and contractors may have access to personally identifiable
                        information to help carry out the services they are performing for
                        us.</span>

                    <br />
                    <br />

                    <span className={contentStyle}>Although we take appropriate measures to safeguard against
                        unauthorized disclosures of information, we cannot assure you that
                        personally identifiable information that we collect will never be
                        disclosed in a manner that is inconsistent with this Privacy
                        Policy.</span>

                    <br />
                    <br />

                    <span className={contentStyle}>We may disclose personally identifiable information about you to
                        companies whose practices are not covered by this Privacy Policy
                        (e.g., other marketers, publishers, retailers, participatory databases
                        and non-profit organizations) that want to directly market products or
                        services to you. If we share personally identifiable information with
                        such companies, we will provide you with an opportunity to opt out or
                        block such uses. If at some point in the future you decide that you no
                        longer want us to share your information for these purposes, send us
                        an email at <a href="mailto:privacy@starpot.com">privacy@starpot.com</a></span>

                    <br />
                    <br />

                    <span className={contentStyle}>We may, on occasion, combine information we receive from you with
                        outside records to enhance our ability to market to you those products
                        or services that may be of interest to you. We provide you with an
                        opportunity to express your preferences with respect to receiving
                        information about products and services from us and selected third
                        parties when you interact with us. If you ever decide in the future
                        that you would like to update these preferences, you may log into your
                        account if you have created one with us, you can follow the
                        "unsubscribe" instructions provided on any marketing e-mail you
                        receive from us, or you may send us an email at <a href="mailto:privacy@starpot.com">privacy@starpot.com</a> and we will edit your preferences accordingly.</span>

                    <br />
                    <br />
                    <br />

                    <span className={titleStyle}>COLLECTION OF INFORMATION BY THIRD PARTY SITES, AD SERVERS, AND SPONSORS</span>

                    <br />
                    <br />

                    <span className={contentStyle}>Some of our Services contain links to or integrations with other
                        sites (e.g., Facebook, Twitter) whose information practices may be
                        different than ours. Visitors should consult the other sites' or
                        services' privacy policies before submitting any information, as we
                        have no control over information that is submitted to, or collected
                        by, these third parties.</span>

                    <br />
                    <br />

                    <span className={contentStyle}>We sometimes may offer content or programs (e.g., contests,
                        sweepstakes, promotions, games, applications or social network
                        integrations) that are sponsored by or co-branded with identified
                        third parties. By virtue of these relationships, the third parties may
                        obtain personally identifiable information that visitors voluntarily
                        submit to participate in the activity. We have no control over these
                        third parties' use of this information. Our Services will notify you
                        at the time of requesting personally identifiable information if these
                        third parties will obtain such information.</span>

                    <br />
                    <br />

                    <span className={contentStyle}>At some of our Services, reputable third parties may present or serve
                        the advertisements, provide us with data collection, reporting, ad
                        response measurement, site analytics, as well as assist with delivery
                        of relevant marketing messages and advertisements. These third parties
                        may view, edit, or set their own cookies. The use of these
                        technologies by these third parties is subject to their own privacy
                        policies and is not covered by this privacy statement</span>

                    <br />
                    <br />
                    <br />

                    <span className={titleStyle}>COOKIES AND OTHER TECHNICAL INFORMATION</span>

                    <br />
                    <br />

                    <span className={contentStyle}>We, our third party service providers, and our partners may send
                        "cookies" to your computer or use similar technologies to enhance your
                        experience with our Services. "Cookies" are files that can identify
                        you as a unique customer and store your personal preferences as well
                        as technical information (including click through and click stream
                        data). Cookies manage and measure the performance of advertisements
                        displayed on or delivered by or through us and/or other networks or
                        sites. Cookies can either be permanent (i.e., they remain on your
                        computer until you delete them) or temporary (i.e., they last only
                        until you close your browser).</span>

                    <br />
                    <br />

                    <span className={contentStyle}>We may also use "Web beacons" that monitor your use of our Services.
                        Web beacons (or Web bugs) are small strings of code that provide a
                        method for delivering a graphic image on a Web page for the purpose of
                        transferring data, such as the IP (Internet Protocol) address of the
                        computer that downloaded the page on which the Web beacon appears, the
                        URL (Uniform Resource Locator) of the page on which the Web beacon
                        appears, the time the page containing the Web beacon was viewed, the
                        types of browser that fetched the Web beacon and the identification
                        number of any cookie on the computer previously placed by that
                        server.</span>

                    <span className={contentStyle}>When we correspond with you via HTML capable e-mail, Web beacons let
                        us know whether you received and opened our e-mail. On their own,
                        cookies or Web beacons do not contain or reveal any personally
                        identifiable information. However, if you choose to furnish personally
                        identifiable information, this information can be linked to the data
                        stored in the cookies/Web beacons.</span>

                    <span className={contentStyle}>By accepting this Privacy Policy, you specifically agree to our use
                        of cookies and Web beacons as described herein. You may adjust your
                        browser to reject cookies from us or from any other Web site.
                        Additionally, by setting your Web browser to display HTML e-mails as
                        text only, you may be able to prevent the use of some Web beacons.
                        Please consult the "Help" section of your browser for more
                        information. However, certain areas of our Services can only be
                        accessed in conjunction with cookies or similar devices and you should
                        be aware that disabling cookies or similar devices might prevent you
                        from accessing some of our content.</span>

                    <br />
                    <br />
                    <br />

                    <span className={titleStyle}>OUR COMMITMENT TO SECURITY</span>

                    <br />
                    <br />

                    <span className={contentStyle}>We have put in place appropriate physical, electronic, and managerial
                        procedures to safeguard and help prevent unauthorized access, maintain
                        data security, and correctly use the information we collect
                        online.</span>

                    <br />
                    <br />
                    <br />

                    <span className={titleStyle}>HOW YOU CAN ACCESS OR CORRECT INFORMATION</span>

                    <br />
                    <br />

                    <span className={contentStyle}>To access the personally identifiable information that our Services
                        collected about you online from the pages on which this Privacy Policy
                        is posted, to correct factual errors in such information, or to update
                        your interests, preferences or other portions of your user profile,
                        please log into your profile if you have created one with us or send
                        an email to <a href="mailto:privacy@starpot.com">privacy@starpot.com</a></span>

                    <br />
                    <br />

                    <span className={contentStyle}>To protect your privacy and security, we will take reasonable steps
                        to help verify your identity before granting access or making
                        corrections.</span>

                    <br />
                    <br />
                    <br />

                    <span className={titleStyle}>INTERNATIONAL TRANSFER</span>

                    <br />
                    <br />

                    <span className={contentStyle}>We operate internationally and many of our computer systems are
                        currently based in the United States, your personal data will be
                        processed by us in the U.S. where data protection and privacy
                        regulations may not offer the same level of protection as in other
                        parts of the world, such as the European Union. If you create a user
                        account at our Services as a visitor from outside the United States,
                        you consent to the collection and/or processing of your personally
                        identifiable information in the United States, which includes the use
                        of cookies as described above.</span>

                    <br />
                    <br />
                    <br />

                    <span className={titleStyle}>NOTIFICATION REGARDING UPDATES</span>

                    <br />
                    <br />

                    <span className={contentStyle}>From time to time, we may update this privacy notice. We will notify
                        you about material changes in the way we treat personally identifiable
                        information by placing a notice on our site or by sending you an email
                        message. We encourage you to periodically check back and review this
                        policy so that you know our current privacy practices.</span>

                    <br />
                    <br />
                    <br />

                    <span className={titleStyle}>HOW TO CONTACT US</span>

                    <br />
                    <br />

                    <span className={contentStyle}>admin@starpot.com</span>
                </div>
            </div>
        </main>
    )
}

export default PrivacyPolicy