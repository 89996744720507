import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

//icons
import { FaUserCheck } from "react-icons/fa";
import { BsStarFill } from "react-icons/bs";

import bookmark from '../assets/images/small_bookmark.png'

import { TextAtom } from '../view/atoms'
import TextMarquee from '../view/atoms/TextMarquee';
import MySlider from "../components/MySlider";

import { useIsMobileViewContext } from "../context/isMobileView";

function BrandCard({ list }) {
  const { isMobileView } = useIsMobileViewContext();

  const [categorizedBrands, setCategorizedBrands] = useState([]);

  useEffect(() => {
    if (list) {
      let newCategorizedBrands = [];
      list
        .slice(15, list.length)
        .forEach(item => {
          if (item.type && item.type.length > 0) {
            item.type.forEach(type => {
              if (newCategorizedBrands[type] && newCategorizedBrands[type].length < 10) {
                newCategorizedBrands[type].push(item);
              } else {
                newCategorizedBrands[type] = [];
                newCategorizedBrands[type].push(item);
              }
            });
          }
        });
      setCategorizedBrands(newCategorizedBrands);
    }
  }, [list]);

  return <>
    <div className="flex flex-col mt-2 w-full">
      {list &&
        <>
          <span className={`text-gray-300 font-bold mx-2 ${isMobileView ? "text-sm" : "text-xl"}`}>Top 15 Brands</span>
          <MySlider isScrollActionEnabled={list.length > (isMobileView ? 2 : 6)} isMobileView={isMobileView}>
            {list
              .slice(0, 15)
              .map((item) => (
                <MyCardItem index={item.rank} item={item} isMobileView={isMobileView} />
              ))}
          </MySlider>
        </>
      }

      {categorizedBrands &&
        Object.entries(categorizedBrands)
          .map(([key, value]) => (
            <>
              {value &&
                <>
                  <div key={key} className="flex items-center justify-between px-2">
                    <span className={`text-gray-300 font-bold mx-2 my-2 ${isMobileView ? "text-sm" : "text-xl"}`}>{key}</span>
                    {value.length === 10 ? <Link to={`brand/more/${key}`} className={`font-semibold text-primary mx-2 ${isMobileView ? "text-sm" : "text-md"}`}>More &gt;</Link> : <></>}
                  </div>
                  <MySlider>
                    {value
                      .map((item, index) => (
                        <MyCardItem index={index + 1} item={item} isMobileView={isMobileView} />
                      ))}
                  </MySlider>
                </>
              }
            </>
          ))}
    </div>
  </>
}

function MyCardItem({ index, item, isMobileView }) {
  return (
    <div className="mr-2 m-2 bg-gray-800 mx-1 rounded-lg drop-shadow-md" key={item.title}>
      <Link to={`/brand/${item.id + (`-${index}`)}`}>
        <div className={`overflow-hidden relative bg-gray-700 rounded-tr-lg rounded-tl-lg ${isMobileView ? "w-36 h-40" : "w-56 h-56"}`}>
          {item.cover_url &&
            <img
              src={item.cover_url}
              className={`hover:scale-110 transistion duration-500 object-cover rounded-tr-lg rounded-tl-lg ${isMobileView ? "w-36 h-40" : "w-56 h-56"}`}
              alt="" />
          }

          {index && <div
            className={`absolute top-0 left-5 ${isMobileView ? "w-6 h-8" : "w-8 h-16"} ml-2 text-center`}
            style={{
              backgroundImage: `url(${bookmark})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
            }}
          >
            <span className={`text-gray-200 ${isMobileView ? "text-xs" : "text-md"}`}>{`#${index}`}</span>
          </div>
          }
        </div>

        <TextMarquee
          title={item.title}
          marqueeAfterLength={isMobileView ? 20 : 30}
          text_style={`text-gray-200 ${isMobileView ? "text-xs" : "text-md"}`}
          div_style={`${isMobileView ? "w-36" : "w-56"}`} />

        <div className="flex justify-between items-center">
          <div className="flex-1 py-1 px-0.5 bg-gray-700 flex items-center justify-center mr-0.5 rounded-bl-lg">
            <BsStarFill className={`text-primary ${isMobileView ? "w-3 h-3" : "w-4 h-4"}`} />
            <TextAtom div_style="flex mx-1"
              text_style={`text-gray-400 ${isMobileView ? "text-xs" : "text-sm"}`}
              text={item.rating} />
          </div>
          <div className="flex-1 py-1 px-0.5 bg-gray-700  flex items-center justify-center ml-0.5 rounded-br-lg">
            <FaUserCheck className={`text-primary ${isMobileView ? "w-3 h-3" : "w-4 h-4"}`} />
            <TextAtom div_style="flex mx-1"
              text_style={`text-gray-400 ${isMobileView ? "text-xs" : "text-sm"}`}
              text={item.reviewCount || 0} />
          </div>
        </div>
      </Link>
    </div>
  );
}

export default BrandCard