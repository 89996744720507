import { useEffect, useState, useRef } from 'react';
import { IoSend, IoVideocamOutline, IoVideocamOffOutline } from "react-icons/io5";

import ReviewCard from './ReviewCard';
import UserReviewCard from './UserReviewCard';

import { useIsMobileViewContext } from "../context/isMobileView";

import { loadReviews, loadMoreReviews, loadUserReview, addReview, updateReview, deleteReview } from '../service/review.service';

import {
  useAppSelector,
  useAppDispatch,
  updateCelebrityReviewCount,
  updateMovieReviewCount,
  updateNewsReviewCount,
  updateProductReviewCount,
  updateBrandReviewCount,
  updateShowReviewCount,
  addUserReview,
  updateUserReview,
  reduceCelebrityReviewCount,
  reduceMovieReviewCount,
  reduceNewsReviewCount,
  reduceProductReviewCount,
  reduceBrandReviewCount,
  reduceShowReviewCount,
  removeUserReview
} from '../redux';

function getReduxSourceName(value) {
  switch (value) {
    case "movie":
      return "movies";
    case "celebrity":
      return "celebrity";
    case "product":
      return "products";
    case "brand":
      return "brands";
    case "news":
      return "news";
    case "shows":
      return "shows";
    default:
      return "";
  }
}

function ReviewList({ sourceId, source }) {
  const dispatch = useAppDispatch();

  const { isMobileView } = useIsMobileViewContext()

  const user = useAppSelector(state => state.user.profile);
  const list = useAppSelector(state => state[source][getReduxSourceName(source)]);

  const [reviewList, setReviewList] = useState(null);
  const [endReached, setEndReached] = useState(false);

  const [userReview, setUserReview] = useState(undefined);

  const [isEditing, setIsEditing] = useState(false);
  const [inputText, setInputText] = useState("");
  const [inputError, setInputError] = useState("");
  const [showVideoLinkInput, setShowVideoLinkInput] = useState(false);
  const [videoUrlInput, setVideoUrlInput] = useState("");

  const lastDocSnapshot = useRef(null);

  useEffect(() => {
    if (reviewList === null) {
      loadReviews(source, sourceId, user.id)
        .then(result => {
          if (result !== null) {
            setReviewList(result.data);
            lastDocSnapshot.current = result.lastDocSnapshot;
          } else {
            setReviewList([]);
          }
        }).catch((err) => {
          setReviewList([]);
        });
    }
  }, [reviewList]);

  useEffect(() => {
    if (userReview === undefined) {
      loadUserReview(source, sourceId, user.id)
        .then(result => {
          if (result !== null) {
            setUserReview(result);
          } else {
            setUserReview(null);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [userReview]);

  const handleLoadMoreClick = () => {
    loadMoreReviews(source, sourceId, user.id, lastDocSnapshot.current)
      .then(result => {
        if (result !== null) {
          setReviewList([...reviewList, ...result.data]);
          lastDocSnapshot.current = result.lastDocSnapshot;
        } else {
          setEndReached(true);
        }
      })
      .catch(err => {
        console.error(err);
      });
  }

  const handleEditClick = () => {
    setInputText(userReview.review);
    if (userReview.link) {
      setVideoUrlInput(userReview.link);
      setShowVideoLinkInput(true);
    }
    setIsEditing(true);
  }

  const handleCancelClick = () => {
    setIsEditing(false);
  }

  const handleSaveReview = () => {
    if (videoUrlInput.length > 0) {
      saveReview();
    } else if (inputText.length > 0) {
      if (inputText.split(" ").length > 25 || inputText.length > 75) {
        saveReview();
      } else {
        setInputError("Enter atleast 25 words to write a review");
      }
    } else {
      setInputError("Enter some content to write a review");
    }
  }

  const saveReview = () => {
    if (isEditing) {
      let data = { ...userReview, review: inputText, link: videoUrlInput };

      updateReview(source, data, userReview.id)
        .then(result => {
          setUserReview(result);
          setIsEditing(false);
          dispatch(updateUserReview(data));
        })
        .catch(err => {
          setInputError(err.message);
          console.error(err);
        });
    } else {
      let sourceData = list.filter(item => item.id === sourceId)[0];

      let data = {
        [`${source}Id`]: sourceId,
        review: inputText,
        userId: user.id,
        userName: user.name,
        sourceName: sourceData.name || sourceData.title,
        sourceCoverUrl: sourceData.cover_url || "",
        sourceType: source, sourceId,
        link: videoUrlInput
      };

      addReview(source, data)
        .then(result => {
          updateReduxCount(source, sourceId);
          setUserReview(result);
          dispatch(addUserReview(data));
        })
        .catch(err => {
          setInputError(err.message);
          console.error(err);
        });

    }
  }

  const removeReview = () => {
    deleteReview(source, userReview.id)
      .then(_ => {
        setInputText("");
        setInputError("");
        setVideoUrlInput("");
        setShowVideoLinkInput(false);
        setUserReview(null);
        setIsEditing(false);
        reduceReduxCount(source, sourceId);
        dispatch(removeUserReview(userReview.id));
      })
      .catch(err => {
        setInputError(err.message);
        console.error(err);
      });
  }

  const updateReduxCount = (source, id) => {
    switch (source) {
      case "movie":
        dispatch(updateMovieReviewCount({ id: id }));
        return "movies";
      case "celebrity":
        dispatch(updateCelebrityReviewCount({ id: id }));
        return "celebrity";
      case "product":
        dispatch(updateProductReviewCount({ id: id }));
        return "products";
      case "brand":
        dispatch(updateBrandReviewCount({ id: id }));
        return "brands";
      case "news":
        dispatch(updateNewsReviewCount({ id: id }));
        return "news";
      case "shows":
        dispatch(updateShowReviewCount({ id: id }));
        return "shows";
      default:
        return "";
    }
  }

  const reduceReduxCount = (source, id) => {
    switch (source) {
      case "movie":
        dispatch(reduceMovieReviewCount({ id: id }));
        return "movies";
      case "celebrity":
        dispatch(reduceCelebrityReviewCount({ id: id }));
        return "celebrity";
      case "product":
        dispatch(reduceProductReviewCount({ id: id }));
        return "products";
      case "brand":
        dispatch(reduceBrandReviewCount({ id: id }));
        return "brands";
      case "news":
        dispatch(reduceNewsReviewCount({ id: id }));
        return "news";
      case "shows":
        dispatch(reduceShowReviewCount({ id: id }));
        return "shows";
      default:
        return "";
    }
  }

  const renderInputBox = () => (
    <div className="w-full px-2 mb-1">
      <div className="px-2 bg-gray-900 rounded-md border border-gray-600">
        <div className="flex items-end justify-between py-1 px-1 my-1">
          <div className="mr-3 w-full outline-none">
            <textarea
              value={inputText}
              maxLength={600}
              onChange={(e) => setInputText(e.target.value)}
              className={`px-3 w-full text-gray-400 font-sans rounded-xl border border-gray-800 bg-gray-900 outline-none placeholder-gray-600 ${isMobileView ? "h-20" : "h-32"}`}
              placeholder="Your Review..." />
            {showVideoLinkInput
              ? <input
                value={videoUrlInput}
                className={`mt-2 w-full text-gray-400 font-sans bg-gray-900 border-b-1 border-gray-400 focus:border-primary outline-none placeholder-gray-600 ${isMobileView ? "text-xs" : "text-sm"}`}
                onChange={(e) => setVideoUrlInput(e.target.value)}
                placeholder="Video Link"
              />
              : <></>}
          </div>
          {showVideoLinkInput ?
            <IoVideocamOffOutline onClick={() => setShowVideoLinkInput(false)} className="text-primary w-6 h-6 cursor-pointer mx-2" /> :
            <IoVideocamOutline onClick={() => setShowVideoLinkInput(true)} className="text-primary w-6 h-6 cursor-pointer mx-2" />}
          <IoSend onClick={() => handleSaveReview()} className="text-primary w-6 h-6 cursor-pointer ml-2" />
        </div>
      </div>
      <span className="text-red-500 text-sm">{inputError}</span>
      {isEditing
        ? <div className={`flex items-center justify-end ${isMobileView ? "text-sm" : "text-md"}`}>
          <button
            className="cursor-pointer text-gray-500 border border-gray-500 rounded-md px-2 py-1 m-2"
            onClick={() => handleCancelClick()}>
            Cancel
          </button>
          <button
            className="cursor-pointer text-white bg-red-400 rounded-md px-2 py-1 m-2"
            onClick={() => removeReview()}>
            Delete
          </button>
        </div>
        : <></>
      }
    </div>
  );

  return (
    <div className="bg-gray-900 py-2">
      {!isEditing && userReview ?
        <div>
          <span className={`text-gray-200 ${isMobileView ? "text-xs" : "text-md"}`}>My Review</span>
          {userReview && <UserReviewCard showActionButtons={true} review={userReview} handleEditClick={handleEditClick} handleRemoveClick={removeReview} />}
        </div> :
        <>{renderInputBox(userReview)}</>
      }

      {reviewList && reviewList.length > 0 ?
        <div className="mt-4 pb-4">
          <span className={`text-gray-200 ${isMobileView ? "text-xs" : "text-md"}`}>All Reviews</span>
          {reviewList.map((review, index) => (
            <ReviewCard key={review.id} review={review} index={index + 1} />
          ))}

          {!endReached
            ? <div className="text-center pt-2 pb-4">
              <span
                className={`cursor-pointer text-gray-200 border border-gray-200 p-1 m-2 ${isMobileView ? "text-xs" : "text-md"}`}
                onClick={() => handleLoadMoreClick()}>
                Load More
              </span>
            </div>
            : <></>}
        </div>
        : <></>}
    </div>
  )
}

export default ReviewList;