import { useEffect, useState } from "react";

import ReviewCard from "../../components/ReviewCard";

import { useAppDispatch, useAppSelector, loadTopReview, loadAdvertisement } from "../../redux";

function TopReviewTemplate() {
  const dispatch = useAppDispatch();

  const [adsFilter, setAdsFilter] = useState(null)

  const advertisements = useAppSelector((state) => state.advertisement.advertisements);
  const topReview = useAppSelector(state => state.topReview.top_review);
  const isTopReviewEndReached = useAppSelector(state => state.topReview.isEndReached);

  useEffect(() => {
    if (advertisements.length <= 0)
      dispatch(loadAdvertisement())

    if (!isTopReviewEndReached && topReview.length <= 0)
      dispatch(loadTopReview())
  }, [dispatch]);

  useEffect(() => {
    setAdsFilter(advertisements.filter(item => item.type === "topreview" && item.orientatin === "horizontal"));
  }, [advertisements]);

  return <div className="bg-gray-900">
    <div className="flex justify-center">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-1 max-w-screen-xl w-full">
        {topReview && topReview.map((item, index) => (
          <ReviewCard key={item.id} review={item} index={index + 1} />
        ))}
      </div>
    </div>
  </div>
}

export { TopReviewTemplate };
