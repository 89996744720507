import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useAppSelector, useAppDispatch, loadBrands, addBrand } from "../../redux";

import { loadBrandWithId } from "../../service/brand.service";

import { BrandDetails } from "../organisms";

import Footer from "../../components/Footer";
import BannerSlider from "../../components/banner_slider";
import BackArrowNavigation from "../../components/BackArrowNavigation";

function BrandDetailsTemplate() {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const brands = useAppSelector((state) => state.brand.brands);
  const advertisements = useAppSelector((state) => state.advertisement.advertisements);

  const [rank, setRank] = useState(null)
  const [link, setLink] = useState(null)
  const [currentBrand, setCurrentBrand] = useState(null);

  const [adsFilter, setAdsFilter] = useState(null);
  const [verticalAd1, setVerticalAd1] = useState(null);
  const [verticalAd2, setVerticalAd2] = useState(null);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [link]);

  useEffect(() => {
    if (brands.length <= 0)
      dispatch(loadBrands())
  }, [brands, dispatch]);

  useEffect(() => {
    if (brands.length > 0 && currentBrand === null && link !== null) {
      if (brands.some(product => product.id === link))
        setCurrentBrand(brands.filter((product) => product.id === link)[0]);
      else
        loadBrandWithId(link)
          .then(result => {
            dispatch(addBrand(result));
            setCurrentBrand(result);
          })
          .catch(e => console.error(e));
    }
  }, [currentBrand, dispatch, link, brands]);

  useEffect(() => {
    let path = location.pathname;
    let idWithRank = path.slice(path.lastIndexOf("/") + 1, path.length);
    if (idWithRank.includes("-")) {
      let id = idWithRank.slice(0, idWithRank.lastIndexOf("-"));
      let rank = idWithRank.slice(idWithRank.lastIndexOf("-") + 1, idWithRank.length);
      setRank(rank)
      setLink(id)
    } else {
      setLink(idWithRank)
    }
  }, [location.pathname]);

  useEffect(() => {
    setAdsFilter(advertisements.filter(item => item.type === "brands" && item.orientation === "horizontal"));
    let verticalAds = advertisements.filter(item => item.type === "brands" && item.orientation === "vertical");

    if (verticalAds.length > 0) {
      setVerticalAd1(verticalAds[Math.floor(Math.random(verticalAds.length) * verticalAds.length)]);
      setVerticalAd2(verticalAds[Math.floor(Math.random(verticalAds.length) * verticalAds.length)]);
    }
  }, [advertisements]);

  return (
    <div className="bg-gray-900">
      <BackArrowNavigation />

      <div className="pt-10">
        {adsFilter && <BannerSlider ads={adsFilter} />}

        <div className="mt-2 pb-2">
          {currentBrand &&
            <>
              <div className="flex justify-center">
                <div className="max-w-screen-xl flex">
                  {/* {verticalAd1 &&
                    <a href={verticalAd1.link} target="_blank" rel="noreferrer">
                      <div className="md:w-40 lg:object-fill md:object-contain lg:w-72 w-0">
                        <img
                          src={verticalAd1.banner_url}
                          alt="" />
                      </div>
                    </a>
                  } */}

                  <div className="mx-4">
                    <BrandDetails
                      rank={rank}
                      brand_id={link}
                      brand={currentBrand}
                      info={currentBrand.info}
                    />

                    {/* <Advertisement type="brands" /> */}
                  </div>

                  {/* {verticalAd2 &&
                    <a href={verticalAd2.link} target="_blank" rel="noreferrer">
                      <div className="md:w-40 lg:object-fill md:object-contain lg:w-72 w-0">
                        <img
                          src={verticalAd2.banner_url}
                          alt="" />
                      </div>
                    </a>
                  } */}
                </div>
              </div>
            </>
          }
        </div>
      </div>

      <Footer />
    </div>
  )
}

export { BrandDetailsTemplate }