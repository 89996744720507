import { Link } from "react-router-dom";

function NotExistPage() {
    return (
        <main className="min-h-screen flex-grow bg-gray-900">
            <div className="flex flex-col justify-center items-center p-6 w-full mx-auto">
                <span className="my-4 text-xl md:text-2xl text-gray-300">
                    User does not exists, try registering with us
                </span>
                <Link
                    to="/register"
                    className="bg-primary rounded-sm py-2 px-4 mt-6 text-sm md:text-lg text-white"
                >
                    Register Now
                </Link>
            </div>
        </main>
    )
}

export default NotExistPage