import { useEffect } from "react";
import TimeAgo from 'react-timeago';

import dummyuserProfile from "../assets/images/dummyUser.png";

import { useAppSelector, useAppDispatch, loadNotification, loadMoreNotification } from "../redux";

function NotificationsPage() {
    const dispatch = useAppDispatch();

    const user = useAppSelector(state => state.user.profile);
    const notifications = useAppSelector(state => state.notification.notifications);
    const notificationEndReached = useAppSelector(state => state.notification.isEndReached);

    useEffect(() => {
        if (!notificationEndReached && notifications.length <= 0) {
            dispatch(loadNotification(user.id));
        }
    }, [dispatch]);

    const handleLoadMoreClick = () => {
        dispatch(loadMoreNotification(user.id));
    }

    return (
        <main className="min-h-screen bg-gray-900">
            <div className="flex flex-col items-center">
                {notifications.map(item => (
                    <div className="w-full flex items-center justify-around p-2 bg-gray-700 my-1">
                        {item.cover_url ?
                            <img src={item.cover_url} className="w-10 h-10 rounded-full" alt="" />
                            : <img src={dummyuserProfile} className="w-10 h-10 rounded-full" alt="" />
                        }
                        <div className="flex flex-col items-start w-3/4">
                            <div className="flex items-center">
                                <span className="text-gray-200 text-sm font-semibold -mt-1">{item.name}</span>
                                <div className="w-1 h-1 bg-gray-500 rounded-full mx-2" />
                                <TimeAgo
                                    className="text-gray-500 text-xs"
                                    date={item.createdAt ? item.createdAt.toDate() : new Date()} />
                            </div>
                            <span className="text-gray-400 text-sm text-wrap">{item.message}</span>
                        </div>
                    </div>
                ))}

                {/* <span className="w-1/3 text-center border border-gray-300 text-gray-300 px-2 py-1 my-4" onClick={() => handleLoadMoreClick()}>Load More</span> */}
            </div>
        </main>
    )
}

export default NotificationsPage