import { Link, useLocation } from "react-router-dom";

const navigationList = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "News",
    path: "/top-news",
  },
  {
    title: "Movies",
    path: "/movies",
  },
  {
    title: "Stars",
    path: "/celebrity",
  },
  
  {
    title: "Rental",
    path: "/Offers",
  },
  {
    title: "Blog",
    path: "/Jobpage",
  },
];
// {
//   title: "TopReview",
//   path: "/top-review",
// },
function NavigationMobile() {
  const location = useLocation();

  return (
    <div style={{backgroundColor:'#0f172a'}} className="flex flex-row w-full overflow-scroll mb-2 py-2">
      {navigationList.map((item) => (
        <Link to={item.path} key={item.title}
          className={`mx-5 cursor-pointer  ${(location.pathname === "/" && location.pathname === item.path) ||
            location.pathname === item.path ||
            (item.path === "/" && location.pathname.includes("Home")) ||
            (location.pathname !== "/" && item.path !== "/" && (location.pathname.includes(item.path)))
            ? "border-primary border-b-2 text-primary" : "text-white"}`}>
          {item.title}
        </Link>
      ))}
    </div>
  );
}

export default NavigationMobile;
