import { useEffect, useState } from "react";

import ShowsCard from "../../components/ShowsCard";
import BannerSlider from "../../components/banner_slider";

import { useAppDispatch, useAppSelector, loadAdvertisement, loadShows } from "../../redux";

import { useIsMobileViewContext } from '../../context/isMobileView';

function ShowsTemplate() {
  const dispatch = useAppDispatch();

  const { isMobileView } = useIsMobileViewContext();

  const [adsFilter, setAdsFilter] = useState(null)

  const shows = useAppSelector((state) => state.shows.shows);
  const isShowEndReached = useAppSelector((state) => state.shows.isEndReached);
  const advertisements = useAppSelector((state) => state.advertisement.advertisements);

  useEffect(() => {
    if (!isShowEndReached && shows.length <= 0)
      dispatch(loadShows());

    if (advertisements.length <= 0)
      dispatch(loadAdvertisement())
  }, [dispatch]);

  useEffect(() => {
    setAdsFilter(advertisements.filter(item => item.type === "shows" && item.orientation === "horizontal"))
  }, [advertisements])

  return <div className="bg-gray-900">
    <div className={`w-full mx-auto ${adsFilter && adsFilter.length > 1 ? isMobileView ? "px-6" : "px-10" : ""}`}>
      {adsFilter && <BannerSlider ads={adsFilter} />}
    </div>

    {shows && <ShowsCard list={shows} />}
  </div>;
}

export { ShowsTemplate };
