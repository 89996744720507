import BackArrowNavigation from '../components/BackArrowNavigation';

import { ReviewTemplate } from "../view/templates";

function ReviewPage() {

  return (
    <main className="flex-grow min-h-screen bg-gray-900">
      <div className="bg-gray-900">
        <BackArrowNavigation />

        <div className="pt-10 max-w-xl mx-auto px-4">
          <ReviewTemplate />
        </div>
      </div>
    </main>
  )
}

export default ReviewPage;