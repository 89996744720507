import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  collection,
  db,
  getDocs,
  limit,
  startAfter,
  ref,
  storage,
  getDownloadURL,
} from "../../firebase";

const initialState = {
  notifications: [],
  isEndReached: false,
  isLoading: false,
  isError: false,
  error: "",
};

var lastDocSnap = null;

export const loadNotification = createAsyncThunk(
  "notification/loadNotification",
  async (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const querySnapShot = await getDocs(
          collection(db, "users", id, "notifications"),
          limit(50)
        );

        let list = [];
        if (querySnapShot.docs.length > 0) {
          querySnapShot.forEach((doc) => {
            list.push({ ...doc.data() });
          });
          lastDocSnap = querySnapShot.docs[querySnapShot.docs.length - 1];
          loadProfileImages(0, list, resolve);
        } else {
          resolve(list);
        }
      } catch (e) {
        reject(e.message);
      }
    });
  }
);

export const loadMoreNotification = createAsyncThunk(
  "notification/loadMoreNotification",
  async (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const querySnapShot = await getDocs(
          collection(db, "users", id, "notifications"),
          startAfter(lastDocSnap),
          limit(10)
        );

        let list = [];
        if (querySnapShot.docs.length > 0) {
          querySnapShot.forEach((doc) => {
            list.push({ ...doc.data() });
          });
          lastDocSnap = querySnapShot.docs[querySnapShot.docs.length - 1];
          loadProfileImages(0, list, resolve);
        } else {
          resolve(list);
        }
      } catch (e) {
        reject(e.message);
      }
    });
  }
);

const loadProfileImages = async (index, list, resolve) => {
  if (index < list.length) {
    try {
      let data = list[index];
      let fileName = `user/${data.userId}.jpg`;
      const storageRef = ref(storage, fileName);
      let downloadUrl = await getDownloadURL(storageRef);
      list[index].cover_url = downloadUrl;
      loadProfileImages(index + 1, list, resolve);
    } catch (err) {
      loadProfileImages(index + 1, list, resolve);
    }
  } else {
    resolve(list);
  }
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  extraReducers: (builder) => {
    //loadNotification
    builder.addCase(loadNotification.pending, (state, _) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(loadNotification.fulfilled, (state, { payload }) => {
      state.notifications = payload;
      state.isEndReached = payload.length < 1;
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(loadNotification.rejected, (state, { error }) => {
      state.isLoading = false;
      state.isError = false;
      state.error = error.message;
    });

    //loadMoreNotification
    builder.addCase(loadMoreNotification.pending, (state, _) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(loadMoreNotification.fulfilled, (state, { payload }) => {
      state.notifications = [...state.notifications, ...payload];
      state.isEndReached = payload.length < 1;
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    });
    builder.addCase(loadMoreNotification.rejected, (state, { error }) => {
      state.isLoading = false;
      state.isError = false;
      state.error = error.message;
    });
  },
});

export const notificationReducer = notificationSlice.reducer;
