import React, { useEffect, useRef, useState } from 'react'
import message from '../assets/images/message.png'
import { IoLocationOutline } from "react-icons/io5";
import { RiDeleteBin6Line } from 'react-icons/ri'
import BackArrowNavigation from '../components/BackArrowNavigation';
import { useNavigate } from 'react-router-dom';
import { BiArrowBack, BiLike, BiSolidLike } from 'react-icons/bi';
import { FaAngleRight } from 'react-icons/fa';
import dummyUser from '../assets/images/dummyUser.png'
import axios from 'axios';
import { GetTheData } from '../components/Api/FetchData';
import { useAppSelector } from '../redux'
function JobPage() {

    const array = [
        {
            id: 1,
            title: 'Jio Fiber Associate',
            company: 'Jio',
            experience: '0 - 1 years',
            salary: '1.5-2 Lacs P.A.',
            location: 'Chennai( Kilpauk, Madhavaram, TVK Nagar, Ambattur, Anna Nagar, Royapuram, Thiruvallur, Porur )',
            time: '5 days ago',
            Openings: '70',
            cImage: 'https://img.naukimg.com/logo_images/groups/v1/2095704.gif',
            Timings: 'Full-time',
            skils: 'Fiber work'
        },
        {
            id: 2,
            title: 'Scala Developer',
            company: 'Capgemini',
            experience: '6 - 11 years',
            salary: 'Not Disclosed',
            location: 'Chennai, Bengaluru, Mumbai (All Areas)',
            time: '1 days ago',
            Openings: '10',
            cImage: 'https://img.naukimg.com/logo_images/groups/v1/1288.gif',
            Timings: '',
            skils: 'Java,HTML CSS Java Script'
        },
        {
            id: 3,
            title: 'Immediate Hiring For Title Searcher',
            company: 'Dyuti Technologies',
            experience: '1-2 Years',
            salary: 'Not Disclosed',
            location: 'Bangalore/Bengaluru( Basavangudi, Chamarajapet, Srinagar, Jayanagar, JP Nagar )',
            time: '7 days ago',
            Openings: '30',
            cImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1Dvmbuv3O7OtTmtn2e-8brptizrR-8rWPWBMKoyRvPA&s',
            Timings: 'Full-time',
            skils: 'Java,HTML CSS Java Script'
        },

    ]
    const handleBackClick = () => {
        setOpenPostPage(false)
    }
    const navigate = useNavigate()
    const [openPostPage, setOpenPostPage] = useState(false)
    const [BlogBanner, setBlogBanner] = useState(null)
    const [BlogGallery, setBlogGallery] = useState(null)
    function BlogGalleryFunction(e) {
        let temp = [...e.target.files];
        if (temp.length >= 5) {
            setBlogGallery(temp.slice(0, 5));
        } else
            setBlogGallery(temp);
    }
    function DeleteBlogGalleryItem(index) {
        let temp = [...BlogGallery]
        temp.splice(index, 1);
        setBlogGallery(temp)
        if (temp.length == 0) {
            setBlogGallery(null)
        }
    }
    useEffect(() => {
        GetTheBlog()
    }, [])

    const [userName, setUserName] = useState('')
    const [blogName, setBlogName] = useState('')
    const [blogDesc, setBlogDesc] = useState('')
    const [blogYt, setBlogYt] = useState('')

    function PostTheBlog() {
        if (blogName.length > 0 && blogDesc.length > 0 && blogYt.length > 0 && BlogBanner != null && BlogGallery != null) {
            const data =
            {
                "content": blogDesc,
                "title": blogName,
                "postedBy": user.name,
                "creationTime": new Date().getUTCFullYear() + '-' + (new Date().getUTCMonth() + 1) + '-' + new Date().getUTCDate() + '-' + new Date().getUTCHours() + '-' + new Date().getUTCMinutes() + '-' + new Date().getUTCSeconds(),
                "updatedTime": new Date().getUTCFullYear() + '-' + (new Date().getUTCMonth() + 1) + '-' + new Date().getUTCDate() + '-' + new Date().getUTCHours() + '-' + new Date().getUTCMinutes() + '-' + new Date().getUTCSeconds(),
                "status": 0,
                "likesCount": 0,
                "youtubeVideoId": blogYt
            }
            try {
                axios.post('https://cinimini-apis-3nugbllihq-el.a.run.app/blog', data)
                    .then((responce) => {
                        if (responce.status == 200) {
                            alert('Your Blog will be Visible Once It Will Be Approved')
                            UploadThumbIntoBucket(responce.data.insertId)
                            UploadGalleryIntoBucket(responce.data.insertId)
                            PatchTheUploadedImage(responce.data.insertId)

                        }
                    }).catch((error) => {
                        console.log(error.message);
                    })
            } catch (error) {
                console.log(error.message);
            }
        } else {
            alert('Fireld are missing')
        }
    }
    function UploadThumbIntoBucket(_id) {
        try {
            let formdata = new FormData()
            formdata.append("file", BlogBanner)
            axios.post(`https://cinimini-apis-3nugbllihq-el.a.run.app/upload/blogs/${_id}`, formdata,
                {
                    headers:
                    {
                        "Content-Type": 'multipart/form-data'
                    }
                }).then((responce) => {
                    if (responce.status == 200) {
                        console.log(responce.data);
                    }
                }).catch((error) => {
                    console.log(error.message);
                })
        } catch (error) {
            console.log(error);
        }
    }

    function UploadGalleryIntoBucket(_id) {
        try {

            for (let i = 0; i < BlogGallery.length; i++) {
                let formdata = new FormData()
                formdata.append("file", BlogGallery[i])
                axios.post(`https://cinimini-apis-3nugbllihq-el.a.run.app/upload/blogs/${_id}`, formdata,
                    {
                        headers:
                        {
                            "Content-Type": 'multipart/form-data'
                        }
                    }).then((responce) => {
                        if (responce.status == 200) {
                            console.log(responce.data);
                        }
                    }).catch((error) => {
                        console.log(error.message);
                    })
            }
        } catch (error) {
            console.log(error);
        }
    }
    function PatchTheUploadedImage(_id) {
        let temp = [];
        for (let i = 0; i < BlogGallery.length; i++) {
            temp.push(BlogGallery[i].name);
        }
        try {
            const data = {
                "bannersUrl": JSON.stringify(temp),
                "updatedTime": new Date().getUTCFullYear() + '-' + (new Date().getUTCMonth() + 1) + '-' + new Date().getUTCDate() + '-' + new Date().getUTCHours() + '-' + new Date().getUTCMinutes() + '-' + new Date().getUTCSeconds(),
                "thumbnailUrl": BlogBanner.name,
            }
            axios.patch('https://cinimini-apis-3nugbllihq-el.a.run.app/blog/' + _id, data)
                .then((responce) => {
                    if (responce.status == 200) {
                        console.log(responce.data);
                        setBlogName('')
                        setBlogDesc('')
                        setBlogYt('')
                        setUserName('')
                        setBlogGallery(null)
                        setBlogBanner(null)
                        setOpenPostPage(false)
                    }
                }).catch((error) => {
                    console.log(error.message);
                })
        } catch (error) {
console.log(error.message);
        }
    }
    const [blogData, setBlogData] = useState([])
    async function GetTheBlog() {
        try {
            const response = await GetTheData('blog/approved')
            setBlogData(response.data)
            SortingBlogs(response.data)
        } catch (error) {
            console.log(error.message);
        }
    }

    function PatchTheLikeCount(_id) {
        
        if(user.id!=null){
            try {
                let colllection = blogData.find(e => e.id == _id)
                let temp = []
                if (colllection.likes === null || JSON.parse(colllection.likes).length == 0) {
                    temp.push(user.id)
                } else if (colllection.likes != null) {
                    let temp = [...JSON.parse(colllection.likes)]
                    let result = temp.findIndex(e => e == user.id)
                    if (result == -1) {
                        temp.push(user.id)
                    } else {
                        temp.splice(result, 1)
                    }
                }
                console.log(temp);
                const data = {
                    "likes": temp.length==0?null:JSON.stringify(temp),
                }
                axios.patch('https://cinimini-apis-3nugbllihq-el.a.run.app/blog/' + _id, data)
                    .then((responce) => {
                        if (responce.status == 200) {
                            console.log(responce.data);
                            let tempBlogData = [...blogData]
                            tempBlogData.find(e => e.id == _id).likes = JSON.stringify(temp)
                            setBlogData(tempBlogData)
                            SortingBlogs(tempBlogData)
                        }
                    }).catch((error) => {
                        console.log(error.message);
                    })
            } catch (error) {
                console.log(error.message);
            }
        }
        else{
            alert('Login Do First')
        }
        
    }

    const [SortedArray, setSortedArray] = useState([])
    function SortingBlogs(data) {
        let temp = [...data]
        temp.sort((firstItem, secondItem) => JSON.parse(secondItem.likes).length - JSON.parse(firstItem.likes).length );
        setSortedArray(temp)
    }
    const user = useAppSelector(state => state.user.profile)
    console.log(user);
    
    function PostFunction() {
        if (user.id != null) {
            setOpenPostPage(true)
        } else {
            alert('Do Login First')
        }

    }
    return (
        <div className='w-full bg-[#0e162b]  overflow-auto flex flex-col items-center pb-20 sm:pb-0'>
            {openPostPage ?
                <div className='w-full z-[9] absolute bg-[#0e162b]'>
                    <div className="h-10 bg-gray-900 rounded-xl m-2 flex flex-row ml-10 items-center px-3 text-gray-200 text-sm font-bold cursor-pointer z-10 fixed top-[100px]"
                        onClick={() => handleBackClick()}>
                        <BiArrowBack className="w-6 h-6 " /><span>Back</span>
                    </div>
                    <div className='max-w-4xl mx-auto flex flex-col gap-y-5 pb-20 px-5 pt-10'>
                        <div className='flex flex-col items-center w-full mt-20'>
                            <div className='gap-y-2 flex w-full flex-col'>
                                <h1 className='text-white text-sm font-semibold mb-2'>Blog Banner *</h1>
                                <div className='w-full self-end relative h-[200px] lg:h-[440px]'>
                                    <div onClick={() => setBlogBanner(null)} className='absolute w-6 h-6 -top-[10px] -right-[10px] bg-white rounded-full flex flex-col items-center justify-center '>
                                        <RiDeleteBin6Line className='w-4 h-4 cursor-pointer' />
                                    </div>
                                    {BlogBanner != null ? <img className='w-full h-full object-fill' src={URL.createObjectURL(BlogBanner)} /> : <div className='w-full h-full bg-white/75' />}
                                </div>
                                <div className='w-[110px] mt-5 flex  flex-col items-center justify-center rounded-sm h-[34px]  bg-white hov hover:bg-primary text-white duration-300 cursor-pointer' >
                                    <h5 className='text-[13px] cursor-pointer text-black font-bold'>Upload Photo</h5>
                                    <input onChange={(e) => setBlogBanner(e.target.files[0])} className='absolute w-[105px] bg-red-500 cursor-pointer opacity-0' type='file' />
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col  gap-5 w-full'>
                            {/* <div className="relative w-full">
                                <h1 className='text-white text-sm font-semibold mb-2'>User Name *</h1>
                                <input value={userName} onChange={(e) => setUserName(e.target.value)} type="search" id="default-search" className="block w-full p-3 ps-4 outline-none font-semibold text-base text-gray-900 rounded-lg bg-gray-50 " placeholder="User Name" />
                            </div> */}
                            <div className="relative w-full">
                                <h1 className='text-white text-sm font-semibold mb-2'>Blog Title *</h1>
                                <input value={blogName} onChange={(e) => setBlogName(e.target.value)} type="search" id="default-search" className="block w-full p-3 ps-4 outline-none font-semibold text-base text-gray-900 rounded-lg bg-gray-50 " placeholder="Blog Title" />
                            </div>
                            <div className="relative w-full">
                                <h1 className='text-white text-sm font-semibold mb-2'>Blog Description *</h1>
                                <textarea value={blogDesc} onChange={(e) => setBlogDesc(e.target.value)} type="search" id="default-search" className="block w-full p-3 ps-4 outline-none font-semibold text-base text-gray-900 rounded-lg bg-gray-50 " placeholder="Blog Description" />
                            </div>
                            <div className="relative w-full">
                                <h1 className='text-white text-sm font-semibold mb-2'>Youtube Url *</h1>
                                <input value={blogYt} onChange={(e) => setBlogYt(e.target.value)} type="search" id="default-search" className="block w-full p-3 ps-4 outline-none font-semibold text-base text-gray-900 rounded-lg bg-gray-50 " placeholder="Blog Youtube Url" />
                            </div>
                            <h1 className='text-white text-sm font-semibold '>Blog Banner *</h1>
                            <div className='gap-5 w-full grid grid-cols-1 lg:grid-cols-3 '>
                                {BlogGallery != null ? BlogGallery.map((e, index) =>
                                    <div className='w-full self-end relative '>
                                        <div onClick={() => DeleteBlogGalleryItem(index)} className='absolute w-6 h-6 -top-[10px] -right-[10px] bg-white rounded-full flex flex-col items-center justify-center '>
                                            <RiDeleteBin6Line className='w-4 h-4 cursor-pointer' />
                                        </div>
                                        <img key={index} className='w-full h-[160px] object-fill' src={URL.createObjectURL(e)} />
                                    </div>
                                ) : <div className='w-full bg-white/75 h-[160px]' />}
                            </div>
                            <div className='w-[110px] mt-5 flex  flex-col items-center justify-center rounded-sm h-[34px] cursor-pointer  bg-white hov hover:bg-primary text-white duration-300 ' >
                                <h5 className='text-[13px] cursor-pointer text-black font-bold'>Upload Photo</h5>
                                <input multiple={true} onChange={(e) => BlogGalleryFunction(e)} className='absolute w-[105px] bg-red-500 cursor-pointer opacity-0' type='file' />
                            </div>
                        </div>
                        <div onClick={() => PostTheBlog()} className=" mt-5 ">
                            <button className="text-white bg-primary hover:bg-primary/75 px-10 rounded-full py-3 font-bold">Post The Blog</button>
                        </div>
                    </div>
                </div>
                : null}
            <div className='container flex flex-col sm:flex-row items-center lg:mt-10 mb-5 px-2 lg:px-8 justify-between'>
                <div className='flex text-white flex-row  items-center lg:mt-0 mt-16 '>
                    <h1 onClick={() => navigate('/')} className=' font-bold cursor-pointer hover:text-primary duration-200'>Home</h1>
                    <FaAngleRight className='' />
                    <h1 className='text-gray-300 text-sm'>Blog</h1>
                </div>
                <div onClick={() => PostFunction()} className="">
                    <button className="text-white bg-primary hover:bg-primary/75 md:mt-0 mt-5 px-8 rounded-full py-1 font-bold">Post</button>
                </div>
            </div>
            {blogData.length > 0 ? 
            <div className='container  flex flex-col xl:flex-row  gap-10  mx-auto px-2 '>
                <div className=' w-full  xl:w-[70%] flex flex-col lg:px-5'>
                    <div className='w-full flex flex-col gap-3 mb-10'>
                        <div className='flex flex-row items-center gap-2'>
                            <img className='md:w-[40px] w-[35px] md:h-[40px] h-[35px]' src={dummyUser} />
                            <h1 className='text-primary text-base md:text-lg font-bold tracking-wider' >{blogData[0]?.postedBy}</h1>
                        </div>
                        <img onClick={() => navigate('/JobpageDescription', { state: { data: blogData[0] } })} className='w-full h-[500px] cursor-pointer object-top object-cover' src={`https://storage.googleapis.com/cinimini-console/blogs/${blogData[0]?.id}/${blogData[0]?.thumbnailUrl}`} />
                        <div className='flex flex-col items-start'>
                            <section className='bg-primary px-3 py-1 text-white rounded-sm'>
                                <h1 className='text-[12px] font-bold'>Featured</h1>
                            </section>
                        </div>
                        <h1 onClick={() => navigate('/JobpageDescription', { state: { data: blogData[0] } })} className='hover:text-primary text-white font-bold cursor-pointer duration-300 text-xl lg:text-2xl'>
                            {blogData[0]?.title}</h1>
                        <div className='flex flex-row items-center gap-x-3'>
                            <h1 className='text-[14px] font-bold text-primary'>{blogData[0]?.postedBy}</h1>
                            <h1 className='text-[12px] font-bold text-gray-100'>.</h1>
                            <h1 className='text-[12px] font-bold text-gray-100'>{new Date(blogData[0].creationTime).getUTCDate() + ' ' + (new Date(blogData[0].creationTime).toLocaleDateString('en-US', { month: 'short' })) + ' ' + new Date(blogData[0].creationTime).getUTCFullYear()}</h1>
                        </div>
                        <h1 className='text-gray-300 line-clamp-2'>{blogData[0]?.content}</h1>
                        <div className='flex flex-row items-center gap-x-1'>
                            {blogData[0].likes != null ? JSON.parse(blogData[0].likes).findIndex(e => e == user.id) != -1 ? <BiSolidLike onClick={() => PatchTheLikeCount(blogData[0].id)} className='text-primary cursor-pointer text-lg' /> :
                                <BiLike onClick={() => PatchTheLikeCount(blogData[0].id)} className='text-primary cursor-pointer text-lg' /> :
                                <BiLike onClick={() => PatchTheLikeCount(blogData[0].id)} className='text-primary cursor-pointer text-lg' />}
                            <h1 className='text-gray-500'>{blogData[0].likes != null ? JSON.parse(blogData[0].likes).findIndex(e => e == user.id) != -1 ? 1 : 0 : 0}</h1>
                        </div>
                    </div>
                    {
                        blogData.slice(1).map((e, index) =>
                            <div key={index} className='flex flex-col lg:flex-row  w-full gap-5 lg:mb-10 mb-5 items-center'>
                                <img onClick={() => navigate('/JobpageDescription', { state: { data: e } })} className='lg:w-[480px] cursor-pointer w-full h-full lg:h-[270px]' src={`https://storage.googleapis.com/cinimini-console/blogs/${e.id}/${e.thumbnailUrl}`} />
                                <div className='w-full xl:w-[50%] flex flex-col gap-3'>
                                    <h1 onClick={() => navigate('/JobpageDescription', { state: { data: e } })} className='hover:text-primary text-white font-bold cursor-pointer duration-300  text-xl lg:text-2xl'>{e.title}</h1>
                                    <div className='flex flex-row items-center gap-x-3'>
                                        <h1 className='text-[14px] font-bold text-primary'>{e?.postedBy}</h1>
                                        <h1 className='text-[12px] font-bold text-gray-100'>.</h1>
                                        <h1 className='text-[12px] font-bold text-gray-100'>{new Date(e.creationTime).getUTCDate() + ' ' + (new Date(e.creationTime).toLocaleDateString('en-US', { month: 'short' })) + ' ' + new Date(e.creationTime).getUTCFullYear()}</h1>
                                    </div>
                                    <h1 className='text-gray-300 line-clamp-2'>{e.content}</h1>
                                    <div className='flex flex-row items-center gap-x-1'>
                                        {e.likes != null ? JSON.parse(e.likes).findIndex(e => e == user.id) != -1 ? <BiSolidLike onClick={() => PatchTheLikeCount(e.id)} className='text-primary cursor-pointer text-lg' /> :
                                            <BiLike onClick={() => PatchTheLikeCount(e.id)} className='text-primary cursor-pointer text-lg' /> :
                                            <BiLike onClick={() => PatchTheLikeCount(e.id)} className='text-primary cursor-pointer text-lg' />}
                                        <h1 className='text-gray-500'>{e.likes != null ? JSON.parse(e.likes).findIndex(e => e == user.id) != -1 ? 1 : 0 : 0}</h1>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className='xl:w-[30%] w-full '>
                    <h1 className=' text-white font-semibold mb-4 cursor-pointer duration-300  text-lg lg:text-2xl'>Trending Blogs</h1>
                    {SortedArray.map((e, index) =>
                        <div key={index} className='flex flex-row gap-3 p-2 w-full border-b border-b-gray-500 pb-3 my-5'>
                            <img onClick={() => navigate('/JobpageDescription', { state: { data: e } })} className='w-[120px] h-[80px] cursor-pointer object-cover object-top' src={`https://storage.googleapis.com/cinimini-console/blogs/${e?.id}/${e?.thumbnailUrl}`} />
                            <div className='flex flex-col justify-between'>
                                <h1 onClick={() => navigate('/JobpageDescription', { state: { data: e } })} className='hover:text-primary text-white font-semibold cursor-pointer duration-300  text-lg'>{e.title}</h1>
                                <div className='flex gap-3 flex-row items-center'>
                                    <div className='flex flex-row items-center gap-x-1'>
                                        {e.likes != null ? JSON.parse(e.likes).findIndex(e => e == user.id) != -1 ? <BiSolidLike onClick={() => PatchTheLikeCount(e.id)} className='text-primary cursor-pointer text-lg' /> :
                                            <BiLike onClick={() => PatchTheLikeCount(e.id)} className='text-primary cursor-pointer text-lg' /> :
                                            <BiLike onClick={() => PatchTheLikeCount(e.id)} className='text-primary cursor-pointer text-lg' />}
                                        <h1 className='text-gray-500'>{e.likes != null ? JSON.parse(e.likes).findIndex(e => e == user.id) != -1 ? 1 : 0 : 0}</h1>
                                    </div>
                                    <h1 className='text-[12px] font-bold text-gray-100'>.</h1>
                                    <h1 className='text-[12px] font-bold text-gray-500'>{new Date(e.creationTime).getUTCDate() + ' ' + (new Date(e.creationTime).toLocaleDateString('en-US', { month: 'short' })) + ' ' + new Date(e.creationTime).getUTCFullYear()}</h1>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div> : null}
        </div>
    )
}

export default JobPage

//  <form className="sm:max-w-6xl py-1 mx-auto bg-white flex flex-row mt-5 rounded-full items-center px-5">
//                 <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
//                 <div className="relative">
//                     <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
//                         <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
//                             <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
//                         </svg>
//                     </div>
//                     <input type="search" id="default-search" className="block min-[375px]:w-[300px] min-[325px]:w-full p-4 ps-10 outline-none font-semibold text-base text-gray-900 rounded-lg bg-gray-50 " placeholder="Search Skills / designations / companies" />
//                 </div>
//                 <div className='font-bold sm:block hidden text-gray-500'>|</div>
//                 <div className="ml-4 sm:block hidden">
//                     <select id="select-type" className="block w-[120px] py-5 h-full outline-none mx-4 font-semibold text-base ">
//                         <option className='' value="mockups">Fresher</option>
//                         <option className='' value="logos">1 Year</option>
//                         <option className='' value="logos">2 Year</option>
//                         <option className='' value="logos">3 Year</option>
//                         <option className='' value="logos">4 Year</option>
//                     </select>
//                 </div>
//                 <div className='font-bold  sm:block hidden text-gray-500'>|</div>
//                 <div className="ml-4  sm:block hidden">
//                     <input type="search" id="another-search" className="block w-full p-4 text-base font-semibold text-gray-900 outline-none" placeholder="Search Location" />
//                 </div>
//                 <div className="ml-4  sm:block hidden">
//                     <button className="text-white bg-primary hover:bg-primary/75 px-10 rounded-full py-3 font-bold">Search</button>
//                 </div>
//             </form> 
{/* <div className='container w-full mt-10 px-5 sm:px-0'>
                <h1 className='text-white text-3xl font-bold'>Recent Posts</h1>
                <div className='w-full grid grid-cols-1 sm:grid-cols-3  gap-8 mt-5'>
                    {
                        array.map((e, index) =>
                            <div key={index} className='bg-white flex flex-col justify-between rounded-2xl p-1 py-3'>
                                <div className='flex flex-row items-start '>
                                    <img className='w-[100px] h-[100px] object-cover object-top rounded-xl' src={e.cImage} />
                                    <div className='px-3'>
                                        <h1 className='text-primary font-bold text-lg line-clamp-1  mt-2'>{e.title}</h1>
                                        <h2 className='text-black font-semibold line-clamp-1 text-base '>{e.company}</h2>
                                        <div className='flex flex-row items-center mt-2 gap-x-3 flex-wrap'>
                                            <div className='flex flex-row  items-center gap-x-2'>
                                                <img className='w-5 h-5' src={message} />
                                                <h4 className='font-bold text-sm'>{e.experience}</h4>
                                            </div>
                                            <div className='text-gray-500'>|</div>
                                            <div className='flex flex-row  items-center gap-x-2'>
                                                <h2 className='font-bold'>₹</h2>
                                                <h4 className='font-bold text-sm'>{e.salary}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-row mt-2 gap-x-2 px-3'>
                                    <IoLocationOutline className='w-[25px] h-[25px]' />
                                    <h4 className='font-semibold text-sm'>{e.location}</h4>
                                </div>
                                <h4 className='font-bold text-sm px-3 mt-3'>Key Skills:- <span className='text-gray-900 font-semibold tracking-wider'>{e.skils}</span></h4>
                                <div className='border-b-[2px] border-b-gray-300 my-3' />
                                <div className='flex flex-row items-center justify-between mt-2 gap-x-2 px-3'>
                                    <div className='flex flex-row items-center  gap-x-2 '>
                                        <div className='flex flex-row  gap-x-2 '>
                                            <h3 className='text-sm'>Posted : </h3>
                                            <h3 className='text-sm font-bold'>{e.time}</h3>
                                        </div>
                                        <div className='text-gray-500'>|</div>
                                        <div className='flex flex-row  gap-x-2 '>
                                            <h3 className='text-sm'>Openings : </h3>
                                            <h3 className='text-sm font-bold'>{e.Openings}</h3>
                                        </div>
                                    </div>
                                    <h3 className='font-bold text-primary hover:translate-x-3 duration-300 cursor-pointer'>More</h3>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div> */}

{/* <div className='max-w-4xl mx-auto flex flex-col gap-y-5 pb-20 px-5 pt-10'>
                        <div className="h-10 bg-gray-900 rounded-xl m-2 flex flex-row items-center px-3 text-gray-200 text-sm font-bold cursor-pointer z-10 fixed top-10"
                            onClick={() => handleBackClick()}>
                            <BiArrowBack className="w-6 h-6" /><span>Back</span>
                        </div>
                        <div className='flex flex-col sm:flex-row items-center justify-center sm:justify-between w-full mt-20'>
                            <div className='flex flex-col items-center sm:mb-0 mb-10'>
                                <h1 className='text-primary text-4xl font-bold'>Compnay Details</h1>
                                <h2 className='text-gray-300 text-sm font-normal'>Tell us more about your company, your logo and other details</h2>
                            </div>
                            <div className='gap-y-2 flex flex-col'>
                                <div className='w-[150px] self-end relative h-[150px] rounded-full bg-gray-300'>
                                    <div className='absolute w-6 h-6 right-0 bg-white rounded-full flex flex-col items-center justify-center my-3'>
                                        <RiDeleteBin6Line className='w-4 h-4 cursor-pointer' />
                                    </div>
                                    <img className='w-full h-full rounded-full object-cover object-top' src={'https://img.naukimg.com/logo_images/groups/v1/2095704.gif'} />
                                </div>
                                <div className='w-[110px] self-center flex  flex-col items-center justify-center rounded-sm h-[34px] cursor-pointer  bg-white text-white' >
                                    <h5 className='text-[13px] cursor-pointer text-black font-bold'>Upload Photo</h5>
                                    <input className='absolute w-[105px] bg-red-500 cursor-pointer opacity-0' type='file' />
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col sm:flex-row items-center gap-10 w-full'>
                            <div className="relative w-full">
                                <h1 className='text-white text-sm font-semibold mb-2'>Company Name *</h1>
                                <input type="search" id="default-search" className="block w-full p-3 ps-10 outline-none font-semibold text-base text-gray-900 rounded-lg bg-gray-50 " placeholder="Company Name" />
                            </div>
                            <div className="relative w-full">
                                <h1 className='text-white text-sm font-semibold mb-2'>Website *</h1>
                                <input type="search" id="default-search" className="block w-full p-3 ps-10 outline-none font-semibold text-base text-gray-900 rounded-lg bg-gray-50 " placeholder="Website Url" />
                            </div>
                        </div>
                        <h1 className='text-primary text-4xl mt-5 font-bold'>Job Details</h1>
                        <h2 className='text-gray-300 text-sm font-normal'>Describe the role and responsibilities of the position.</h2>
                        <div className="relative w-full">
                            <h1 className='text-white text-sm font-semibold mb-2'>Job Title *</h1>
                            <input type="search" id="default-search" className="block w-full p-3 ps-10 outline-none font-semibold text-base text-gray-900 rounded-lg bg-gray-50 "
                                placeholder="Job Title" />
                        </div>
                        <div className='flex flex-col sm:flex-row items-center gap-10 w-full'>
                            <div className="relative w-full">
                                <h1 className='text-white text-sm font-semibold mb-2'>Experience *</h1>
                                <select className='w-full p-3 text-gray-900 font-semibold rounded-lg outline-none'>
                                    <option className='text-gray-900 font-semibold'>Fresher</option>
                                    <option className='text-gray-900 font-semibold'>1 Year</option>
                                    <option className='text-gray-900 font-semibold'>2 Year</option>
                                    <option className='text-gray-900 font-semibold'>3 Year</option>
                                    <option className='text-gray-900 font-semibold'>More than 3</option>
                                </select>
                            </div>
                            <div className="relative w-full">
                                <h1 className='text-white text-sm font-sans font-semibold mb-2'>Job Type *</h1>
                                <select className='w-full p-3 text-gray-900 font-semibold rounded-lg outline-none'>
                                    <option className='text-gray-900 font-semibold'>Java Developer</option>
                                    <option className='text-gray-900 font-semibold'>Python Developer</option>
                                    <option className='text-gray-900 font-semibold'>Mern Stack Developer</option>
                                </select>
                            </div>
                        </div>
                        <div className='flex flex-col sm:flex-row items-center gap-10 w-full'>
                            <div className="relative w-full">
                                <h1 className='text-white text-sm font-semibold mb-2'>Minimum Salary / Year *</h1>
                                <input type="search" id="default-search" className="block w-full p-3 ps-10 outline-none font-semibold text-base text-gray-900 rounded-lg bg-gray-50 "
                                    placeholder="Job Title" />
                            </div>
                            <div className="relative w-full">
                                <h1 className='text-white text-sm font-semibold mb-2'>Maximum Salary / year*</h1>
                                <input type="search" id="default-search" className="block w-full p-3 ps-10 outline-none font-semibold text-base text-gray-900 rounded-lg bg-gray-50 "
                                    placeholder="Job Title" />
                            </div>
                        </div>
                        <div className="relative w-full">
                            <h1 className='text-white text-sm font-semibold mb-2'>Company Location *</h1>
                            <input type="search" id="default-search" className="block w-full p-3 ps-10 outline-none font-semibold text-base text-gray-900 rounded-lg bg-gray-50 "
                                placeholder="Job Title" />
                        </div>
                        <div className="relative w-full">
                            <h1 className='text-white text-sm font-semibold mb-2'>JOb Description / Required Skills *</h1>
                            <textarea type="search" id="default-search" className="block w-full h-40 p-3 ps-10 outline-none font-semibold text-base text-gray-900 rounded-lg bg-gray-50 "
                                placeholder="Job Description" />
                        </div>
                        <h1 className='text-primary text-4xl mt-5 font-bold'>Contact Details</h1>
                        <h2 className='text-gray-300 text-sm font-normal'>In order to reach you, we need your contact information. These details stay private.</h2>
                        <div className='flex flex-col sm:flex-row items-center gap-10 w-full'>
                            <div className="relative w-full">
                                <h1 className='text-white text-sm font-semibold mb-2'>Email *</h1>
                                <input type="search" id="default-search" className="block w-full p-3 ps-10 outline-none font-semibold text-base text-gray-900 rounded-lg bg-gray-50 "
                                    placeholder="Email Address" />
                            </div>
                            <div className="relative w-full">
                                <h1 className='text-white text-sm font-semibold mb-2'>Phone Number *</h1>
                                <input type="number" id="default-search" className="block w-full p-3 ps-10 outline-none font-semibold text-base text-gray-900 rounded-lg bg-gray-50 "
                                    placeholder="Phone Number" />
                            </div>
                        </div>
                        <div className=" mt-5 self-center">
                            <button className="text-white bg-primary hover:bg-primary/75 px-10 rounded-full py-3 font-bold">Post The Job</button>
                        </div>
                    </div> */}