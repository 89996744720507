import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import BannerSlider from "../../components/banner_slider";
import Footer from "../../components/Footer";
import BackArrowNavigation from "../../components/BackArrowNavigation";

import { useAppSelector, useAppDispatch, loadCelebrity, addCelebrity } from "../../redux";

import { loadCelebrityWithId } from "../../service/celebrity.service";
import { TextAtom } from "../atoms";
import { useIsMobileViewContext } from "../../context/isMobileView";
import { GetTheData } from "../../components/Api/FetchData";
import ReactPlayer from "react-player";

function CelebrityDetailsTemplate({ }) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const datas = location.state?.data
  // const celebrity = useAppSelector((state) => state.celebrity.celebrity);
  const advertisements = useAppSelector((state) => state.advertisement.advertisements);

  const [adsFilter, setAdsFilter] = useState(null);
  const [verticalAd1, setVerticalAd1] = useState(null);
  const [verticalAd2, setVerticalAd2] = useState(null);
  const [rank, setRank] = useState(null)
  const [link, setLink] = useState(null)

  const [currentCelebrity, setCurrentCelebrity] = useState(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
}, []);
  // useEffect(() => {
  //   if (celebrity.length <= 0)
  //     dispatch(loadCelebrity());
  // }, [celebrity, dispatch]);

  // useEffect(() => {
  //   if (celebrity.length > 0 && currentCelebrity === null && link !== null) {
  //     if (celebrity.some(celeb => celeb.id === link))
  //       setCurrentCelebrity(celebrity.filter(celeb => celeb.id === link)[0]);
  //     else
  //       loadCelebrityWithId(link)
  //         .then(result => {
  //           dispatch(addCelebrity(result));
  //           setCurrentCelebrity(result);
  //         })
  //         .catch(e => console.error(e));
  //   }
  // }, [celebrity, currentCelebrity, dispatch, link])

  useEffect(() => {
    setAdsFilter(advertisements.filter(item => item.type === "celebrity" && item.orientation === "horizontal"));
    let verticalAds = advertisements.filter(item => item.type === "celebrity" && item.orientation === "vertical");
    if (verticalAds.length > 0) {
      setVerticalAd1(verticalAds[Math.floor(Math.random(verticalAds.length) * verticalAds.length)]);
      setVerticalAd2(verticalAds[Math.floor(Math.random(verticalAds.length) * verticalAds.length)]);
    }
  }, [advertisements]);

  // useEffect(() => {
  //   let path = location.pathname;
  //   let idWithRank = path.slice(path.lastIndexOf("/") + 1, path.length);
  //   if (idWithRank.includes("-")) {
  //     let id = idWithRank.slice(0, idWithRank.lastIndexOf("-"));
  //     let rank = idWithRank.slice(idWithRank.lastIndexOf("-") + 1, idWithRank.length);
  //     setRank(rank)
  //     setLink(id)
  //   } else {
  //     setRank(0);
  //     setLink(idWithRank);
  //   }
  // }, [location.pathname]);
  const { isMobileView } = useIsMobileViewContext();

  useEffect(() => {
    FetchingCategory()
  }, [])
  const [CelebrityCategory, setCelebrityCategory] = useState([])
  async function FetchingCategory() {
    try {
      const response = await GetTheData('celebrityCategories')
      console.log(response.data);
      setCelebrityCategory(response.data)
    } catch (error) {
      console.log(error.message);
    }
  }
  function FindCategory(param) {
    console.log(param);
    return CelebrityCategory.length > 0 && CelebrityCategory.find(e => e.id == Number(param)).name

  }
  const returnTheReleaseDate = (_date) => {
    let year = new Date(_date).getUTCFullYear();
    let month =
      (Number(new Date(_date).getUTCMonth()) + 1).toString().length < 2
        ? (Number(new Date(_date).getUTCMonth()) + 1).toString().padStart(2, 0)
        : (Number(new Date(_date).getUTCMonth()) + 1).toString();
    let date =
      new Date(_date).getUTCDate().toString().length < 2
        ? new Date(_date).getUTCDate().toString().padStart(2, 0)
        : new Date(_date).getUTCDate().toString();
    let final = date + "-" + month + "-" + year;
    console.log(final);
    return final;
  };
  return (
    <div className="bg-gray-900">
      <BackArrowNavigation />

      <div className="pt-10">
        {adsFilter && <BannerSlider ads={adsFilter} />}
        <div>

          <>
            <div className="flex justify-center">
              <div className=" container flex flex-col lg:flex-row px-2 lg:px-20 gap-10 my-5">
                
                <div className="lg:w-[30%] w-full">
                  <h1 className="text-gray-500 my-3 font-serif">Home / Star / <span className="text-white">{datas.name}</span></h1>
                  {datas.profilePictureUrls != null ? <img className="w-full h-[530px] object-cover object-top" src={'https://storage.googleapis.com/cinimini-console/celebrities/' + datas.id + '/' + (JSON.parse(datas.profilePictureUrls)[0])} /> : null}
                  <div className="w-full p-5 bg-[#131721] mt-5 rounded-sm">
                    {/* <h1 className="text-white text-2xl">Known For</h1>
                    <div className="border-b-[1px] border-b-gray-700 my-2" />
                    <div className="flex flex-row items-center">
                      {datas.categoryId != null && JSON.parse(datas.categoryId).map((item, index) =>
                        <h3 key={index} className="text-gray-400 text-md">{FindCategory(item)} </h3>
                      )}
                    </div> */}
                    <div className=' w-full '>
                      <div className=' space-y-6  rounded-md '>
                        <p className='flex text-white flex-row hover:cursor-default w-[100%] justify-between gap-2'>Birth Of Date: <h5 className='text-white text-left w-[50%] text-sm lg:text-base font-normal '>{returnTheReleaseDate(JSON.parse(datas.personalInformation).born)}</h5> </p>
                        <p className='flex text-white flex-row hover:cursor-default w-[100%] justify-between gap-2'>AlmaMater: <h5 className='text-white text-left w-[50%] text-sm lg:text-base font-normal '>{JSON.parse(datas.personalInformation).alm}</h5></p>
                        <p className='flex text-white flex-row hover:cursor-default w-[100%] break-all justify-between gap-2'>Occupation: <h5 className='text-white  text-left w-[50%] text-sm lg:text-base font-normal '>{JSON.parse(datas.personalInformation).occ}</h5></p>
                        <p className='flex text-white flex-row hover:cursor-default w-[100%] justify-between gap-2'>Active Year: <h5 className='text-white text-left w-[50%] text-sm lg:text-base font-normal '>{JSON.parse(datas.personalInformation).yea}</h5></p>
                        {JSON.parse(datas.movieIds).length !== 0 ? <p className='flex flex-row text-white hover:cursor-default w-[100%] justify-between gap-2'>Movie Count: <h5 className='text-white text-left w-[50%] text-sm lg:text-base font-normal '>{JSON.parse(datas.movieIds).length}</h5></p> : null}
                        <p className='flex text-white flex-row hover:cursor-default w-[100%] justify-between gap-2'>Works: <h5 className='text-white text-left w-[50%] break-all text-sm lg:text-base font-normal '>{JSON.parse(datas.personalInformation).wor}</h5></p>
                        <p className='flex text-white flex-row hover:cursor-default w-[100%] justify-between gap-2'>Spouse: <h5 className='text-white text-left w-[50%] break-all text-sm lg:text-base font-normal '>{JSON.parse(datas.personalInformation).spo}</h5></p>
                        <p className='flex text-white flex-row hover:cursor-default w-[100%] justify-between gap-2'>Childran: <h5 className='text-white text-left w-[50%] text-sm lg:text-base font-normal '>{JSON.parse(datas.personalInformation).chi}</h5></p>
                        <p className='flex text-white flex-row hover:cursor-default w-[100%] justify-between gap-2'>Family: <h5 className='text-white text-left w-[50%] text-sm lg:text-base font-normal '>{JSON.parse(datas.personalInformation).fam}</h5></p>
                        <p className='flex text-white flex-row hover:cursor-default w-[100%] justify-between gap-2 '>facebook:  <a className='w-[50%] ' href={JSON.parse(datas.personalInformation).fac}><label className=' text-[#24baef]  font-normal text-sm lg:text-base'>See {datas.name} facebook page</label></a></p>
                        {JSON.parse(datas.personalInformation).ins != '-' ? <p className='flex flex-row text-white hover:cursor-default justify-between w-[100%] gap-2'>Instagram:  <a className='w-[50%] ' href={JSON.parse(datas.personalInformation).ins}><label className=' text-[#24baef] w-[50%] font-normal text-sm lg:text-base'>See {datas.name} Instagram page</label></a></p> : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" lg:w-[70%] w-full">
                  <h3 className="text-white font-bold mt-10 text-3xl">{datas.name}</h3>
                  <p className="text-gray-400 mt-5">{datas.description}</p>
                  <div className="w-full lg:h-[450px] h-[250px] mt-5">
                    <ReactPlayer
                      width="100%"
                      height='100%'
                      url={`https://www.youtube.com/watch?v=${JSON.parse(datas.personalInformation).you}`}
                      loop={true}
                      muted={false}
                      // light={true}
                      controls={true}
                    />
                  </div>
                  {datas.bannerPictureUrls != null ?
                    <div className="flex flex-col my-10 w-full">
                      <h3 className="text-white font-bold text-xl mb-5">Gallery Images</h3>
                      <div className="flex flex-row items-center overflow-x-auto gap-5 w-full">
                        {JSON.parse(datas.bannerPictureUrls).map((e, index) =>
                          <img key={index}
                            className="h-[300px] w-[200px] object-cover rounded-lg"
                            src={'https://storage.googleapis.com/cinimini-console/celebrities/' + datas.id + '/' + e}
                            alt=''
                          />
                          
                        )}
                      </div>
                    </div>
                    : null
                  }
                </div>
              </div>
            </div>
          </>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export { CelebrityDetailsTemplate }